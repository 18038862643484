import React, { useEffect, useMemo, useState } from "react";
import { PrimaryButton, SecondaryButton } from "../../shared/button";
import type {
  FieldState,
  FormFieldInput,
  FormFieldProps,
  FormFieldSelect
} from "../form-field";
import { EMAIL_REGEX } from "../../accounts/email_regex";
import { toast } from "react-toastify";
import { toasterOptions } from "../../../state/errors/errors.epic";
import { IntegrationHeader } from "../integration-header";
import {
  areClientCredentialsRequired,
  saveConnection,
  saveConnectionSecret,
  testConnection
} from "../../../api/jira";
import {
  Connection,
  InstanceType,
  OAuthVersion
} from "../../../state/jira/types";
import { v4 as uuid } from "uuid";
import { pendingTokenRefresh } from "../../../api/auth";
import { isValidUrl } from "../../../helpers/isValidUrl";
import { IntegrationConnectionForm } from "../integration-connection-form";
import { trimIntegrationUrl } from "../../../helpers/trimIntegrationUrl";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { jiraActions } from "../../../state/jira";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToast,
  showSuccessToast
} from "../../../helpers/show.toast.helpers";
import { showJiraCloudOAuth2 } from "../../../state/featureFlags/selectors";

const initialFieldsState = (value: string | undefined): FieldState => ({
  value: value ?? "",
  touched: false,
  error: false
});

export type NewConnectionFormProps = {
  type: InstanceType;
  onCancel: () => void;
};

export type ExistingConnectionFormProps = {
  originalConnection: Connection;
  onCancel: () => void;
};

export type FormProps = NewConnectionFormProps | ExistingConnectionFormProps;

const isNewConnectionProps = (p: FormProps): p is NewConnectionFormProps =>
  "type" in p;

const unpackProps = (props: FormProps) => {
  if (isNewConnectionProps(props))
    return {
      originalConnection: undefined,
      jiraType: props.type,
      onCancel: props.onCancel
    };
  else
    return {
      originalConnection: props.originalConnection,
      jiraType: props.originalConnection.type,
      onCancel: props.onCancel
    };
};

export const JiraConnectionForm: React.FC<FormProps> = (props): JSX.Element => {
  const { originalConnection, jiraType, onCancel } = unpackProps(props);
  const dispatch = useDispatch();
  const showJiraCloudOAuth2Flag = useSelector(showJiraCloudOAuth2);

  const isOAuth2Available: boolean =
    jiraType === InstanceType.Server || showJiraCloudOAuth2Flag;

  const [connectionName, setConnectionName] = useState(
    initialFieldsState(originalConnection?.name)
  );
  const [url, setUrl] = useState(
    initialFieldsState(originalConnection?.jiraUrl)
  );
  const [userId, setUserId] = useState(
    initialFieldsState(originalConnection?.user)
  );
  const [secret, setSecret] = useState(initialFieldsState(""));
  const [oAuthVersion, setOAuthVersion] = useState<FieldState>(() =>
    getDefaultOAuthVersion(originalConnection, isOAuth2Available)
  );
  const [clientId, setClientId] = useState(
    initialFieldsState(originalConnection?.clientId)
  );
  const [clientSecret, setClientSecret] = useState(initialFieldsState(""));
  const [send, setSend] = useState(false);

  const tApp = useI18n();
  const t = useI18n("INTEGRATIONS.");
  const tJira = useI18n("JIRA.");

  const title = useMemo(() => {
    return !originalConnection
      ? t("SET_TITLE", "Jira")
      : t("UPDATE_INTEGRATION", "Jira", originalConnection.name);
  }, [originalConnection, t]);

  const getLabelForSecret = (): string => {
    if (jiraType === InstanceType.Server) {
      return tJira("PASSWORD");
    }

    return t("TOKEN", "Jira");
  };

  const getPlaceholderForSecret = (): string => {
    return jiraType === InstanceType.Server
      ? tJira("PASSWORD_PLACEHOLDER")
      : t("TOKEN_PLACEHOLDER", "Jira");
  };

  const isClientDataNeeded = areClientCredentialsRequired(
    jiraType,
    oAuthVersion.value
  );

  const oAuthSelector: FormFieldSelect = isOAuth2Available
    ? {
        id: "oAuth",
        type: "select",
        label: tJira("OAUTH_VERSION"),
        data: oAuthVersion,
        changeHandler: setOAuthVersion,
        readonly: send || !!originalConnection,
        options: [
          { label: tJira(OAuthVersion.OAuth1), value: OAuthVersion.OAuth1 },
          { label: tJira(OAuthVersion.OAuth2), value: OAuthVersion.OAuth2 }
        ]
      }
    : {
        id: "oAuth",
        type: "select",
        label: tJira("OAUTH_VERSION"),
        data: oAuthVersion,
        changeHandler: () => {},
        readonly: true,
        options: [
          { label: tJira(OAuthVersion.OAuth1), value: OAuthVersion.OAuth1 }
        ]
      };

  const clientCredentials: FormFieldInput[] = isClientDataNeeded
    ? [
        {
          id: "clientId",
          type: "text",
          label: tJira("CLIENT_ID"),
          placeholder: tJira("CLIENT_ID_PLACEHOLDER"),
          data: clientId,
          changeHandler: setClientId,
          readonly: send
        },
        {
          id: "clientSecret",
          type: "password",
          label: tJira("CLIENT_SECRET"),
          placeholder: tJira("CLIENT_SECRET_PLACEHOLDER"),
          data: clientSecret,
          changeHandler: setClientSecret,
          readonly: send
        }
      ]
    : [];

  const fields: FormFieldProps[] = [
    {
      id: "connectionName",
      type: "text",
      label: t("CONNECTION_NAME"),
      placeholder: t("CONNECTION_NAME_PLACEHOLDER", "Jira"),
      data: connectionName,
      changeHandler: setConnectionName,
      required: false,
      readonly: send
    },
    {
      id: "url",
      type: "text",
      label: t("URL", "Jira"),
      placeholder: tJira("URL_PLACEHOLDER", jiraType),
      data: url,
      changeHandler: setUrl,
      errorMessage: t("URL_ERROR"),
      readonly: send
    },
    oAuthSelector,
    ...clientCredentials,
    {
      id: "userId",
      type: "text",
      label: tJira("USER"),
      placeholder: tJira(
        "USER_PLACEHOLDER",
        jiraType === InstanceType.Cloud ? "E-mail" : "Login"
      ),
      data: userId,
      changeHandler: setUserId,
      errorMessage: tJira("USER_ERROR"),
      readonly: send
    },
    {
      id: "secret",
      type: "password",
      label: getLabelForSecret(),
      placeholder: getPlaceholderForSecret(),
      data: secret,
      changeHandler: setSecret,
      readonly: send
    }
  ];

  // userId validation - if Jira cloud we check if it is an email, if Jira server we just check if it exists
  useEffect(() => {
    if (userId.touched) {
      setUserId(userId => ({
        ...userId,
        error:
          !userId.value.trim() ||
          (jiraType === InstanceType.Cloud &&
            !!userId.value &&
            !userId.value.trim().match(EMAIL_REGEX))
      }));
    }
  }, [userId.value, userId.touched, jiraType]);

  // url validation
  useEffect(() => {
    if (url.touched) {
      setUrl(url => ({
        ...url,
        error: !!url.value.trim() && !isValidUrl(url.value)
      }));
    }
  }, [url.value, url.touched]);

  // secret (token or password) validation (only check if exists)
  useEffect(() => {
    if (secret.touched) {
      setSecret(url => ({
        ...url,
        error: !secret.value.trim()
      }));
    }
  }, [secret.value, secret.touched]);

  useEffect(() => {
    if (clientSecret.touched) {
      setClientSecret(value => ({
        ...value,
        error: !clientSecret.value.trim()
      }));
    }
  }, [clientSecret.value, clientSecret.touched]);

  const getDefaultJiraConnectionName = (connectionType: InstanceType) => {
    return connectionType === InstanceType.Cloud ? "Jira Cloud" : "Jira Server";
  };

  const isNotReadyForSubmit = (): boolean => {
    const omitted = ["connectionName"];
    return fields
      .filter(field => !omitted.includes(field.id))
      .some(field => !field.data.value || field.data.error);
  };

  const createConnection = (): Connection => {
    if (!jiraType) throw new Error("Jira type is not set");

    const connection: Connection = {
      name: connectionName.value.trim()
        ? connectionName.value.trim()
        : getDefaultJiraConnectionName(jiraType),
      type: jiraType,
      jiraUrl: trimIntegrationUrl(url.value),
      user: userId.value.trim(),
      oAuthVersion: oAuthVersion.value as OAuthVersion,
      clientId: isClientDataNeeded ? clientId.value : undefined
    };

    // syncing send value with the component state
    if (!connectionName.value.trim()) {
      setConnectionName({
        ...connectionName,
        value: getDefaultJiraConnectionName(jiraType)
      });
    }

    return connection;
  };

  const handleConfirmClick = () => {
    setSend(true);
    const connection = createConnection();

    testConnection({
      apiToken: secret.value,
      userId: connection.user,
      jiraUrl: connection.jiraUrl,
      type: connection.type
    })
      .then(resp => {
        if (resp.error) {
          throw resp.error;
        }
        showSuccessToast(t("TEST_SUCCESS"));
        save(connection);
      })
      .catch(er => {
        if (!pendingTokenRefresh) {
          console.error(er);
          showErrorToast(t("TEST_ERROR"), er);
          setSend(false);
        }
      });
  };

  const save = (connection: Connection) => {
    const id = originalConnection?.settingName ?? `connection-${uuid()}`;

    const connectionPromises = [
      saveConnection(connection, id),
      saveConnectionSecret(secret.value.trim(), id)
    ];
    if (isClientDataNeeded) {
      connectionPromises.push(
        saveConnectionSecret(clientSecret.value.trim(), `${id}-oauth2`)
      );
    }

    Promise.all(connectionPromises)
      .then(results => {
        const errors = results.filter(result => result.error);
        if (errors.length === 0) {
          setSend(true);
          showSuccessToast(t("SAVE_SUCCESS"));
        } else {
          if (!pendingTokenRefresh) {
            // in case of some error usually we will see both requests failing due to the same reason so no need to show all errors
            toast.error(errors[0].error, toasterOptions);
          }
        }
      })
      .catch(er => console.error(er))
      .finally(() => dispatch(jiraActions.getJiraConnectionsAsync.request()));
  };

  return (
    <>
      <IntegrationHeader text={title} />
      <IntegrationConnectionForm fields={fields} />
      <div className={"mt-6 mb-8 inline-flex gap-4"}>
        <SecondaryButton onClick={onCancel} disabled={false}>
          {tApp("CANCEL")}
        </SecondaryButton>
        <PrimaryButton
          onClick={handleConfirmClick}
          disabled={isNotReadyForSubmit() || send}
        >
          {tApp("CONFIRM")}
        </PrimaryButton>
      </div>
    </>
  );
};

function getDefaultOAuthVersion(
  connection: Connection | undefined,
  isOAuth2Available: boolean
): FieldState {
  const defaultOAuthVersion = isOAuth2Available
    ? OAuthVersion.OAuth2
    : OAuthVersion.OAuth1;
  const oAuth = connection?.oAuthVersion ?? defaultOAuthVersion;
  return {
    value: oAuth,
    touched: false,
    error: false
  };
}
