import { Modal } from "../modals/modal";
import { ModalHeader } from "../modals/modalElements/modal.header";
import React, { useContext, useState } from "react";
import { AppContext, ManagementPortalContext } from "../../context";
import { ImportAccounts } from "./import.accounts";
import { AddAccounts } from "./add.accounts";
import { AccountData } from "./account.entry";
import { EmailCorrection } from "./email.correction";
import { EmailData } from "./email.entry";

type AddAccountsProps = {
  initAccounts?: AccountData[];
  initialQuarantine?: AccountData[];
  onClose?: () => void;
};

export const AddAccountModal = (props: AddAccountsProps): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);

  const [accounts, setAccounts] = useState<Record<string, AccountData>>(() => {
    const initAccounts: Record<string, AccountData> = {};
    for (const account of props.initAccounts ?? []) {
      initAccounts[account.email] = account;
    }
    return initAccounts;
  });
  const [quarantine, setQuarantine] = useState<AccountData[]>(
    props.initialQuarantine || []
  );

  const onContinue = (emails: EmailData[]) => {
    const newAccounts = { ...accounts };
    for (const emailData of emails.filter(e => e.selected)) {
      const originalRecord = quarantine.find(a => a.id === emailData.id);
      if (originalRecord)
        newAccounts[emailData.email] = {
          ...originalRecord,
          email: emailData.email
        };
    }
    setAccounts(newAccounts);
    setQuarantine([]);
  };

  const renderContent = () => {
    if (Object.entries(accounts).length === 0) {
      return (
        <ImportAccounts
          setAccounts={setAccounts}
          setQuarantine={setQuarantine}
        />
      );
    }
    if (quarantine.length !== 0) {
      return (
        <EmailCorrection initialEmails={quarantine} onContinue={onContinue} />
      );
    }
    return <AddAccounts accounts={accounts} onClose={props.onClose} />;
  };

  return (
    <Modal>
      <ModalHeader
        modalTitle={context.Localized.string("ADD_ACCOUNTS.TITLE")}
        mainIcon={() => <></>}
        closeModal={props.onClose}
      />
      {renderContent()}
    </Modal>
  );
};
