const globalHeaders: Headers = new Headers();

export const set = (key: string, value: string): void => {
  globalHeaders.set(key, value);
};

export const remove = (key: string): void => {
  globalHeaders.delete(key);
};

export const authorization = (value: string | null): void => {
  value ? set("Authorization", value) : remove("Authorization");
};

export const contentType = (value: string | null): void => {
  value ? set("Content-Type", value) : remove("Content-Type");
};
window.globalHeaders = globalHeaders;

declare global {
  interface Window {
    globalHeaders: Headers;
  }
}

export default globalHeaders;
