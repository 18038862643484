import React, { useRef, useState } from "react";
import { useI18n } from "../../helpers/hooks/useI18n";
import { PrimaryButton } from "../shared/button";
import { saveToClipboard, sendEmail } from "../../helpers/share.dialog.helpers";

type CopyEmailLinkProps = {
  workspaceName?: string;
  shareLink?: string;
};

export const CopyEmailLink: React.FC<CopyEmailLinkProps> = ({
  workspaceName,
  shareLink
}) => {
  const t = useI18n("SHARE_WORKSPACE.COPY_EMAIL_LINK.");
  const [copyClicked, setCopyClicked] = useState(false);
  const shareLinkRef = useRef<HTMLSpanElement>(null);

  const isSmall = window.innerWidth < 760;

  let copyText = isSmall
    ? copyClicked
      ? t("SMALL_COPIED_LINK")
      : t("SMALL_COPY_LINK")
    : copyClicked
    ? t("COPIED_LINK")
    : t("COPY_LINK");
  let emailText = isSmall ? t("SMALL_EMAIL") : t("EMAIL");

  return (
    <div className="flex justify-between items-center">
      {shareLink && (
        <div className={"truncate appearance-none focus:shadow-outline w-80"}>
          <span className={"text-sm"} ref={shareLinkRef}>
            {shareLink.replace("https://", "")}
          </span>
        </div>
      )}
      <div className={`flex justify-end gap-2 ml-auto`}>
        <PrimaryButton
          title={t("COPY_WORKSPACE_LINK")}
          onClick={() => {
            saveToClipboard(shareLink);
            setCopyClicked(true);
          }}
          disabled={!shareLink}
        >
          {copyText}
        </PrimaryButton>
        <PrimaryButton
          title={t("EMAIL_WORKSPACE_LINK")}
          onClick={() =>
            workspaceName && shareLink && sendEmail(workspaceName, shareLink, t)
          }
          disabled={!workspaceName || !shareLink}
        >
          {emailText}
        </PrimaryButton>
      </div>
    </div>
  );
};
