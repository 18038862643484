import { PrimaryButton } from "../shared/button";
import { AppContext, ManagementPortalContext } from "../../context";
import React, { useContext, useState } from "react";
import { IntegrationHeader } from "./integration-header";

type EulaAgreementProps = {
  serviceName: string;
  onAcceptance: () => void;
};

export const EulaAgreement = (props: EulaAgreementProps) => {
  const context: ManagementPortalContext = useContext(AppContext);
  const [isEulaAccepted, setIsEulaAccepted] = useState(false);

  return (
    <div>
      <IntegrationHeader
        text={context.Localized.string(
          "INTEGRATIONS.SET_TITLE",
          props.serviceName
        )}
      />
      <div className="pb-4 flex justify-start content-center">
        <input
          className="self-center"
          id="eula_agree"
          type="checkbox"
          checked={isEulaAccepted}
          onChange={() => setIsEulaAccepted(!isEulaAccepted)}
        />
        <label
          htmlFor="eula_agree"
          className="ml-4 flex flex-col justify-start"
        >
          {context.Localized.string("INTEGRATIONS.EULA", props.serviceName)}
        </label>
      </div>
      <PrimaryButton disabled={!isEulaAccepted} onClick={props.onAcceptance}>
        {context.Localized.string("ACCEPT_AND_CONTINUE")}
      </PrimaryButton>
    </div>
  );
};
