import { AppContext, ManagementPortalContext } from "../../context";
import React, { CSSProperties, useContext, useState } from "react";
import { DropdownPicker } from "../dropdown.picker";
import { randomPick } from "../../helpers/random.pick";

export const DEFAULT_DROPDOWN_PICKER_COLORS = [
  "#58f7a6",
  "#ff7bab",
  "#cc7cff",
  "#8c6e63",

  "#01a89c",
  "#e76161",
  "#9474cc",
  "#c59b6d",

  "#6eae3d",
  "#ff7b54",
  "#4c70c5",
  "#778f9b",

  "#b8e942",
  "#fbaf3d",
  "#5b9de7",
  "#b2b2b2",

  "#ffff5c",
  "#f9cf28",
  "#77e6e9",
  "#dfdfdf"
];

export type DropdownColorPickerProps = {
  pickedColors: ReadonlySet<string>;
  onColorPick: (color: string) => void;
  initial?: string;
  colors?: string[];
};

/**
 * DropdownColorPicker - use it to pick a color from given palette.
 * - colors { string[] } - array of colors in HEX format,
 * - pickedColors { Set<string> } - this is used to indicate which colors are taken,
 * - onColorPick { (color: string) => void } - a callback which will be called on color click.
 * */
export const DropdownColorPicker: React.FC<DropdownColorPickerProps> = ({
  pickedColors,
  onColorPick,
  initial,
  colors = DEFAULT_DROPDOWN_PICKER_COLORS
}) => {
  const context: ManagementPortalContext = useContext(AppContext);
  const [currentColor, setCurrentColor] = useState(
    initial ?? pickInitialColor(colors, pickedColors)
  );

  return (
    <DropdownPicker label={context.Localized.string("COLOR")}>
      {{
        picked: <PreviewColorsEntry color={currentColor} />,
        dropdown: (
          <ColorsDropdown
            colors={colors}
            pickedColors={pickedColors}
            currentColor={currentColor}
            onColorPick={(color: string) => {
              setCurrentColor(color);
              onColorPick(color);
            }}
          />
        )
      }}
    </DropdownPicker>
  );
};

type ColorsDropdownProps = {
  colors: string[];
  pickedColors: ReadonlySet<string>;
  currentColor?: string;
  onColorPick: (color: string) => void;
};

/**
 * ColorsDropdown - this will generate dropdown with given colors as a grid of 4 colors per row.
 * - colors { string[] } - array of colors in HEX format,
 * - pickedColors { Set<string> } - this is used to indicate which colors are taken,
 * - onColorPick { (color: string) => void } - a callback which will be called on color click,
 * - currentColor { string | undefined } - it is needed here to indicate current color.
 * */
export const ColorsDropdown: React.FC<ColorsDropdownProps> = ({
  colors,
  pickedColors,
  currentColor,
  onColorPick
}) => {
  return (
    <div
      data-test-id={"dropdown-color-picker-dropdown"}
      className={
        "absolute grid grid-cols-4 bg-white left-0 top-full mt-1 rounded w-max overflow-hidden"
      }
    >
      {colors.map(color => {
        return (
          <ColorsEntry
            key={color}
            color={color}
            onColorPick={onColorPick}
            isThisCurrentColor={color === currentColor}
            isPicked={pickedColors.has(color)}
          />
        );
      })}
    </div>
  );
};

type ColorsEntryProps = {
  color: string;
  isThisCurrentColor: boolean;
  isPicked: boolean;
  onColorPick?: (color: string) => void;
};

/**
 * ColorsEntry - a cell of the ColorsDropdown. By default it will render square like icon filled with color.
 * - color { string } - single color in HEX format,
 * - isThisCurrentColor - true will override other icons. When set to true, default icon will be replaced by square
 * one with checkmark,
 * - isPicked - when set to true, default icon will be replaced by square one with dot,
 * - onColorPick { (color: string) => void } - a callback which will be called on color click.
 * */
export const ColorsEntry: React.FC<ColorsEntryProps> = ({
  color,
  onColorPick,
  isPicked,
  isThisCurrentColor
}): JSX.Element => {
  const style: CSSProperties = {
    color: color
  };
  let icon = isPicked ? "hicon dice0 dot" : "hicon dice0";
  if (isThisCurrentColor) {
    icon = `hicon dice0 checkmark bg-colorTableHeadPortalBackground`;
  }
  const className = `leading-none p-1 hover:bg-colorTableHeadPortalBackground ${icon}`;
  return (
    <div
      data-test-id={color}
      className={className}
      style={style}
      onClick={() => onColorPick && onColorPick(color)}
    ></div>
  );
};

type PreviewColorsEntryProps = {
  color: string;
};

const PreviewColorsEntry: React.FC<PreviewColorsEntryProps> = ({ color }) => {
  const style: CSSProperties = {
    color: color
  };
  return (
    <div
      className={"leading-none hicon dice0"}
      data-test-id={"dropdown-picker-color-preview"}
      style={style}
    ></div>
  );
};

function pickInitialColor(colors: string[], takenColors: ReadonlySet<string>) {
  const freeColors = getFreeColors(colors, takenColors);
  // Pick initial color by choosing randomly between colors which are not taken.
  return freeColors.length > 0 ? randomPick(freeColors) : randomPick(colors);
}

/**
 * getFreeColors - This will return all of the colors which are not in takenColors.
 * - colors { string[] } - array of colors which will be filtered,
 * - takenColors { Set<string> } - set of colors to filter.
 * @returns: string[] - filtered array, new object.
 * */
const getFreeColors = (
  colors: string[],
  takenColors: ReadonlySet<string>
): string[] => {
  return colors.filter(c => !takenColors.has(c));
};
