import { useSelector } from "react-redux";
import { sessionSelectors } from "../../../state/session";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { FullPageTile } from "../../tile";

export const OrganizationLicense: React.FC = () => {
  const t = useI18n("BILLING.ORGANIZATION.");
  const userOrganization = useSelector(sessionSelectors.userOrganization);

  return (
    <FullPageTile>
      {t("YOUR_HOYLU_ACCESS_IS_MANAGED_BY", userOrganization?.name)}
    </FullPageTile>
  );
};
