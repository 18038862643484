import { createReducer } from "typesafe-actions";
import { Connection, JiraState } from "./types";
import { getJiraConnectionsAsync } from "./actions";

export const jiraInitialState: JiraState = {
  loading: false,
  error: false,
  payload: [] as Connection[]
};

export default createReducer(jiraInitialState)
  .handleAction(getJiraConnectionsAsync.request, (state, action) => ({
    ...state,
    loading: true,
    error: false
  }))
  .handleAction(getJiraConnectionsAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: true
  }))
  .handleAction(getJiraConnectionsAsync.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    payload: action.payload
  }));
