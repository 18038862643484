import { Localized } from "../../context/Localized";
import { Project } from "../../state/projects/types";
import Papa from "papaparse";
import { printNiceDayDate } from "../../helpers/print.nice.day.date";
import { downloadCsv } from "../../helpers/download.csv";
import { getCollaborators } from "../../api/projects";
import { showError } from "../../helpers/show.toast.helpers";
import { PROJECT_ADMIN_ID } from "../../api/collaborators.roles.ids";

type CollaboratorsListForExport = {
  [key: string]: string;
};

export const prepareProjectsCSV = async (
  localized: Localized,
  projects: Project[]
): Promise<void> => {
  const t = (address: string) => localized.string("PROJECTS." + address);

  const csvKeys = [
    t("NAME"),
    t("DESCRIPTION"),
    t("STATUS"),
    t("DURATION"),
    t("ADMIN_ACCESS"),
    t("BASIC_ACCESS")
  ];
  const notStarted = t("NOT_STARTED");
  const na = t("NA");

  const { admins, otherCollaborators } = await prepareCollaborators(projects);

  const toArray = (project: Project) => {
    return [
      project.name,
      project.description,
      project.status,
      `${printNiceDayDate(project.startDate, notStarted)} - ${printNiceDayDate(
        project.endDate,
        na
      )}`,
      admins[project.id],
      otherCollaborators[project.id]
    ];
  };

  const csvContent: string = Papa.unparse({
    fields: csvKeys,
    data: projects.map(toArray)
  });

  downloadCsv(csvContent, "projects.csv");
};

const prepareCollaborators = async (
  projects: Project[]
): Promise<{
  admins: CollaboratorsListForExport;
  otherCollaborators: CollaboratorsListForExport;
}> => {
  let otherCollaborators: CollaboratorsListForExport = {};
  let admins: CollaboratorsListForExport = {};

  for (let project of projects) {
    const collaboratorsResponse = await getCollaborators(project.id);

    if (
      collaboratorsResponse.data &&
      Array.isArray(collaboratorsResponse.data)
    ) {
      const projectCollaborators = collaboratorsResponse.data;
      let adminEmails: string[] = [];
      let collaboratorsEmails: string[] = [];

      projectCollaborators.forEach(collaborator => {
        if (collaborator.roleId === PROJECT_ADMIN_ID) {
          adminEmails.push(collaborator.email);
        } else {
          collaboratorsEmails.push(collaborator.email);
        }
      });

      otherCollaborators[project.id] = collaboratorsEmails.join(" ");
      admins[project.id] = adminEmails.join("");
    } else {
      const errorMessage = `Couldn't fetch collaborators of project ${project.name} for csv export. ${collaboratorsResponse.error}`;
      console.error(errorMessage);
      showError(errorMessage);
    }
  }

  return { admins, otherCollaborators };
};
