import { useSelector } from "react-redux";
import { sessionSelectors } from "../../state/session";
import { NavBar } from "../navbar/navbar";
import { useContext } from "react";
import { AppContext } from "../../context";
import { projectsSelectors } from "../../state/projects";

export const ManagementPanel = (): JSX.Element => {
  const context = useContext(AppContext);
  const isAdmin = useSelector(sessionSelectors.isAdmin);
  const isProjectAdmin = useSelector(projectsSelectors.isProjectAdmin);

  return (
    <div className="flex items-start justify-center flex-1">
      <div className="container h-full flex flex-col justify-start">
        {!(isAdmin || isProjectAdmin) && (
          <div className="pt-20 text-center">
            {context.Localized.string("NOT_ADMIN")}
          </div>
        )}
        {(isAdmin || isProjectAdmin) && <NavBar />}
      </div>
    </div>
  );
};
