import React from "react";

export const ConnectionDetailsList = ({ details }) => {
  return (
    <ul className="mb-4">
      {details.map(detail => (
        <li key={detail.title}>
          <span className="w-80 pr-10 inline-block text-lg font-bold mb-2">
            {detail.title}
          </span>
          <span>{detail.value}</span>
        </li>
      ))}
    </ul>
  );
};
