import { RootState } from "typesafe-actions";
import { createSelector } from "reselect";
import { isAllowedADIntegration } from "../organization/selectors";

export const showExplicitExternalAccessPolicy = (state: RootState): boolean => {
  return state.featureFlags.showExplicitExternalAccessPolicy;
};

export const useJiraIntegration = (state: RootState): boolean => {
  return state.featureFlags.useJiraIntegration;
};

export const useAgilityIntegration = (state: RootState): boolean => {
  return state.featureFlags.useAgilityIntegration;
};

export const orgPolicyOnlyOrgAdminsCanCreateWorkspace = (
  state: RootState
): boolean => {
  return state.featureFlags.orgPolicyOnlyOrgAdminsCanCreateWorkspace;
};

export const useTwoFactorAuth = (state: RootState): boolean => {
  return state.featureFlags.useTwoFactorAuth;
};

// it should return true if any integration presented in the Integration tab is true
export const showIntegrations: (state: RootState) => boolean = createSelector(
  useJiraIntegration,
  useAgilityIntegration,
  isAllowedADIntegration,
  (useJiraIntegration, useAgilityIntegration, isAllowedADIntegration) =>
    useJiraIntegration || isAllowedADIntegration || useAgilityIntegration
);

export const showProjects = (state: RootState): boolean => {
  return state.featureFlags.showProjects;
};

export const showWorkspaces = (state: RootState): boolean => {
  return state.featureFlags.showWorkspaces;
};

export const showStripePurchase = (state: RootState): boolean => {
  return state.featureFlags.showStripePurchase;
};

export const showJiraCloudOAuth2 = (state: RootState): boolean => {
  return state.featureFlags.showJiraCloudOAuth2;
};
