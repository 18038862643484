import { RootState } from "typesafe-actions";
import { License, LicenseInfo } from "./types";

const checkIfModuleIsAllowed = (
  state: RootState,
  moduleName: string
): boolean => {
  const license = licenseInfo(state);
  return license ? license.allowedPremiumModules.includes(moduleName) : false;
};

export const availableLicenses = (state: RootState): License[] =>
  state.licenses.availableLicenses.payload ?? [];
export const loading = (state: RootState): boolean =>
  state.licenses.availableLicenses.loading;
export const error = (state: RootState): boolean =>
  state.licenses.availableLicenses.error;
export const isAllowedToUseJiraModule = (state: RootState): boolean => {
  return checkIfModuleIsAllowed(state, "Jira Integration");
};
export const isAllowedToUseAgilityModule = (state: RootState): boolean => {
  return checkIfModuleIsAllowed(state, "Agility Integration");
};

export const licenseInfo = (state: RootState): LicenseInfo | undefined =>
  state.licenses.licenseInfo.payload;
export const selectLicenseInfoLoading = (state: RootState): boolean =>
  state.licenses.licenseInfo.loading;
export const selectLicenseInfoError = (state: RootState): boolean =>
  state.licenses.licenseInfo.error;
