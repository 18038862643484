import React from "react";
import { FormField, FormFieldProps } from "./form-field";

interface IntegrationConnectionFormProps {
  fields: FormFieldProps[];
}

export const IntegrationConnectionForm: React.FC<
  IntegrationConnectionFormProps
> = ({ fields }) => {
  return (
    <form
      className="grid gap-y-4 gap-x-2 items-center break-words mt-6"
      style={{
        gridTemplateColumns:
          "minmax(8rem, auto) minmax(6rem, 1fr) minmax(2rem, .5fr)"
      }}
    >
      {fields.map(field => (
        <FormField key={field.id} {...field} />
      ))}
    </form>
  );
};
