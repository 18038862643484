import { CollaboratorsList } from "./collaborators.list";
import { PrimaryButton, TextButton } from "../../shared/button";
import React, { useState } from "react";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { Project } from "../../../state/projects/types";
import { inviteToHoylu } from "../../../api/users";
import { showError } from "../../../helpers/show.toast.helpers";
import { AddUsers } from "./add.users";
import {
  addUsersToProject,
  UserLimitReachedError
} from "./add.users.to.project";
import { projectsActions } from "../../../state/projects";
import { useDispatch } from "react-redux";

type CollaboratorsProps = {
  project: Project;
  isAddingCollaborators: boolean;
  setIsAddingCollaborators: (val: boolean) => void;
  setShowSaveButtons?: (val: boolean) => void;
};

export const Collaborators = ({
  project,
  isAddingCollaborators,
  setIsAddingCollaborators,
  setShowSaveButtons
}: CollaboratorsProps) => {
  const t = useI18n(`PROJECTS.`);
  const tg = useI18n();
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [failedEmails, setFailedEmails] = useState<string[]>([]);

  const clearFailedEmails = () => {
    setFailedEmails([]);
    setShowSaveButtons && setShowSaveButtons(true);
  };

  const addFailedEmails = (emails: string[]) => {
    setFailedEmails(emails);
    setShowSaveButtons && setShowSaveButtons(false);
  };

  const onInviteFailedEmails = async () => {
    const emails = failedEmails;
    if (emails.length === 0) return;
    try {
      setIsProcessing(true);
      await inviteToHoylu({
        emails: emails.slice(0, 10), // invite endpoint only allows 10 emails at a time
        reason: t("INVITE_REASON", project.name)
      });
      // clear failed emails, because onUserAdd might add it again
      clearFailedEmails();
      // onUserAdd will call finishAddUsers when done, no need to call it again
      await onUsersAdd(emails);
    } catch (e) {
      showError(t("GENERIC_USER_ADD_ERROR"));
      finishAddingUsers();
    }
  };

  const onUsersAdd = async (emails: string[]) => {
    if (emails.length === 0) {
      setIsAddingCollaborators(false);
    }

    if (emails.length > 0) {
      setIsAddingCollaborators(false);
      setIsProcessing(true);

      try {
        const result = await addUsersToProject(project.id, emails);
        addFailedEmails(result.emailsWithoutAccount);
      } catch (e) {
        if (e instanceof UserLimitReachedError) {
          showError(t("USERS_LIMIT"));
        } else {
          showError(t("GENERIC_USER_ADD_ERROR"));
        }
      }
      finishAddingUsers();
    }
  };

  const finishAddingUsers = () => {
    dispatch(projectsActions.getCollaboratorsAsync.request(project.id));
    setIsProcessing(false);
  };

  return (
    <>
      {!isAddingCollaborators ? (
        <>
          <CollaboratorsList
            usersLimit={project.maxProjectUsers}
            projectId={project.id}
            addUsers={setIsAddingCollaborators}
            isProcessing={isProcessing}
          />
          {!!failedEmails.length && (
            <div
              data-test-id="project-editor-invite-emails"
              className="text-sm p-4 text-colorWarning border-2 border-colorWarning rounded mt-4"
            >
              {t("NO_ACCOUNTS_ERROR")}
              <ul className="list-disc ml-8">
                {failedEmails.map(email => (
                  <li key={email}>{email}</li>
                ))}
              </ul>
              <div className="text-right text-colorText">
                <TextButton onClick={clearFailedEmails}>
                  {tg("CANCEL")}
                </TextButton>
                <PrimaryButton
                  data-test-id="project-invite-and-add-button"
                  onClick={onInviteFailedEmails}
                >
                  {t("INVITE_AND_ADD")}
                </PrimaryButton>
              </div>
            </div>
          )}
        </>
      ) : (
        <AddUsers
          projectId={project.id}
          cancel={() => setIsAddingCollaborators(false)}
          apply={onUsersAdd}
        />
      )}
    </>
  );
};
