// Organization types
import { UserData } from "../accounts/types";
import { Group } from "../groups/types";
import { SupportedPoliciesResponse } from "../../api/types";

export const GET_ORGANIZATION_INFO_LOADING =
  "@organization/GET_ORGANIZATION_INFO_LOADING";
export const GET_ORGANIZATION_INFO_ERROR =
  "@organization/GET_ORGANIZATION_INFO_ERROR";
export const GET_ORGANIZATION_INFO_SUCCESS =
  "@organization/GET_ORGANIZATION_INFO_SUCCESS";

export const GET_ORGANIZATION_POLICIES_LOADING =
  "@organization/GET_ORGANIZATION_POLICIES_LOADING";
export const GET_ORGANIZATION_POLICIES_ERROR =
  "@organization/GET_ORGANIZATION_POLICIES_ERROR";
export const GET_ORGANIZATION_POLICIES_SUCCESS =
  "@organization/GET_ORGANIZATION_POLICIES_SUCCESS";

export const SAVE_ORGANIZATION_POLICIES_LOADING =
  "@organization/SAVE_ORGANIZATION_POLICIES_LOADING";
export const SAVE_ORGANIZATION_POLICIES_ERROR =
  "@organization/SAVE_ORGANIZATION_POLICIES_ERROR";
export const SAVE_ORGANIZATION_POLICIES_SUCCESS =
  "@organization/SAVE_ORGANIZATION_POLICIES_SUCCESS";

export const SAVE_ORGANIZATION_DEPARTMENTS_LOADING =
  "@organization/SAVE_ORGANIZATION_DEPARTMENTS_LOADING";
export const SAVE_ORGANIZATION_DEPARTMENTS_ERROR =
  "@organization/SAVE_ORGANIZATION_DEPARTMENTS_ERROR";
export const SAVE_ORGANIZATION_DEPARTMENTS_SUCCESS =
  "@organization/SAVE_ORGANIZATION_DEPARTMENTS_SUCCESS";

export const GET_MEMBERS_SUCCESS = "@organization/GET_MEMBERS_SUCCESS";
export const EDIT_MEMBER_DEPARTMENT = "@organization/EDIT_MEMBER_DEPARTMENT";
export const EDIT_MEMBER_DEPARTMENT_ERROR =
  "@organization/EDIT_MEMBER_DEPARTMENT_ERROR";

export const REMOVE_MEMBERS_LOADING = "@accounts/REMOVE_MEMBERS_LOADING";
export const REMOVE_MEMBERS_SUCCESS = "@accounts/REMOVE_MEMBERS_SUCCESS";
export const REMOVE_MEMBERS_ERROR = "@accounts/REMOVE_MEMBERS_ERROR";

export interface Organization {
  members: UserData[];
  admins: string[];
  agreementDate: number;
  departments: string[];
  domains: string[];
  exclusive: boolean;
  force: boolean;
  hoyluUserGroups: string[];
  hoyluUserGroupDetails: Group[];
  isAllowedADIntegration: boolean;
  loginType: string;
  name: string;
  policies: string[];
  uid: string;
}

export interface Policies {
  active: string[];
  rejected?: { [key: string]: string };
  supported: SupportedPoliciesResponse | null | undefined;
}

export interface Departments {
  [key: number]: string;
}

export interface PoliciesState {
  loading: boolean;
  error: boolean;
  payload: Policies;
}

export interface DepartmentsState {
  loading: boolean;
  error: boolean;
  payload: Departments;
}

export interface OrganizationState {
  loading: boolean;
  error: boolean;
  payload: Organization;
  policies: PoliciesState;
  departments: DepartmentsState;
}
