import React, { useState } from "react";
import { useI18n } from "../../helpers/hooks/useI18n";
import { HeaderPermissions } from "./header.permissions";
import { CopyEmailLink } from "./copy.email.link";
import { ShareDialogPermissionsBar } from "./share.dialog.permission.bar";
import { Icon } from "../shared/icon";
import { Section } from "../shared/sections/section";
import { saveToClipboard, sendEmail } from "../../helpers/share.dialog.helpers";
import { getWorkspaceUrl } from "../../helpers/get.workspace.url";
import { Workspace } from "../../api/types";
import {
  LINK_ACCESS_OPTIONS,
  ShareOptionValue
} from "../shared/permissions/permissions.types";

export type LinkSharingSectionProperties = {
  workspace: Workspace;
  publicPermissionLevel: ShareOptionValue;
  email: string;
  commitUpdate: (sharingOption: ShareOptionValue) => Promise<void>;
  isAdmin: boolean;
  isOpenInitially?: boolean;
};

export const LinkSharingSection: React.FC<LinkSharingSectionProperties> = ({
  workspace,
  publicPermissionLevel,
  email,
  commitUpdate,
  isAdmin,
  isOpenInitially = false
}) => {
  const t = useI18n("SHARE_WORKSPACE.DIALOG.");
  const tcl = useI18n("SHARE_WORKSPACE.COPY_EMAIL_LINK.");
  const workspaceName = workspace.documentName ?? "";
  const workspaceUrl = getWorkspaceUrl(workspace.documentId);
  const [isSectionCollapsed, setIsSectionCollapsed] = useState(
    !isOpenInitially
  );

  return (
    <Section
      icon={"share"}
      title={t("LINK_SHARING")}
      initiallyCollapsed={isSectionCollapsed}
      dataTestId="share-link"
      externalExpandHandler={setIsSectionCollapsed}
      headerContent={
        <>
          {isSectionCollapsed && (
            <HeaderPermissions
              text={t("ANYONE_WITH_LINK")}
              enabledOption={publicPermissionLevel}
            />
          )}
          {workspaceUrl && (
            <div className={"flex ml-auto"} onClick={e => e.stopPropagation()}>
              <Icon
                icon={"copy"}
                title={workspaceUrl}
                onClick={() => saveToClipboard(workspaceUrl)}
              />
              <Icon
                icon={"email"}
                title={workspaceName}
                onClick={() => sendEmail(workspaceName, workspaceUrl, tcl)}
              />
            </div>
          )}
        </>
      }
    >
      <div className={"flex flex-col gap-2 px-7"}>
        <CopyEmailLink workspaceName={workspaceName} shareLink={workspaceUrl} />
        <ShareDialogPermissionsBar
          currentUser={email}
          text={t("ANYONE_WITH_LINK") + " "}
          commitUpdate={commitUpdate}
          isAdmin={isAdmin}
          enabledOption={publicPermissionLevel}
          options={LINK_ACCESS_OPTIONS}
        />
      </div>
    </Section>
  );
};
