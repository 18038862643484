export type IconProps = {
  icon: string; // name of the icon from icon fonts; look for hoylu-ui-icons
  title?: string;
  color?: string; // hash color
  onClick?: () => void;
  additionalStyles?: string; // tailwind class
};

/*
 *
 * With onClick passed in properties this is clickable icon with cursor pointer.
 * Without onClick passed it is just a visual element.
 *
 *  */
export function Icon({
  icon,
  color,
  title,
  onClick = undefined,
  additionalStyles = ""
}: IconProps) {
  const dynamicStyles = onClick
    ? "cursor-pointer hover:text-colorButtonPrimary"
    : "active:pointer-events-none";

  return (
    <div
      style={{ color: `${color}` }}
      className={`inline-block p-1 hicon ${icon} ${dynamicStyles} ${additionalStyles}`}
      title={title}
      onClick={onClick}
      data-test-id={title ? title + " icon" : "icon-" + icon}
    />
  );
}
