import { ConfigData } from "./api/types";

interface EnvironmentConfig {
  CONFIG_URL: string;
}

declare global {
  interface Window {
    __env: EnvironmentConfig;
  }
}

const environmentConfig: EnvironmentConfig = { ...window.__env };

let sessionConfig: (EnvironmentConfig & ConfigData) | undefined;
try {
  const sessionConfigJson = sessionStorage.getItem("configuration");
  if (sessionConfigJson != null) {
    sessionConfig = JSON.parse(sessionConfigJson);
  }
} catch (e) {}

// todo - I am not sure management portal uses URLSearchParams
let urlConfig: { [key: string]: string | false } = {};
if (typeof URLSearchParams === "function") {
  var urlParams = new URLSearchParams(window.location.search);
  for (var pair of urlParams.entries()) {
    let key = pair[0].toUpperCase();
    let value = pair[1];
    urlConfig[key] = value;
  }
}

let finalconfig: EnvironmentConfig & ConfigData = Object.assign(
  environmentConfig,
  sessionConfig,
  urlConfig
);

for (var key in finalconfig) {
  if (finalconfig[key].toString().toLowerCase() === "false")
    // @ts-ignore
    finalconfig[key] = false;
}

sessionStorage.setItem("configuration", JSON.stringify(finalconfig));

export function addConfiguration(config: any) {
  finalconfig = Object.assign(finalconfig, config);
  sessionStorage.setItem("configuration", JSON.stringify(finalconfig));
}

export default finalconfig;
