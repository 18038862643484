// licenses reducer
import { LicensesState } from "./types";
import { createReducer } from "typesafe-actions";
import {
  getAvailableLicencesAsync,
  getLicenseInfoAsync,
  setLicenseToUserAsync,
  takeLicenseFromUserAsync
} from "./actions";

export const initialLicensesState: LicensesState = {
  availableLicenses: { loading: false, error: false },
  licenseInfo: { loading: false, error: false }
};

export default createReducer(initialLicensesState)
  .handleAction(
    [
      getAvailableLicencesAsync.request,
      setLicenseToUserAsync.request,
      takeLicenseFromUserAsync.request
    ],
    state => ({
      ...state,
      availableLicenses: {
        ...state.availableLicenses,
        loading: true,
        error: false
      }
    })
  )
  .handleAction(
    [
      getAvailableLicencesAsync.failure,
      setLicenseToUserAsync.failure,
      takeLicenseFromUserAsync.failure
    ],
    state => ({
      ...state,
      availableLicenses: {
        ...state.availableLicenses,
        loading: false,
        error: true
      }
    })
  )
  .handleAction(
    [setLicenseToUserAsync.success, takeLicenseFromUserAsync.success],
    state => ({
      ...state,
      availableLicenses: {
        ...state.availableLicenses,
        loading: false,
        error: false
      }
    })
  )
  .handleAction(getAvailableLicencesAsync.success, (state, action) => ({
    ...state,
    availableLicenses: {
      loading: false,
      error: false,
      payload: action.payload
    }
  }))
  .handleAction(getLicenseInfoAsync.request, state => ({
    ...state,
    licenseInfo: {
      ...state.licenseInfo,
      loading: true,
      error: false
    }
  }))
  .handleAction(getLicenseInfoAsync.success, (state, action) => ({
    ...state,
    licenseInfo: {
      loading: false,
      error: false,
      payload: action.payload
    }
  }))
  .handleAction(getLicenseInfoAsync.failure, state => ({
    ...state,
    licenseInfo: {
      loading: false,
      error: true
    }
  }));
