import auth from "@hoylu/login";
import { HoyluAuthResponse, ResponsePromise } from "./types";
import config from "../configuration";
import decodeJWT from "jwt-decode";
import { PartialOrgInfo, SessionData, UserFullPayload } from "../state/session/types";
import handleFetchRequest from "./handle-fetch-request";
import { UserProfile } from "../state/accounts/types";
import { httpMethods } from "./http-methods";
import globalHeaders from "./global-headers";
import { getErrorMessage } from "../state/errors/errors.epic";

export const handleLogin = (): Promise<HoyluAuthResponse> => {
  const AUTH = new auth({
    loginHost: config["serviceConfig"].login,
    google_enabled: false
  });
  return AUTH.login().catch((err: string) => {
    throw new Error(err);
  });
};

export const handleLogout = async (): Promise<HoyluAuthResponse> => {
  const AUTH = new auth({
    loginHost: config["serviceConfig"].login,
    google_enabled: false
  });
  try {
    return await AUTH.logout();
  } catch (err) {
    throw new Error(getErrorMessage(err));
  }
};

export let pendingTokenRefresh = false;
let pendingPromises: any[] = [];

export const getTokenSilent = async (): Promise<HoyluAuthResponse> => {
  if (pendingTokenRefresh) {
    return new Promise(resolve => {
      pendingPromises.push(resolve);
    });
  } else {
    pendingTokenRefresh = true;
    let errorResult: Promise<never> | undefined = undefined;

    try {
      const AUTH = new auth({
        loginHost: config["serviceConfig"].login,
        google_enabled: false
      });

      const token = await AUTH.getTokenSilent();
      pendingTokenRefresh = false;
      pendingPromises.forEach(pending => pending(token));
      pendingPromises = [];
      return token;
    } catch (e) {
      errorResult = Promise.reject(e);
    }

    pendingTokenRefresh = false;
    pendingPromises.forEach(pending => pending(errorResult));
    pendingPromises = [];
    return errorResult;
  }
};

export const getUserProfile = (
  userId: string
): ResponsePromise<UserProfile> => {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].auth}/userProfile/${userId}`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
};

export const getOrgInfo = (
  userId: string
): ResponsePromise<PartialOrgInfo> => {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].auth}/userProfile/${userId}/orgInfo`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
};

export const requestAccountDelete = (
  userId: string
): ResponsePromise => {
  return handleFetchRequest(() =>
    fetch(`${config.serviceConfig.auth}/userProfile/${userId}/requestDelete`, {
      method: httpMethods.PUT,
      headers: globalHeaders,
      body: undefined      
    })
  );
};

export const prepareSessionData = (token: string): SessionData => {
  const claims = decodeJWT<UserFullPayload>(token);
  return {
    sessionToken: token,
    tokenExpiration: claims.exp * 1000,
    orgId: claims.Permissions.Administer[0],
    userId: claims.userId,
    isPartialAccount: claims.isPartialAccount
  };
};
