// Organization reducer
import { Organization, OrganizationState, Policies } from "./types";
import { createReducer } from "typesafe-actions";
import {
  editMemberDepartmentAsync,
  getOrganizationInfoAsync,
  getOrganizationPoliciesAsync,
  removeMembersAsync,
  saveOrganizationDepartmentsAsync,
  saveOrganizationPoliciesAsync
} from "./actions";
import { logoutAsync } from "../session/actions";

export const initialState: OrganizationState = {
  loading: false,
  error: false,
  payload: {} as Organization,
  policies: {
    loading: false,
    error: false,
    payload: {} as Policies
  },
  departments: {
    loading: false,
    error: false,
    payload: {}
  }
};

export default createReducer(initialState)
  .handleAction(getOrganizationInfoAsync.request, (state, action) => ({
    ...state,
    loading: true,
    error: false
  }))
  .handleAction(getOrganizationInfoAsync.success, (state, action) => {
    if (action.payload) {
      return {
        ...state,
        loading: false,
        error: false,
        payload: action.payload
      };
    } else {
      return { ...state };
    }
  })
  .handleAction(getOrganizationInfoAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: true
  }))
  .handleAction(editMemberDepartmentAsync.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    payload: {
      ...state.payload,
      members: "payload" in action ? action.payload : []
    }
  }))
  .handleAction(removeMembersAsync.request, (state, action) => ({
    ...state,
    loading: true,
    error: false
  }))
  .handleAction(removeMembersAsync.success, (state, action) => {
    let newPayload = { ...state.payload };
    if (state.payload.members) {
      newPayload.members = newPayload.members.filter(member => {
        return !action.payload.includes(member.uid);
      });
    }
    return { ...state, payload: newPayload, loading: false };
  })
  .handleAction(removeMembersAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: true
  }))
  .handleAction(
    [
      getOrganizationPoliciesAsync.request,
      saveOrganizationPoliciesAsync.request
    ],
    (state, action) => ({
      ...state,
      policies: {
        ...state.policies,
        loading: true,
        error: false
      }
    })
  )
  .handleAction(
    [
      getOrganizationPoliciesAsync.success,
      saveOrganizationPoliciesAsync.success
    ],
    (state, action) => ({
      ...state,
      policies: {
        ...state.policies,
        loading: false,
        error: false,
        payload: {
          ...state.policies.payload,
          ...("payload" in action ? action.payload : undefined)
        }
      }
    })
  )
  .handleAction(
    [
      getOrganizationPoliciesAsync.failure,
      saveOrganizationPoliciesAsync.failure
    ],
    (state, action) => ({
      ...state,
      policies: {
        ...state.policies,
        loading: false,
        error: true
      }
    })
  )
  .handleAction(saveOrganizationDepartmentsAsync.request, (state, action) => ({
    ...state,
    departments: {
      ...state.departments,
      loading: true,
      error: false
    }
  }))
  .handleAction(saveOrganizationDepartmentsAsync.success, (state, action) => ({
    ...state,
    payload: {
      ...state.payload,
      departments:
        "payload" in action
          ? action.payload
          : state.payload.departments
          ? [...state.payload.departments]
          : []
    },
    departments: {
      ...state.departments,
      loading: false,
      error: false,
      payload: {
        ...state.departments.payload,
        ...("payload" in action ? action.payload : [])
      }
    }
  }))
  .handleAction(saveOrganizationDepartmentsAsync.failure, (state, action) => ({
    ...state,
    departments: {
      ...state.departments,
      loading: false,
      error: true
    }
  }))
  .handleAction(logoutAsync.request, () => ({ ...initialState }));
