import { ColumnInstance, TableInstance } from "react-table";

export type TableProps<T extends object> = Pick<
  TableInstance<T>,
  | "getTableProps"
  | "headerGroups"
  | "getTableBodyProps"
  | "page"
  | "prepareRow"
  | "rows"
>;

const SortIndicator = <T extends object>({
  isSorted,
  isSortedDesc,
  canSort
}: ColumnInstance<T>) => {
  if (!canSort) return null;

  return (
    <span className="relative inline-block w-6 h-3">
      <span
        className={`hicon triangleup absolute bottom-0 ${
          isSorted && !isSortedDesc ? "" : "text-colorPrimary"
        }`}
      />
      <span
        className={`hicon triangledown absolute top-0 ${
          isSorted && isSortedDesc ? "" : "text-colorPrimary"
        }`}
      />
    </span>
  );
};

const cellStyle =
  "px-2 " +
  "overflow-x-clip " +
  "overflow-y-visible " +
  "text-clip " +
  "text-ellipsis " +
  "whitespace-nowrap";

// IMPORTANT
// This is older type of table, the new one is in shared/table/table.tsx
// We keep that one for now as it is used in some older components

export const Table = <T extends object>({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  rows,
  prepareRow
}: TableProps<T>): JSX.Element => (
  <table
    {...getTableProps({
      className: "border-separate w-full table-fixed",
      style: { borderSpacing: "0 0.25rem" }
    })}
  >
    <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps?.({ className: "text-left" })}>
          {headerGroup.headers.map(column => (
            <th
              {...column.getHeaderProps(
                column.getSortByToggleProps?.({
                  style: { width: column.width }
                })
              )}
              className={
                cellStyle +
                "border-colorBackgroundLightTheme border-b text-xs text-colorTextAndIconLighterLightTheme"
              }
            >
              <div className={"flex items-center gap-2"}>
                {column.render("Header")}
                {!column.disableSortBy && <SortIndicator {...column} />}
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {(page || rows).map(row => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => {
              return (
                <td
                  {...cell.getCellProps({
                    style: { width: cell.column.width },
                    className: cellStyle + " bg-colorSelectedLightTheme"
                  })}
                >
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  </table>
);
