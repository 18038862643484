import { createReducer } from "typesafe-actions";
import { setFeatureFlags } from "./actions";
import { defaultLaunchDarklyFeatureFlag } from "@hoylu/client-common";

export interface FeatureFlagState {
  showExplicitExternalAccessPolicy: boolean;
  useJiraIntegration: boolean;
  useAgilityIntegration: boolean;
  orgPolicyOnlyOrgAdminsCanCreateWorkspace: boolean;
  useTwoFactorAuth: boolean;
  showWorkspaces: boolean;
  showProjects: boolean;
  showStripePurchase: boolean;
  showJiraCloudOAuth2: boolean;
}

export const initialState: FeatureFlagState = {
  showExplicitExternalAccessPolicy: !!defaultLaunchDarklyFeatureFlag.get(
    "enable-explicit-external-access-policy"
  ),
  useJiraIntegration: !!defaultLaunchDarklyFeatureFlag.get("jira-integration"),
  useAgilityIntegration: !!defaultLaunchDarklyFeatureFlag.get(
    "agility-integration"
  ),
  orgPolicyOnlyOrgAdminsCanCreateWorkspace:
    !!defaultLaunchDarklyFeatureFlag.get(
      "org-policy-only-org-admins-can-create-workspace"
    ),
  useTwoFactorAuth: !!defaultLaunchDarklyFeatureFlag.get("Enable-2fa"),
  showWorkspaces: !!defaultLaunchDarklyFeatureFlag.get("MP-workspace-overview"),
  showProjects: !!defaultLaunchDarklyFeatureFlag.get("project-licensing"),
  showStripePurchase: !!defaultLaunchDarklyFeatureFlag.get(
    "show-stripe-purchase"
  ),
  showJiraCloudOAuth2: !!defaultLaunchDarklyFeatureFlag.get(
    "show-jira-cloud-o-auth-2"
  )
};

export const featureFlags = createReducer(initialState).handleAction(
  setFeatureFlags,
  (state: FeatureFlagState, action) => {
    return { ...state, ...action.payload.flags };
  }
);

export default featureFlags;
