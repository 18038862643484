import * as types from "./types";
import { Organization, Policies } from "./types";
import { UserData } from "../accounts/types";
import { SavePoliciesResponse } from "../../api/types";
import { SelectedUsers } from "../accounts/reducer";
import { createAsyncAction } from "typesafe-actions";

export const getOrganizationInfo = (orgId: string) => {
  return getOrganizationInfoAsync.request(orgId);
};

export const getOrganizationPolicies = (orgId: string) => {
  return getOrganizationPoliciesAsync.request(orgId);
};

export function saveOrganizationPolicies(orgId: string, policies: Set<string>) {
  return saveOrganizationPoliciesAsync.request({ orgId, policies });
}

export function saveOrganizationDepartments(
  orgId: string,
  departments: string[]
) {
  return saveOrganizationDepartmentsAsync.request({ orgId, departments });
}

export function editUserDepartment(uid: string, department: string) {
  return editMemberDepartmentAsync.request({ uid, department });
}

export const removeMembers = (users: SelectedUsers, orgId: string) => {
  return removeMembersAsync.request({ users, orgId });
};

export const getOrganizationInfoAsync = createAsyncAction(
  types.GET_ORGANIZATION_INFO_LOADING,
  types.GET_ORGANIZATION_INFO_SUCCESS,
  types.GET_ORGANIZATION_INFO_ERROR
)<string, Organization, string>();

export const getOrganizationPoliciesAsync = createAsyncAction(
  types.GET_ORGANIZATION_POLICIES_LOADING,
  types.GET_ORGANIZATION_POLICIES_SUCCESS,
  types.GET_ORGANIZATION_POLICIES_ERROR
)<string, Policies, string>();

export const saveOrganizationPoliciesAsync = createAsyncAction(
  types.SAVE_ORGANIZATION_POLICIES_LOADING,
  types.SAVE_ORGANIZATION_POLICIES_SUCCESS,
  types.SAVE_ORGANIZATION_POLICIES_ERROR
)<{ orgId: string; policies: Set<string> }, SavePoliciesResponse, string>();

export const saveOrganizationDepartmentsAsync = createAsyncAction(
  types.SAVE_ORGANIZATION_DEPARTMENTS_LOADING,
  types.SAVE_ORGANIZATION_DEPARTMENTS_SUCCESS,
  types.SAVE_ORGANIZATION_DEPARTMENTS_ERROR
)<{ orgId: string; departments: string[] }, string[], string>();

export const editMemberDepartmentAsync = createAsyncAction(
  types.EDIT_MEMBER_DEPARTMENT,
  types.GET_MEMBERS_SUCCESS,
  types.EDIT_MEMBER_DEPARTMENT_ERROR
)<{ uid: string; department: string }, UserData[], string>();

export const removeMembersAsync = createAsyncAction(
  types.REMOVE_MEMBERS_LOADING,
  types.REMOVE_MEMBERS_SUCCESS,
  types.REMOVE_MEMBERS_ERROR
)<{ users: SelectedUsers; orgId: string }, string[], string>();
