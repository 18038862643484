import React, { useContext, useState } from "react";
import { AppContext, ManagementPortalContext } from "../../../context";
import { IntegrationHeader } from "../integration-header";
import { PrimaryButton } from "../../shared/button";
import { AgilityConnection } from "../../../state/agility/types";
import { AgilityConnectionForm } from "./connection-form";
import { ConnectionDetailsList } from "../connection-details-list";
import { FullPageTile } from "../../tile";
import { deleteAgilityConnection } from "../../../api/agility";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { useDispatch } from "react-redux";
import { agilityActions } from "../../../state/agility";
import { ConfirmModal } from "../../modals/confirm.modal";
import {
  showErrorToast,
  showSuccessToast
} from "../../../helpers/show.toast.helpers";

type AgilityConnectionDetailsProps = {
  connection: AgilityConnection;
};

export const AgilityConnectionDetails = ({
  connection
}: AgilityConnectionDetailsProps) => {
  const dispatch = useDispatch();
  const t = useI18n("INTEGRATIONS.");
  const context: ManagementPortalContext = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const details = [
    {
      title: `${context.Localized.string("INTEGRATIONS.URL", "Agility")}:`,
      value: connection.agilityUrl
    }
  ];

  const updateConnection = () => {
    setShowForm(true);
  };

  const removeConnection = async () => {
    const response = await deleteAgilityConnection(connection.settingName);
    if (response.error) {
      showErrorToast(t("DELETE_ERROR"), response.error);
    } else {
      showSuccessToast(t("DELETE_SUCCESS"));
      dispatch(agilityActions.getAgilityConnectionsAsync.request());
    }
    setShowModal(false);
  };

  return (
    <li>
      <FullPageTile>
        {!showForm ? (
          <>
            <IntegrationHeader
              text={`${context.Localized.string(
                "INTEGRATIONS.INTEGRATION_CONNECTION",
                "Agility"
              )}: ${connection.name}`}
            />
            <ConnectionDetailsList details={details} />
            <div className={"mb-8 inline-flex gap-4"}>
              <PrimaryButton onClick={updateConnection}>
                {context.Localized.string("UPDATE")}
              </PrimaryButton>
              <PrimaryButton onClick={() => setShowModal(true)}>
                {context.Localized.string("DELETE")}
              </PrimaryButton>
            </div>
            {showModal && (
              <ConfirmModal
                title={t("REMOVE_INTEGRATION")}
                onOk={removeConnection}
                onCancel={() => setShowModal(false)}
              >
                {t("REMOVE_CONFIRM", connection.name)}
              </ConfirmModal>
            )}
          </>
        ) : (
          <AgilityConnectionForm
            settingName={connection.settingName}
            name={connection.name}
          />
        )}
      </FullPageTile>
    </li>
  );
};
