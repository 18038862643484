import { useI18n } from "../../../../helpers/hooks/useI18n";
import { Header } from "../../../navbar/header";
import { PrimaryButton, SecondaryButton } from "../../../shared/button";
import { PlanTile } from "./plan.tile";

export const AddProject: React.FC = () => {
  const tp = useI18n("BILLING.PLANS.");
  const t = useI18n("BILLING.PLANS.ADD_PROJECT.");
  const tl = useI18n("LINKS.");

  const learnMore = () => window.open(tl("PROJECTS"), "_blank");
  const contactSales = () => window.open(`mailto:${tl("SALES")}`, "_blank");

  return (
    <PlanTile>
      <Header title={t("TITLE")} />
      {t("DESCRIPTION")}
      <ul className="list-disc ml-5">
        <li>{t("UNLIMITED_WORKSPACES")}</li>
        <li>{t("UNLIMITED_PROJECT_MEMBERS")}</li>
        <li>{t("PREDEFINED_TIME_PERIOD")}</li>
      </ul>
      <div className="grow" />
      <div className="grid grid-flow-col auto-cols-fr gap-4">
        <SecondaryButton onClick={learnMore}>
          {tp("LEARN_MORE")}
        </SecondaryButton>
        <PrimaryButton onClick={contactSales}>
          {tp("CONTACT_SALES")}
        </PrimaryButton>
      </div>
    </PlanTile>
  );
};
