// Session reducer
import { SessionState } from "./types";
import { createReducer } from "typesafe-actions";
import {
  getUserOrgInfoAsync,
  getUserProfileAsync,
  loginAsync,
  logoutAsync,
  refreshTokenAsync
} from "./actions";
import { userProfileToUserData } from "../../helpers/user.profile.conversion";

export default createReducer({} as SessionState)
  .handleAction(loginAsync.request, state => ({
    ...state,
    inProgress: true
  }))
  .handleAction(logoutAsync.request, () => ({} as SessionState))
  .handleAction(loginAsync.success, (state, action) => ({
    sessionToken: action.payload.sessionToken,
    tokenExpiration: action.payload.tokenExpiration,
    userId: action.payload.userId,
    orgId: action.payload.orgId,
    inProgress: false,
    error: null,
    isPartialAccount: action.payload.isPartialAccount
  }))
  .handleAction(refreshTokenAsync.success, (state, action) => ({
    ...state,
    sessionToken: action.payload.sessionToken,
    tokenExpiration: action.payload.tokenExpiration,
    userId: action.payload.userId,
    orgId: action.payload.orgId,
    error: null
  }))
  .handleAction(loginAsync.failure, (state, action) => ({
    error: {
      type: "login",
      info: action.payload
    },
    inProgress: false
  }))
  .handleAction(getUserProfileAsync.success, (state, action) => {
    const userProfile = action.payload;
    if (userProfile) {
      const userData = userProfileToUserData(
        userProfile,
        !!state.isPartialAccount,
        state.orgId ?? ""
      );
      return {
        ...state,
        loggedInUserData: userData
      };
    }
    return state;
  })
  .handleAction(getUserProfileAsync.failure, (state, action) => ({
    ...state,
    loggedInUserData: undefined,
    error: {
      type: "userProfile",
      info: action.payload
    }
  }))
  .handleAction(getUserOrgInfoAsync.success, (state, action) => ({
    ...state,
    userOrganization: action.payload
  }))
  .handleAction(getUserOrgInfoAsync.failure, (state, action) => ({
    ...state,
    userOrganization: undefined,
    error: {
      type: "userOrganization",
      info: action.payload
    }
  }));
