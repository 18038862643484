import { Project } from "../../../state/projects/types";
import { ModalHeader } from "../../modals/modalElements/modal.header";
import React from "react";
import { Modal } from "../../modals/modal";
import { ProjectEditor } from "./project.editor";
import { useI18n } from "../../../helpers/hooks/useI18n";

interface ProjectEditorModalProps {
  project: Project;
  editCollaborators?: boolean;
  onClose: () => void;
}

export const ProjectEditorModal = ({
  onClose,
  editCollaborators = false,
  project
}: ProjectEditorModalProps) => {
  const t = useI18n(`PROJECTS.`);
  return (
    <Modal handleOutsideClick={onClose}>
      <ModalHeader
        modalTitle={t("EDITOR_TITLE")}
        mainIcon={() => <></>}
        closeModal={onClose}
      />
      <ProjectEditor
        onClose={onClose}
        editCollaborators={editCollaborators}
        project={project}
      />
    </Modal>
  );
};
