type HeaderProps = {
  title: string;
  subTitle?: string;
  clickHandler?: () => void;
};
export const Header = ({
  title,
  subTitle,
  clickHandler
}: HeaderProps): JSX.Element => {
  return (
    <div className="flex justify-start items-center flex-grow-0 px-12 py-4 text-xl font-bold font-black">
      <span
        className={clickHandler ? "cursor-pointer" : ""}
        onClick={clickHandler}
      >
        {title}
      </span>
      {subTitle ? <span className="ml-1">{subTitle}</span> : null}
    </div>
  );
};
