import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserData } from "../../state/accounts/types";
import { PrimaryButton, TextButton } from "../shared/button";
import { Label } from "../input";
import { Modal } from "../modals/modal";
import { ModalHeader } from "../modals/modalElements/modal.header";
import { licensesActions, licensesSelectors } from "../../state/licenses";
import { DateTime } from "luxon";
import { License, LicenseFlags } from "../../state/licenses/types";
import { Spinner } from "../spinner";
import { Section } from "../shared/sections/section";
import { LocalizingFunction, useI18n } from "../../helpers/hooks/useI18n";
import { Dropdown, Option } from "../shared/dropdown";

type LicenseGroup = {
  firstId: string;
  expire: number;
  count: number;
};

const groupByExpire = (licenses: License[]): LicenseGroup[] => {
  const grouped = licenses.reduce((acc, license) => {
    const group = acc.get(license.expire);
    if (group) {
      group.push(license);
    } else {
      acc.set(license.expire, [license]);
    }
    return acc;
  }, new Map<number, License[]>());

  return Array.from(
    grouped,
    ([expire, group]): LicenseGroup => ({
      expire,
      count: group.length,
      firstId: group[0].id
    })
  );
};

const licenseInfoValue = (
  expire: number,
  flags: LicenseFlags,
  t: LocalizingFunction
): string => {
  if (flags.isExpired) {
    return t("ACCOUNTS_LIST.EDIT_ACCOUNT.EXPIRED");
  }
  if (!flags.isOrgLicense) {
    return t("ACCOUNTS_LIST.EDIT_ACCOUNT.PERSONAL");
  }
  return t(
    "ACCOUNTS_LIST.EDIT_ACCOUNT.LICENSE_VALID_UNTIL_DATE",
    DateTime.fromMillis(expire).toLocaleString()
  );
};

const groupedLicenseInfoValue = (
  expire: number,
  count: number,
  t: LocalizingFunction
): string => {
  return (
    t(
      "ACCOUNTS_LIST.EDIT_ACCOUNT.LICENSE_VALID_UNTIL_DATE",
      DateTime.fromMillis(expire).toLocaleString()
    ) + ` (${count})`
  );
};

// const filterProjectsByKeyword = (projects: Project[], keyword: string) => {
//   return projects.flatMap(project => {
//     return project.name.toLowerCase().includes(keyword.toLowerCase())
//       ? [project]
//       : [];
//   });
// };

export const EditAccountDialog: React.FC<EditAccountProps> = ({
  account,
  onClose
}) => {
  const dispatch = useDispatch();
  const t = useI18n("ACCOUNTS_LIST.");
  const tb = useI18n("");

  const availableLicenses = useSelector(licensesSelectors.availableLicenses);
  const areLicensesLoading = useSelector(licensesSelectors.loading);
  const licensesError = useSelector(licensesSelectors.error);
  const accountLicenseId = account.license?.id ?? "";
  const [selectedLicenseId, setSelectedLicenseId] = useState(accountLicenseId);
  // TODO: Uncomment code related to projects once required changes on backed are implemented

  // const projects = useSelector(projectsSelectors.projects);
  // useEffect(() => {
  //   if (!projects || projects.length === 0) {
  //     dispatch(getProjectsAsync.request(account.org));
  //   }
  // }, [dispatch, account.uid, account.org, projects]);

  // const showProjects = useSelector(featureFlagsSelectors.showProjects);
  // const [projectFilter, setProjectFilter] = useState("");

  // user project should be populated with api call
  // const [userProjectsAndRole, setUserProjectsAndRole] = useState();

  // const filteredAccountProjects = useMemo(() => {
  //   if (!userProjectsAndRole) return [];
  //   const userProjects = [...userProjectsAndRole.keys()];
  //   return filterProjectsByKeyword(userProjects, projectFilter);
  // }, [projectFilter, userProjectsAndRole]);
  // const projectsWithoutCurrentUser = useMemo(() => {
  //   const projectsToFilter = projects.filter(
  //     project =>
  //       !filteredAccountProjects.some(
  //         userProject => project.id === userProject.id
  //       )
  //   );
  //   return filterProjectsByKeyword(projectsToFilter, projectFilter);
  // }, [filteredAccountProjects, projects, projectFilter]);
  //
  // const showProjectsSection =
  //   filteredAccountProjects.length !== 0 ||
  //   projectsWithoutCurrentUser.length !== 0;

  const saveLicense = (account: UserData, licenseId: string) => {
    if (licenseId) {
      dispatch(licensesActions.setLicenseToUser(licenseId, account.uid));
    } else {
      dispatch(licensesActions.takeFromUser(account));
    }
  };

  const allLicenses = account.license
    ? [account.license, ...availableLicenses]
    : [...availableLicenses];

  let selectedLicense: License | undefined = undefined;
  const selectedLicenseIndex = allLicenses.findIndex(
    l => l.id === selectedLicenseId
  );
  if (selectedLicenseIndex >= 0) {
    selectedLicense = allLicenses[selectedLicenseIndex];
    allLicenses.splice(selectedLicenseIndex, 1);
  }

  const groupedLicenses = groupByExpire(allLicenses);
  const isLicenseChanged = selectedLicenseId !== accountLicenseId;

  const availableLicensesOptions = useMemo(() => {
    const result: Option<string>[] = [];
    // Add option to remove license
    result.push({
      label: t("EDIT_ACCOUNT.LICENSE_NONE_REMOVE"),
      value: ""
    });
    groupedLicenses.forEach(license => {
      result.push({
        label: groupedLicenseInfoValue(license.expire, license.count, tb),
        value: license.firstId
      });
    });
    return result;
  }, [groupedLicenses, t, tb]);

  const currentLicenseHeading = selectedLicense
    ? licenseInfoValue(selectedLicense.expire, selectedLicense.flags, tb)
    : t("EDIT_ACCOUNT.LICENSE");

  const handleSave = () => {
    onClose();
    if (isLicenseChanged) {
      saveLicense(account, selectedLicenseId);
    }
  };

  const isLicenseDisabled = () => {
    return (
      areLicensesLoading ||
      (selectedLicense &&
        !selectedLicense.flags.isExpired &&
        !selectedLicense.flags.isOrgLicense)
    );
  };

  return (
    <Modal handleOutsideClick={onClose}>
      <ModalHeader
        modalTitle={t("EDIT_ACCOUNT.TITLE")}
        mainIcon={() => <></>}
        closeModal={onClose}
      />
      <Section
        title={t("EDIT_ACCOUNT.APPLICATION_ACCESS")}
        icon={"hoylu"}
        isCollapsible={false}
      >
        <div className="flex flex-row justify-between items-center text-sm my-2 min-w-xl">
          <Label htmlFor="license" className={"mx-2 relative"}>
            {t("EDIT_ACCOUNT.ASSIGN_HOYLU_LICENSE")}
            {areLicensesLoading && (
              <Spinner classNames="in-line absolute top-1" size={3} />
            )}
          </Label>
          <Dropdown
            options={availableLicensesOptions}
            heading={currentLicenseHeading}
            handleChange={option => setSelectedLicenseId(option.value)}
            currentValue={selectedLicense?.id ?? ""}
            disabled={isLicenseDisabled()}
            makeRelative={false}
          />
        </div>
        {licensesError && (
          <p className={"text-colorWarning mx-2"}>
            {t("EDIT_ACCOUNT.FAILED_TO_LOAD")}
          </p>
        )}
      </Section>
      {/*{showProjects && (*/}
      {/*  <Section*/}
      {/*    title={t("EDIT_ACCOUNT.ADD_PROJECT_ACCESS")}*/}
      {/*    icon={"protectedaccess"}*/}
      {/*    isCollapsible={showProjectsSection}*/}
      {/*    initiallyCollapsed={true}*/}
      {/*  >*/}
      {/*    {showProjectsSection && (*/}
      {/*      <div className={"flex flex-col gap-2 max-h-xs customScrollbar"}>*/}
      {/*        <SearchBar onChange={setProjectFilter} />*/}
      {/*        {filteredAccountProjects.length > 0 && (*/}
      {/*          <div className={"text-black text-sm text-center font-bold"}>*/}
      {/*            {t("EDIT_ACCOUNT.USER_PROJECTS")}*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*        {filteredAccountProjects.map(project => {*/}
      {/*          const role = userProjectsAndRole.get(project);*/}
      {/*          return (*/}
      {/*            <ProjectLicensesBar*/}
      {/*              key={project.id}*/}
      {/*              icon={project.appearanceProperties?.icon}*/}
      {/*              projectName={project.name}*/}
      {/*              roleID={role}*/}
      {/*            />*/}
      {/*          );*/}
      {/*        })}*/}
      {/*        {projectsWithoutCurrentUser && (*/}
      {/*          <div className={"text-black text-sm text-center font-bold"}>*/}
      {/*            {t("EDIT_ACCOUNT.PROJECTS_IN_ORGANIZATION")}*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*        {projectsWithoutCurrentUser.map(project => {*/}
      {/*          return (*/}
      {/*            <ProjectLicensesBar*/}
      {/*              key={project.id}*/}
      {/*              icon={project.appearanceProperties?.icon}*/}
      {/*              projectName={project.name}*/}
      {/*            />*/}
      {/*          );*/}
      {/*        })}*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </Section>*/}
      {/*)}*/}
      <div className="flex justify-end mt-3 space-x-2">
        <TextButton onClick={onClose}>{tb("CANCEL")}</TextButton>
        <PrimaryButton onClick={handleSave} disabled={!isLicenseChanged}>
          {tb("SAVE")}
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export type EditAccountProps = {
  account: UserData;
  onClose: () => void;
};
