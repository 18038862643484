import React, { useEffect, useState } from "react";
import { printNiceDayDate } from "../../../helpers/print.nice.day.date";
import { Project } from "../../../state/projects/types";
import {
  DEFAULT_DROPDOWN_PICKER_COLORS,
  DropdownColorPicker
} from "../../shared/dropdown.color.picker";
import { DropdownIconPicker } from "../../shared/dropdown.icon.picker";
import { Input } from "../../shared/input";
import { Textarea } from "../../shared/textarea";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { projectsSelectors } from "../../../state/projects";
import { useSelector } from "react-redux";
import { ProjectLimitInformation } from "./project.limit.information";
import { showError } from "../../../helpers/show.toast.helpers";
import { getWorkspacesByProject } from "../../../api/projects";

interface ProjectRoomProps {
  project: Project;
  updateProject: (project: Project, projectChanges: Partial<Project>) => void;
  hasErrors: (hasErrors: boolean) => void;
}

export const ProjectRoom = ({
  project,
  updateProject,
  hasErrors
}: ProjectRoomProps) => {
  const { name, startDate, endDate, description } = project;
  const { icon, color } = project.appearanceProperties ?? {
    icon: "",
    color: DEFAULT_DROPDOWN_PICKER_COLORS[0]
  };
  const [invalidName, setInvalidName] = useState(false);
  const [workspacesQuantity, setWorkspacesQuantity] = useState<number | null>(
    null
  );
  const t = useI18n("PROJECTS.");
  const collaborators = useSelector(
    projectsSelectors.findCollaboratorsByProject(project.id)
  );

  const onNameChange = (newName: string) => {
    updateProject({ ...project, name: newName }, { name: newName.trim() });
    setInvalidName(!newName || newName.trim().length < 1);
  };

  useEffect(() => {
    hasErrors(invalidName);
  }, [invalidName, hasErrors]);

  useEffect(() => {
    const getWorkspacesQuantity = async (): Promise<number | undefined> => {
      try {
        const workspaces = await getWorkspacesByProject(project.id);

        if (workspaces.data) {
          return workspaces.data.length;
        }

        if (workspaces.error) {
          showError(t("WORKSPACES_COUNT_ERROR"));
        }
      } catch (e) {
        showError(t("WORKSPACES_COUNT_ERROR"));
      }
    };

    getWorkspacesQuantity().then((response: number | undefined) =>
      setWorkspacesQuantity(response ?? null)
    );
  }, [project.id, t]);

  const changeColor = (newColor: string) => {
    const change = {
      appearanceProperties: {
        ...project.appearanceProperties,
        icon,
        color: newColor
      }
    };

    updateProject(
      {
        ...project,
        ...change
      },
      change
    );
  };

  const changeIcon = (newIcon: string) => {
    const change = {
      appearanceProperties: {
        ...project.appearanceProperties,
        color,
        icon: newIcon
      }
    };

    updateProject(
      {
        ...project,
        ...change
      },
      change
    );
  };

  const changeDescription = (description: string) => {
    updateProject(
      {
        ...project,
        description
      },
      { description }
    );
  };

  return (
    <div data-test-id={"project_room_expanded"}>
      <div className="flex gap-1">
        <DropdownColorPicker
          initial={color}
          onColorPick={changeColor}
          pickedColors={new Set([])}
        />
        <DropdownIconPicker initial={icon} onIconPick={changeIcon} />
        <Input
          type="text"
          value={name}
          onChange={onNameChange}
          label={t("NAME")}
          style={{ width: "100%" }}
          error={invalidName ? "Name can't be empty" : ""}
        />
      </div>
      <Textarea
        value={description ?? ""}
        label={t("DESCRIPTION")}
        textareaHeight="6rem"
        onChange={changeDescription}
      />
      <div className="flex gap-1">
        <Input
          type="text"
          value={printNiceDayDate(startDate, t("NOT_STARTED"))}
          label={t("START")}
          disabled={true}
          style={{ width: "100%" }}
        />
        <Input
          type="text"
          value={printNiceDayDate(endDate, t("NA"))}
          label={t("END")}
          disabled={true}
          style={{ width: "100%" }}
        />
      </div>
      <div>
        <div className="flex">
          <span className="w-3/5 p-1">{t("USER_COUNT")}</span>
          <ProjectLimitInformation
            limit={project.maxProjectUsers}
            currentAmount={collaborators.length}
          />
        </div>
        <div className="flex">
          <span className="w-3/5 p-1">{t("WORKSPACES_COUNT")}</span>
          <ProjectLimitInformation
            limit={project.maxProjectWorkspaces}
            currentAmount={workspacesQuantity || 0}
          />
        </div>
      </div>
    </div>
  );
};
