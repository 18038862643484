// Session types
import { UserData } from "../accounts/types";

export const LOGIN_LOADING = "@session/LOGIN_LOADING";
export const LOGIN_SUCCESS = "@session/LOGIN_SUCCESS";
export const LOGIN_ERROR = "@session/LOGIN_ERROR";
export const LOGOUT = "@session/LOGOUT";
export const LOGOUT_SUCCESS = "@session/LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "@session/LOGOUT_ERROR";
export const REFRESH_TOKEN = "@session/REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "@session/REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "@session/REFRESH_TOKEN_ERROR";

export const GET_USER_PROFILE_LOADING = "@session/GET_USER_PROFILE_LOADING";
export const GET_USER_PROFILE_SUCCESS = "@session/GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_ERROR = "@session/GET_USER_PROFILE_ERROR";

export const GET_USER_ORG_INFO_LOADING = "@session/GET_USER_ORG_INFO_LOADING";
export const GET_USER_ORG_INFO_SUCCESS = "@session/GET_USER_ORG_INFO_SUCCESS";
export const GET_USER_ORG_INFO_ERROR = "@session/GET_USER_ORG_INFO_ERROR";
export const REQUEST_DELETE_REQUEST = "@session/REQUEST_DELETE_REQUEST";
export const REQUEST_DELETE_SUCCESS = "@session/REQUEST_DELETE_SUCCESS";
export const REQUEST_DELETE_FAILURE = "@session/REQUEST_DELETE_FAILURE";

export interface SessionData {
  sessionToken?: string;
  tokenExpiration?: number;
  orgId?: string;
  userId?: string;
  isPartialAccount?: boolean;
  loggedInUserData?: UserData;
  userOrganization?: PartialOrgInfo;
}

export type SessionState = {
  inProgress: boolean;
  error: { type: string; info: string } | null;
} & SessionData;

export interface UserFullPayload {
  iss: string;
  aud: string;
  exp: number;
  jti: string;
  iat: number;
  nbf: number;
  sub: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  licenseValid: boolean;
  licenseExpiration: number;
  isPartialAccount: boolean;
  offlineUsageExpiration: number;
  org: string;
  Permissions: {
    Read: string[];
    Write: string[];
    ShareExternal: string[];
    Administer: string[];
  };
}

export interface PartialOrgInfo {
  name: string;
  departments?: String[];
}
