import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext, ManagementPortalContext } from "../../../context";
import { PrimaryButton } from "../../shared/button";
import { FormFieldInput } from "../form-field";
import { toast } from "react-toastify";
import { toasterOptions } from "../../../state/errors/errors.epic";
import { IntegrationHeader } from "../integration-header";
import { v4 as uuid } from "uuid";
import { pendingTokenRefresh } from "../../../api/auth";
import { isValidUrl } from "../../../helpers/isValidUrl";
import { AgilityConnection } from "../../../state/agility/types";
import { IntegrationConnectionForm } from "../integration-connection-form";
import {
  saveAgilityConnection,
  saveAgilityConnectionSecret,
  testAgilityConnection
} from "../../../api/agility";
import { trimIntegrationUrl } from "../../../helpers/trimIntegrationUrl";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { useDispatch } from "react-redux";
import { agilityActions } from "../../../state/agility";
import {
  showErrorToast,
  showSuccessToast
} from "../../../helpers/show.toast.helpers";

const testEndpointNotReady = true;

const initialFieldsState = {
  value: "",
  touched: false,
  error: false
};

type formProps = {
  settingName?: string;
  name?: string;
};

export const defaultAgilityConnectionName = "Agility Instance";

export const AgilityConnectionForm = ({
  settingName,
  name
}: formProps): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);
  const dispatch = useDispatch();
  const [connectionName, setConnectionName] = useState(initialFieldsState);
  const [url, setUrl] = useState(initialFieldsState);
  const [token, setToken] = useState(initialFieldsState);
  const [send, setSend] = useState(false);
  const t = useI18n("INTEGRATIONS.");

  const title = useMemo(() => {
    return !name
      ? t("SET_TITLE", "Agility")
      : t("UPDATE_INTEGRATION", "Agility", name);
  }, [name, t]);

  const fields: FormFieldInput[] = [
    {
      id: "connectionName",
      label: t("CONNECTION_NAME"),
      placeholder: t("CONNECTION_NAME_PLACEHOLDER", "Agility"),
      data: connectionName,
      type: "text",
      changeHandler: setConnectionName,
      required: false,
      readonly: send
    },
    {
      id: "url",
      label: t("URL", "Agility"),
      placeholder: context.Localized.string("AGILITY.URL_PLACEHOLDER"),
      data: url,
      type: "text",
      changeHandler: setUrl,
      errorMessage: t("URL_ERROR"),
      readonly: send
    },
    {
      id: "token",
      label: t("TOKEN", "Agility"),
      placeholder: t("TOKEN_PLACEHOLDER", "Agility"),
      data: token,
      type: "password",
      changeHandler: setToken,
      readonly: send
    }
  ];

  // url validation
  useEffect(() => {
    if (url.touched) {
      setUrl(url => ({
        ...url,
        error: !!url.value.trim() && !isValidUrl(url.value)
      }));
    }
  }, [url.value, url.touched]);

  // token validation (only check if exists)
  useEffect(() => {
    if (token.touched) {
      setToken(url => ({
        ...url,
        error: !token.value.trim()
      }));
    }
  }, [token.value, token.touched]);

  const isReadyForSubmit = (): boolean => {
    return fields
      .filter(field => field.id !== "connectionName")
      .some(field => !field.data.value || field.data.error);
  };

  const createConnection = (): AgilityConnection => {
    const connection: AgilityConnection = {
      name: connectionName.value.trim()
        ? connectionName.value.trim()
        : defaultAgilityConnectionName,
      agilityUrl: trimIntegrationUrl(url.value)
    };

    // syncing send value with the component state
    if (!connectionName.value.trim()) {
      setConnectionName({
        ...connectionName,
        value: defaultAgilityConnectionName
      });
    }

    return connection;
  };

  const handleClick = () => {
    const connection = createConnection();

    if (testEndpointNotReady) {
      save(connection);
      return;
    }
    // the code below needs endpoint authentication/verify, if it will be ready remove testEndpointNotReady flag and the if statement above
    setSend(true);
    testAgilityConnection({
      apiToken: token.value,
      agilityUrl: connection.agilityUrl
    })
      .then(resp => {
        if (resp.error) {
          throw resp.error;
        }
        showSuccessToast(t("TEST_SUCCESS"));
        save(connection);
      })
      .catch(er => {
        if (!pendingTokenRefresh) {
          console.error(er);
          showErrorToast(t("TEST_ERROR"), er);
          setSend(false);
        }
      });
  };

  const save = (connection: AgilityConnection) => {
    const id = settingName ? settingName : `connection-${uuid()}`;

    const connectionPromises = [
      saveAgilityConnection(connection, id),
      saveAgilityConnectionSecret(token.value.trim(), id)
    ];

    Promise.all(connectionPromises)
      .then(results => {
        const errors = results.filter(result => result.error);
        if (errors.length === 0) {
          setSend(true);
          showSuccessToast(t("SAVE_SUCCESS"));
        } else {
          if (!pendingTokenRefresh) {
            // in case of some error usually we will see both requests failing due to the same reason so no need to show all errors
            toast.error(errors[0].error, toasterOptions);
          }
        }
      })
      .catch(er => console.error(er))
      .finally(() =>
        dispatch(agilityActions.getAgilityConnectionsAsync.request())
      );
  };

  return (
    <>
      <IntegrationHeader text={title} />
      <IntegrationConnectionForm fields={fields} />
      <PrimaryButton
        additionalStyling="mt-6 mb-8 mr-8"
        onClick={handleClick}
        disabled={isReadyForSubmit() || send}
      >
        {context.Localized.string("CONFIRM")}
      </PrimaryButton>
    </>
  );
};
