import { Epic } from "redux-observable";
import { ApiResponse, ApiType } from "../../api/types";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import { from, of } from "rxjs";
import * as types from "../jira/types";
import { getJiraConnectionsAsync } from "./actions";

export const getJiraConnectionsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { jira }) => {
  return action$.pipe(
    filter(isActionOf(getJiraConnectionsAsync.request)),
    mergeMap(action =>
      from(jira.getConnections()).pipe(
        map((response: ApiResponse<types.Connection[]>) => {
          const { data, error } = response;
          const connections: types.Connection[] =
            data && Array.isArray(data) ? data : [];

          return error
            ? getJiraConnectionsAsync.failure(error)
            : getJiraConnectionsAsync.success(connections);
        }),
        catchError(error =>
          of(getJiraConnectionsAsync.failure(error.toString()))
        )
      )
    )
  );
};
