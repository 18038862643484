// Session actions
import * as types from "./types";
import { SessionData } from "./types";
import { createAsyncAction } from "typesafe-actions";
import { UserProfile } from "../accounts/types";

export function login() {
  return loginAsync.request();
}

export const refreshToken = () => {
  return refreshTokenAsync.request();
};

export const logoutAsync = createAsyncAction(
  types.LOGOUT,
  types.LOGOUT_SUCCESS,
  types.LOGOUT_ERROR
)<undefined, undefined, string>();

export const loginAsync = createAsyncAction(
  types.LOGIN_LOADING,
  types.LOGIN_SUCCESS,
  types.LOGIN_ERROR
)<undefined, SessionData, string>();

export const refreshTokenAsync = createAsyncAction(
  types.REFRESH_TOKEN,
  types.REFRESH_TOKEN_SUCCESS,
  types.REFRESH_TOKEN_ERROR
)<undefined, SessionData, string>();

export const getUserProfileAsync = createAsyncAction(
  types.GET_USER_PROFILE_LOADING,
  types.GET_USER_PROFILE_SUCCESS,
  types.GET_USER_PROFILE_ERROR
)<string, UserProfile | undefined, string>();

export const getUserOrgInfoAsync = createAsyncAction(
  types.GET_USER_ORG_INFO_LOADING,
  types.GET_USER_ORG_INFO_SUCCESS,
  types.GET_USER_ORG_INFO_ERROR
)<string, types.PartialOrgInfo, string>();

export const requestDeleteAccount = createAsyncAction(
  types.REQUEST_DELETE_REQUEST,
  types.REQUEST_DELETE_SUCCESS,
  types.REQUEST_DELETE_FAILURE
)<undefined, undefined, string>();
