import config from "../configuration";
import handleFetchRequest from "./handle-fetch-request";
import { httpMethods } from "./http-methods";
import globalHeaders from "./global-headers";
import {
  DocumentMetadataRequest,
  DocumentMetadataV1Response,
  Label,
  ResponsePromise,
  WorkspacesResponse,
  WorkspacesSort
} from "./types";
import { encodeBase64 } from "../helpers/encode";

export const MAX_LIMIT_OF_WORKSPACES_PER_REQUEST = 50;

export type FirstPageOfWorkspacesRequestArgs = {
  orgId: string;
  limit?: number;
  sort?: WorkspacesSort;
};

export function getFirstPageOfWorkspaces({
  orgId,
  limit = 10,
  sort
}: FirstPageOfWorkspacesRequestArgs): ResponsePromise<WorkspacesResponse> {
  if (limit > MAX_LIMIT_OF_WORKSPACES_PER_REQUEST) {
    limit = MAX_LIMIT_OF_WORKSPACES_PER_REQUEST;
  }

  let url = `${config["serviceConfig"].documentMetadata}/api/v2/workspaces/org/${orgId}?limit=${limit}`;

  if (sort) {
    url += `&sort=${sort}`;
  }

  return handleFetchRequest(() =>
    fetch(url, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
}

export function getPageOfWorkspacesByUrl(
  nextUrl: string
): ResponsePromise<WorkspacesResponse> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].documentMetadata}${nextUrl}`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
}

export const getDocument = (
  documentId: string
): ResponsePromise<DocumentMetadataV1Response> => {
  return handleFetchRequest<DocumentMetadataV1Response>(() =>
    fetch(
      `${config["serviceConfig"].documentMetadata}/api/v2/${documentId}?schemaVersion=3`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
};

// TODO: This endpoint can produce data-loss (collisions) because it always changes the entirety of all metadata.
// Change this to a partial change by only accepting properties that should be changed + get the current metadata from the service here, merge the changes and finally put the entire metadata set to the service again.
export const updateDocument = (
  documentMetadata: DocumentMetadataRequest
): ResponsePromise<DocumentMetadataV1Response> => {
  return handleFetchRequest<DocumentMetadataV1Response>(() =>
    fetch(
      `${config["serviceConfig"].documentMetadata}/api/v1/${documentMetadata.documentId}`,
      {
        method: httpMethods.PUT,
        headers: globalHeaders,
        body: JSON.stringify(documentMetadata)
      }
    )
  );
};

export const setWorkspacePassword = (
  workspaceId: string,
  newPassword: string
) => {
  return handleFetchRequest<void>(() =>
    fetch(
      `${config["serviceConfig"].documentMetadata}/api/v1/${workspaceId}/password`,
      {
        method: httpMethods.POST,
        headers: globalHeaders,
        body: JSON.stringify({ newPassword: newPassword })
      }
    )
  );
};

export const changeWorkspacePassword = (
  workspaceId: string,
  currentPassword: string,
  newPassword: string
) => {
  return handleFetchRequest<void>(() =>
    fetch(
      `${config["serviceConfig"].documentMetadata}/api/v1/${workspaceId}/password`,
      {
        method: httpMethods.POST,
        headers: {
          Authorization: globalHeaders.get("Authorization") || "",
          "Content-Type": "application/json",
          "X-Hoylu-Document-Password": encodeBase64(currentPassword)
        },
        body: JSON.stringify({ newPassword: newPassword })
      }
    )
  );
};

export const removeWorkspacePassword = (
  workspaceId: string,
  currentPassword: string
) => {
  return handleFetchRequest<void>(() =>
    fetch(
      `${config["serviceConfig"].documentMetadata}/api/v1/${workspaceId}/password`,
      {
        method: httpMethods.DELETE,
        headers: {
          Authorization: globalHeaders.get("Authorization") || "",
          "X-Hoylu-Document-Password": encodeBase64(currentPassword)
        }
      }
    )
  );
};

export const updateLabels = (workspaceId: string, labels: Label[]) => {
  return handleFetchRequest<Label[]>(() =>
    fetch(
      `${config["serviceConfig"].documentMetadata}/api/v2/${workspaceId}/labels`,
      {
        method: httpMethods.PUT,
        headers: globalHeaders,
        body: JSON.stringify(labels)
      }
    )
  );
};
