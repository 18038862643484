import { GlobalAccess } from "../../../api/types";

export type Permissions = {
  administrators: string[];
  writers: string[];
  readers: string[];
  unspecifiedUsers: string[];
  globalAccess: GlobalAccess;
};

export type ShareOptionValue = {
  title: string;
  color?: string;
  border?: string;
  value: number;
};

export const ShareType = {
  ADMIN: {
    title: "SHARE_TYPE.ADMIN",
    color: "colorPermissionAdmin",
    value: 3
  },
  EDIT: {
    title: "SHARE_TYPE.EDIT",
    color: "colorSuccess",
    value: 2
  },
  READ: {
    title: "SHARE_TYPE.READ",
    color: "colorPermissionsRead",
    value: 1
  },
  NO_ACCESS: {
    title: "SHARE_TYPE.NO_ACCESS",
    color: "colorPermissionsNo",
    value: 0
  },
  REMOVE: {
    title: "SHARE_TYPE.REMOVE",
    value: -1
  }
};

export const USER_ACCESS_OPTIONS: ShareOptionValue[] = [
  ShareType.ADMIN,
  ShareType.EDIT,
  ShareType.READ,
  ShareType.NO_ACCESS
];

export const LINK_ACCESS_OPTIONS: ShareOptionValue[] = [
  ShareType.EDIT,
  ShareType.READ,
  ShareType.NO_ACCESS
];

export type User = {
  email: string;
  permission: ShareOptionValue;
};
