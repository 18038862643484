export const GET_AVAILABLE_LOADING = "@licenses/GET_AVAILABLE_LOADING";
export const GET_AVAILABLE_ERROR = "@licenses/GET_AVAILABLE_ERROR";
export const GET_AVAILABLE_SUCCESS = "@licenses/GET_AVAILABLE_SUCCESS";

export const SET_LICENSE_TO_USER_LOADING =
  "@licenses/SET_LICENSE_TO_USER_LOADING";
export const SET_LICENSE_TO_USER_ERROR = "@licenses/SET_LICENSE_TO_USER_ERROR";
export const SET_LICENSE_TO_USER_SUCCESS =
  "@licenses/SET_LICENSE_TO_USER_SUCCESS";

export const TAKE_LICENSE_FROM_USER_LOADING =
  "@licenses/TAKE_LICENSE_FROM_USER_LOADING";
export const TAKE_LICENSE_FROM_USER_ERROR =
  "@licenses/TAKE_LICENSE_FROM_USER_ERROR";
export const TAKE_LICENSE_FROM_USER_SUCCESS =
  "@licenses/TAKE_LICENSE_FROM_USER_SUCCESS";

export const EXTEND_LICENSE_LOADING = "@licenses/EXTEND_LICENSE_LOADING";
export const EXTEND_LICENSE_ERROR = "@licenses/EXTEND_LICENSE_ERROR";
export const EXTEND_LICENSE_SUCCESS = "@licenses/EXTEND_LICENSE_SUCCESS";

export const CREATE_LICENSES_LOADING = "@licenses/CREATE_LICENSES_LOADING";
export const CREATE_LICENSES_ERROR = "@licenses/CREATE_LICENSES_ERROR";
export const CREATE_LICENSES_SUCCESS = "@licenses/CREATE_LICENSES_SUCCESS";

export const GET_LICENSE_INFO_LOADING = "@licenses/GET_LICENSE_INFO_LOADING";
export const GET_LICENSE_INFO_ERROR = "@licenses/GET_LICENSE_INFO_ERROR";
export const GET_LICENSE_INFO_SUCCESS = "@licenses/GET_LICENSE_INFO_SUCCESS";

export interface LicenseFlags {
  isOrgLicense: boolean;
  isExpired: boolean;
}

export interface License {
  expire: number;
  flags: LicenseFlags;
  id: string;
  org?: string;
}

export interface LicensesState {
  availableLicenses: LoadableState<License[]>;
  licenseInfo: LoadableState<LicenseInfo>;
}


//TODO: use also in other states
interface LoadableState<T> {
  loading: boolean;
  error: boolean;
  payload?: T;
}

export interface LicenseInfo {
  allowedPremiumModules: string[];
  expiration: string;
  isExpired: boolean;
  orgOwnerId: string;
  type: string;
}

export interface PriceInfo {
  id: string;
  currency: string;
  unitAmount: number;
  interval: string;
  intervalCount: number; 
}

export interface ProductInfo {
  id: string;
  name: string;
  description: string;
  defaultPriceId?: string;
  prices: PriceInfo[]; 
}

export const isEnterprise = (type?: string): type is "Enterprise" => {
  return type === "Enterprise";
};

export interface LicenseData {
  id: string;
  userId: string | null;
  orgId: string | null;
  isTrial: boolean;
  expiration: string | null;
  subscriptionId: string | null;
  isExpired: boolean;
  isAssigned: boolean;
}

type SubscriptionStatus =
  | "active"
  | "canceled"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "paused"
  | "trialing"
  | "unpaid";

export interface SubscriptionInfo {
  id: string;
  status: SubscriptionStatus;
  customerId: string;
  customerEmail: string;
  startDate: string;
  currentPeriodEnd: string;
  canceledAt?: string;
  productName: string;
}
