import { useContext, useState } from "react";
import { InstanceType } from "../../../state/jira/types";
import { IntegrationHeader } from "../integration-header";
import { AppContext, ManagementPortalContext } from "../../../context";
import { JiraTypeSelector } from "./jiraTypeSelector";
import { JiraConnectionForm } from "./connection-form";

export const JiraConnectionCreator: React.FC = () => {
  const context: ManagementPortalContext = useContext(AppContext);
  const [jiraType, setJiraType] = useState<InstanceType | null>(null);

  return !jiraType ? (
    <>
      <IntegrationHeader
        text={`${context.Localized.string(
          "INTEGRATIONS.INTEGRATION_CONNECTION",
          "Jira"
        )}`}
      />
      <JiraTypeSelector setType={setJiraType} />
    </>
  ) : (
    <JiraConnectionForm
      type={jiraType}
      onCancel={() => {
        setJiraType(null);
      }}
    />
  );
};
