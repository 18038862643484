import type { Connection } from "../../../state/jira/types";
import React, { useContext, useState } from "react";
import { AppContext, ManagementPortalContext } from "../../../context";
import { IntegrationHeader } from "../integration-header";
import { PrimaryButton } from "../../shared/button";
import { JiraConnectionForm } from "./connection-form";
import { ConnectionDetailsList } from "../connection-details-list";
import { FullPageTile } from "../../tile";
import {
  areClientCredentialsRequired,
  deleteConnection,
  deleteSecret
} from "../../../api/jira";
import { useDispatch } from "react-redux";
import { jiraActions } from "../../../state/jira";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { ConfirmModal } from "../../modals/confirm.modal";
import {
  showErrorToast,
  showSuccessToast
} from "../../../helpers/show.toast.helpers";

type ConnectionDetailsProps = {
  connection: Connection;
};

export const JiraConnectionDetails: React.FC<ConnectionDetailsProps> = ({
  connection
}) => {
  const dispatch = useDispatch();
  const t = useI18n("INTEGRATIONS.");
  const tJira = useI18n("JIRA.");
  const context: ManagementPortalContext = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const details = [
    {
      title: `${tJira("TYPE")}:`,
      value: tJira(connection.type)
    },
    {
      title: `${tJira("URL")}:`,
      value: connection.jiraUrl
    },
    {
      title: `${tJira("USER")}:`,
      value: connection.user
    },
    {
      title: `${tJira("OAUTH_VERSION")}:`,
      value: tJira(connection.oAuthVersion)
    }
  ];

  const updateConnection = () => {
    setShowForm(true);
  };

  const removeConnection = () => {
    const { settingName } = connection;
    if (!settingName) throw new Error();
    const removeClientSecret = areClientCredentialsRequired(
      connection.type,
      connection.oAuthVersion
    );
    const deletePromises = [
      deleteConnection(settingName),
      deleteSecret(settingName)
    ];
    if (removeClientSecret)
      deletePromises.push(deleteSecret(`${settingName}-oauth2`));

    Promise.all(deletePromises)
      .then(results => {
        const errors = results.filter(result => result.error);
        if (errors.length > 0) {
          console.error(errors);
          showErrorToast(t("DELETE_ERROR"), errors[0]);
        } else {
          showSuccessToast(t("DELETE_SUCCESS"));
          dispatch(jiraActions.getJiraConnectionsAsync.request());
        }
        setShowRemoveModal(false);
      })
      .catch(er => console.error(er));
  };

  return (
    <li>
      <FullPageTile>
        {!showForm ? (
          <>
            <IntegrationHeader
              text={`${context.Localized.string(
                "INTEGRATIONS.INTEGRATION_CONNECTION",
                "Jira"
              )}: ${connection.name}`}
            />
            <ConnectionDetailsList details={details} />
            <div className={"mb-8 inline-flex gap-4"}>
              <PrimaryButton onClick={updateConnection}>
                {context.Localized.string("UPDATE")}
              </PrimaryButton>
              <PrimaryButton onClick={() => setShowRemoveModal(true)}>
                {context.Localized.string("DELETE")}
              </PrimaryButton>
            </div>
            {showRemoveModal && (
              <ConfirmModal
                title={t("REMOVE_INTEGRATION")}
                onOk={removeConnection}
                onCancel={() => setShowRemoveModal(false)}
              >
                {t("REMOVE_CONFIRM", connection.name)}
              </ConfirmModal>
            )}
          </>
        ) : (
          <JiraConnectionForm
            originalConnection={connection}
            onCancel={() => {
              setShowForm(false);
            }}
          />
        )}
      </FullPageTile>
    </li>
  );
};
