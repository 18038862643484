import React, { useContext, useEffect, useState } from "react";
import { TableInstance } from "react-table";
import { AppContext } from "../../../context";
import { PageButton } from "../button";
import { PageSizeSelect } from "./page.size.select";

export type PaginationProps = Pick<
  TableInstance<any>,
  | "canPreviousPage"
  | "canNextPage"
  | "pageCount"
  | "gotoPage"
  | "nextPage"
  | "previousPage"
  | "setPageSize"
  | "state"
>;

export const Pagination: React.FC<PaginationProps> = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  state: { pageIndex, pageSize }
}) => {
  const context = useContext(AppContext);
  const [page, setPage] = useState(String(pageIndex + 1));
  useEffect(() => {
    setPage(String(pageIndex + 1));
  }, [pageIndex]);

  return (
    <div className="flex flex-wrap justify-end space-x-1 leading-tight">
      <span className="flex-grow flex justify-center space-x-2">
        <span className="space-x-1">
          <PageButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <span className={"hicon doublearrowleft"} />
          </PageButton>
          <PageButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <span className={"hicon left text-sm"} />
          </PageButton>
        </span>

        <span className="space-x-1">
          <span>
            {context.Localized.string("ACCOUNTS_LIST.PAGINATION.PAGE_NUMBER")}
          </span>
          <input
            type="text"
            className="w-10 h-6 text-center outline-none bg-transparent border border-colorPrimary rounded"
            value={page}
            onChange={e => {
              setPage(e.target.value);
              const value = Number(e.target.value);
              if (isNaN(value)) return;
              const page = value - 1;
              gotoPage(page);
            }}
            onBlur={() => {
              setPage(String(pageIndex + 1));
            }}
          />
          <span>
            {context.Localized.string(
              "ACCOUNTS_LIST.PAGINATION.OF_TOTAL_PAGES",
              pageCount
            )}
          </span>
        </span>

        <span className="space-x-1">
          <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
            <span className={"hicon right text-sm"} />
          </PageButton>
          <PageButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span className={"hicon doublearrowright"} />
          </PageButton>
        </span>
      </span>
      <PageSizeSelect pageSize={pageSize} setPageSize={setPageSize} />
    </div>
  );
};
