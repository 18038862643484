import React, { CSSProperties, useContext } from "react";
import { AppContext, ManagementPortalContext } from "../../context";
import { useI18n } from "../../helpers/hooks/useI18n";

export type LabelType = "default" | "enterprise" | "plus";

export const isPremiumLabel = (
  licenseType?: string
): licenseType is LabelType => {
  return (
    !!licenseType && (licenseType === "enterprise" || licenseType === "plus")
  );
};

/**
 * @param iconColor color in hex code - apply it in styles,
 * @param textColor color from the tailwind - apply it with tailwind class.
 * */
export type LabelProps = {
  icon?: string;
  label?: string;
  type?: LabelType;
  /** Tailwind class */
  backgroundColor?: string;
  /** CSS Property */
  iconColor?: string;
  /** Tailwind class */
  textColor?: string;
  onRemove?: () => void;
  strikeTrough?: boolean;
  tooltip?: string;
};

export const Label: React.FC<LabelProps> = ({
  icon,
  label,
  type = "default",
  backgroundColor,
  iconColor,
  textColor,
  onRemove,
  strikeTrough
}) => {
  const getLabel = (type: LabelType) => {
    switch (type) {
      // Default label type was created with projects labels and future labels in mind
      case "default":
        return (
          <DefaultLabel
            icon={icon}
            label={label}
            backgroundColor={backgroundColor}
            iconColor={iconColor}
            textColor={textColor}
            onRemove={onRemove}
            strikeTrough={strikeTrough}
          />
        );
      case "enterprise":
        return <EnterpriseLabel />;
      case "plus":
        return <PlusPlanLabel strikeTrough={strikeTrough} />;
    }
  };
  return getLabel(type);
};

const DefaultLabel: React.FC<LabelProps> = ({
  icon,
  label,
  backgroundColor = "bg-colorSelectedLightTheme",
  iconColor = "#3B434A",
  textColor = "text-colorTextAndIconLightTheme",
  onRemove,
  strikeTrough = false,
  tooltip = label
}) => {
  const textStyle = `text-xxs`;
  const labelStyle = strikeTrough ? "line-through" : "";
  const iconStyle: CSSProperties = {
    color: iconColor
  };
  return (
    <div
      data-test-id={"label"}
      className={`flex items-center gap-1 px-1 py-0125 ${backgroundColor} rounded-sm w-fit shadow-label`}
    >
      <span
        data-test-id={"label-icon"}
        className={`hicon ${icon} ${textStyle}`}
        style={iconStyle}
      />
      <span
        data-test-id={"label-text"}
        className={`${textStyle} ${textColor} truncate max-w-32 ${labelStyle}`}
        title={tooltip}
      >
        {label}
      </span>
      {onRemove && (
        <span
          data-test-id={"label-remove"}
          className={`hicon cancel ${textStyle} ${textColor} cursor-pointer`}
          onClick={onRemove}
        />
      )}
    </div>
  );
};

const EnterpriseLabel = () => {
  const context: ManagementPortalContext = useContext(AppContext);
  return (
    <DefaultLabel
      icon={"construction2"}
      label={context.Localized.string("LICENSES.ENTERPRISE")}
      backgroundColor={"bg-colorPrimaryLightTheme"}
      textColor={"text-white"}
      iconColor={"white"}
    />
  );
};

type PlusPlanLabelProps = {
  strikeTrough?: boolean;
};

const PlusPlanLabel = ({ strikeTrough }: PlusPlanLabelProps) => {
  const t = useI18n("");
  return (
    <DefaultLabel
      icon={"plus"}
      label={t("LICENSES.PLUS_PLAN")}
      backgroundColor={"bg-colorButtonPrimary"}
      strikeTrough={strikeTrough}
      tooltip={
        strikeTrough ? t("ACCOUNTS_LIST.EDIT_ACCOUNT.EXPIRED") : undefined
      }
    />
  );
};
