import { HTMLAttributes } from "react";

export const Tile: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = "",
  ...props
}) => (
  <div
    className={`m-2 p-4 shadow-xl bg-colorTableBackground rounded ${className}`}
    {...props}
  >
    {children}
  </div>
);

export const FullPageTile: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = "",
  ...props
}) => (
  <div
    className={`m-2 p-10 shadow-xl bg-colorTableBackground rounded ${className}`}
    {...props}
  >
    {children}
  </div>
);