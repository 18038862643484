import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useI18n } from "../../helpers/hooks/useI18n";
import { MODE } from "./workspace.password";
import {
  calculatePermissions,
  extractPermissions,
  filterExistingPermissions,
  formatWorkspaceId
} from "../../helpers/share.dialog.helpers";
import { LinkSharingSection } from "./link.sharing.section";
import { PermissionsSection } from "./permissions.section";
import { UserData } from "../../state/accounts/types";
import {
  changeWorkspacePassword,
  getDocument,
  removeWorkspacePassword,
  setWorkspacePassword,
  updateDocument
} from "../../api/workspaces";
import { Modal } from "../modals/modal";
import { ModalHeader } from "../modals/modalElements/modal.header";
import { useSelector } from "react-redux";
import { orgPolicyAllOrgAdminsGetAdminAccess } from "../../state/organization/selectors";
import type {
  ApiResponse,
  DocumentMetadataRequest,
  GlobalAccess,
  Workspace
} from "../../api/types";
import { getWorkspaceUrl } from "../../helpers/get.workspace.url";
import { isWorkspaceAdministrator } from "../../helpers/is.workspace.administrator";
import {
  Permissions,
  ShareOptionValue,
  ShareType
} from "../shared/permissions/permissions.types";
import { showErrorToast } from "../../helpers/show.toast.helpers";

export type ShareWorkspaceDialogProps = {
  workspace: Workspace;
  userData: UserData;
  onClose: () => void;
  openPermissionsInitially?: boolean;
  refreshPage: () => void;
};

export const ShareWorkspaceDialog: React.FC<ShareWorkspaceDialogProps> = ({
  workspace,
  userData,
  onClose,
  openPermissionsInitially = false,
  refreshPage
}) => {
  const t = useI18n("SHARE_WORKSPACE.DIALOG.");
  const tError = useI18n("SHARE_WORKSPACE.ERROR.");
  const [currentWorkspace, setCurrentWorkspace] = useState<
    Workspace | undefined
  >(workspace);
  const showAdminPolicy = useSelector(orgPolicyAllOrgAdminsGetAdminAccess);
  const permissionLevelSets = useMemo(() => {
    if (!currentWorkspace) return undefined;
    return filterExistingPermissions(currentWorkspace);
  }, [currentWorkspace]);

  const getWorkspace = useCallback(async () => {
    if (!currentWorkspace?.documentId) return undefined;

    const response = await getDocument(currentWorkspace.documentId);
    let actualDocument: Workspace | undefined = undefined;

    if (response.data) {
      actualDocument = response.data;
    } else {
      showErrorToast("There was problem with refreshing data", response.error);
      closeModal();
    }

    return actualDocument;
  }, [currentWorkspace?.documentId]);

  useEffect(() => {
    const updateCurrentWorkspace = async () => {
      const freshWorkspace = await getWorkspace();
      setCurrentWorkspace(freshWorkspace);
    };

    updateCurrentWorkspace();
  }, [getWorkspace]);

  if (!currentWorkspace) return <></>;

  const permissions: Permissions = extractPermissions(currentWorkspace);
  const isAdmin = isWorkspaceAdministrator(
    userData.email,
    currentWorkspace.administrators,
    showAdminPolicy
  );

  const commitUpdate = async (shareOption: ShareOptionValue) => {
    let globalAccess: GlobalAccess;
    if (shareOption === ShareType.EDIT) {
      globalAccess = "WRITE";
    } else if (shareOption === ShareType.READ) {
      globalAccess = "READ";
    } else {
      globalAccess = "NONE";
    }

    await updatePermissions({
      ...permissions,
      globalAccess
    });
    refreshPage();
  };

  const updatePermissions = async (newPermissions: Permissions) => {
    try {
      const actualDocument = await getWorkspace();
      const response: ApiResponse<Workspace> = await updateDocument({
        ...actualDocument,
        permissions: newPermissions
      } as DocumentMetadataRequest);

      if (response.error) {
        showErrorToast(tError("CANNOT_APPLY_CHANGES"), response.error);
      }

      if (!response || !response.data) return;

      const newWorkspaceData = {
        ...currentWorkspace,
        ...response.data
      };
      refreshPage();
      setCurrentWorkspace(newWorkspaceData);
    } catch (e) {
      showErrorToast(tError("CANNOT_APPLY_CHANGES"), e);
    }
  };

  let publicPermissionLevel = calculatePermissions(permissions.globalAccess);

  const handleWorkspacePassword = (
    password: string,
    currentPassword: string,
    mode: string
  ): void => {
    switch (mode) {
      case MODE.ADD:
        setWorkspacePassword(currentWorkspace.documentId, password)
          .then(r => {
            if (r.error) throw r.error;
            setCurrentWorkspace({
              ...currentWorkspace,
              hasPassword: true
            });
            refreshPage();
          })
          .catch(e => showErrorToast(tError("CANNOT_SET_PASSWORD"), e));
        break;
      case MODE.REMOVE:
        removeWorkspacePassword(currentWorkspace.documentId, password)
          .then(r => {
            if (r.error) throw r.error;
            setCurrentWorkspace({
              ...currentWorkspace,
              hasPassword: false
            });
            refreshPage();
          })
          .catch(e => {
            showErrorToast(tError("CANNOT_REMOVE_PASSWORD"), e);
          });
        break;
      case MODE.CHANGE:
        changeWorkspacePassword(
          currentWorkspace.documentId,
          currentPassword,
          password
        )
          .then(r => {
            if (r.error) throw r.error;
            setCurrentWorkspace({
              ...currentWorkspace,
              hasPassword: true
            });
          })
          .catch(e => showErrorToast(tError("CANNOT_CHANGE_PASSWORD"), e));
        break;
    }
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal handleOutsideClick={closeModal}>
      <div className="share-md:w-176 h-auto" data-test-id="share-dialog">
        <div className={"relative font-lato"}>
          <ModalHeader
            modalTitle={`${t("SHARE_THIS_WORKSPACE")}: ${formatWorkspaceId(
              currentWorkspace.documentId
            )}`}
            closeModal={closeModal}
          />
          <LinkSharingSection
            workspace={currentWorkspace}
            publicPermissionLevel={publicPermissionLevel}
            email={userData.email}
            commitUpdate={commitUpdate}
            isAdmin={isAdmin}
            isOpenInitially={!openPermissionsInitially}
          />
          <PermissionsSection
            workspace={currentWorkspace}
            permissionLevelSets={permissionLevelSets}
            email={userData.email}
            updatePermissions={updatePermissions}
            handleWorkspacePassword={(password, currentPassword, mode) =>
              handleWorkspacePassword(password, currentPassword, mode)
            }
            requiresPassword={false}
            hasPassword={!!currentWorkspace.hasPassword}
            sectionTitle={t("PERMISSIONS")}
            shareLink={getWorkspaceUrl(currentWorkspace.documentId)}
            showOrgAdminPolicy={showAdminPolicy}
            isAdmin={isAdmin}
            isOpenInitially={!!openPermissionsInitially}
          />
        </div>
      </div>
    </Modal>
  );
};
