import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeMembers } from "../../state/organization/actions";
import { sessionSelectors } from "../../state/session";
import { PrimaryButton } from "../shared/button";
import { ConfirmModal } from "../modals/confirm.modal";
import { organizationSelectors } from "../../state/organization";
import { useI18n } from "../../helpers/hooks/useI18n";

export interface RemoveAccountsProps {
  selectedRowIds: Record<string, boolean>;
}

export const RemoveAccounts: React.FC<RemoveAccountsProps> = ({
  selectedRowIds
}) => {
  const t = useI18n("ACCOUNTS_LIST.");
  const tl = useI18n("LINKS.");
  const dispatch = useDispatch();
  const orgId = useSelector(sessionSelectors.orgId);
  const admins = useSelector(organizationSelectors.admins);
  const selectedCount = Object.keys(selectedRowIds).length;
  const [showModal, setShowModal] = useState(false);
  const [accountsToRemove, setAccountsToRemove] = useState({});
  const [isBlockedAdminRemoval, setIsBlockedAdminRemoval] = useState(false);

  const onRemovalInit = () => {
    setShowModal(true);

    const copyOfSelectedRowIds = { ...selectedRowIds };

    admins.forEach((adminId: string) => {
      if (copyOfSelectedRowIds[adminId]) {
        delete copyOfSelectedRowIds[adminId];
        setIsBlockedAdminRemoval(true);
      }
    });

    setAccountsToRemove(copyOfSelectedRowIds);
  };

  const onClose = () => {
    setShowModal(false);
    setIsBlockedAdminRemoval(false);
    setAccountsToRemove({});
  };

  const removedCount = Object.keys(accountsToRemove).length;

  const onConfirm = () => {
    onClose();

    if (removedCount > 0) {
      dispatch(removeMembers(accountsToRemove, orgId));
    }
  };

  return (
    <>
      <PrimaryButton onClick={onRemovalInit} disabled={selectedCount === 0}>
        {t("REMOVE_ACCOUNTS")}
      </PrimaryButton>
      {showModal && (
        <ConfirmModal
          title={t("REMOVE_ACCOUNTS")}
          onOk={onConfirm}
          onCancel={onClose}
        >
          {isBlockedAdminRemoval && (
            <div className="py-4">
              <p>{t("ADMIN_REMOVAL_INFO")}</p>
              <p>
                <span>{`${t("CONTACT_SUPPORT")} - `}</span>
                <a
                  className="text-colorAccent"
                  href={`mailto:${tl("ADMIN_SUPPORT")}`}
                >
                  {tl("ADMIN_SUPPORT")}
                </a>
                .
                {removedCount > 0 && (
                  <span>{` ${t("OTHER_REMOVAL_INFO")}`}</span>
                )}
              </p>
            </div>
          )}
          {removedCount > 0 && t("REMOVE_CONFIRM", removedCount)}
        </ConfirmModal>
      )}
    </>
  );
};
