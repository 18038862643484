import { Connection } from "../../../state/jira/types";
import { JiraConnectionDetails } from "./connection-details";

export type JiraConnectionsProps = {
  connections: Connection[];
};

export const JiraConnections: React.FC<JiraConnectionsProps> = ({
  connections
}) => {
  return (
    <ul>
      {connections.map((connection, index) => {
        return (
          <JiraConnectionDetails
            key={connection.settingName}
            connection={connection}
          />
        );
      })}
    </ul>
  );
};
