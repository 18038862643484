import React, { useContext } from "react";
import { PermissionsPillDivider, PermissionsPill } from "./permissions.pill";
import { AppContext, ManagementPortalContext } from "../../../context";
import { NOOP } from "../../../helpers/noop";

export type PermissionsOptionsProps = {
  canBeEdited: boolean;
  roleType: "admin" | "basic";
  onPermissionChange?: (type: "admin" | "basic") => void;
};

export const ProjectPermissions: React.FC<PermissionsOptionsProps> = ({
  canBeEdited,
  roleType,
  onPermissionChange = NOOP
}) => {
  const context: ManagementPortalContext = useContext(AppContext);

  return (
    <div data-test-id={"permissions"} className={"flex items-center"}>
      <PermissionsPill
        label={context.Localized.string("PERMISSIONS.ADMIN")}
        onClick={() => onPermissionChange("admin")}
        color={"colorPermissionAdmin"}
        isActive={roleType === "admin"}
        canBeEdited={canBeEdited}
      />
      <PermissionsPillDivider />
      <PermissionsPill
        label={context.Localized.string("PERMISSIONS.BASIC")}
        onClick={() => onPermissionChange("basic")}
        color={"colorPermissionBasic"}
        isActive={roleType === "basic"}
        canBeEdited={canBeEdited}
      />
    </div>
  );
};
