import { AgilityConnection } from "./types";
import { RootState } from "typesafe-actions";

export const getConnections = (state: RootState): AgilityConnection[] => {
  return state.agility.payload ? state.agility.payload : [];
};

export const loading = (state: RootState): boolean => {
  return state.agility.loading ? state.agility.loading : false;
};

export const error = (state: RootState): boolean => {
  return state.agility.error ? state.agility.error : false;
};
