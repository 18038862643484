import { UserData, UserProfile } from "../state/accounts/types";

/**
 * Converts {@link UserProfile} to {@link UserData}.
 * Since we don't have information about org and partial account in UserProfile,
 * we should provide it externally (eg. from user token).
 * */
export const userProfileToUserData = (
  userProfile: UserProfile,
  isPartialAccount: boolean = false,
  org: string = ""
): UserData => {
  return {
    uid: userProfile.uuid,
    firstName: userProfile.fName,
    lastName: userProfile.lName,
    email: userProfile.email,
    org,
    companyName: userProfile.company,
    department: userProfile.department,
    gdprConsentDate: userProfile.gdprConsentTime,
    lastLoginMillis: userProfile.lastLoginInfo.lastLoginTime,
    isPartialAccount
  };
};
