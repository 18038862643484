import React, { useMemo } from "react";
import { LinkNavBar, Tab } from "../navbar/linknavbar";
import { Billing } from "../tabs/billing/billing";
import { ProfileInfo } from "../tabs/profile.info/profile.info";
import { useSelector } from "react-redux";
import { projectsSelectors } from "../../state/projects";
import { Projects } from "../navbar/tabs/projects";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  getSubscriptions,
  getCustomerPortalSessionUrl,
  getCheckoutSessionUrl,
  getProducts
} from "../../api/subscriptions";
import {
  BillingPath,
  ProfileInfoPath,
  ProjectsPath,
  SingleProjectPath
} from "../../routes";
import { useI18n } from "../../helpers/hooks/useI18n";
import { featureFlagsSelectors } from "../../state/featureFlags";
import { sessionSelectors } from "../../state/session";
import { ProjectPage } from "../projects.list/project.page/project.page";

export const ProfilePanel: React.FC = () => {
  const t = useI18n("NAVBAR.");
  const isAdmin = useSelector(sessionSelectors.isAdmin);
  const isProjectAdmin = useSelector(projectsSelectors.isProjectAdmin);
  const showProjects = useSelector(featureFlagsSelectors.showProjects);
  const enableProjectsTab = (isAdmin || isProjectAdmin) && showProjects;

  const tabs = useMemo(() => {
    const tabs: Tab[] = [
      {
        title: t("PROFILE"),
        path: ProfileInfoPath,
        element: <ProfileInfo />
      },
      {
        title: t("BILLING"),
        path: BillingPath,
        element: (
          <Billing
            getSubscriptions={getSubscriptions}
            getCustomerPortalSessionUrl={getCustomerPortalSessionUrl}
            getProducts={getProducts}
            getCheckoutSessionUrl={getCheckoutSessionUrl}
          />
        )
      }
    ];
    if (enableProjectsTab)
      tabs.push({
        title: t("PROJECTS"),
        path: ProjectsPath,
        element: <Projects />
      });
    return tabs;
  }, [t, enableProjectsTab]);

  return (
    <div className="flex items-start justify-center flex-1">
      <div className="container h-full flex flex-col justify-start">
        <LinkNavBar tabs={tabs} />
        <div
          className="container bg-colorTableHeadPortalBackground p-4 overflow-y-auto"
          style={{ flex: "1 1 0px" }}
        >
          <Routes>
            {tabs.map(t => (
              <Route path={t.path} key={t.path} element={t.element} />
            ))}
            <Route
              key={"Project page"}
              path={SingleProjectPath}
              element={<ProjectPage />}
            />
            <Route
              path="*"
              element={<Navigate to={ProfileInfoPath} replace />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};
