import React from "react";
import { Localized } from "./Localized";
import { English } from "../_localized/en";

export const AppContext = React.createContext<ManagementPortalContext>(
  {} as ManagementPortalContext
);
AppContext.displayName = "AppContext";

// main source of the data for the application should be the Redux store, we want to use context only for localizations
export class ManagementPortalContext {
  readonly Localized: Localized;

  constructor() {
    this.Localized = new Localized();
    this.Localized.pushLocale("en", English);
  }
}
