import { isProjectLimitReached } from "./is.limit.reached";
import React from "react";

type ProjectLimitInformationProps = {
  limit: number | null;
  currentAmount: number;
};

export const ProjectLimitInformation = ({
  limit,
  currentAmount
}: ProjectLimitInformationProps): JSX.Element => {
  return (
    <span
      className={`p-1 ${
        isProjectLimitReached(limit, currentAmount) ? "text-colorWarning" : ""
      }`}
    >
      {currentAmount}/{limit === -1 ? "∞" : limit}
    </span>
  );
};
