import React from "react";
import { Row } from "react-table";
import { Project } from "../../state/projects/types";
import { Icon } from "../shared/icon";
import { useSelector } from "react-redux";
import { organizationSelectors } from "../../state/organization";
import { useI18n } from "../../helpers/hooks/useI18n";

export type ProjectNameCellProps = {
  row: Row<Project>;
};

export const ProjectNameCell = ({ row }: ProjectNameCellProps) => {
  const organization = useSelector(organizationSelectors.organization);
  const t = useI18n(`PROJECTS.`);

  const projectBelongsToTheOrg = row.original.ownerId === organization.uid;
  const classNames =
    "flex max-h-12 overflow-hidden" +
    (!projectBelongsToTheOrg ? " text-colorTextAndIconLighterLightTheme" : "");
  const title = projectBelongsToTheOrg
    ? organization.name // organization can be an empty object, so we check if name exists
      ? `${organization.name}: ${row.original.name}`
      : row.original.name
    : `${t("EXTERNAL_ORG")} ${row.original.name}`;

  return (
    <div className={classNames}>
      <div className="self-center flex justify-center items-center bg-white w-7 h-7 mr-2 flex-shrink-0 rounded border">
        <Icon
          icon={row.original.appearanceProperties?.icon ?? ""}
          color={row.original.appearanceProperties?.color}
        />
      </div>
      <div className="font-bold" title={title}>
        {row.original.name}
      </div>
    </div>
  );
};
