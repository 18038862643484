import { NavLink } from "react-router-dom";

export interface Tab {
  title: string;
  path: string;
  element: React.ReactNode;
}

interface LinkNavBarProps {
  tabs: Tab[];
}

export const LinkNavBar: React.FC<LinkNavBarProps> = ({ tabs }) => {
  return (
    <div className="px-12 flex justify-between" data-test-id="navbar">
      <div className="p-4 flex flex-auto justify-start space-x-20 flex-grow-0 text-base">
        {tabs.map(t => (
          <NavLink
            key={t.title}
            to={t.path}
            className={({ isActive }) => getStyle(isActive)}
          >
            {t.title.toUpperCase()}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export function getStyle(isSelected: boolean): string {
  if (isSelected) {
    return "text-colorAccent font-bold border-b-2 border-colorAccent self-end cursor-pointer";
  } else {
    return "font-bold self-end cursor-pointer";
  }
}
