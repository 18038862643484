import { NOOP } from "../../helpers/noop";
import React, { useState } from "react";

export type Primitive = string | number | boolean;

export type SimpleDropdownProps<T extends Primitive> = {
  options: readonly T[];
  currentValue?: T;
  heading: string;
  handleChange?: (newValue: T) => void;
  width?: string; // tailwind style that will be applied to component
  textSize?: string; // name of the text size e.g. "xs"
};

export function SimpleDropdown<T extends Primitive>({
  options,
  currentValue,
  heading,
  handleChange = NOOP,
  width,
  textSize = "base"
}: SimpleDropdownProps<T>) {
  const [open, setOpen] = useState(false);
  const shouldBeDisabled = options.length === 0;
  const widthStyle = width ? width : "w-fit";
  const mainStyles = `select-none flex items-center cursor-pointer ${widthStyle}`;
  const textStyle = `text-colorTextAndIconLighterLightTheme text-${textSize}`;
  const iconStyles =
    "text-colorTextAndIconLighterLightTheme " +
    "hicon ml-auto " +
    `${!shouldBeDisabled ? "hover:text-blue-100" : ""}`;
  const disabledStyle = options.length === 0 ? "pointer-events-none" : "";
  return (
    <div
      data-test-id={"simple-dropdown"}
      tabIndex={0}
      onClick={() => setOpen(!open)}
      onBlur={() => setOpen(false)}
      className={`relative ${widthStyle} ${disabledStyle}`}
    >
      <div className={`${mainStyles} ${width ? "" : "min-w-dropdown"}`}>
        <span
          data-test-id={"simple-dropdown-heading"}
          className={`${textStyle} truncate`}
          title={currentValue ? `${currentValue}` : heading}
        >
          {currentValue ?? heading}
        </span>
        {!open ? (
          <span
            data-test-id={"dropdown-arrow-down"}
            className={`${iconStyles} triangledown`}
          />
        ) : (
          <span
            data-test-id={"dropdown-arrow-up"}
            className={`${iconStyles} triangleup`}
          />
        )}
      </div>
      {open && (
        <div
          data-test-id={"dropdown-popup"}
          className="absolute top-full mt-1 pt-px-8 w-fit bg-colorTableBackground z-100"
        >
          <ul
            role="listbox"
            className={`max-h-xs overflow-y-auto overflow-x-hidden w-fit min-w-full max-w-80 rounded shadow-md customScrollbar`}
          >
            {options.map((o, i) => {
              return (
                <li
                  className="m-0 list-none py-0.5 px-1.5 hover:bg-blue-100"
                  key={i}
                  role="option"
                  title={o ? `${o}` : ""}
                  onClick={e => {
                    handleChange(o);
                    setOpen(false);
                    e.stopPropagation();
                  }}
                >
                  <label
                    className={`${textStyle} box-border cursor-pointer block outline-0 p-px-5 truncate max-w-10`}
                  >
                    {o}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
