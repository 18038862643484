import { Select } from "../../input";
import React from "react";
import { AppContext } from "../../../context";
import { useContext } from "react";

type PageSizeSelectProps = {
  pageSize: number;
  setPageSize: (number: number) => void;
  pageSizeOptions?: number[];
};

const defaultPageSizeOptions = [10, 20, 30, 40, 50];

export const PageSizeSelect = ({
  pageSize,
  setPageSize,
  pageSizeOptions = defaultPageSizeOptions
}: PageSizeSelectProps): JSX.Element => {
  const context = useContext(AppContext);

  return (
    <Select
      h={6}
      value={pageSize}
      onChange={e => {
        setPageSize(Number(e.target.value));
      }}
    >
      {pageSizeOptions.map(pageSize => (
        <option key={pageSize} value={pageSize}>
          {context.Localized.string(
            "ACCOUNTS_LIST.PAGINATION.SHOW_NUMBER_PAGES",
            pageSize
          )}
        </option>
      ))}
    </Select>
  );
};
