import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Column,
  TableInstance,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable
} from "react-table";
import { AppContext } from "../../context";
import { printNiceDayDate } from "../../helpers/print.nice.day.date";
import { organizationSelectors } from "../../state/organization";
import { projectsActions, projectsSelectors } from "../../state/projects";
import { Project } from "../../state/projects/types";
import { PrimaryButton, SecondaryButton } from "../shared/button";
import {
  ColorItemRenderer,
  Dropdown,
  IconItemRenderer,
  Option
} from "../shared/dropdown";
import { CollaboratorsCell } from "./collaborators.cell";
import { DatesCell } from "./dates.cell";
import { GlobalFilter } from "../accounts-list/global-filter";
import { Table } from "../shared/table/table";
import { prepareProjectsCSV } from "./csv.export";
import { LocalizingFunction, useI18n } from "../../helpers/hooks/useI18n";
import { Spinner } from "../spinner";
import { sessionSelectors } from "../../state/session";
import { PROJECT_ADMIN_ID } from "../../api/collaborators.roles.ids";
import { ProjectNameCell } from "./project.name.cell";
import { useNavigate } from "react-router-dom";
import { ProjectEditorModal } from "./project.editor/project.editor.modal";
import { usePanelDetector } from "../../helpers/hooks/usePanelDetector";

const editColumn = (
  label: string,
  t: LocalizingFunction,
  navigateToProject,
  isAdmin: boolean,
  orgId: string
): Column<Project> => ({
  id: "edit",
  disableSortBy: true,
  width: "5.5rem",
  Cell: ({ row }) => {
    const isReadonly =
      !(isAdmin && row.original.ownerId === orgId) &&
      row.original.userRole?.roleId !== PROJECT_ADMIN_ID;
    return (
      <SecondaryButton
        disabled={isReadonly}
        onClick={() => navigateToProject(row.original)}
        title={isReadonly ? t("YOU_ARE_NOT_THE_PROJECT_ADMIN") : ""}
      >
        {label}
      </SecondaryButton>
    );
  }
});

export const ProjectsList = () => {
  const context = useContext(AppContext);
  const dispatch = useDispatch();
  const isAdmin = useSelector(sessionSelectors.isAdmin);
  const projects = useSelector(projectsSelectors.projects);
  const orgId = useSelector(organizationSelectors.uid);
  const isLoading = useSelector(projectsSelectors.loading);
  const [editCollaborators, setEditCollaborators] = useState(false);
  const [editedProject, setEditedProject] = useState<Project | undefined>(
    undefined
  );
  const [preparingExport, setPreparingExport] = useState(false);
  const t = useI18n(`PROJECTS.`);
  const getRowId = useMemo(() => (project: Project) => project.id, []);
  const navigate = useNavigate();
  const { isUserProfilePanel } = usePanelDetector();

  useEffect(() => {
    dispatch(projectsActions.getMyProjectsAsync.request());
  }, [dispatch]);

  const showProjectEditor = (project: Project, editCollaborators) => {
    setEditCollaborators(editCollaborators);
    setEditedProject(project);
  };

  const navigateToProject = useCallback(
    (project: Project) => {
      navigate(`${project.id}`);
    },
    [navigate]
  );

  const getActionHandler = useCallback(() => {
    return isUserProfilePanel ? navigateToProject : showProjectEditor;
  }, [isUserProfilePanel, navigateToProject]);

  const closeEditor = () => {
    setEditCollaborators(false);
    setEditedProject(undefined);
  };

  const columns = useMemo(
    (): Column<Project>[] => [
      {
        Header: t("NAME"),
        accessor: "name",
        id: "name",
        width: "25%",
        Cell: ({ row }) => <ProjectNameCell row={row} />,
        filter: (rows, columnsIds, filterValue) => {
          return rows.filter(row => {
            const hasProperIcon = filterValue.icon
              ? row.original.appearanceProperties?.icon === filterValue.icon
              : true;
            const hasProperColor = filterValue.color
              ? row.original.appearanceProperties?.color === filterValue.color
              : true;
            return hasProperIcon && hasProperColor;
          });
        }
      },
      {
        Header: t("DESCRIPTION"),
        accessor: (project: Project) => project.description,
        id: "description",
        width: "25%",
        Cell: ({ row }) => (
          <div
            className="text-sm text-colorTextAndIconLighterLightTheme max-h-10 overflow-hidden flex-shrink-1"
            title={row.original.description}
          >
            {row.original.description}
          </div>
        )
      },
      {
        Header: t("STATUS"),
        accessor: "status",
        id: "status",
        width: "8%",
        filter: (rows, columnsIds, filterValue) => {
          return rows.filter(
            row =>
              row.original.status.toLowerCase() === filterValue.toLowerCase()
          );
        },
        Cell: ({ row }) => (
          <div
            className={`text-${
              row.original.status.toLowerCase() === "active"
                ? "colorTextAndIconLighterLightTheme"
                : "colorWarning"
            }`}
          >
            {row.original.status.toLowerCase() === "active"
              ? t("ACTIVE")
              : t("INACTIVE")}
          </div>
        )
      },
      {
        Header: t("DURATION"),
        id: "dates",
        width: "10%",
        Cell: ({ row }) => (
          <DatesCell
            startDate={row.original.startDate}
            endDate={row.original.endDate}
          />
        ),
        filter: (rows, columnsIds, filterValue) => {
          return rows.filter(row => {
            const hasProperStartDate =
              typeof filterValue.startDate === "string"
                ? row.original.startDate === filterValue.startDate
                : true;
            const hasProperEndDate =
              typeof filterValue.endDate === "string"
                ? row.original.endDate === filterValue.endDate
                : true;
            return hasProperStartDate && hasProperEndDate;
          });
        }
      },
      {
        Header: t("COLLABORATORS"),
        id: "collaborators",
        width: "25%",
        Cell: ({ row }) => (
          <CollaboratorsCell
            project={row.original}
            showProjectEditor={getActionHandler()}
          />
        ),
        filter: (rows, columnsIds, filterValue) =>
          rows.filter(row =>
            filterValue.collaboratorProjectsIds.includes(row.original.id)
          )
      }
    ],
    [t, getActionHandler]
  );

  const data = useMemo(() => projects, [projects]);

  const table: TableInstance<Project> = useTable(
    {
      columns,
      data,
      getRowId,
      autoResetSortBy: false
    },
    useGlobalFilter,
    useFilters,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        ...columns,
        editColumn(
          context.Localized.string("ACCOUNTS_LIST.EDIT"),
          t,
          getActionHandler(),
          isAdmin,
          orgId
        )
      ]);
    }
  );

  const { setFilter, setAllFilters, state } = table;

  const getColumnFilters = (columnId: string) => {
    return state.filters.find(filter => columnId === filter.id);
  };

  const setIconFilter = (iconOption: Option<string>) => {
    const currentFilter = getColumnFilters("name");
    setFilter("name", { ...currentFilter?.value, icon: iconOption.value });
  };

  const setColorFilter = (colorOption: Option<string>) => {
    const currentFilter = getColumnFilters("name");
    setFilter("name", { ...currentFilter?.value, color: colorOption.value });
  };

  const setStateFilter = (statusOption: Option<string>) => {
    setFilter("status", statusOption.value);
  };

  const setStartFilter = (startOption: Option<string>) => {
    const currentFilter = getColumnFilters("dates");
    setFilter("dates", {
      ...currentFilter?.value,
      startDate: startOption.value
    });
  };

  const setEndFilter = (endOption: Option<string>) => {
    const currentFilter = getColumnFilters("dates");
    setFilter("dates", { ...currentFilter?.value, endDate: endOption.value });
  };

  const valuesForOptions = useMemo(() => {
    let values = {
      colors: new Set<string>([]),
      icons: new Set<string>([]),
      startDates: new Set<string>([]),
      endDates: new Set<string>([])
    };
    data.forEach(project => {
      if (project.appearanceProperties?.color)
        values.colors.add(project.appearanceProperties.color);
      if (project.appearanceProperties?.icon)
        values.icons.add(project.appearanceProperties.icon);
      if (project.startDate) values.startDates.add(project.startDate);
      if (project.endDate) values.endDates.add(project.endDate);
    });
    return values;
  }, [data]);

  const iconDropdownOptions: Option<string>[] = useMemo(() => {
    return Array.from(valuesForOptions.icons).map(icon => ({
      label: icon,
      value: icon
    }));
  }, [valuesForOptions.icons]);

  const colorDropdownOptions: Option<string>[] = useMemo(() => {
    return Array.from(valuesForOptions.colors).map(color => ({
      label: color,
      value: color
    }));
  }, [valuesForOptions.colors]);

  const statusOptions = useMemo(
    () => [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "Inactive" }
    ],
    []
  );

  const startDateOptions = useMemo(() => {
    return Array.from(valuesForOptions.startDates).map(date => ({
      label: printNiceDayDate(date, t("NOT_STARTED")),
      value: date
    }));
  }, [valuesForOptions.startDates, t]);

  const endDateOptions = useMemo(() => {
    return Array.from(valuesForOptions.endDates).map(date => ({
      label: printNiceDayDate(date, t("NA")),
      value: date
    }));
  }, [valuesForOptions.endDates, t]);

  const exportProjects = async () => {
    setPreparingExport(true);
    await prepareProjectsCSV(context.Localized, projects);
    setPreparingExport(false);
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-4 mb-8">
          <div className="py-1">{t("FILTER")}</div>
          <Dropdown
            options={iconDropdownOptions}
            heading={t("ICONS")}
            handleChange={setIconFilter}
            icon={getColumnFilters("name")?.value.icon}
            ItemRenderer={IconItemRenderer}
            width={28}
            applyFlex={true}
          />
          <Dropdown
            options={colorDropdownOptions}
            heading={t("COLORS")}
            handleChange={setColorFilter}
            icon={getColumnFilters("name")?.value.color ? "dice0" : ""}
            iconColor={getColumnFilters("name")?.value.color}
            ItemRenderer={ColorItemRenderer}
            width={28}
            applyFlex={true}
          />
          <Dropdown
            options={statusOptions}
            heading={t("STATUS")}
            currentValue={getColumnFilters("status")?.value}
            handleChange={setStateFilter}
            width={28}
          />
          <Dropdown
            options={startDateOptions}
            heading={t("START")}
            currentValue={getColumnFilters("dates")?.value.startDate}
            handleChange={setStartFilter}
            width={30}
          />
          <Dropdown
            options={endDateOptions}
            heading={t("END")}
            currentValue={getColumnFilters("dates")?.value.endDate}
            handleChange={setEndFilter}
            width={30}
          />
          <PrimaryButton
            additionalStyling="self-end"
            onClick={() => setAllFilters([])}
          >
            {t("RESET")}
          </PrimaryButton>
          <div>
            <PrimaryButton
              onClick={exportProjects}
              disabled={preparingExport || isLoading}
            >
              {t("EXPORT_DATA")}
            </PrimaryButton>
            {preparingExport && (
              <Spinner
                classNames="inline-block mt-2 ml-2 absolute"
                size={4}
                useSpinnerClass={false}
              />
            )}
          </div>
        </div>
        <GlobalFilter {...table} placeholder={t("SEARCH_PLACEHOLDER")} />
      </div>
      <Table {...table} />
      {editedProject && (
        <ProjectEditorModal
          project={editedProject}
          onClose={closeEditor}
          editCollaborators={editCollaborators}
        />
      )}
    </>
  );
};
