import React from "react";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { isUserData, UserData } from "../../state/accounts/types";
import { AvatarDetails } from "./avatar.details";

export const assignColor = (initials: string): string => {
  const COLORS = [
    "#58f7a6",
    "#ff7bab",
    "#cc7cff",
    "#8c6e63",

    "#01a89c",
    "#e76161",
    "#9474cc",
    "#c59b6d",

    "#6eae3d",
    "#fbaf3d",
    "#4c70c5",
    "#778f9b",

    "#b8e942",
    "#ff7b54",
    "#5b9de7",
    "#b2b2b2",

    "#ffff5c",
    "#f9cf28",
    "#77e6e9",
    "#dfdfdf"
  ];
  let colorIndex = 0;
  if (initials) {
    colorIndex += initials.charCodeAt(0);
    if (initials[1]) {
      colorIndex += initials.charCodeAt(1);
    }
  }
  return COLORS[colorIndex % COLORS.length].toString();
};

export type AvatarProps = {
  user: UserData | string; // if app has no data of the given user send here an email
  textStyle?: string;
  circleStyle?: string;
  onClick?: () => void;
  avatarDetailsPosition?: string; // tailwind classes
};

export const Avatar = ({
  textStyle = "font-bold font-black",
  circleStyle = "w-16 h-16",
  onClick = () => {},
  user,
  avatarDetailsPosition = ""
}: AvatarProps): JSX.Element => {
  const [showDetails, setShowDetails] = useState(false);

  let name =
    typeof user === "string"
      ? user
      : `${user.firstName ? user.firstName : ""} ${
          user.lastName ? user.lastName : ""
        }`;

  const initials = name
    .trim()
    .split(" ")
    .map(chars => chars[0])
    .slice(0, 2)
    .join("");

  const avatarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = avatarRef.current;
    const openDetails = () => setShowDetails(true);
    const closeDetails = () => setShowDetails(false);

    if (element) {
      element.addEventListener("mouseenter", openDetails);
      element.addEventListener("mouseleave", closeDetails);
      return () => {
        element.removeEventListener("mouseenter", openDetails);
        element.removeEventListener("mouseleave", closeDetails);
      };
    }
  }, [avatarRef]);

  return (
    <div
      style={{
        backgroundColor: assignColor(initials)
      }}
      className={`relative flex justify-center content-center self-center rounded-full ${circleStyle} ${
        showDetails ? "z-10" : ""
      }`}
      onClick={onClick}
      ref={avatarRef}
      title={isUserData(user) ? "" : user}
    >
      <span className={`self-center ${textStyle}`}>{initials}</span>
      {showDetails && isUserData(user) && (
        <AvatarDetails user={user} positioningClasses={avatarDetailsPosition} />
      )}
    </div>
  );
};
