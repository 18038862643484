import { Pagination } from "./pagination";
import React from "react";
import { ColumnInstance, TableInstance } from "react-table";

export const SortIndicator = <T extends object>({
  isSorted,
  isSortedDesc,
  canSort
}: ColumnInstance<T>) => {
  if (!canSort) return null;

  return (
    <span className="relative inline-block w-6 h-3">
      <span
        className={`hicon triangleup absolute bottom-0 ${
          isSorted && !isSortedDesc ? "" : "text-colorPrimary"
        }`}
      />
      <span
        className={`hicon triangledown absolute top-0 ${
          isSorted && isSortedDesc ? "" : "text-colorPrimary"
        }`}
      />
    </span>
  );
};

export type TableProps<T extends object> = Pick<
  TableInstance<T>,
  | "getTableProps"
  | "headerGroups"
  | "getTableBodyProps"
  | "page"
  | "prepareRow"
  | "rows"
  | "canPreviousPage"
  | "canNextPage"
  | "pageCount"
  | "gotoPage"
  | "nextPage"
  | "previousPage"
  | "setPageSize"
  | "state"
> & { useTablePagination?: boolean };

export const Table = <T extends object>(props: TableProps<T>): JSX.Element => {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    rows,
    prepareRow
  } = props;

  const showPagination =
    props.useTablePagination === undefined ? true : props.useTablePagination;

  return (
    <>
      <div
        data-test-id={"table"}
        className="table mb-4"
        {...getTableProps({ style: { width: "100%" } })}
      >
        <div className="thead text-colorTextAndIconLighterLightTheme">
          {headerGroups.map(headerGroup => (
            <div
              className="tr flex items-center px-2 border-b mb-2"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map(column => {
                let headerProps = column.getHeaderProps({
                  style: { width: column.width }
                });
                if (column.canSort) {
                  headerProps = {
                    ...headerProps,
                    ...column.getSortByToggleProps({
                      style: { width: column.width }
                    })
                  };
                }
                return (
                  <div className="th inline-block p-1 text-sm" {...headerProps}>
                    {column.render("Header")}
                    <SortIndicator {...column} />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="tbody" {...getTableBodyProps()}>
          {(page || rows).map(row => {
            prepareRow(row);
            return (
              <div
                className="tr flex items-center bg-colorHoverLightTheme mb-2 rounded px-2"
                {...row.getRowProps()}
              >
                {row.cells.map(cell => {
                  return (
                    <div
                      className="td p-1"
                      {...cell.getCellProps({
                        style: { width: cell.column.width }
                      })}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {showPagination && <Pagination {...props} />}
    </>
  );
};
