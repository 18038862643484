import React from "react";
import { RefObject } from "react";
import { InputTopLabel } from "./sections/input.top.label";

export type InputProps = {
  label: string;
  value?: string | null;
  disabled?: boolean;
  toolTip?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  type?: string;
  isFocus?: boolean;
  error?: string;
  onBlur?: () => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  parentRef?: RefObject<HTMLInputElement>;
  onChange?: (value: string) => void;
};

export function Input({
  label,
  value,
  onChange = () => {},
  disabled = false,
  toolTip,
  style,
  inputStyle,
  placeholder,
  error,
  type = "text",
  isFocus,
  onBlur,
  onKeyUp,
  parentRef
}: InputProps) {
  return (
    <div
      data-test-id={"text-input"}
      className={`p-1 pb-0 mb-1 border-b border-solid border-${
        error ? "colorWarning" : "white"
      } rounded-t-lg bg-${disabled ? "colorForegroundLightTheme" : "white"}`}
      style={style}
    >
      <InputTopLabel text={label} error={error} />
      <input
        ref={parentRef}
        aria-label={error || label}
        autoFocus={isFocus}
        className="bg-color-transparent text-sm border-none outline-none w-full"
        style={inputStyle}
        value={value == null ? undefined : value}
        disabled={disabled}
        title={error ?? toolTip ?? value ?? ""}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        type={type}
        onBlur={() => onBlur && onBlur()}
        onKeyUp={e => onKeyUp && onKeyUp(e)}
      />
    </div>
  );
}
