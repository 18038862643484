import { useI18n } from "../../../../helpers/hooks/useI18n";
import { Header } from "../../../navbar/header";
import { PrimaryButton } from "../../../shared/button";
import { PlanTile } from "./plan.tile";

export const Enterprise: React.FC = () => {
  const t = useI18n("BILLING.PLANS.ENTERPRISE.");
  const tp = useI18n("BILLING.PLANS.");
  const tl = useI18n("LINKS.");

  const contactSales = () => window.open(`mailto:${tl("SALES")}`, "_blank");

  return (
    <PlanTile>
      <Header title={t("TITLE")} />
      {t("DESCRIPTION")}
      <ul className="list-disc ml-5">
        <li>{t("MANAGEMENT_PORTAL")}</li>
        <li>{t("SSO_OKTA_AZURE_AD")}</li>
        <li>{t("USER_DOMAIN")}</li>
        <li>{t("DEDICATED_CUSTOMER_SUCCESS_MANAGER")}</li>
      </ul>
      <div className="grow" />
      <div className="flex justify-end">
        <PrimaryButton onClick={contactSales}>
          {tp("CONTACT_SALES")}
        </PrimaryButton>
      </div>
    </PlanTile>
  );
};
