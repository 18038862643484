import { Group } from "../state/groups/types";
import { LoginType } from "@hoylu/login";
import { Api } from "typesafe-actions";

declare module "typesafe-actions" {
  export type Api = typeof import("./index").default;
}

export type ApiResponse<DataType = void> = {
  data?: DataType | null;
  error?: string;
  status?: number;
};

export type ApiType = Api;

export type ResponsePromise<DataType = void> = Promise<ApiResponse<DataType>>;

type Token = string;

export type HoyluAuthResponse = {
  token: Token;
  loginType?: LoginType;
};

// typed is only the part of config which is used by management portal, the object has more properties
export interface ConfigData {
  serviceConfig: {
    auth: string;
    login: string;
    jira: string;
    agility: string;
    licensing: string;
    documentMetadata: string;
    hoylu_app: string;
    management_portal: string;
  };
  launchDarkly: {
    clientId: string;
  };
}

export interface LicenceAssignmentParameters {
  orgId: string;
  licenseId: string;
  userId: string;
}

export interface LicenceExtendParameters {
  orgId: string;
  licenseIds: string[];
  extendByMonths: number;
}

export interface LicenseCreateParameters {
  orgId: string;
  count: number;
  expireInMonths: number;
}

export interface HoyluAzureGroupDataResponse {
  nextPageKey: string;
  groupList: Group[];
}

export interface InvitationResponse {
  [key: string]: string;
}

export interface SupportedPoliciesResponse {
  [key: string]: string;
}

export interface ActivePoliciesResponse {
  policies: string[];
}

export interface SavePoliciesResponse {
  active: string[];
  rejected: { [key: string]: string };
}

export interface EditUserDepartmentResponse {
  company: string;
  department: string;
  email: string;
  emailValidated: boolean;
  fName: string;
  lName: string;
  lastLogin: number;
  licenseExpire: number;
  licenseId: string;
  type: string;
  uid: string;
}

export interface InvitedUser {
  /** Email of user to be invited. Note: credentials.email needs to be set to the same value, because the auth service expects this. */
  email: string;
  credentials: { email: string };
  /** Firstname, not used by auth-service, but cannot be undefined either */
  firstName: string;
  /** Lastname, not used by auth-service, but cannot be undefined either */
  lastName: string;
  department?: string;
}

export type DocumentMetadataRequest = {
  documentId?: string;
  documentType: "HOYLU" | "SKETCH" | "FLOW" | "EXPERIMENTAL";
  documentName: string;
  schemaVersion?: number;
  module: { name: string; configuration?: Object };
  pageSize?: { width: number; height: number };
  permissions?: {
    administrators: string[];
    readers: string[];
    writers: string[];
    unspecifiedUsers: string[];
    globalAccess: GlobalAccess;
  };
  password?: string;
};

/**
 * Used by documentid-server v1 responses
 * the name of the type is unified with the name used by Dashboard
 */
export type DocumentMetadataV1Response = {
  documentId: string;
  documentType: WorkspaceType;
  owners: string[];
  orgs: string[];
  documentName: string;
  createTime: number;
  lastModified: number;
  schemaVersion: number;
  module: { name: string; configuration: Object };
  pageSize: { width: number; height: number };
  administrators: string[];
  readers: string[];
  writers: string[];
  hasPassword: boolean;
  globalAccess: GlobalAccess;
  unspecifiedUsers: string[];
  labels?: WorkspaceLabels;
  containerId?: string; // container the workspace is in (can be a projectId)
  weight: number | null;
  templateId?: string; // Template workspaces
};
/**
 * alias name for DocumentMetadataV1Response as it is used as a source of workspace data in almost whole management portal
 */
export type Workspace = DocumentMetadataV1Response;

/**
 * Used by documentid-server v3 responses
 * the name of the type is unified with the name used by Dashboard
 */
export interface MyWorkspaceMetadataV3 {
  containerId: string | null;
  createTime: string; // ISO 8601
  hasPassword: boolean;
  id: number;
  isHidden: boolean | null;
  labels: Label[] | null;
  lastAccess: string | null; // ISO 8601
  lastModified: string; // ISO 8601
  module: {
    name: string;
    configuration: Object | null;
  };
  myPermissions: MyWorkspacePermissions;
  orgId: string;
  schemaVersion: number;
  templateId: string | null;
  weight: number | null;
  workspaceId: string;
  workspaceName: string | null;
  workspaceType: WorkspaceType;
}

export type MyWorkspacePermissions = {
  canRead: boolean;
  canWrite: boolean;
  canAdmin: boolean;
  canExport: boolean;
  canGrow: boolean;
  canLeave: boolean;
};

export type GlobalAccess = "WRITE" | "READ" | "NONE";
export type Label = { name: string };
export type WorkspaceLabels = Label[];
export type WorkspaceType =
  | "HOYLU"
  | "SKETCH"
  | "FLOW"
  | "EXPERIMENTAL"
  | "DELETED";

export interface WorkspacesResponse {
  workspaces: DocumentMetadataV1Response[];
  next?: string;
}

export enum WorkspacesSort {
  nameDescending = "-name",
  nameAscending = "name",
  createDescending = "-createTime",
  createAscending = "createTime",
  // the two below are offered by backend but not used in our table
  typeDescending = "-type",
  typeAscending = "type"
}
