import { useDispatch, useSelector } from "react-redux";
import { licensesSelectors } from "../../../state/licenses";
import { Subscriptions, SubscriptionsProps } from "./subscriptions";
import { Header } from "../../navbar/header";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { Plans, PlansProps } from "./plans/plans";
import { isEnterprise } from "../../../state/licenses/types";
import { OrganizationLicense } from "./organization.license";
import { useEffect } from "react";
import { getLicenseInfoAsync } from "../../../state/licenses/actions";
import { Spinner } from "../../spinner";

interface BillingProps extends SubscriptionsProps, PlansProps {}

export const Billing: React.FC<BillingProps> = ({
  getSubscriptions,
  getCustomerPortalSessionUrl,
  getProducts,
  getCheckoutSessionUrl
}) => {
  const dispatch = useDispatch();
  const t = useI18n("BILLING.");

  const licenseInfo = useSelector(licensesSelectors.licenseInfo);
  const isLicenseLoading = useSelector(licensesSelectors.selectLicenseInfoLoading);

  const isEnterpriseLicense = isEnterprise(licenseInfo?.type);
  const showOrganizationLicense = !isLicenseLoading && isEnterpriseLicense;
  const showPlans = !isLicenseLoading && !isEnterpriseLicense

  useEffect(() => {
    dispatch(getLicenseInfoAsync.request());
  }, [dispatch]);

  return (
    <>
      <Header title={t("PLAN_DETAILS")} />

      {isLicenseLoading && <Spinner />}
      {showOrganizationLicense && <OrganizationLicense />}
      {showPlans && (
        <Plans
          getCheckoutSessionUrl={getCheckoutSessionUrl}
          getProducts={getProducts}
        />
      )}

      <Subscriptions
        getSubscriptions={getSubscriptions}
        getCustomerPortalSessionUrl={getCustomerPortalSessionUrl}
      />
    </>
  );
};
