import { FullPageTile } from "../../tile";
import { useEffect, useState } from "react";
import { EulaAgreement } from "../eula-agreement";
import { useDispatch, useSelector } from "react-redux";
import { jiraActions, jiraSelectors } from "../../../state/jira";
import { Spinner } from "../../spinner";
import { JiraConnections } from "./jira-connections";
import { UpsellMessage } from "../../upsell/upsell-message";
import { JiraConnectionCreator } from "./jira-connection-creator";

type JiraIntegrationProps = {
  hasAccessToJira: boolean;
};

export const JiraIntegration = ({ hasAccessToJira }: JiraIntegrationProps) => {
  const dispatch = useDispatch();
  const [isEulaAccepted, setIsEulaAccepted] = useState(false);
  const isLoading = useSelector(jiraSelectors.loading);
  const connections = useSelector(jiraSelectors.getConnections);

  useEffect(() => {
    dispatch(jiraActions.getJiraConnectionsAsync.request());
  }, [dispatch]);

  useEffect(() => {
    if (connections.length > 0) {
      setIsEulaAccepted(true);
    }
  }, [connections.length]);

  return hasAccessToJira ? (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          {!isEulaAccepted && (
            <FullPageTile>
              <EulaAgreement
                onAcceptance={() => setIsEulaAccepted(true)}
                serviceName="Jira"
              />
            </FullPageTile>
          )}
          {isEulaAccepted && (
            <>
              <FullPageTile>
                <JiraConnectionCreator />
              </FullPageTile>
              {!!connections.length && (
                <JiraConnections connections={connections} />
              )}
            </>
          )}
        </div>
      )}
    </>
  ) : (
    <FullPageTile>
      <UpsellMessage />
    </FullPageTile>
  );
};
