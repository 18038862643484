import { httpMethods } from "./http-methods";
import handleFetchRequest from "./handle-fetch-request";
import { ApiResponse, ConfigData } from "./types";

export const getConfig = (): Promise<ApiResponse<ConfigData>> => {
  return handleFetchRequest<ConfigData>(() =>
    fetch(window.__env.CONFIG_URL, {
      method: httpMethods.GET
    })
  );
};
