export const JIRA_CONNECTIONS_LOADING = "@jira/CONNECTIONS_LOADING";
export const JIRA_CONNECTIONS_SUCCESS = "@jira/CONNECTIONS_SUCCESS";
export const JIRA_CONNECTIONS_ERROR = "@jira/CONNECTIONS_ERROR";

export enum InstanceType {
  Server = "server",
  Cloud = "cloud"
}

export enum OAuthVersion {
  OAuth1 = "OAuth1",
  OAuth2 = "OAuth2"
}

export interface Connection {
  name: string;
  type: InstanceType;
  jiraUrl: string;
  user: string;
  settingName?: string;
  oAuthVersion: OAuthVersion;
  clientId?: string;
}

export interface JiraState {
  payload: Connection[];
  loading: boolean;
  error: boolean;
}
