import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./root.reducer";
import middlewares from "./middlewares";
import { RootState } from "typesafe-actions";

export default function configureStore(initialState = {} as RootState) {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(middlewares)
  );
}
