export const isWorkspaceAdministrator = (
  userEmail: string | undefined,
  administrators: string[] | undefined,
  isOrgAdminAccessPolicySet: boolean
): boolean => {
  if (isOrgAdminAccessPolicySet) return true;
  if (!userEmail) return false;
  if (
    !administrators ||
    !Array.isArray(administrators) ||
    administrators.length === 0
  ) {
    return false;
  }

  return administrators.includes(userEmail);
};
