import React, { MouseEvent, ReactNode } from "react";

type ModalProps = {
  handleOutsideClick?: (e: MouseEvent) => void;
  zIndex?: string;
  children?: ReactNode;
};
export const Modal: React.FC<ModalProps> = ({
  handleOutsideClick,
  children,
  zIndex = "z-50"
}) => {
  return (
    <React.Fragment>
      <div
        onClick={handleOutsideClick}
        className={`fixed top-0 left-0 ${zIndex} opacity-50 w-full h-full bg-colorPrimary`}
        onDragOver={e => e.preventDefault()}
        onDrop={event => {
          event.preventDefault();
        }}
      />
      <div
        className={`pointer-events-none fixed top-0 left-0 ${zIndex} w-full h-screen flex justify-center items-center`}
      >
        <div
          className={
            "relative bg-colorSelectedLightTheme rounded-lg p-3 pointer-events-auto mx-auto"
          }
        >
          <div className="overflow-y-auto max-w-90vw max-h-90vh xxs:w-full sm:w-auto customScrollbar">
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
