export const AGILITY_CONNECTIONS_LOADING = "@agility/CONNECTIONS_LOADING";
export const AGILITY_CONNECTIONS_SUCCESS = "@agility/CONNECTIONS_SUCCESS";
export const AGILITY_CONNECTIONS_ERROR = "@agility/CONNECTIONS_ERROR";

export interface AgilityConnection {
  name: string;
  agilityUrl: string;
  settingName?: string;
}

export interface AgilityState {
  payload: AgilityConnection[];
  loading: boolean;
  error: boolean;
}
