import { sessionActions } from "../../state/session";
import { useDispatch } from "react-redux";
import { PrimaryButton } from "../shared/button";
import { useI18n } from "../../helpers/hooks/useI18n";

export const Login: React.FC = () => {
  const t = useI18n("LOGIN.");
  const tl = useI18n("LINKS.");
  const dispatch = useDispatch();

  const initiateLogin = (): void => {
    dispatch(sessionActions.login());
  };

  return (
    <div className="flex items-start justify-center flex-1">
      <div className="flex flex-col space-y-8 p-40">
        <h3 className="text-center text-2xl">{t("HEADER")}</h3>
        <div className="text-center">
          <PrimaryButton onClick={initiateLogin}>{t("LOGIN")}</PrimaryButton>
        </div>
        <div className="text-center">
          <a href={tl("CONTACT")}>{t("CONTACT")}</a>
        </div>
      </div>
    </div>
  );
};
