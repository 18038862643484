import React, { useState } from "react";
import { useSelector } from "react-redux";
import { requestAuth } from "../../api/organization";
import { sessionSelectors } from "../../state/session";
import { EMAIL_REGEX } from "../accounts/email_regex";
import { PrimaryButton } from "../shared/button";
import { Input } from "../input";
import { FullPageTile } from "../tile";
import { IntegrationHeader } from "../integrations/integration-header";
import { useI18n } from "../../helpers/hooks/useI18n";

//This prop is just for storybook
type ADRequestProps = {
  initIsSend?: boolean;
};

export const ADRequest: React.FC<ADRequestProps> = ({ initIsSend = false }) => {
  const t = useI18n("AD_MANAGEMENT.");
  const orgId = useSelector(sessionSelectors.orgId);

  const [isSent, setIsSent] = useState(initIsSend);

  const title = isSent ? t("REQUEST_SEND_HEADER") : t("REQUEST_HEADER");

  const sendRequest = (email: string) => {
    requestAuth(orgId, email).then(() => {
      setIsSent(true);
    });
  };

  return (
    <FullPageTile>
      <IntegrationHeader text={title} />
      {isSent ? (
        <RequestSentConfirmation />
      ) : (
        <RequestForm sendRequest={sendRequest} />
      )}
    </FullPageTile>
  );
};

const RequestSentConfirmation = () => {
  const t = useI18n("AD_MANAGEMENT.");
  const tl = useI18n("LINKS.");

  const adminSupportMail = tl("ADMIN_SUPPORT");

  return (
    <div className="flex flex-col space-y-8">
      <span>
        {/* Hacky, to get mailto into localized string */}
        {t("REQUEST_SEND_P1").split("${email}")[0]}
        <a href={`mailto:${adminSupportMail}`}>{adminSupportMail}</a>
        {t("REQUEST_SEND_P1").split("${email}")[1]}
      </span>
      <span>
        {t("REQUEST_SEND_P2").split("${email}")[0]}
        <a href={`mailto:${adminSupportMail}`}>{adminSupportMail}</a>
        {t("REQUEST_SEND_P2").split("${email}")[1]}
      </span>
    </div>
  );
};

interface RequestFormParams {
  sendRequest: (email: string) => void;
}

const RequestForm: React.FC<RequestFormParams> = ({ sendRequest }) => {
  const t = useI18n("AD_MANAGEMENT.");
  const [email, setEmail] = useState("");

  const isValid = EMAIL_REGEX.test(email);

  return (
    <div className="flex flex-col space-y-2">
      <span>{t("REQUEST_TEXT")}</span>
      <Input
        id="email"
        type="text"
        value={email}
        placeholder={t("ENTER_EMAIL")}
        onChange={e => {
          setEmail(e.target.value);
        }}
      />
      <PrimaryButton
        additionalStyling="w-max"
        disabled={!isValid}
        onClick={() => {
          sendRequest(email);
        }}
      >
        {t("SUBMIT")}
      </PrimaryButton>
    </div>
  );
};
