import { License } from "../../state/licenses/types";
import { AppContext, ManagementPortalContext } from "../../context";
import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  organizationActions,
  organizationSelectors
} from "../../state/organization";
import { sessionSelectors } from "../../state/session";
import { DateTime } from "luxon";
import { Table } from "../table";
import { Column, useSortBy, useTable } from "react-table";

type LicensesProps = {
  licenses: License[];
  agreementDate?: number;
};

type AvailableLicenses = {
  expire: string;
  qty: number;
};

export const Licenses = (props: LicensesProps): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);
  const dispatch = useDispatch();

  const orgId = useSelector(sessionSelectors.orgId);

  const activePolicies = useSelector(organizationSelectors.policies.active);

  useEffect(() => {
    dispatch(organizationActions.getOrganizationPolicies(orgId));
    // warning is disabled because we DO want to call it only on component launch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupByExpire = (licenses: License[]): AvailableLicenses[] => {
    const reduced = licenses.reduce((previousValue, currentValue) => {
      const dateKey = DateTime.fromMillis(currentValue.expire).toLocaleString();
      previousValue[dateKey] = previousValue[dateKey]
        ? previousValue[dateKey] + 1
        : 1;
      return previousValue;
    }, {} as Record<string, number>);
    return Object.entries(reduced).map(([exp, qty]) => {
      return { expire: exp, qty };
    });
  };

  const parseAgreementDate = (): string | undefined => {
    if (props.agreementDate) {
      const date = DateTime.fromMillis(props.agreementDate);
      if (date.startOf("day") <= DateTime.local().startOf("day")) {
        return;
      }
      return date.toLocaleString();
    }
    return;
  };

  const checkAutoAssignPolicy = (e: React.ChangeEvent<HTMLInputElement>) => {
    var newPolicies;
    if (e.target.checked) {
      newPolicies = new Set(["licenses_auto_assign", ...activePolicies]);
    } else {
      newPolicies = new Set(
        activePolicies.filter(p => p !== "licenses_auto_assign")
      );
    }
    dispatch(organizationActions.saveOrganizationPolicies(orgId, newPolicies));
  };

  const availableLicences = groupByExpire(props.licenses);

  const parsedAgreementDate = parseAgreementDate();

  const columns = useMemo(
    (): Column<AvailableLicenses>[] => [
      {
        Header: context.Localized.string("LICENSES.EXPIRE"),
        accessor: "expire",
        id: "expire"
      },
      {
        Header: context.Localized.string("LICENSES.QTY"),
        accessor: "qty",
        id: "qty"
      }
    ],
    [context]
  );

  const instance = useTable<AvailableLicenses>(
    {
      columns,
      data: availableLicences,
      manualSortBy: true,
      disableSortBy: true
    },
    useSortBy
  );

  return (
    <div className="min-h-50 w-100 flex flex-col justify-between">
      <div className="pb-4 flex justify-between flex-grow-0">
        <span className="text-lg font-bold">
          {context.Localized.string("LICENSES.HEAD")}
        </span>
        {!parsedAgreementDate && (
          <span className="text-lg font-bold">{props.licenses.length}</span>
        )}
      </div>
      {!!parsedAgreementDate ? (
        <div>
          <span>
            {context.Localized.string(
              "LICENSES.AGREEMENT",
              parsedAgreementDate
            )}
          </span>
        </div>
      ) : (
        <React.Fragment>
          <Table {...instance} />
          <div className="p-4 flex justify-start content-center">
            <input
              className="self-center"
              id="auto_assign"
              type="checkbox"
              checked={activePolicies.includes("licenses_auto_assign")}
              value="licenses_auto_assign"
              onChange={checkAutoAssignPolicy}
            />
            <label
              htmlFor="auto_assign"
              className="ml-4 flex flex-col justify-start"
            >
              <span className="text-sm font-bold">
                {context.Localized.string("LICENSES.AUTO_ASSIGN")}
              </span>
            </label>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
