import { ResponsePromise } from "./types";
import { pendingTokenRefresh } from "./auth";
import { getErrorMessage } from "../state/errors/errors.epic";

const confirmThatRefreshingIsFinished = async () => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (!pendingTokenRefresh) {
        resolve("done");
        clearInterval(interval);
      }
    }, 1000);
  });
};

export const handleFetchRequest = async <DataType>(
  makeRequest: Function
): ResponsePromise<DataType> => {
  try {
    let response = await makeRequest();
    if (response.status === 401) {
      tryToRefreshToken();
      await confirmThatRefreshingIsFinished();
      response = await makeRequest();
    }
    const text: string = await response.text();

    if (text) {
      let isJson: boolean = true;
      const contentType: string = response.headers.get("content-type");

      if (contentType && contentType.indexOf("text/plain") !== -1) {
        isJson = false;
      }

      return {
        [response.ok ? "data" : "error"]: isJson ? JSON.parse(text) : text,
        status: response.status
      };
    } else {
      return response.ok
        ? { status: response.status }
        : { error: response.status, status: response.status };
    }
  } catch (error) {
    return {
      error: getErrorMessage(error),
      data: null
    };
  }
};

let tokenRefreshAction: () => void;
export const setTokenRefreshAction = (func: () => void): void => {
  tokenRefreshAction = func;
};

const tryToRefreshToken = () => {
  tokenRefreshAction();
};

export default handleFetchRequest;
