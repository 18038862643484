import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectsActions, projectsSelectors } from "../../state/projects";
import { Project } from "../../state/projects/types";
import { AvatarsCell } from "../shared/table/avatars.cell";
import { useCanEditProject } from "../../helpers/hooks/useCanEditProject";

interface CollaboratorsCellProps {
  project: Project;
  showProjectEditor: (project: Project, editCollaborators: boolean) => void;
}

export const CollaboratorsCell = ({
  project,
  showProjectEditor
}: CollaboratorsCellProps) => {
  const dispatch = useDispatch();
  const projectCollaborators = useSelector(
    projectsSelectors.findCollaboratorsByProject(project.id)
  );
  const canEditProject = useCanEditProject(project);

  useEffect(() => {
    if (projectCollaborators.length === 0) {
      dispatch(projectsActions.getCollaboratorsAsync.request(project.id));
    }
  }, [dispatch, project.id, projectCollaborators.length]);

  return (
    <AvatarsCell
      usersEmails={projectCollaborators.map(collaborator => collaborator.email)}
      editAction={() => showProjectEditor(project, true)}
      showActionButton={canEditProject}
    />
  );
};
