import * as auth from "./auth";
import * as users from "./users";
import * as licenses from "./licenses";
import * as organization from "./organization";
import * as globalHeaders from "./global-headers";
import * as sessionState from "./session-state";
import * as jira from "./jira";
import * as agility from "./agility";
import * as projects from "./projects";
import * as workspaces from "./workspaces";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auth,
  users,
  licenses,
  organization,
  globalHeaders,
  sessionState,
  jira,
  agility,
  projects,
  workspaces
};
