import React, { useContext } from "react";
import { ProjectPermissions } from "./projectPermissions";
import { AppContext, ManagementPortalContext } from "../../../context";
import { IndeterminateCheckbox } from "../../accounts-list/indeterminate-checkbox";
import { updateUserRoleOnProject } from "../../../api/projects";
import {
  PROJECT_ADMIN_ID,
  PROJECT_MEMBER_ID
} from "../../../api/collaborators.roles.ids";
import { useDispatch } from "react-redux";
import { projectsActions } from "../../../state/projects";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { useState } from "react";
import { Spinner } from "../../spinner";
import { showError } from "../../../helpers/show.toast.helpers";

export type CollaboratorBarProps = {
  label: string;
  userId: string | undefined;
  onUserSelect?: (value: string) => void;
  roleId?: string;
  canBeEdited?: boolean;
  projectId: string;
};

export const CollaboratorBar: React.FC<CollaboratorBarProps> = ({
  label,
  userId,
  onUserSelect,
  roleId,
  canBeEdited = true,
  projectId
}) => {
  const t = useI18n("PROJECTS.");
  const context: ManagementPortalContext = useContext(AppContext);
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  const setRole = async (newRole: "admin" | "basic") => {
    const newRoleId =
      newRole === "admin" ? PROJECT_ADMIN_ID : PROJECT_MEMBER_ID;

    if (userId && roleId) {
      setShowLoader(true);
      try {
        const response = await updateUserRoleOnProject(
          projectId,
          userId,
          newRoleId
        );

        if (response && !response.error) {
          dispatch(projectsActions.getCollaboratorsAsync.request(projectId));
        }

        if (response.error) {
          showError(t("ROLE_CHANGE_ERROR"));
        }
      } catch (e) {
        showError(t("ROLE_CHANGE_ERROR"));
      } finally {
        // TODO WP consider adding registering loading of collaborators in the state when time allows
        // this would also allow to give nice indicator while removing users as that operation also can take a few seconds
        // setTimeout used to keep dot a little longer
        setTimeout(() => {
          setShowLoader(false);
        }, 500);
      }
    }
  };

  return (
    <div
      className={
        "flex items-center gap-3 p-2 h-8 bg-white text-sm text-colorText rounded mb-1"
      }
      data-test-id={label}
    >
      {!!onUserSelect && (
        <IndeterminateCheckbox
          onChange={() => userId && onUserSelect(userId)}
        />
      )}
      <span className={"grow min-w-8 truncate"} title={label}>
        {label}
      </span>
      <span className={"ml-auto"}>
        {showLoader && (
          <Spinner
            classNames="inline-block mt-1 absolute"
            size={4}
            useSpinnerClass={false}
          />
        )}
        {context.Localized.string("PERMISSIONS.HAS")}
      </span>
      <ProjectPermissions
        canBeEdited={canBeEdited}
        roleType={roleId === PROJECT_ADMIN_ID ? "admin" : "basic"}
        onPermissionChange={setRole}
      />
      <span>{context.Localized.string("PERMISSIONS.ACCESS")}</span>
    </div>
  );
};

export type DefinedProjectBarProps = {
  roleType: string;
  projectId: string;
};

export const ByPolicyAnyProjectAdminBar: React.FC<DefinedProjectBarProps> = ({
  roleType,
  projectId
}) => {
  const context: ManagementPortalContext = useContext(AppContext);
  return (
    <CollaboratorBar
      label={context.Localized.string(
        "PERMISSIONS.BY_POLICY_ANY_PROJECT_ADMIN"
      )}
      userId={undefined}
      canBeEdited={false}
      roleId={roleType}
      projectId={projectId}
    />
  );
};

export const AnyProjectAdminBar: React.FC<DefinedProjectBarProps> = ({
  roleType,
  projectId
}) => {
  const context: ManagementPortalContext = useContext(AppContext);
  return (
    <CollaboratorBar
      label={context.Localized.string("PERMISSIONS.ANY_PROJECT_ADMIN")}
      userId={undefined}
      canBeEdited={false}
      roleId={roleType}
      projectId={projectId}
    />
  );
};
