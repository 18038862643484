import React from "react";
import { Label, Select } from "../../input";
import { InstanceType } from "../../../state/jira/types";
import { useI18n } from "../../../helpers/hooks/useI18n";

type JiraTypeSelectorProps = {
  setType: (type: InstanceType) => void;
};

export const JiraTypeSelector: React.FC<JiraTypeSelectorProps> = ({
  setType
}) => {
  const t = useI18n("JIRA.");
  const htmlFor = "type";
  const label = t("SELECT_TYPE");

  return (
    <div className="w-1/2 mb-10">
      <Label htmlFor={htmlFor}>{label}</Label>
      <Select
        id={htmlFor}
        value=""
        onChange={e => setType(e.target.value as InstanceType)}
      >
        <option disabled />
        <option value={InstanceType.Cloud}>{t(InstanceType.Cloud)}</option>
        <option value={InstanceType.Server}>{t(InstanceType.Server)}</option>
      </Select>
    </div>
  );
};
