import { PROJECT_MEMBER_ID } from "../../../api/collaborators.roles.ids";
import { addUserToProject } from "../../../api/projects";

export interface AddUsersToProjectReturn {
  emailsWithoutAccount: string[];
}

export class UserLimitReachedError extends Error {
  constructor(message: string) {
    super(message);
    // we are extending a built-in class
    Object.setPrototypeOf(this, UserLimitReachedError.prototype);
  }
}

export class GenericAddUserToProjectError extends Error {
  constructor(message: string) {
    super(message);
    // we are extending a built-in class
    Object.setPrototypeOf(this, GenericAddUserToProjectError.prototype);
  }
}

/*
 * Helper function to try to add a list of emails to a project with PROJECT_MEMBER role
 */
export async function addUsersToProject(
  projectId: string,
  emails: string[]
): Promise<AddUsersToProjectReturn> {
  const result: AddUsersToProjectReturn = {
    emailsWithoutAccount: []
  };
  for (let email of emails) {
    const response = await addUserToProject(
      projectId,
      { userEmail: email },
      PROJECT_MEMBER_ID
    );

    if (response.status && response.status < 300) continue;

    if (response.error && response.status === 400) {
      // TODO => according to model at this level we should have error as string
      // project endpoint errors are often objects with at least a few different fields
      // for now TS issue here is ignored but it will need handling by changing errors handling in the backend
      // or preparing management portal for all different versions of error messages
      if (
        typeof response.error !== "string" &&
        // @ts-ignore - TODO to be handled later: explanation above
        response.error.code === "UserLimitReached"
      ) {
        // @ts-expect-error - TODO fix ApiResponse typing
        throw new UserLimitReachedError(response.error.message);
      } else if (
        typeof response.error !== "string" &&
        // @ts-ignore - TODO to be handled later: explanation above
        response.error.code === "UserNotFound"
      ) {
        result.emailsWithoutAccount.push(email);
        continue;
      }
    } else {
      throw new GenericAddUserToProjectError(String(response.error));
    }
  }
  return result;
}
