import {
  InputHTMLAttributes,
  LabelHTMLAttributes,
  SelectHTMLAttributes
} from "react";

// IMPORTANT
// This is older type of input, the new one is in shared/input.tsx
// We keep that one for now as it is used in some older components

export const Label: React.FC<LabelHTMLAttributes<HTMLLabelElement>> = ({
  children,
  ...props
}) => <label {...props}>{children}</label>;

export type InputProps = {
  error?: boolean | undefined;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input: React.FC<InputProps> = ({ error, ...attributeProps }) => (
  <input
    className={
      "appearance-none rounded bg-transparent outline-none h-8 p-1 text-base " +
      (attributeProps.readOnly
        ? ""
        : error || (attributeProps.required && !attributeProps.value)
        ? "border border-colorWarning"
        : "border border-colorPrimary")
    }
    {...attributeProps}
  />
);

export type SelectProps = {
  h?: number;
} & SelectHTMLAttributes<HTMLSelectElement>;

export const Select: React.FC<SelectProps> = ({
  children,
  h = 8,
  ...props
}) => (
  <span>
    <select
      className={`appearance-none rounded bg-transparent border border-colorPrimary outline-none disabled:text-colorPrimary disabled:cursor-not-allowed pl-1 pr-6 w-full h-${h}`}
      {...props}
    >
      {children}
    </select>
    <span
      className={
        "-ml-6 w-6 inline-block hicon down text-colorPrimary pointer-events-none"
      }
    />
  </span>
);
