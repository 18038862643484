import React from "react";
import "./index.css";
import "./assets/fonts/hoylu/hoylu-ui-icons-centered.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppContext, ManagementPortalContext } from "./context";
import { ApiResponse, ConfigData } from "./api/types";
import { getConfig } from "./api/config";
import { addConfiguration } from "./configuration";
import createInitialState from "./state/initial-state";
import configureStore from "./state/store";
import { Provider } from "react-redux";
import { Store } from "redux";
import { setTokenRefreshAction } from "./api/handle-fetch-request";
import { refreshToken } from "./state/session/actions";
import { rootEpic } from "./state/root.epic";
import { epicMiddleware } from "./state/middlewares";
import api from "./api";
import { connectFeatureFlagsToDispatch } from "./connectLaunchDarklyToDispatch";
import { RootState } from "typesafe-actions";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "@hoylu/client-common/dist/esm/assets/style/fonts.css";

window.Buffer = window.Buffer || require("buffer").Buffer;
const context = new ManagementPortalContext();

api.globalHeaders.contentType("application/json");

getConfig().then(init).catch(console.error);

function render(store: Store) {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <AppContext.Provider value={context}>
            <App />
          </AppContext.Provider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function init(configResult: ApiResponse<ConfigData>) {
  if (configResult.error || !configResult.data) return;

  addConfiguration(configResult.data);

  const token = api.sessionState.get("token");

  const initialState = (
    token ? createInitialState(token) : { session: {} }
  ) as RootState;

  const store = configureStore(initialState);

  setTokenRefreshAction(() => store.dispatch(refreshToken()));

  api.globalHeaders.authorization(`Bearer ${token}`);

  epicMiddleware.run(rootEpic);

  connectFeatureFlagsToDispatch(store, configResult.data);

  render(store);
}
