interface TwoLevelCellProps {
  topValue: string;
  bottomValue: string;
  topTitle?: string;
  bottomTitle?: string;
  bottomAlert?: boolean;
}

export const TwoLevelCell = ({
  topValue,
  bottomValue,
  topTitle,
  bottomTitle,
  bottomAlert = false
}: TwoLevelCellProps): JSX.Element => {
  return (
    <div className="text-sm text-colorTextAndIconLighterLightTheme">
      <div title={topTitle ?? topValue}>{topValue}</div>
      <div
        title={bottomTitle ?? bottomValue}
        className={bottomAlert ? "text-colorWarning" : ""}
      >
        {bottomValue}
      </div>
    </div>
  );
};
