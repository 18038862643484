import { AccountsList } from "../../accounts-list/accounts-list";
import React, { useContext } from "react";
import { TotalAccounts } from "../../accounts/totalAccounts";
import { useSelector } from "react-redux";
import { organizationSelectors } from "../../../state/organization";
import { Spinner } from "../../spinner";
import { Licenses } from "../../licenses/licenses";
import { licensesSelectors } from "../../../state/licenses";
import { Header } from "../header";
import { AppContext, ManagementPortalContext } from "../../../context";
import { Tile } from "../../tile";

export const Accounts = (): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);

  const isLoading = useSelector(organizationSelectors.isLoading);
  const members = useSelector(organizationSelectors.members);
  const availableLicenses = useSelector(licensesSelectors.availableLicenses);
  const agreementDate = useSelector(organizationSelectors.agreementDate);

  const countActive = () => {
    return members.filter(m => m.license && !m.license.flags.isExpired).length;
  };

  return isLoading || !members.length ? (
    <Spinner />
  ) : (
    <div className="container h-full flex flex-col overflow-y-auto">
      <Header title={context.Localized.string("ACCOUNTS.TITLE")} />
      <div className="flex flex-col xxl:flex-row ">
        <div className="flex flex-col items-center lg:flex-row lg:items-start xxl:flex-col">
          <Tile>
            <TotalAccounts total={members.length || 0} active={countActive()} />
          </Tile>
          <Tile>
            <Licenses
              licenses={availableLicenses}
              agreementDate={agreementDate}
            />
          </Tile>
        </div>
        <Tile className="flex-grow">
          <AccountsList />
        </Tile>
      </div>
    </div>
  );
};
