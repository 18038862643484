import { Store } from "redux";
import jwt_decoder from "jwt-decode";
import { ConfigData } from "./api/types";
import { setFeatureFlags } from "./state/featureFlags/actions";
import { FeatureFlagState } from "./state/featureFlags/reducer";
import { RootState } from "typesafe-actions";
import {
  LDClientInterface,
  LDSingleKindContext,
  LDClientWrapper
} from "@hoylu/client-common";

export function connectFeatureFlagsToDispatch(
  store: Store,
  config: ConfigData
) {
  const unsubscribe = store.subscribe(() => {
    const state = store.getState() as RootState;
    // wait until we get a user to init feature flags
    if (state.session.userId) {
      unsubscribe();
      initLaunchDarkly(store, state, config);
    }
  });
}

let client: LDClientInterface;

function initLaunchDarkly(store: Store, state: RootState, config: ConfigData) {
  const user = buildUserFromToken(state.session.sessionToken!!);
  const ldClientId = config.launchDarkly.clientId;

  client = new LDClientWrapper(ldClientId, user);
  function update() {
    store.dispatch(setFeatureFlags(getFlags()));
  }
  client.on("ready", update);
  client.on("change", update);
}

function getFlags(): FeatureFlagState {
  return {
    showExplicitExternalAccessPolicy: !!client.variation("enable-explicit-external-access-policy"),
    useJiraIntegration: !!client.variation("jira-integration"),
    useAgilityIntegration: !!client.variation("agility-integration"),
    orgPolicyOnlyOrgAdminsCanCreateWorkspace: !!client.variation("org-policy-only-org-admins-can-create-workspace"),
    useTwoFactorAuth: !!client.variation("Enable-2fa"),
    showWorkspaces: !!client.variation("MP-workspace-overview"),
    showProjects: !!client.variation("project-licensing"),
    showStripePurchase: !!client.variation("show-stripe-purchase"),
    showJiraCloudOAuth2: !!client.variation("show-jira-cloud-o-auth-2")
  };
}

function buildLDContext({
  userId,
  org,
  email
}: {
  userId: string;
  org: string;
  email: string;
}) {
  return {
    kind: "user",
    key: userId,
    email,
    orgId: org,
    _meta: {
      privateAttributes: ["email"]
    }
  };
}

function buildUserFromToken(token: string): LDSingleKindContext {
  // https://docs.launchdarkly.com/sdk/client-side/javascript#users
  let user: any = jwt_decoder(token);
  return buildLDContext(user);
}
