import React, { useState } from "react";
import { Icon } from "../shared/icon";
import { WorkspacePassword } from "./workspace.password";
import { WorkspacePermissions } from "./workspace.permissions";
import { Permissions } from "../shared/permissions/permissions.types";
import { Section } from "../shared/sections/section";
import { useI18n } from "../../helpers/hooks/useI18n";
import { Workspace } from "../../api/types";

export type PermissionsSectionProperties = {
  workspace: Workspace;
  permissionLevelSets: Permissions | undefined;
  email: string;
  updatePermissions: (newPermissions: Permissions) => Promise<void>;
  handleWorkspacePassword: (
    password: string,
    currentPassword: string,
    mode: string
  ) => void;
  requiresPassword: boolean;
  hasPassword: boolean;
  sectionTitle: string;
  shareLink: string | undefined;
  showOrgAdminPolicy: boolean;
  isAdmin: boolean;
  isOpenInitially?: boolean;
};

export const PermissionsSection: React.FC<PermissionsSectionProperties> = ({
  workspace,
  permissionLevelSets,
  email,
  updatePermissions,
  handleWorkspacePassword,
  requiresPassword,
  hasPassword,
  sectionTitle,
  shareLink,
  showOrgAdminPolicy,
  isAdmin,
  isOpenInitially = false
}) => {
  const t = useI18n("PERMISSIONS.");
  const [isUsersSectionActive, setIsUsersSectionActive] = useState(true);
  const [isSectionCollapsed, setIsSectionCollapsed] = useState(
    !isOpenInitially
  );

  const handlePasswordClick = () => {
    const isPasswordSectionAlreadyRendered = !isUsersSectionActive;
    setIsUsersSectionActive(false);
    setIsSectionCollapsed(
      isSectionCollapsed ? false : isPasswordSectionAlreadyRendered
    );
  };

  const handleUsersClick = () => {
    const isUsersSectionAlreadyRendered = isUsersSectionActive;
    setIsUsersSectionActive(true);
    setIsSectionCollapsed(
      isSectionCollapsed ? false : isUsersSectionAlreadyRendered
    );
  };

  const sectionExpandHandler = (value: boolean) => {
    setIsSectionCollapsed(value);
  };

  return (
    <Section
      dataTestId="set-permissions"
      title={sectionTitle}
      icon={"link"}
      initiallyCollapsed={isSectionCollapsed}
      externalExpandHandler={sectionExpandHandler}
      headerContent={
        <div className={"flex ml-auto"} onClick={e => e.stopPropagation()}>
          {isAdmin && (
            <Icon
              icon={hasPassword ? "locked" : "unlocked"}
              onClick={handlePasswordClick}
              title={t(hasPassword ? "LOCKED" : "UNLOCKED")}
            />
          )}
          <Icon
            icon={"persons"}
            onClick={handleUsersClick}
            title={t("USERS")}
          />
        </div>
      }
    >
      <div className={"px-7"}>
        {isUsersSectionActive && permissionLevelSets ? (
          <WorkspacePermissions
            workspace={workspace}
            permissionLevelSets={permissionLevelSets}
            email={email}
            updatePermissions={updatePermissions}
            permissionsIsOpen={isUsersSectionActive}
            shareLink={shareLink}
            showOrgAdminPolicy={showOrgAdminPolicy}
          />
        ) : (
          <WorkspacePassword
            hasPassword={!!workspace.hasPassword}
            confirmPasswordAction={(password, currentPassword, mode) =>
              handleWorkspacePassword(password, currentPassword, mode)
            }
            requiresPassword={requiresPassword}
          />
        )}
      </div>
    </Section>
  );
};
