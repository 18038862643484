export function downloadCsv(csvContent: string, fileName: string): void {
  // Force browser to download a generated file
  const link = document.createElement("a");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf8;" });
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
