import React, { Fragment, useState } from "react";
import { LocalizingFunction, useI18n } from "../../helpers/hooks/useI18n";
import { Spinner } from "../spinner";
import { Icon } from "../shared/icon";
import {
  PermissionsPillDivider,
  PermissionsPill
} from "../shared/permissions/permissions.pill";
import { useSelector } from "react-redux";
import {
  orgPolicyDisallowExternalRead,
  orgPolicyDisallowExternalWrite
} from "../../state/organization/selectors";
import {
  ShareOptionValue,
  ShareType
} from "../shared/permissions/permissions.types";

type ShareDialogPermissionsBarProps = {
  commitUpdate?: (shareOption: ShareOptionValue) => Promise<void>;
  enabledOption: ShareOptionValue;
  options: ShareOptionValue[];
  text: string;
  currentUser?: string;
  isAdmin: boolean;
};

export const ShareDialogPermissionsBar: React.FC<
  ShareDialogPermissionsBarProps
> = ({ commitUpdate, enabledOption, options, text, currentUser, isAdmin }) => {
  const t = useI18n("SHARE_WORKSPACE.PERMISSION_BAR.");
  const [showLoadingSpinner, toggleLoadingSpinner] = useState(false);
  const disallowExternalRead = useSelector(orgPolicyDisallowExternalRead);
  const disallowExternalWrite = useSelector(orgPolicyDisallowExternalWrite);

  const setAccessOption = (
    option: ShareOptionValue,
    enabledOption: ShareOptionValue
  ): void => {
    if (currentUser === text || option === enabledOption || !commitUpdate)
      return;
    toggleLoadingSpinner(true);
    commitUpdate(option).then(() => toggleLoadingSpinner(false));
  };

  const shouldRemoveBeDisplayed =
    text.indexOf(" ") === -1 && isAdmin && currentUser !== text;

  const permissionTooltip = calculatePermissionTooltip(
    text,
    disallowExternalRead,
    disallowExternalWrite,
    t
  );

  return (
    <div className="flex">
      <div className="bg-white relative flex justify-between rounded xs:pl-2 xs:p-1 w-full h-10 text-sm">
        <div
          title={permissionTooltip}
          className="flex items-center w-56 truncate"
        >
          {" "}
          {text}
        </div>
        <div className="flex flex-row gap-1 items-center">
          <span
            className={`flex items-center hidden sm:block relative ${
              showLoadingSpinner ? "" : "invisible"
            }`}
          >
            <Spinner classNames="m-0" size={3} useSpinnerClass={false} />
          </span>
          {currentUser && <div>{t("HAS")}</div>}
          <div
            className="flex items-center"
            title={t("ONLY_ADMINS_CHANGE_ACCESS")}
          >
            {options.map((option, index) => {
              return (
                <PermissionOption
                  key={index}
                  isAdmin={isAdmin}
                  index={index}
                  option={option}
                  enabledOption={enabledOption}
                  setAccessOption={setAccessOption}
                />
              );
            })}
          </div>
          <div className={`${shouldRemoveBeDisplayed ? "mr-1.5" : "mr-8"}`}>
            {t("ACCESS")}
          </div>
          {shouldRemoveBeDisplayed && (
            <Icon
              icon={"windowclose"}
              onClick={() => setAccessOption(ShareType.REMOVE, enabledOption)}
              title={t("REMOVE_USER")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const calculatePermissionTooltip = (
  defaultText: string,
  disallowExternalRead: boolean,
  disallowExternalWrite: boolean,
  t: LocalizingFunction
): string => {
  if (disallowExternalRead) {
    return t("AUTHENTICATED_ACCOUNTS_NO_ACCESS");
  }
  if (disallowExternalWrite) {
    return t("AUTHENTICATED_ACCOUNTS_READ_ONLY_ACCESS");
  }
  return defaultText;
};

type PermissionOptionProperties = {
  isAdmin: boolean;
  index: number;
  option: ShareOptionValue;
  enabledOption: ShareOptionValue;
  setAccessOption: (
    option: ShareOptionValue,
    enabledOption: ShareOptionValue
  ) => void;
};

const PermissionOption: React.FC<PermissionOptionProperties> = ({
  isAdmin,
  index,
  option,
  enabledOption,
  setAccessOption
}) => {
  const t = useI18n("SHARE_WORKSPACE.");
  return (
    <Fragment>
      {index !== 0 && <PermissionsPillDivider />}
      <PermissionsPill
        label={t(option.title)}
        color={option.color}
        canBeEdited={isAdmin}
        isActive={enabledOption === option}
        onClick={() => setAccessOption(option, enabledOption)}
        dataTestId={option.title}
      />
    </Fragment>
  );
};
