import React, { useEffect, useState } from "react";
import { SectionHeader } from "./section.header";

export type SectionProps = {
  title: string;
  icon: string; // hoylu font icons class (no need to pass hicon class)
  children: React.ReactNode;
  isCollapsible?: boolean;
  initiallyCollapsed?: boolean;
  headerContent?: React.ReactNode;
  dataTestId?: string;
  externalExpandHandler?: (value: boolean) => void;
};

export const Section: React.FC<SectionProps> = ({
  children,
  title,
  icon,
  initiallyCollapsed,
  isCollapsible = true,
  headerContent,
  dataTestId,
  externalExpandHandler
}: SectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  useEffect(() => {
    setIsCollapsed(initiallyCollapsed ?? false);
  }, [initiallyCollapsed]);

  const handleClick = () => {
    if (isCollapsible) setIsCollapsed(!isCollapsed);
    if (isCollapsible && externalExpandHandler) {
      externalExpandHandler(!isCollapsed);
    }
  };

  const style = "p-2 mb-2 rounded bg-colorForegroundLightTheme last:mb-0";
  return (
    <div className={style} data-test-id={dataTestId}>
      <SectionHeader
        sectionIcon={icon}
        sectionTitle={title}
        onClick={isCollapsible ? handleClick : undefined}
        isCollapsed={isCollapsed}
      >
        {headerContent ? headerContent : <></>}
      </SectionHeader>
      {!isCollapsed && <div>{children}</div>}
    </div>
  );
};
