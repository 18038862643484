import React, {
  Dispatch,
  DragEventHandler,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";
import { AppContext, ManagementPortalContext } from "../../context";
import { PrimaryButton } from "../shared/button";
import { EMAIL_REGEX } from "./email_regex";
import Papa from "papaparse";
import { v4 as uuid } from "uuid";
import { AccountData } from "./account.entry";
import { Spinner } from "../spinner";

export type ImportAccountsProps = {
  setAccounts: Dispatch<SetStateAction<Record<string, AccountData>>>;
  setQuarantine: Dispatch<SetStateAction<AccountData[]>>;
};

const CSVToAccountData = (csvRow: Record<string, string>): AccountData => {
  return {
    id: uuid(),
    email: csvRow["User"]?.trim() ?? "",
    firstName: csvRow["First Name"]?.trim(),
    lastName: csvRow["Last Name"]?.trim(),
    department: csvRow["Department"]?.trim(),
    readonly: false,
    selected: true,
    rejected: null,
    isValid: false
  };
};

export const ImportAccounts = (props: ImportAccountsProps): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);

  const [text, setText] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<Record<string, AccountData>>({});
  const [isValid, setValid] = useState(false);

  const isDefinedAndNotEmpty = (v?: string): boolean => {
    return v !== undefined && v !== "";
  };

  useEffect(() => {
    if (text) {
      const emails = text?.replace(/\s/g, "").split(",");
      const allValid = emails.every(e => EMAIL_REGEX.test(e));
      setValid(allValid);
      if (allValid) {
        const newAccounts: Record<string, AccountData> = {};
        for (const email of emails) {
          newAccounts[email] = {
            email,
            id: uuid(),
            isValid: false,
            rejected: null,
            readonly: false,
            selected: true
          };
        }
        setAccounts(newAccounts);
      }
    }
  }, [text]);

  const handleCsvImport: DragEventHandler<HTMLTextAreaElement> = e => {
    e.preventDefault();
    setText("");
    let filesEvent = e.dataTransfer || e.target;
    setLoading(true);
    Papa.parse<Record<string, string>>(filesEvent.files[0], {
      skipEmptyLines: true,
      header: true,
      worker: false,
      complete: result => {
        setLoading(false);
        const accountsData: Record<string, AccountData> = {};
        const quarantineData: AccountData[] = [];
        result.data.forEach(r => {
          const accountData = CSVToAccountData(r);
          if (EMAIL_REGEX.test(accountData.email)) {
            accountsData[accountData.email] = accountData;
          } else {
            quarantineData.push(accountData);
          }
        });
        props.setQuarantine(quarantineData);
        props.setAccounts(accountsData);
      }
    });
  };

  return (
    <div className="flex flex-col justify-items-stretch h-56 w-96">
      {isLoading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="block relative flex-grow-1 my-4 h-full border-2 border-colorTableHeadPortalBackground rounded">
            {!isDefinedAndNotEmpty(text) && (
              <div className="h-full w-full absolute flex flex-col justify-center">
                <span className="text-sm text-gray-400 text-opacity-50 text-center">
                  {context.Localized.string("ADD_ACCOUNTS.DROP_AREA_WATERMARK")}
                </span>
              </div>
            )}
            <textarea
              value={text}
              className="p-1 h-full w-full absolute bg-transparent outline-none"
              onChange={event => setText(event.target.value)}
              onDrop={handleCsvImport}
            />
          </div>
          <PrimaryButton
            additionalStyling="w-max self-center"
            title={context.Localized.string("ADD_ACCOUNTS.IMPORT")}
            disabled={!isValid}
            onClick={() => props.setAccounts(accounts)}
          />
        </React.Fragment>
      )}
    </div>
  );
};
