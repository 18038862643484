import { toast } from "react-toastify";
import { Epic } from "redux-observable";
import { filter, map, tap, ignoreElements } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import { ApiType } from "../../api/types";
import { ManagementPortalContext } from "../../context";
import {
  getAvailableLicencesAsync,
  getLicenseInfoAsync,
  setLicenseToUserAsync,
  takeLicenseFromUserAsync
} from "../licenses/actions";
import { requestError } from "./actions";
import { sessionSelectors } from "../session";
import { getGroupsAsync, updateGroupLicenseAsync } from "../groups/actions";
import { sendADAdminInviteAsync } from "../invite-admin/actions";
import {
  editMemberDepartmentAsync,
  getOrganizationInfoAsync,
  getOrganizationPoliciesAsync,
  removeMembersAsync,
  saveOrganizationDepartmentsAsync,
  saveOrganizationPoliciesAsync
} from "../organization/actions";
import {
  getUserProfileAsync,
  loginAsync,
  refreshTokenAsync
} from "../session/actions";
import { pendingTokenRefresh } from "../../api/auth";
import { getJiraConnectionsAsync } from "../jira/actions";
import {
  getCollaboratorsAsync,
  getAllOrgProjectsAsync,
  updateProjectAsync,
  getMyProjectsAsync
} from "../projects/actions";

export const failureActions = [
  getAvailableLicencesAsync.failure,
  setLicenseToUserAsync.failure,
  takeLicenseFromUserAsync.failure,
  getGroupsAsync.failure,
  updateGroupLicenseAsync.failure,
  sendADAdminInviteAsync.failure,
  getOrganizationInfoAsync.failure,
  getOrganizationPoliciesAsync.failure,
  saveOrganizationPoliciesAsync.failure,
  saveOrganizationDepartmentsAsync.failure,
  editMemberDepartmentAsync.failure,
  removeMembersAsync.failure,
  loginAsync.failure,
  getJiraConnectionsAsync.failure,
  getLicenseInfoAsync.failure,
  getAllOrgProjectsAsync.failure,
  getMyProjectsAsync.failure,
  getCollaboratorsAsync.failure,
  updateProjectAsync.failure,
  getUserProfileAsync.failure
];

export const toasterOptions = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 10000
};

export const errorsEpic: Epic<RootAction, RootAction, RootState, ApiType> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(failureActions)),
    map(action => {
      const localized = new ManagementPortalContext().Localized;
      const isLoggedIn = sessionSelectors.isLoggedIn(state$.value);

      const stringAddress = isLoggedIn
        ? action.type.split("/")[1]
        : "LOGGED_OUT";
      const customError = localized.string(`ERRORS.${stringAddress}`);

      if (!pendingTokenRefresh) {
        const originalErrorMessage = getErrorMessage(action.payload);

        toast.error(
          () => (
            <div>
              {customError}.<br />
              <i>
                {originalErrorMessage && isLoggedIn ? originalErrorMessage : ""}
              </i>
            </div>
          ),
          { ...toasterOptions, toastId: stringAddress }
        );
      }

      return requestError(customError);
    })
  );
};

export const dismissErrorEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = action$ =>
  action$.pipe(
    filter(isActionOf([loginAsync.success, refreshTokenAsync.success])),
    tap(() => toast.dismiss("LOGGED_OUT")),
    ignoreElements()
  );

export const getErrorMessage = (error: any | unknown) => {
  if (typeof error === "string") return error;
  if (error && typeof error.message === "string") return error.message;
  if (error && typeof error.error === "string") return error.error;
  if (error) return error.toString();
  return "Unknown error";
};
