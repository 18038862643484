import React, { useMemo, useState } from "react";
import { Avatar } from "../avatar/avatar";
import { UserData } from "../../state/accounts/types";
import { useDispatch, useSelector } from "react-redux";
import {
  organizationActions,
  organizationSelectors
} from "../../state/organization";
import { SimpleDropdown } from "../shared/simple.dropdown";
import { isPremiumLabel, Label } from "../shared/label";
import { licensesSelectors } from "../../state/licenses";
import { isEnterprise } from "../../state/licenses/types";
import { useI18n } from "../../helpers/hooks/useI18n";

export type NameCellProps = {
  user: UserData;
};

export const NameCell: React.FC<NameCellProps> = ({ user }) => {
  const { firstName, lastName, email } = user;

  return (
    <div className={"flex"}>
      <Avatar
        user={user}
        circleStyle={"w-8 h-8"}
        textStyle={"font-bold text-colorText text-xxs"}
        avatarDetailsPosition={"-top-1/2 left-6"}
      />
      <div className={"flex flex-col ml-2 w-10/12 overflow-hidden"}>
        <span
          data-test-id={"name-cell-full-name"}
          className={"text-black font-bold"}
        >{`${firstName} ${lastName}`}</span>
        <span
          data-test-id={"name-cell-email"}
          className={
            "text-colorTextAndIconLighterLightTheme max-h-12 break-all"
          }
        >
          {email}
        </span>
      </div>
    </div>
  );
};

export type DepartmentCellProps = {
  id: string;
  department: string;
  clearFilters: () => void;
};

export const DepartmentCell: React.FC<DepartmentCellProps> = ({
  id,
  department,
  clearFilters
}) => {
  const t = useI18n("ACCOUNTS_LIST.");
  const dispatch = useDispatch();
  const departmentNames = useSelector(organizationSelectors.departments.names);
  const [currentDepartment, setCurrentDepartment] = useState(department);
  const saveDepartment = (accountId: string, department: string) =>
    dispatch(organizationActions.editUserDepartment(accountId, department));
  const departments = useMemo(() => {
    return departmentNames.filter(d => d !== "");
  }, [departmentNames]);
  return (
    <SimpleDropdown
      heading={t("DEPARTMENT")}
      options={departments}
      currentValue={currentDepartment}
      handleChange={option => {
        saveDepartment(id, option);
        setCurrentDepartment(option);
        clearFilters();
      }}
      width={"w-32"}
      textSize={"xs"}
    />
  );
};

export type LabelCellProps = {
  account: UserData;
};

export const LabelCell: React.FC<LabelCellProps> = ({ account }) => {
  const licenseInfo = useSelector(licensesSelectors.licenseInfo);
  const licenseLabelType = licenseInfo?.type.toLowerCase();
  const isLicenseExpired = !!account.license?.flags?.isExpired;
  return (
    <div className={"flex gap-1 max-h-11 flex-wrap overflow-hidden"}>
      {(account.license || isEnterprise(licenseInfo?.type)) &&
        licenseLabelType &&
        isPremiumLabel(licenseLabelType) && (
          <Label type={licenseLabelType} strikeTrough={isLicenseExpired} />
        )}
    </div>
  );
};

export const DefaultCell: React.FC<{ label: string }> = ({ label }) => {
  return (
    <div className={"text-xs text-colorTextAndIconLighterLightTheme"}>
      {label}
    </div>
  );
};
