import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, tap } from "rxjs/operators";
import {
  getUserOrgInfoAsync,
  getUserProfileAsync,
  loginAsync,
  logoutAsync,
  refreshTokenAsync,
  requestDeleteAccount
} from "./actions";
import { from, of } from "rxjs";
import { ApiResponse, ApiType, HoyluAuthResponse } from "../../api/types";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import { UserProfile } from "../accounts/types";
import { PartialOrgInfo } from "./types";
import { userId } from "./selectors";

export const loginEpic: Epic<RootAction, RootAction, RootState, ApiType> = (
  action$,
  state$,
  { auth, globalHeaders, sessionState }
) =>
  action$.pipe(
    filter(isActionOf(loginAsync.request)),
    mergeMap(() =>
      from(auth.handleLogin()).pipe(
        tap((response: HoyluAuthResponse) => {
          globalHeaders.authorization(`Bearer ${response.token}`);
          sessionState.store("token", response.token);
        }),
        map((response: HoyluAuthResponse) => {
          const sessionData = auth.prepareSessionData(response.token);
          return loginAsync.success({ ...sessionData });
        }),
        catchError(error => of(loginAsync.failure(error.toString())))
      )
    )
  );

export const getUserProfileEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { auth }) => {
  return action$.pipe(
    filter(isActionOf(getUserProfileAsync.request)),
    mergeMap(action =>
      from(auth.getUserProfile(action.payload)).pipe(
        map((response: ApiResponse<UserProfile>) => {
          if (response.data) {
            return getUserProfileAsync.success(response.data);
          }

          return getUserProfileAsync.failure(
            response.error ?? "No data received"
          );
        }),
        catchError(error => of(getUserProfileAsync.failure(error.toString())))
      )
    )
  );
};

export const refreshTokenEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { auth, globalHeaders, sessionState }) =>
  action$.pipe(
    filter(isActionOf(refreshTokenAsync.request)),
    mergeMap(() =>
      from(auth.getTokenSilent()).pipe(
        tap((response: HoyluAuthResponse) => {
          globalHeaders.authorization(`Bearer ${response.token}`);
          sessionState.store("token", response.token);
        }),
        map((response: HoyluAuthResponse) => {
          const sessionData = auth.prepareSessionData(response.token);
          return refreshTokenAsync.success(sessionData);
        }),
        catchError(er => {
          return of(
            refreshTokenAsync.failure(er.toString()),
            logoutAsync.request()
          );
        })
      )
    )
  );

export const logoutEpic: Epic<RootAction, RootAction, RootState, ApiType> = (
  action$,
  state$,
  { auth, globalHeaders, sessionState }
) =>
  action$.pipe(
    filter(isActionOf(logoutAsync.request)),
    tap(() => {
      globalHeaders.authorization(null);
      sessionState.clear("token");
    }),
    mergeMap(() =>
      from(auth.handleLogout()).pipe(
        map(() => {
          return logoutAsync.success();
        }),
        catchError(error => {
          console.error(error);
          return of(logoutAsync.failure(error.toString()));
        })
      )
    )
  );

export const getUserOrgInfoEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { auth }) =>
  action$.pipe(
    filter(isActionOf(getUserOrgInfoAsync.request)),
    mergeMap(action =>
      from(auth.getOrgInfo(action.payload)).pipe(
        map((response: ApiResponse<PartialOrgInfo>) => {
          if (response.data) {
            return getUserOrgInfoAsync.success(response.data);
          }

          return getUserOrgInfoAsync.failure(
            response.error ?? "No data received"
          );
        }),
        catchError(error => of(getUserOrgInfoAsync.failure(error.toString())))
      )
    )
  );

  export const requestDeleteEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    ApiType
  > = (action$, state$, { auth }) =>
    action$.pipe(
      filter(isActionOf(requestDeleteAccount.request)),
      mergeMap(() =>
        from(auth.requestAccountDelete(userId(state$.value))).pipe(
          mergeMap(() => of(logoutAsync.request())),
          catchError(error => {
            console.error(error);
            return of(requestDeleteAccount.failure(error.toString()));
          })
        )
      )
    );
