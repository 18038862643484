// accounts reducer
import { createReducer } from "typesafe-actions";
import { clearSelectedUsers, toggleUser } from "./actions";

export interface AccountsState {
  selectedUsers: SelectedUsers;
}

export interface SelectedUsers {
  [key: string]: boolean;
}

export const availableInitialState: AccountsState = {
  selectedUsers: {}
};

export default createReducer(availableInitialState)
  .handleAction(toggleUser, (state, action) => {
    let selectedUsers: SelectedUsers = { ...state.selectedUsers };
    if (selectedUsers[action.payload.uid]) {
      delete selectedUsers[action.payload.uid];
    } else {
      selectedUsers[action.payload.uid] = true;
    }
    return { ...state, selectedUsers: selectedUsers };
  })
  .handleAction(clearSelectedUsers, (state, action) => ({
    ...state,
    selectedUsers: {}
  }));
