import { createSelector } from "reselect";
import { RootState } from "typesafe-actions";
import { AccountsState, SelectedUsers } from "./reducer";

export const accounts = (state: RootState) => state.accounts;

export const selectedUsers: (
  state: RootState
) => SelectedUsers = createSelector(
  accounts,
  (accounts: AccountsState) => accounts.selectedUsers
);

export const isAUserSelected: (state: RootState) => boolean = createSelector(
  selectedUsers,
  (selectedUsers: SelectedUsers) => Object.values(selectedUsers).length > 0
);
