import { useDispatch, useSelector } from "react-redux";
import { featureFlagsSelectors } from "../../../state/featureFlags";
import { JiraIntegration } from "../../integrations/jira/jira-integration";
import { useCallback, useEffect, useState } from "react";
import { ADManagement } from "./ad.management";
import { organizationSelectors } from "../../../state/organization";
import { getLicenseInfoAsync } from "../../../state/licenses/actions";
import { licensesSelectors } from "../../../state/licenses";
import { AgilityIntegration } from "../../integrations/agility/agility-integration";

interface IntegrationTypeData {
  name: string;
  component: JSX.Element;
  available: boolean;
}

export const Integrations = () => {
  const dispatch = useDispatch();
  const [
    selectedIntegration,
    setSelectedIntegration
  ] = useState<IntegrationTypeData | null>(null);
  const [availableIntegrations, setAvailableIntegrations] = useState(
    [] as IntegrationTypeData[]
  );

  const isAllowedADIntegration = useSelector(
    organizationSelectors.isAllowedADIntegration
  );
  const useJiraIntegration = useSelector(
    featureFlagsSelectors.useJiraIntegration
  );
  const isAllowedToUseJiraModule = useSelector(
    licensesSelectors.isAllowedToUseJiraModule
  );
  const useAgilityIntegration = useSelector(
    featureFlagsSelectors.useAgilityIntegration
  );
  const isAllowedToUseAgilityModule = useSelector(
    licensesSelectors.isAllowedToUseAgilityModule
  );

  useEffect(() => {
    dispatch(getLicenseInfoAsync.request());
  }, [dispatch]);

  const prepareAvailableIntegrations = useCallback((): IntegrationTypeData[] => {
    const integrations = [
      {
        name: "Active Directory",
        component: <ADManagement />,
        available: isAllowedADIntegration
      },
      {
        name: "Jira",
        component: (
          <JiraIntegration hasAccessToJira={isAllowedToUseJiraModule} />
        ),
        available: useJiraIntegration
      },
      {
        name: "Agility",
        component: (
          <AgilityIntegration
            hasAccessToAgility={isAllowedToUseAgilityModule}
          />
        ),
        available: useAgilityIntegration
      }
    ];

    const availableIntegrations = integrations.filter(
      integration => integration.available
    );
    setSelectedIntegration(availableIntegrations[0]);
    return availableIntegrations;
  }, [
    isAllowedADIntegration,
    useJiraIntegration,
    isAllowedToUseJiraModule,
    isAllowedToUseAgilityModule,
    useAgilityIntegration
  ]);

  useEffect(() => {
    setAvailableIntegrations(prepareAvailableIntegrations());
  }, [prepareAvailableIntegrations]);

  const renderAvailableIntegrations = (): JSX.Element[] => {
    return availableIntegrations.map(integration => {
      const classNames =
        integration.name === selectedIntegration?.name
          ? "text-colorAccent font-bold border-b-2 border-colorAccent self-end cursor-pointer"
          : "font-bold self-end cursor-pointer";

      return (
        <li
          className={`inline-block mx-12 ${classNames}`}
          key={integration.name}
          onClick={() => setSelectedIntegration(integration)}
        >
          {integration.name}
        </li>
      );
    });
  };

  return (
    <>
      <ul className="pb-5">{renderAvailableIntegrations()}</ul>
      {selectedIntegration && selectedIntegration.component}
    </>
  );
};
