import React, { HTMLAttributes, useContext } from "react";
import { AppContext, ManagementPortalContext } from "../../context";
import { EntryInput, EntryMsg, EntrySelect } from "./account.entries.helpers";
import { useSelector } from "react-redux";
import { organizationSelectors } from "../../state/organization";

export type AccountData = {
  id: string;
  email: string;
  selected: boolean;
  readonly: boolean;
  isValid: boolean;
  rejected: string | null;
  firstName?: string;
  lastName?: string;
  department?: string;
};

type WrapProps = {
  hasError: boolean;
  first?: boolean;
  last?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Wrap: React.FC<WrapProps> = ({
  children,
  hasError,
  first,
  last,
  className = "",
  ...props
}) => {
  const borderColor = hasError
    ? "border-red-500"
    : "border-colorTableHeadPortalBackground";
  const leftStyle = first ? "border-l rounded-l" : "";
  const rightStyle = last ? "border-r rounded-r" : "";
  return (
    <div
      className={`p-2 truncate border-t border-b ${borderColor} ${leftStyle} ${rightStyle} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export type AccountEntryProps = {
  accountData: AccountData;
  updateData: (payload: AccountData) => void;
};

export const AccountEntry = (props: AccountEntryProps) => {
  const context: ManagementPortalContext = useContext(AppContext);

  const departments = useSelector(organizationSelectors.departments.names);

  const renderContent = (hasError: boolean): JSX.Element => {
    if (props.accountData.rejected) {
      return (
        <Wrap last hasError={hasError} className="col-span-3">
          <EntryMsg data-test-id="rejected">
            {props.accountData.rejected}
          </EntryMsg>
        </Wrap>
      );
    } else if (props.accountData.readonly) {
      return (
        <Wrap last hasError={hasError} className="col-span-3">
          <EntryMsg data-test-id="registered">
            {context.Localized.string("ADD_ACCOUNTS.REGISTERED_ALREADY")}
          </EntryMsg>
        </Wrap>
      );
    } else {
      return (
        <React.Fragment>
          <Wrap hasError={hasError}>
            <EntryInput
              className="w-full"
              type="text"
              placeholder={context.Localized.string("ADD_ACCOUNTS.FIRSTNAME")}
              value={props.accountData.firstName || ""}
              required={true}
              onChange={e => {
                props.updateData({
                  ...props.accountData,
                  firstName: e.target.value
                });
              }}
            />
          </Wrap>
          <Wrap hasError={hasError}>
            <EntryInput
              className="w-full"
              type="text"
              placeholder={context.Localized.string("ADD_ACCOUNTS.LASTNAME")}
              value={props.accountData.lastName || ""}
              required={true}
              onChange={e => {
                props.updateData({
                  ...props.accountData,
                  lastName: e.target.value
                });
              }}
            />
          </Wrap>
          <Wrap last hasError={hasError}>
            <EntrySelect
              value={props.accountData.department || ""}
              placeholder={context.Localized.string("ADD_ACCOUNTS.DEPARTMENT")}
              onChange={e => {
                props.updateData({
                  ...props.accountData,
                  department: e.target.value
                });
              }}
            >
              {[
                ...new Set([
                  undefined,
                  props.accountData.department,
                  ...departments
                ])
              ].map(d => {
                return (
                  <option key={d + "key"} value={d}>
                    {d || ""}
                  </option>
                );
              })}
            </EntrySelect>
          </Wrap>
        </React.Fragment>
      );
    }
  };

  const hasError =
    ((!props.accountData.isValid && !props.accountData.readonly) ||
      !!props.accountData.rejected) &&
    props.accountData.selected;

  return (
    <>
      <Wrap first hasError={hasError}>
        <input
          className="align-middle"
          type="checkbox"
          checked={props.accountData.selected}
          onChange={_ => {
            props.updateData({
              ...props.accountData,
              selected: !props.accountData.selected
            });
          }}
          disabled={!!props.accountData.rejected}
        />
      </Wrap>
      <Wrap hasError={hasError}>
        <EntryMsg data-test-id="account-email">
          {props.accountData.email}
        </EntryMsg>
      </Wrap>
      {renderContent(hasError)}
    </>
  );
};
