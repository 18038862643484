import React, { ReactElement, useContext } from "react";
import { Navigate, Route, useLocation, useMatch } from "react-router-dom";
import { AppContext } from "../../context";

export const progressPath = "/consent/progress/:app_key/*";
export const finalPath = "/consent/final/:app_key/*";
const deniedPath = "/consent/denied/:app_key/*";
export const failurePath = "/consent/failure/:app_key/*";

const getAppDescriptionId = (appKey: string | undefined): string[] => {
  if (!appKey) return [];

  if (appKey === "ad_server") {
    return ["consent.server-description", "consent.management-description"];
  } else if (appKey === "ad_management") {
    return ["consent.management-description", "consent.server-description"];
  } else {
    return [];
  }
};

export const ConsentProgress = (): ReactElement => {
  const context = useContext(AppContext);
  const match = useMatch(progressPath);

  const [appKey, otherAppKey] = getAppDescriptionId(match?.params.app_key);

  return appKey && otherAppKey ? (
    <div className="m-8 space-y-4">
      <h2 className="text-xl font-bold text-center">
        {context.Localized.string("consent.given")}
      </h2>
      <p>
        {context.Localized.string(
          "consent.given-sub-progress",
          context.Localized.string(appKey),
          context.Localized.string(otherAppKey)
        )}
      </p>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export const ConsentFinal = (): ReactElement => {
  const context = useContext(AppContext);
  const match = useMatch(finalPath);
  const [appKey] = getAppDescriptionId(match?.params.app_key);

  return appKey ? (
    <div className="m-8 space-y-4">
      <h2 className="text-xl font-bold text-center">
        {context.Localized.string("consent.given")}
      </h2>
      <p>
        {context.Localized.string(
          "consent.given-sub-final",
          context.Localized.string(appKey)
        )}
      </p>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export const ConsentDenied = (): ReactElement => {
  const context = useContext(AppContext);

  return (
    <div className="m-8 space-y-4">
      <h2 className="text-xl font-bold text-center">
        {context.Localized.string("consent.not-given")}
      </h2>
      <p>{context.Localized.string("consent.not-given-sub")}</p>
    </div>
  );
};

export const ConsentFailure = (): ReactElement => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const error = query.get("error");
  const error_description = query.get("error_description");

  return error && error_description ? (
    <div className="m-8 space-y-4">
      <h2 className="text-xl font-bold text-center">{error}</h2>
      <p>{error_description}</p>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export const ConsentRoutes = [
  <Route
    key="ad-consent-progress"
    path={progressPath}
    element={<ConsentProgress />}
  />,
  <Route key="ad-consent-final" path={finalPath} element={<ConsentFinal />} />,
  <Route
    key="ad-consent-denied"
    path={deniedPath}
    element={<ConsentDenied />}
  />,
  <Route
    key="ad-consent-failure"
    path={failurePath}
    element={<ConsentFailure />}
  />
];
