import { httpMethods } from "./http-methods";
import globalHeaders from "./global-headers";
import config from "../configuration";
import handleFetchRequest from "./handle-fetch-request";
import {
  ActivePoliciesResponse,
  ResponsePromise,
  HoyluAzureGroupDataResponse,
  InvitationResponse,
  SupportedPoliciesResponse,
  SavePoliciesResponse,
  InvitedUser,
  ApiResponse
} from "./types";
import { Organization, Policies } from "../state/organization/types";
import { Accounts, UserData } from "../state/accounts/types";

export function getInfo(orgId: string): ResponsePromise<Organization> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].auth}/orgs/${orgId}`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
}

export function getMembers(orgId: string): ResponsePromise<UserData[]> {
  return handleFetchRequest(() =>
    fetch(config["serviceConfig"].auth + `/orgs/${orgId}/members`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
}

export function removeMembers(
  orgId: string,
  userIds: string[]
): ResponsePromise<string[]> {
  return handleFetchRequest(() =>
    fetch(config["serviceConfig"].auth + `/api/v1/orgs/${orgId}/members`, {
      method: httpMethods.DELETE,
      headers: globalHeaders,
      body: JSON.stringify(userIds)
    })
  );
}

export function addGroups(
  orgId: string,
  groupsIds: string[]
): ResponsePromise<number> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].auth}/orgs/${orgId}/addGroupsToOrg`, {
      method: httpMethods.PUT,
      headers: globalHeaders,
      body: JSON.stringify(groupsIds)
    })
  );
}

export function removeGroups(
  orgId: string,
  groupsIds: string[]
): ResponsePromise<number> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].auth}/orgs/${orgId}/removeGroupsFromOrg`, {
      method: httpMethods.DELETE,
      headers: globalHeaders,
      body: JSON.stringify(groupsIds)
    })
  );
}

export function getAvailableSSOGroups(
  orgId: string,
  startsWith: string
): ResponsePromise<HoyluAzureGroupDataResponse> {
  const startsWithQuery = startsWith ? `startsWith=${startsWith}&` : "";
  return handleFetchRequest(() =>
    fetch(
      `${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/sso/groups/available?${startsWithQuery}`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
}

export function requestAuth(
  orgId: string,
  email: string,
  redirect: string = `${window.location.origin}/consent`
): ResponsePromise {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/requestAuth`, {
      method: httpMethods.POST,
      headers: globalHeaders,
      body: JSON.stringify({
        email,
        redirect
      })
    })
  );
}

export function inviteToOrg(
  orgId: string,
  people: InvitedUser[]
): ResponsePromise<InvitationResponse> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/invite`, {
      method: httpMethods.POST,
      headers: globalHeaders,
      body: JSON.stringify({ people })
    })
  );
}

export function checkRegister(
  orgId: string,
  emails: string[]
): ResponsePromise<Accounts> {
  return handleFetchRequest(() =>
    fetch(
      `${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/checkregister`,
      {
        method: httpMethods.POST,
        headers: globalHeaders,
        body: JSON.stringify({ emails })
      }
    )
  );
}

const inviteResultActions = ["accept", "decline"];
export function inviteResult(action: string, key: string): ResponsePromise {
  return key && inviteResultActions.indexOf(action) !== -1
    ? handleFetchRequest(() =>
        fetch(
          `${config["serviceConfig"].auth}/api/v1/orgs/invite/${action}?key=${key}`,
          {
            method: httpMethods.POST,
            headers: globalHeaders
          }
        )
      )
    : Promise.reject(new Error("Invalid action"));
}

export function getSupportedPolicies(
  orgId: string
): ResponsePromise<SupportedPoliciesResponse> {
  return handleFetchRequest(() =>
    fetch(
      `${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/policies/supported`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
}

export function getActivePolicies(
  orgId: string
): ResponsePromise<ActivePoliciesResponse> {
  return handleFetchRequest(() =>
    fetch(
      `${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/policies/active`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
}

export function savePolicies(
  orgId: string,
  policies: Set<string>
): ResponsePromise<SavePoliciesResponse> {
  return handleFetchRequest(() =>
    fetch(
      `${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/policies/active`,
      {
        method: httpMethods.POST,
        headers: globalHeaders,
        body: JSON.stringify({ policies: Array.from(policies) })
      }
    )
  );
}

export function saveDepartments(
  orgId: string,
  departments: string[]
): ResponsePromise<string[]> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/departments`, {
      method: httpMethods.POST,
      headers: globalHeaders,
      body: JSON.stringify({ departments: Array.from(departments) })
    })
  );
}

export function getOrganizationWithMembers(
  orgId: string
): Promise<Organization> {
  if (!orgId) {
    const errorMessage =
      "Organization Id is not provided. This is expected when user is not an administrator";
    console.error(errorMessage);
    return Promise.reject(errorMessage);
  }

  const getOrgDetails: Promise<Organization | null | undefined> = getInfo(
    orgId
  ).then((result: ApiResponse<Organization>) => {
    if (result.error) throw result.error;
    return result.data;
  });

  const getOrgMembers: Promise<UserData[] | null | undefined> = getMembers(
    orgId
  ).then((result: ApiResponse<UserData[]>) => {
    if (result.error) throw result.error;
    return result.data;
  });

  return Promise.all([getOrgDetails, getOrgMembers])
    .then(([orgDetails, orgMembers]) => {
      if (
        orgDetails &&
        orgMembers &&
        Array.isArray(orgMembers) &&
        orgMembers.length > 0
      ) {
        const members = orgMembers.map(
          (m: UserData): UserData => {
            let member = {
              ...m,
              email: m.credentials ? m.credentials.email : ""
            };
            delete member.credentials;
            return member;
          }
        );
        const orgResult: Organization = { ...orgDetails, members };

        return orgResult;
      } else {
        throw new Error("Organization or members data are missing");
      }
    })
    .catch(err => {
      console.error(err);
      return Promise.reject(
        new Error("Couldn't fetch organization or members details")
      );
    });
}

export function getAllOrganizationPolicies(orgId: string): Promise<Policies> {
  return Promise.all([getSupportedPolicies(orgId), getActivePolicies(orgId)])
    .then(
      ([supportedResult, activeResult]: [
        ApiResponse<SupportedPoliciesResponse>,
        ApiResponse<ActivePoliciesResponse>
      ]) => {
        if (supportedResult.error || activeResult.error) {
          throw new Error("Couldn't fetch policies");
        }

        const payload: Policies = {
          supported: supportedResult.data,
          active:
            activeResult && activeResult.data ? activeResult.data.policies : []
        };

        return payload;
      }
    )
    .catch(err => {
      console.error(err);
      return Promise.reject(new Error(err));
    });
}
