import { ResponsePromise } from "./types";
import handleFetchRequest from "./handle-fetch-request";
import config from "../configuration";
import { httpMethods } from "./http-methods";
import globalHeaders from "./global-headers";
import { AgilityConnection } from "../state/agility/types";

export interface TestAgilityConnectionArguments {
  agilityUrl: string;
  apiToken: string;
}

export function getAgilityConnectionNames(): ResponsePromise<string[]> {
  return handleFetchRequest<string[]>(() =>
    fetch(`${config["serviceConfig"].agility}/api/v1/configuration/all`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
}

export function getAgilityConnection(name: string): ResponsePromise<string> {
  return handleFetchRequest<string>(() =>
    fetch(`${config["serviceConfig"].agility}/api/v1/configuration/${name}`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
}

export function saveAgilityConnection(
  connection: AgilityConnection,
  settingName: string
): ResponsePromise {
  // as this requests requires value to be passed in headers we take token from globalHeaders and create headers for that request manually
  return handleFetchRequest(() =>
    fetch(
      `${config["serviceConfig"].agility}/api/v1/configuration/${settingName}`,
      {
        method: httpMethods.PUT,
        headers: {
          Authorization: globalHeaders.get("Authorization") || "",
          "Content-Type": "application/json",
          settingValue: JSON.stringify(connection)
        }
      }
    )
  );
}

export function saveAgilityConnectionSecret(
  secret: string,
  settingName: string
): ResponsePromise {
  // as this requests requires value to be passed in headers we take token from globalHeaders and create headers for that request manually
  return handleFetchRequest(() =>
    fetch(
      `${config["serviceConfig"].agility}/api/v1/configuration/${settingName}`,
      {
        method: httpMethods.PUT,
        headers: {
          Authorization: globalHeaders.get("Authorization") || "",
          "Content-Type": "application/json",
          settingValue: secret,
          isSecret: "true"
        }
      }
    )
  );
}

export function deleteAgilityConnection(name): ResponsePromise {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].agility}/api/v1/configuration/${name}`, {
      method: httpMethods.DELETE,
      headers: globalHeaders
    })
  );
}

export function testAgilityConnection({
  agilityUrl,
  apiToken
}: TestAgilityConnectionArguments): ResponsePromise {
  // as this requests requires value to be passed in headers we take token from globalHeaders and create headers for that request manually
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].agility}/api/v1/authentication/verify`, {
      method: httpMethods.GET,
      headers: {
        Authorization: globalHeaders.get("Authorization") || "",
        "Content-Type": "application/json",
        agilityUrl,
        apiToken
      }
    })
  );
}

export const getAgilityConnections = async (): ResponsePromise<
  AgilityConnection[]
> => {
  const connections: AgilityConnection[] = [];
  const connectionsNames = await getAgilityConnectionNames();

  if (connectionsNames.error) {
    return Promise.reject(connectionsNames.error);
  }

  // request was ok, but organization has no connection names so we return empty connections array
  if (!connectionsNames.data || connectionsNames.data.length < 1) {
    return Promise.resolve({ data: [] });
  }

  const connectionsPromises = connectionsNames.data.map((name: string) =>
    getAgilityConnection(name)
  );

  return Promise.allSettled(connectionsPromises)
    .then(results => {
      results.forEach((result, index) => {
        if (result.status === "fulfilled" && result.value.data) {
          const data = JSON.parse(result.value.data);
          connections.push({
            ...data,
            settingName: connectionsNames.data?.[index]
          });
        }
      });

      if (connections.length === 0) {
        return Promise.reject({ error: "Couldn't fetch any connections" });
      }

      // todo - currently we allow only one config, so this situation can not happen
      // but it is possible scenario when there will be more connections
      if (connections.length !== connectionsNames.data?.length) {
        console.error("Failed to fetch some connection(s)");
      }

      return Promise.resolve({ data: [...connections] });
    })
    .catch(er => {
      return Promise.reject(er);
    });
};
