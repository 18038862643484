import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { AccountData, AccountEntry } from "./account.entry";
import { PrimaryButton } from "../shared/button";
import { checkRegister, inviteToOrg } from "../../api/organization";
import { useDispatch, useSelector } from "react-redux";
import { sessionSelectors } from "../../state/session";
import { InvitedUser } from "../../api/types";
import { AppContext, ManagementPortalContext } from "../../context";
import { Spinner } from "../spinner";
import { organizationActions } from "../../state/organization";

export type AddAccountsProps = {
  accounts: Record<string, AccountData>;
  onClose?: () => void;
};

export const addAccountsColumns: CSSProperties = {
  gridTemplateColumns: "min-content minmax(20px, 3fr) 2fr 2fr 2fr"
};

const validate = (payload: AccountData): AccountData => {
  if (
    payload.firstName &&
    payload.lastName &&
    payload.firstName !== "" &&
    payload.lastName !== ""
  ) {
    return { ...payload, isValid: true };
  } else {
    return { ...payload, isValid: false };
  }
};

export const AddAccounts = (props: AddAccountsProps): JSX.Element => {
  const dispatch = useDispatch();
  const context: ManagementPortalContext = useContext(AppContext);
  const orgId = useSelector(sessionSelectors.orgId);

  const [accounts, setAccounts] = useState<Record<string, AccountData>>(() => {
    const accounts: Record<string, AccountData> = {};
    for (const account of Object.values(props.accounts)) {
      accounts[account.id] = validate(account);
    }
    return accounts;
  });
  const [canAdd, setCanAdd] = useState(false);
  const [pending, setPending] = useState(false);
  const [hasRegisteredAccounts, setHasRegisteredAccounts] = useState(false);
  const [showEndMessage, setShowEndMessage] = useState(false);

  const updateEntry = (payload: AccountData) => {
    accounts[payload.id] = validate(payload);

    setAccounts({ ...accounts });
  };

  const commitAccounts = () => {
    const invitedAccounts: InvitedUser[] = Object.values(accounts)
      .filter(a => a.selected)
      .map(a => {
        return {
          credentials: { email: a.email },
          email: a.email,
          firstName: a.firstName ?? "",
          lastName: a.lastName ?? "",
          department: a.department
        };
      });
    setPending(true);
    inviteToOrg(orgId, invitedAccounts).then(_ => {
      setPending(false);
      setShowEndMessage(true);
    });
  };

  const close = () => {
    props.onClose && props.onClose();
    dispatch(organizationActions.getOrganizationInfo(orgId));
  };

  useEffect(() => {
    setPending(true);
    checkRegister(
      orgId,
      Object.values(accounts).map(a => a.email)
    ).then(response => {
      Object.values(accounts).forEach(a => {
        if (Array.isArray(response.data?.rejected)) {
          if (response.data?.rejected.includes(a.email)) {
            a.rejected = "Rejected";
          }
        } else {
          if (response.data?.rejected[a.email]) {
            a.rejected = response.data?.rejected[a.email];
          }
        }
        a.readonly =
          !!a.rejected || !!response.data?.registered.includes(a.email);
        a.selected = !a.rejected;
      });

      response.data?.registered
        ? setHasRegisteredAccounts(response.data?.registered.length > 0)
        : setHasRegisteredAccounts(false);
      setPending(false);
      setAccounts({ ...accounts });
    });
  }, [orgId]);

  useEffect(() => {
    setCanAdd(
      Object.values(accounts).filter(a => a.selected).length !== 0 &&
        Object.values(accounts)
          .filter(a => a.selected)
          .every(a => a.isValid || a.readonly)
    );
  }, [accounts]);

  const sortAccounts = (a: AccountData, b: AccountData) => {
    //rejected first, then readonly, then editable
    if (!!a.rejected < !!b.rejected) return 1;
    if (!!a.rejected > !!b.rejected) return -1;
    if (!!a.readonly < !!b.readonly) return 1;
    if (!!a.readonly > !!b.readonly) return -1;
    return 0;
  };

  return (
    // Tailwind class doesn't work for min with based on vw, hence the style
    <div className="flex flex-col" style={{ minWidth: "75vw" }}>
      {pending && <Spinner />}
      {!pending && !showEndMessage && (
        <React.Fragment>
          <div className="overflow-y-auto my-4 max-h-70vh ">
            <div className="grid gap-y-2" style={addAccountsColumns}>
              {Object.values(accounts)
                .sort(sortAccounts)
                .map(a => (
                  <AccountEntry
                    accountData={a}
                    updateData={updateEntry}
                    key={a.id}
                  />
                ))}
            </div>
          </div>
          <PrimaryButton
            disabled={!canAdd}
            onClick={commitAccounts}
            additionalStyling="w-max self-center"
          >
            {hasRegisteredAccounts
              ? context.Localized.string("ADD_ACCOUNTS.ADD_ACCOUNTS")
              : context.Localized.string("ADD_ACCOUNTS.CREATE")}
          </PrimaryButton>
        </React.Fragment>
      )}
      {!pending && showEndMessage && (
        <div className="flex flex-col">
          <p className="p-2">
            {context.Localized.string("ADD_ACCOUNTS.END_MESSAGE_1")}
          </p>
          <p className="p-2">
            {context.Localized.string("ADD_ACCOUNTS.END_MESSAGE_2")}
          </p>
          <PrimaryButton onClick={close} additionalStyling="self-center mt-4">
            {context.Localized.string("OK")}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};
