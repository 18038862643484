import React, { useState } from "react";
import { Icon } from "../shared/icon";
import { useI18n } from "../../helpers/hooks/useI18n";

type PasswordInputProps = {
  password: string;
  passwordOnChange: (value: string) => void;
  placeholder: string;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  passwordOnChange,
  placeholder
}) => {
  const t = useI18n("SHARE_WORKSPACE.PASSWORD_INPUT.");
  const [isPasswordPlainText, toggleShowPassword] = useState(false);
  const getPasswordInputIcon = (): string => {
    return isPasswordPlainText ? "show" : "hide";
  };

  return (
    <div className="flex my-2 px-1 gap-2 w-2/3 bg-white rounded overflow-hidden">
      <input
        className="input placeholder-indicator w-full p-1 border-none outline-none"
        type={isPasswordPlainText ? "text" : "password"}
        name="password"
        value={password}
        onChange={e => passwordOnChange(e.target.value)}
        placeholder={placeholder}
      />
      <Icon
        icon={getPasswordInputIcon()}
        onClick={() => toggleShowPassword(!isPasswordPlainText)}
        title={t("TOGGLE_PASSWORD_VISIBILITY")}
      />
    </div>
  );
};
