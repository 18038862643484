import { PrimaryButton } from "../shared/button";
import { useI18n } from "../../helpers/hooks/useI18n";

export const UpsellMessage: React.FC = () => {
  const t = useI18n();
  const tu = useI18n("UPSELL.");
  const tl = useI18n("LINKS.");

  const navigateOnClick = () => {
    const win = window.open(tl("PRICING"), "_blank");
    if (win != null) {
      win.focus();
    }
  };

  const commonClasses = "text-center py-5";

  return (
    <div className="flex flex-col">
      <p className={`${commonClasses} text-xl`}>{tu("INFO")}</p>
      <p className={commonClasses}>{tu("CONTACT")}</p>
      <div className={commonClasses}>
        <PrimaryButton
          onClick={navigateOnClick}
          additionalStyling="flex-none w-content"
        >
          {t("CONTACT_US")}
        </PrimaryButton>
      </div>
    </div>
  );
};
