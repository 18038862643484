import { Epic } from "redux-observable";
import { ApiResponse, ApiType } from "../../api/types";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { sessionSelectors } from "../session";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import { sendADAdminInviteAsync } from "./actions";

export const sendADAdminInviteEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { organization }) => {
  return action$.pipe(
    filter(isActionOf(sendADAdminInviteAsync.request)),
    mergeMap(action => {
      const orgId = sessionSelectors.orgId(state$.value);
      return from(organization.requestAuth(orgId, action.payload)).pipe(
        map((response: ApiResponse) => {
          if (response.error) {
            return sendADAdminInviteAsync.failure(response.error);
          }

          return sendADAdminInviteAsync.success();
        }),
        catchError(error =>
          of(sendADAdminInviteAsync.failure(error.toString()))
        )
      );
    })
  );
};
