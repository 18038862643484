import { useI18n } from "../../helpers/hooks/useI18n";
import { useState } from "react";
import React from "react";
import { PasswordInput } from "./password.input";
import { PrimaryButton } from "../shared/button";

export const MODE = {
  DEFAULT: "DEFAULT",
  ADD: "ADD",
  REMOVE: "REMOVE",
  CHANGE: "CHANGE"
};

export const WorkspacePassword: React.FC<WorkspacePasswordProps> = ({
  hasPassword,
  confirmPasswordAction,
  requiresPassword
}) => {
  const t = useI18n("SHARE_WORKSPACE.WORKSPACE_PASSWORD.");
  const [mode, setMode] = useState(MODE.DEFAULT);
  const [isAddingPassword, toggleAddPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, updateConfirmPassword] = useState("");

  function handleChooseMode(newMode: string) {
    switch (newMode) {
      case MODE.ADD:
        toggleAddPassword(true);
        setMode(MODE.ADD);
        break;
      case MODE.REMOVE:
        toggleAddPassword(true);
        setMode(MODE.REMOVE);
        break;
      case MODE.CHANGE:
        toggleAddPassword(true);
        setMode(MODE.CHANGE);
        break;
      default:
        setMode(MODE.DEFAULT);
    }
  }

  const passwordValidation = new RegExp(
    "^([a-zA-Za-zA-Z@#$%^&-+=()\\d]).{7,32}"
  );

  let allowAddPassword: boolean = hasPassword
    ? !requiresPassword || passwordValidation.test(newPassword)
    : passwordValidation.test(newPassword) && newPassword === confirmPassword;

  let allowChangePassword: boolean =
    allowAddPassword &&
    newPassword.length !== 0 &&
    confirmPassword.length !== 0 &&
    newPassword === confirmPassword;

  const triggerPasswordAction = (mode: string): void => {
    confirmPasswordAction(newPassword, currentPassword, mode);
    wipeOutData();
  };

  const wipeOutData = (): void => {
    toggleAddPassword(false);
    setNewPassword("");
    setCurrentPassword("");
    updateConfirmPassword("");
  };

  const setHeaderText = (mode: string): string => {
    switch (mode) {
      case MODE.ADD:
        return t("ADD_WORKSPACE_PASSWORD");
      case MODE.REMOVE:
        return t("REMOVE_WORKSPACE_PASSWORD");
      case MODE.CHANGE:
        return t("CHANGE_WORKSPACE_PASSWORD");
      default:
        return "";
    }
  };

  return (
    <div className={"sm::ml-8"}>
      <div className={isAddingPassword ? "" : "hidden"}>
        <div className="mb-1 text-xs w-4/5">{setHeaderText(mode)}</div>
        {mode !== MODE.REMOVE && (
          <div className="mb-3/2 text-xs">{t("CHARACTERS")}</div>
        )}
        {mode === MODE.REMOVE && (
          <PasswordInput
            password={newPassword}
            passwordOnChange={setNewPassword}
            placeholder={t("ENTER_CURRENT_PASSWORD")}
          />
        )}
        {mode === MODE.ADD && (
          <React.Fragment>
            <PasswordInput
              password={newPassword}
              passwordOnChange={setNewPassword}
              placeholder={t("SET_NEW_PASSWORD")}
            />
            <PasswordInput
              password={confirmPassword}
              passwordOnChange={updateConfirmPassword}
              placeholder={t("CONFIRM_NEW_PASSWORD")}
            />
          </React.Fragment>
        )}
        {mode === MODE.CHANGE && (
          <React.Fragment>
            <PasswordInput
              password={currentPassword}
              passwordOnChange={setCurrentPassword}
              placeholder={t("ENTER_CURRENT_PASSWORD")}
            />
            <PasswordInput
              password={newPassword}
              passwordOnChange={setNewPassword}
              placeholder={t("SET_NEW_PASSWORD")}
            />
            <PasswordInput
              password={confirmPassword}
              passwordOnChange={updateConfirmPassword}
              placeholder={t("CONFIRM_NEW_PASSWORD")}
            />
          </React.Fragment>
        )}
        <div className="flex justify-end gap-2">
          <PrimaryButton title={t("CANCEL")} onClick={() => wipeOutData()}>
            {t("CANCEL")}
          </PrimaryButton>
          {(mode === MODE.ADD || mode === MODE.REMOVE) && (
            <PrimaryButton
              title={hasPassword ? t("REMOVE_PASSWORD") : t("ADD_PASSWORD")}
              onClick={() => triggerPasswordAction(mode)}
              disabled={!allowAddPassword}
            >
              {hasPassword ? t("REMOVE_PASSWORD") : t("ADD_PASSWORD")}
            </PrimaryButton>
          )}
          {mode === MODE.CHANGE && (
            <PrimaryButton
              title={t("CHANGE_PASSWORD")}
              onClick={() => triggerPasswordAction(mode)}
              additionalStyling="btn-primary"
              disabled={!allowChangePassword}
            >
              {t("CHANGE_PASSWORD")}
            </PrimaryButton>
          )}
        </div>
      </div>
      <div
        className={
          "flex justify-end items-center gap-2 " +
          (!isAddingPassword ? "" : "hidden")
        }
      >
        <span className={"mr-auto"}>
          {hasPassword
            ? t("WORKSPACE_PASSWORD_SET")
            : t("WORKSPACE_NO_PASSWORD")}
        </span>
        {hasPassword && (
          <PrimaryButton
            title={t("CHANGE")}
            onClick={() => handleChooseMode(MODE.CHANGE)}
            additionalStyling="btn-primary my-0"
          >
            {t("CHANGE")}
          </PrimaryButton>
        )}

        <PrimaryButton
          title={hasPassword ? t("REMOVE") : t("ADD_PASSWORD")}
          disabled={hasPassword && requiresPassword}
          onClick={() =>
            hasPassword
              ? handleChooseMode(MODE.REMOVE)
              : handleChooseMode(MODE.ADD)
          }
          additionalStyling={"btn-primary my-0"}
        >
          {hasPassword ? t("REMOVE") : t("ADD_PASSWORD")}
        </PrimaryButton>
      </div>
    </div>
  );
};

type WorkspacePasswordProps = {
  hasPassword: boolean;
  confirmPasswordAction: (
    password: string,
    currentPassword: string,
    mode: string
  ) => void;
  requiresPassword: boolean;
};
