import { ApiResponse, ApiType } from "../../api/types";
import * as types from "../licenses/types";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { Epic } from "redux-observable";
import {
  getAvailableLicencesAsync,
  getLicenseInfoAsync,
  setLicenseToUserAsync,
  takeLicenseFromUserAsync
} from "./actions";
import { sessionSelectors } from "../session";
import { getOrganizationInfoAsync } from "../organization/actions";
import { UserData } from "../accounts/types";
import { isActionOf, RootAction, RootState } from "typesafe-actions";

export const getAvailableEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { licenses }) => {
  return action$.pipe(
    filter(isActionOf(getAvailableLicencesAsync.request)),
    mergeMap(action =>
      from(licenses.getAvailable(action.payload)).pipe(
        map((response: ApiResponse<types.License[]>) => {
          const { data, error } = response;
          const licenses: types.License[] =
            data && Array.isArray(data) ? data : [];

          return error
            ? getAvailableLicencesAsync.failure(error)
            : getAvailableLicencesAsync.success(licenses);
        }),
        catchError(error =>
          of(getAvailableLicencesAsync.failure(error.toString()))
        )
      )
    )
  );
};

export const setLicenseToUserEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { licenses }) => {
  return action$.pipe(
    filter(isActionOf(setLicenseToUserAsync.request)),
    mergeMap(action => {
      const { licenseId, userId } = action.payload;
      const orgId = sessionSelectors.orgId(state$.value);
      return from(licenses.setToUser({ licenseId, userId, orgId })).pipe(
        mergeMap((response: ApiResponse<UserData[]>) => {
          if (response.error) {
            return [setLicenseToUserAsync.failure(response.error)];
          }
          return [
            setLicenseToUserAsync.success(),
            getOrganizationInfoAsync.request(orgId),
            getAvailableLicencesAsync.request(orgId)
          ];
        }),
        catchError(error => {
          return of(setLicenseToUserAsync.failure(error.toString()));
        })
      );
    })
  );
};

export const takeLicenseFromUserEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { licenses }) => {
  return action$.pipe(
    filter(isActionOf(takeLicenseFromUserAsync.request)),
    mergeMap(action => {
      const orgId = sessionSelectors.orgId(state$.value);
      const { uid, license } = action.payload;
      if (!license) {
        return [takeLicenseFromUserAsync.failure("Found no licence")];
      }
      return from(
        licenses.takeFromUser({ licenseId: license.id, userId: uid, orgId })
      ).pipe(
        mergeMap((response: ApiResponse<UserData[]>) => {
          if (response.error) {
            return [takeLicenseFromUserAsync.failure(response.error)];
          }
          return [
            takeLicenseFromUserAsync.success(),
            getOrganizationInfoAsync.request(orgId),
            getAvailableLicencesAsync.request(orgId)
          ];
        }),
        catchError(error =>
          of(takeLicenseFromUserAsync.failure(error.toString()))
        )
      );
    })
  );
};

export const getLicenseInfoEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { licenses }) => {
  return action$.pipe(
    filter(isActionOf(getLicenseInfoAsync.request)),
    mergeMap(action =>
      from(licenses.getLicenseInfo()).pipe(
        map((response: ApiResponse<types.LicenseInfo>) => {
          const { data, error } = response;

          return error
            ? getLicenseInfoAsync.failure(error)
            : getLicenseInfoAsync.success(
                data ? data : ({} as types.LicenseInfo)
              );
        }),
        catchError(error => of(getLicenseInfoAsync.failure(error.toString())))
      )
    )
  );
};
