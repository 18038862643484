import { getWorkspacesByProject } from "../../api/projects";
import { showErrorToast } from "../../helpers/show.toast.helpers";
import type { MyWorkspaceMetadataV3 } from "../../api/types";

export const fetchProjectWorkspaces = async (
  projectId: string,
  errorMessage: string
): Promise<MyWorkspaceMetadataV3[] | undefined> => {
  try {
    const workspaces = await getWorkspacesByProject(projectId);

    if (workspaces.data) {
      return workspaces.data;
    }

    if (workspaces.error) {
      showErrorToast(errorMessage, workspaces.error);
    }
  } catch (e) {
    showErrorToast(errorMessage, e);
  }
};
