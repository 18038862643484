import { useContext } from "react";
import { AppContext, ManagementPortalContext } from "../context";
import { DateTime } from "luxon";

export const Footer = (): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);
  const year = DateTime.local().year;
  return (
    <footer className="inline-flex justify-center">
      <span className="text-lg text-colorPrimary">
        {context.Localized.string("FOOTER.CONTENT", year)}
      </span>
      <span className="text-lg text-colorPrimary font-bold ml-1">Hoylu</span>
    </footer>
  );
};
