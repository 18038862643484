import React from "react";
import { useI18n } from "../../helpers/hooks/useI18n";
import { PermissionsPill } from "../shared/permissions/permissions.pill";
import { ShareOptionValue } from "../shared/permissions/permissions.types";

export type HeaderPermissionsProps = {
  enabledOption: ShareOptionValue;
  text: string;
};

export const HeaderPermissions: React.FC<HeaderPermissionsProps> = ({
  enabledOption,
  text
}) => {
  const t = useI18n("SHARE_WORKSPACE.");
  const tp = useI18n("SHARE_WORKSPACE.PERMISSION_BAR.");

  const accessText: string = `${text} ${tp("HAS")}`;
  return (
    <div className="flex flex-row mx-auto">
      <div className="flex w-auto text-sm items-center">
        <div title={accessText}>{accessText}</div>
        <div className="flex items-center">
          <div className="text-black mr-1 sm:mx-2 flex">
            <PermissionsPill
              label={t(enabledOption.title)}
              color={enabledOption.color}
              isActive={true}
            />
          </div>
          <div>{tp("ACCESS")}</div>
        </div>
      </div>
    </div>
  );
};
