import { RootState } from "typesafe-actions";
import { Collaborator, Project, ProjectsCollaborators } from "./types";

export const projects = (state: RootState): Project[] => {
  return state.projects?.projects || [];
};

export const findProject =
  (id: string | undefined) =>
  (state: RootState): Project | undefined => {
    return state.projects.projects?.find(
      (project: Project) => project.id === id
    );
  };

export const findCollaboratorsByProject =
  (projectId: string) =>
  (state: RootState): Collaborator[] => {
    return state.projects.collaborators[projectId] || [];
  };

export const collaborators = (state: RootState): ProjectsCollaborators => {
  return state.projects?.collaborators || {};
};

export const loading = (state: RootState): boolean => {
  return state.projects?.loadingProjects || false;
};

export const error = (state: RootState): boolean => {
  return state.projects?.errorProjects || false;
};

export const isProjectAdmin = (state: RootState): boolean => {
  return !!state.projects?.isProjectAdmin;
};
