import { DateTime } from "luxon";

export const printNiceDayDate = (
  date: number | string | undefined | null,
  defaultValue: string
) => {
  return createDateTime(date, defaultValue).toLocaleString();
};

export const printNiceDateAndHour = (
  date: number | string | undefined,
  defaultValue: string
) => {
  return createDateTime(date, defaultValue).toLocaleString(DateTime.DATETIME_FULL);
};

const createDateTime = (date: number | string | undefined | null,   defaultValue: string) => {
  if (!date) return defaultValue;
  return typeof date === "string"
    ? DateTime.fromISO(date)
    : DateTime.fromMillis(date);
}