import React from "react";
import type { MyWorkspaceMetadataV3, Workspace } from "../../../api/types";
import { WorkspacesList } from "../../workspaces/list/workspaces.list";

type ProjectWorkspacesProps = {
  workspaces: MyWorkspaceMetadataV3[];
  refreshWorkspaces: () => void;
};

export const ProjectWorkspaces = ({
  workspaces,
  refreshWorkspaces
}: ProjectWorkspacesProps) => {
  /**
    changing MyWorkspaceMetadataV3 into model similar to DocumentMetadataV1Response alias Workspace
    the resulting objects will miss permissions related fields as they are not part of MyWorkspaceMetadataV3
    the date fields format will be also different as v1 uses Linux time and v3 ISO8061,
    but we use in the workspaces list printNiceDayDate which handles both types.
    This mapping should be removed when we fully switch to v3 and replaced with proper changes in the workspaces list
   */
  const mapWorkspaces = (): Workspace[] => {
    return workspaces.map((workspace: any) => {
      const { workspaceId, workspaceType, workspaceName, ...rest } = workspace;

      return {
        documentId: workspaceId,
        documentType: workspaceType,
        documentName: workspaceName,
        ...rest
      };
    });
  };

  return (
    <WorkspacesList
      workspaces={mapWorkspaces()}
      silentPageRefresh={refreshWorkspaces}
      showPermissionsColumns={false}
    />
  );
};
