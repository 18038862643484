import { createSelector } from "reselect";
import { Group, GroupState, GroupStateMap, GroupsState } from "./types";
import { organization } from "../organization/selectors";
import { Organization } from "../organization/types";
import { RootState } from "typesafe-actions";

// Groups selectors
export const allGroups = (state: { groups: GroupsState }): GroupStateMap => {
  return state.groups.allGroups || {};
};

export const loading = (state: { groups: GroupsState }): boolean =>
  state.groups.loading || false;
export const error = (state: { groups: GroupsState }): boolean =>
  state.groups.error || false;

export const hasMoreGroups = (state: { groups: GroupsState }): boolean => {
  return state.groups.hasMoreGroups || false;
};

export const groupStartsWith = (state: {
  groups: GroupsState;
}): string | undefined => {
  return state.groups.groupStartsWith;
};

const activeIds = (state: { groups: GroupsState }): string[] =>
  state.groups.activeGroupIds;

export const orgGroupDetails: (
  state: RootState
) => { [key: string]: Group } = createSelector(
  organization,
  (org: Organization): { [key: string]: Group } => {
    return org.hoyluUserGroupDetails
      ? org.hoyluUserGroupDetails.reduce(
          (groupMap, g) => ({ ...groupMap, [g.id]: g }),
          {}
        )
      : {};
  }
);

export const activeGroups = createSelector(
  allGroups,
  activeIds,
  orgGroupDetails,
  (groups: GroupStateMap, activeIds: string[], orgGroupDetails): GroupState[] =>
    activeIds.map(
      id => groups[id] || orgGroupDetails[id] || { id, displayName: id }
    )
);

export const inactiveGroups = createSelector(
  allGroups,
  activeIds,
  (groups: GroupStateMap, activeIds: string[]): GroupState[] => {
    return Object.values(groups).filter(
      (g: GroupState) => !activeIds.includes(g.id)
    );
  }
);

export const updating = createSelector(allGroups, (groups: GroupStateMap) =>
  Object.entries(groups).some(([_, group]) => group.updating)
);
