import { ReactNode, useContext } from "react";
import { AppContext } from "../../context";
import { PrimaryButton, SecondaryButton } from "../shared/button";
import { Modal } from "./modal";
import { ModalHeader } from "./modalElements/modal.header";

export type ConfirmModalProps = {
  title: string;
  onOk: () => void;
  onCancel: () => void;
  children: ReactNode;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  children,
  onOk,
  onCancel
}) => {
  const context = useContext(AppContext);
  return (
    <Modal handleOutsideClick={onCancel}>
      <ModalHeader
        modalTitle={title}
        mainIcon={() => <></>}
        closeModal={onCancel}
      />
      {children}
      <div className="flex justify-end mt-6 space-x-2">
        <SecondaryButton onClick={onCancel}>
          {context.Localized.string("CANCEL")}
        </SecondaryButton>
        <PrimaryButton onClick={onOk}>
          {context.Localized.string("OK")}
        </PrimaryButton>
      </div>
    </Modal>
  );
};
