import { createReducer } from "typesafe-actions";
import { AgilityConnection, AgilityState } from "./types";
import { getAgilityConnectionsAsync } from "./actions";

export const agilityInitialState: AgilityState = {
  loading: false,
  error: false,
  payload: [] as AgilityConnection[]
};

export default createReducer(agilityInitialState)
  .handleAction(getAgilityConnectionsAsync.request, state => ({
    ...state,
    loading: true,
    error: false
  }))
  .handleAction(getAgilityConnectionsAsync.failure, state => ({
    ...state,
    loading: false,
    error: true
  }))
  .handleAction(getAgilityConnectionsAsync.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    payload: action.payload
  }));
