import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../navbar/header";
import { Tile } from "../../tile";
import { AppContext } from "../../../context";
import { ProjectsPath } from "../../../routes";
import { ProjectInfo } from "./project.info";
import { ProjectEditorModal } from "../project.editor/project.editor.modal";
import { Collaborators } from "../project.editor/collaborators";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { useDispatch, useSelector } from "react-redux";
import { projectsActions, projectsSelectors } from "../../../state/projects";
import { useCanEditProject } from "../../../helpers/hooks/useCanEditProject";
import { showError } from "../../../helpers/show.toast.helpers";
import { ProjectWorkspaces } from "./project.workspaces";
import { fetchProjectWorkspaces } from "../fetch.project.workspaces";
import type { MyWorkspaceMetadataV3 } from "../../../api/types";

export const ProjectPage = () => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const params = useParams();
  const dispatch = useDispatch();
  const t = useI18n(`PROJECTS.`);
  const te = useI18n("ERRORS.");
  const [editProject, setEditProject] = useState(false);
  const [isAddingCollaborators, setIsAddingCollaborators] = useState(false);
  const project = useSelector(projectsSelectors.findProject(params.projectId));
  const loading = useSelector(projectsSelectors.loading);
  const [requestDispatched, setRequestDispatched] = useState(false);
  const [workspaces, setWorkspaces] = useState<
    MyWorkspaceMetadataV3[] | undefined
  >(undefined);
  const canEditProject = useCanEditProject(project);

  const navigateToProjects = useCallback(
    () => navigate(`../${ProjectsPath}`, { replace: true }),
    [navigate]
  );

  useEffect(() => {
    if (params.projectId && !project) {
      dispatch(projectsActions.getMyProjectsAsync.request());
      setRequestDispatched(true);
    }
    // warning is disabled because we DO want to call the hook only on component launch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forceNavigateToProjects = useCallback(() => {
    showError(t("FAILED_FETCHING", params.projectId));
    navigateToProjects();
  }, [navigateToProjects, params.projectId, t]);

  useEffect(() => {
    if (requestDispatched && !project && !loading) {
      forceNavigateToProjects();
    }
  }, [loading, project, requestDispatched, forceNavigateToProjects]);

  const loadWorkspaces = useCallback(() => {
    if (!project?.id) return undefined;

    fetchProjectWorkspaces(project.id, te("GET_WORKSPACES_IDS_ERROR")).then(
      workspaces => {
        if (workspaces) {
          const sortedWorkspaces = workspaces.sort((a, b) => {
            if (!a.workspaceName || !b.workspaceName) return 0;
            const nameOfA = a.workspaceName.toLowerCase();
            const nameOfB = b.workspaceName.toLowerCase();

            if (nameOfA < nameOfB) {
              return -1;
            }
            if (nameOfA > nameOfB) {
              return 1;
            }
            return 0;
          });
          setWorkspaces(sortedWorkspaces);
        }
      }
    );
  }, [project?.id, te]);

  useEffect(() => {
    if (project) {
      if (!canEditProject) {
        forceNavigateToProjects();
      } else {
        dispatch(projectsActions.getCollaboratorsAsync.request(project.id));
        loadWorkspaces();
      }
    }
  }, [
    project,
    forceNavigateToProjects,
    dispatch,
    canEditProject,
    loadWorkspaces
  ]);

  return (
    <>
      <div>
        <Header
          title={context.Localized.string("PROJECTS.LIST_TITLE")}
          subTitle={`> ${project?.name || ""}`}
          clickHandler={navigateToProjects}
        />
        <div className="flex justify-center">
          <div className="flex flex-col lg:items-center w-full lg:max-w-80vw">
            <div
              className={
                "flex flex-col flex-grow items-center lg:items-stretch lg:flex-row lg:justify-center w-full"
              }
            >
              {project && [
                <Tile className="w-120" key="Project Info">
                  <ProjectInfo
                    project={project}
                    edit={() => setEditProject(true)}
                    workspacesQuantity={workspaces?.length}
                  />
                </Tile>,
                <Tile className="flex-grow w-full" key="Collaborators">
                  <div className="px-2 py-4 text-xl font-bold font-black">
                    {isAddingCollaborators
                      ? t("ADD_USERS_TITLE")
                      : t("COLLABORATORS")}
                  </div>
                  <Collaborators
                    project={project}
                    isAddingCollaborators={isAddingCollaborators}
                    setIsAddingCollaborators={setIsAddingCollaborators}
                  />
                </Tile>
              ]}
            </div>
            {!!workspaces?.length && (
              <div className="flex w-full">
                <Tile className="flex-grow w-full">
                  <ProjectWorkspaces
                    workspaces={workspaces}
                    refreshWorkspaces={loadWorkspaces}
                  />
                </Tile>
              </div>
            )}
          </div>
        </div>
      </div>
      {editProject && project && (
        <ProjectEditorModal
          project={project}
          onClose={() => setEditProject(false)}
        />
      )}
    </>
  );
};
