import { AppContext, ManagementPortalContext } from "../../context";
import React, { useContext } from "react";
import { EntryInput, EntryMsg } from "./account.entries.helpers";
import { Wrap } from "./account.entry";

export type EmailData = {
  id: string;
  email: string;
  selected: boolean;
  isValid: boolean;
};

type EmailEntryProps = {
  emailData: EmailData;
  updateEmail: (e: EmailData) => void;
};

export const EmailEntry = (props: EmailEntryProps) => {
  const context: ManagementPortalContext = useContext(AppContext);
  const hasError = !props.emailData.isValid && props.emailData.selected;
  return (
    <>
      <Wrap first hasError={hasError}>
        <input
          className="align-middle"
          type="checkbox"
          checked={props.emailData.selected}
          onChange={_ => {
            props.updateEmail({
              ...props.emailData,
              selected: !props.emailData.selected
            });
          }}
        />
      </Wrap>
      <Wrap last hasError={hasError} className="flex gap-2">
        <EntryInput
          className="flex-grow"
          type="text"
          placeholder={context.Localized.string("ADD_ACCOUNTS.EMAIL")}
          value={props.emailData.email}
          onChange={e => {
            props.updateEmail({
              ...props.emailData,
              email: e.target.value
            });
          }}
        />
        {!props.emailData.isValid && (
          <EntryMsg className="truncate">
            {context.Localized.string("ADD_ACCOUNTS.EMAIL_CORRECTION.ERROR")}
          </EntryMsg>
        )}
      </Wrap>
    </>
  );
};
