import { LocalizingFunction } from "../../../helpers/hooks/useI18n";
import {
  getFirstPageOfWorkspaces,
  getPageOfWorkspacesByUrl,
  MAX_LIMIT_OF_WORKSPACES_PER_REQUEST
} from "../../../api/workspaces";
import { printNiceDateAndHour } from "../../../helpers/print.nice.day.date";
import Papa from "papaparse";
import { downloadCsv } from "../../../helpers/download.csv";
import { sleep } from "../../../helpers/sleep";
import { Workspace } from "../../../api/types";

export const prepareWorkspacesCsv = async (
  t: LocalizingFunction,
  orgId: string
): Promise<void | Error> => {
  const workspaces = await getAllWorkspaces(orgId);

  if (!Array.isArray(workspaces)) throw new Error(workspaces);

  const csvKeys = [
    t("ID"),
    t("NAME"),
    t("MODULE"),
    t("CREATOR"),
    t("CREATED"),
    t("LAST_EDITED"),
    t("HAS_PASSWORD"),
    t("PUBLIC_ACCESS"),
    t("OTHER_ADMINS"),
    t("READ_ACCESS"),
    t("WRITE_ACCESS"),
    t("NO_ACCESS")
  ];

  const toArray = (workspace: Workspace) => {
    return [
      workspace.documentId,
      workspace.documentName,
      getModuleOrType(workspace),
      workspace.administrators[0],
      printNiceDateAndHour(workspace.createTime, ""),
      printNiceDateAndHour(workspace.lastModified, ""),
      workspace.hasPassword,
      workspace.globalAccess,
      workspace.administrators.slice(1).join(" "),
      workspace.readers.join(" "),
      workspace.writers.join(" "),
      workspace.unspecifiedUsers.join(" ")
    ];
  };

  const csvContent: string = Papa.unparse({
    fields: csvKeys,
    data: workspaces.map(toArray)
  });

  downloadCsv(csvContent, "workspaces.csv");
};

const getAllWorkspaces = async (
  orgId: string
): Promise<Workspace[] | string> => {
  let workspaces: Workspace[] = [];
  const result = await getFirstPageOfWorkspaces({
    orgId,
    limit: MAX_LIMIT_OF_WORKSPACES_PER_REQUEST
  });

  if (result.error) return result.error;

  let next = result.data?.next;

  if (result.data?.workspaces) {
    workspaces = workspaces.concat(result.data.workspaces);
  }

  while (next) {
    // we do not want to fire these requests too often :)
    await sleep(1000);
    const nextPage = await getPageOfWorkspacesByUrl(next);

    if (nextPage.error) {
      // TODO - ask Jakob about handling - some workspaces were loaded,but we can't continue as we didn't receive next
      console.error("Not all workspaces were fetched.", nextPage.error);
    }

    if (nextPage.data?.workspaces) {
      workspaces = workspaces.concat(nextPage.data.workspaces);
    }

    next = nextPage.data?.next;
  }

  return workspaces;
};

const getModuleOrType = (workspace: Workspace) => {
  let module = workspace.module?.name;
  if (!module) module = workspace.documentType;
  return module;
};
