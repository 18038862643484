import { useLocation } from "react-router-dom";
import { ProfilePanelPath } from "../../routes";

export const usePanelDetector = () => {
  const location = useLocation();
  const isUserProfilePanel = location.pathname.includes(
    `/${ProfilePanelPath}/`
  );

  return { isUserProfilePanel };
};
