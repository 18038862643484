import { License } from "../licenses/types";

export const TOGGLE_USER = "@accounts/TOGGLE_USER";
export const CLEAR_SELECTED_USERS = "@accounts/CLEAR_SELECTED_USERS";

/**
 * Applications main user data structure.
 * It's an extended UserSnapshot object that comes from api v1.
 * */
export interface UserData {
  companyName: string;
  credentials?: { email: string; password: string };
  department: string;
  email: string;
  firstName: string;
  gdprConsentDate: number;
  isPartialAccount: boolean;
  lastLoginMillis: number;
  lastName: string;
  license?: License;
  org: string;
  uid: string;
  projects?: string[];
}

/**
 * Helper structure used to match data type from api. It comes from auth v0 endpoints (eg. "userProfile/${userId}").
 * Refrain from direct usage and use {@link UserData} instead.
 * To map this data type to UserData use {@link userProfileToUserData}.
 * */
export interface UserProfile {
  uuid: string;
  fName: string;
  lName: string;
  email: string;
  company: string;
  department: string;
  creationTime: number;
  lastLoginInfo: {
    lastLoginTime: number;
    lastLoginVersion: string;
  };
  emailValidated: boolean;
  gdprConsentTime: number;
  twoFactor: boolean;
}

export interface Accounts {
  registered: string[];
  rejected: {} | string[];
  unregistered: string[];
}

export const isUserData = (data: any): data is UserData => {
  return (
    data.uid &&
    typeof data.firstName === "string" &&
    typeof data.lastName === "string" &&
    typeof data.email === "string"
  );
};
