import { Epic } from "redux-observable";
import * as types from "../organization/types";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import {
  editMemberDepartmentAsync,
  getOrganizationInfoAsync,
  getOrganizationPoliciesAsync,
  removeMembersAsync,
  saveOrganizationDepartmentsAsync,
  saveOrganizationPoliciesAsync
} from "./actions";
import { from, of } from "rxjs";
import { ApiResponse, ApiType, SavePoliciesResponse } from "../../api/types";
import { sessionSelectors } from "../session";
import { UserData } from "../accounts/types";
import { isActionOf, RootAction, RootState } from "typesafe-actions";

export const organizationInfoEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { organization }: ApiType) => {
  return action$.pipe(
    filter(isActionOf(getOrganizationInfoAsync.request)),
    mergeMap(action =>
      from(organization.getOrganizationWithMembers(action.payload)).pipe(
        map((response: types.Organization) =>
          getOrganizationInfoAsync.success(response)
        ),
        catchError(error =>
          of(getOrganizationInfoAsync.failure(error.toString()))
        )
      )
    )
  );
};

export const organizationPoliciesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { organization }) => {
  return action$.pipe(
    filter(isActionOf(getOrganizationPoliciesAsync.request)),
    mergeMap(action =>
      from(organization.getAllOrganizationPolicies(action.payload)).pipe(
        map(response => getOrganizationPoliciesAsync.success(response)),
        catchError(error =>
          of(getOrganizationPoliciesAsync.failure(error.toString()))
        )
      )
    )
  );
};

export const saveOrganizationPoliciesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { organization }) => {
  return action$.pipe(
    filter(isActionOf(saveOrganizationPoliciesAsync.request)),
    mergeMap(action =>
      from(
        organization.savePolicies(action.payload.orgId, action.payload.policies)
      ).pipe(
        map((result: ApiResponse<SavePoliciesResponse>) => {
          if (result.data) {
            return saveOrganizationPoliciesAsync.success(result.data);
          }

          return saveOrganizationPoliciesAsync.failure(
            result.error ? result.error : "No data received"
          );
        }),
        catchError(error =>
          of(saveOrganizationPoliciesAsync.failure(error.toString()))
        )
      )
    )
  );
};

export const saveOrganizationDepartmentsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { organization }) => {
  return action$.pipe(
    filter(isActionOf(saveOrganizationDepartmentsAsync.request)),
    mergeMap(action =>
      from(
        organization.saveDepartments(
          action.payload.orgId,
          action.payload.departments
        )
      ).pipe(
        map((result: ApiResponse<string[]>) => {
          if (result.data) {
            return saveOrganizationDepartmentsAsync.success(result.data);
          }

          return saveOrganizationDepartmentsAsync.failure(
            result.error ? result.error : "No data received"
          );
        }),
        catchError(error =>
          of(saveOrganizationDepartmentsAsync.failure(error.toString()))
        )
      )
    )
  );
};

export const editMemberDepartmentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { users }) => {
  return action$.pipe(
    filter(isActionOf(editMemberDepartmentAsync.request)),
    mergeMap(action => {
      const orgId = sessionSelectors.orgId(state$.value);

      return from(
        users.handleEditingMemberDepartment(
          action.payload.uid,
          action.payload.department,
          orgId
        )
      ).pipe(
        map((response: UserData[]) =>
          editMemberDepartmentAsync.success(response)
        )
      );
    }),
    catchError(error => of(editMemberDepartmentAsync.failure(error.toString())))
  );
};

export const removeMembersEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { organization }) => {
  return action$.pipe(
    filter(isActionOf(removeMembersAsync.request)),
    mergeMap(action => {
      return from(
        organization.removeMembers(
          action.payload.orgId,
          Object.keys(action.payload.users)
        )
      ).pipe(
        map((response: ApiResponse<string[]>) => {
          if (response.data) {
            return removeMembersAsync.success(response.data);
          }

          return removeMembersAsync.failure(
            response.error ? response.error : "No data received"
          );
        })
      );
    }),
    catchError(error => of(removeMembersAsync.failure(error.toString())))
  );
};
