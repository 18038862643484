import { useContext, useEffect, useState } from "react";
import { Policies } from "./tabs/policies";
import { Accounts } from "./tabs/accounts";
import { AppContext, ManagementPortalContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { organizationActions } from "../../state/organization";
import { licensesActions } from "../../state/licenses";
import { sessionSelectors } from "../../state/session";
import { CompanyInformation } from "./tabs/company.information";
import { featureFlagsSelectors } from "../../state/featureFlags";
import { Integrations } from "./tabs/integrations";
import { Projects } from "./tabs/projects";
import { Workspaces } from "./tabs/workspaces";
import { projectsSelectors } from "../../state/projects";

export const NavBar = (): JSX.Element => {
  const dispatch = useDispatch();
  const [page, setPage] = useState("accounts");
  const context: ManagementPortalContext = useContext(AppContext);

  const orgId = useSelector(sessionSelectors.orgId);
  const showIntegrations = useSelector(featureFlagsSelectors.showIntegrations);
  const showProjects = useSelector(featureFlagsSelectors.showProjects);
  const showWorkspaces = useSelector(featureFlagsSelectors.showWorkspaces);
  const isAdmin = useSelector(sessionSelectors.isAdmin);
  const isProjectAdmin = useSelector(projectsSelectors.isProjectAdmin);

  useEffect(() => {
    if (isAdmin && orgId) {
      dispatch(organizationActions.getOrganizationInfo(orgId));
      dispatch(licensesActions.getAvailable(orgId));
    }
  }, [dispatch, orgId, isAdmin]);

  useEffect(() => {
    if (!isAdmin && isProjectAdmin) {
      setPage("projects");
    }
  }, [isAdmin, isProjectAdmin]);

  const renderContent = () => {
    // Project admin who is not an admin should be able to edit projects, but nothing else
    if (!isAdmin && isProjectAdmin) {
      return <Projects />;
    }
    switch (page) {
      case "accounts":
        return <Accounts />;
      case "policies":
        return <Policies />;
      case "projects":
        return <Projects />;
      case "workspaces":
        return <Workspaces />;
      case "info":
        return <CompanyInformation />;
      case "integrations":
        return <Integrations />;
      default:
        return null;
    }
  };

  const styleOption = (option: string): string => {
    if (page === option) {
      return "text-colorAccent font-bold border-b-2 border-colorAccent self-end cursor-pointer";
    } else {
      return "font-bold self-end cursor-pointer";
    }
  };

  return (
    <div className="flex h-full flex-col justify-start">
      {/*NavBar*/}
      <div className="px-12 flex justify-between" data-test-id="navbar">
        <div className="p-4 flex flex-auto justify-start space-x-20 flex-grow-0 text-base">
          {isAdmin && (
            <span
              className={styleOption("accounts")}
              onClick={() => setPage("accounts")}
            >
              {context.Localized.string("NAVBAR.ACCOUNTS").toUpperCase()}
            </span>
          )}
          {isAdmin && (
            <span
              className={styleOption("policies")}
              onClick={() => setPage("policies")}
            >
              {context.Localized.string("NAVBAR.POLICIES").toUpperCase()}
            </span>
          )}
          {showProjects && (
            <span
              className={styleOption("projects")}
              onClick={() => setPage("projects")}
            >
              {context.Localized.string("NAVBAR.PROJECTS").toUpperCase()}
            </span>
          )}
          {isAdmin && showWorkspaces && (
            <span
              className={styleOption("workspaces")}
              onClick={() => setPage("workspaces")}
            >
              {context.Localized.string("NAVBAR.WORKSPACES").toUpperCase()}
            </span>
          )}
          {isAdmin && (
            <span
              className={styleOption("info") + " cursor-pointer"}
              onClick={() => setPage("info")}
            >
              {context.Localized.string("NAVBAR.INFO").toUpperCase()}
            </span>
          )}
          {isAdmin && showIntegrations && (
            <span
              className={styleOption("integrations")}
              onClick={() => setPage("integrations")}
            >
              {context.Localized.string("NAVBAR.INTEGRATIONS").toUpperCase()}
            </span>
          )}
        </div>
      </div>
      {/*Content  */}
      <div
        className="container bg-colorTableHeadPortalBackground p-4 overflow-y-auto"
        style={{ flex: "1 1 0px" }}
      >
        {renderContent()}
      </div>
    </div>
  );
};
