// Session selectors
import { createSelector } from "reselect";
import { RootState } from "typesafe-actions";
import { UserData } from "../accounts/types";
import { PartialOrgInfo } from "./types";

export const sessionToken = (state: RootState): string =>
  state.session && state.session.sessionToken ? state.session.sessionToken : "";

export const tokenExpiration = (state: RootState): number =>
  state.session && state.session.tokenExpiration
    ? state.session.tokenExpiration
    : 0;

export const userId = (state: RootState): string =>
  state.session && state.session.userId ? state.session.userId : "";

export const orgId = (state: RootState): string =>
  state.session && state.session.orgId ? state.session.orgId : "";

export const loggedInUserData = (state: RootState): UserData | undefined =>
  state.session?.loggedInUserData;

export const inProgress = (state: RootState): boolean =>
  state.session ? state.session.inProgress : false;

export const isLoggedIn: (state: RootState) => boolean = createSelector(
  sessionToken,
  (token: string | null) => !!token
);

export const isAuthenticated: (state: RootState) => boolean = createSelector(
  sessionToken,
  tokenExpiration,
  (token: string, exp: number) => !!token && exp > Date.now()
);

export const isAdmin: (state: RootState) => boolean = createSelector(
  sessionToken,
  orgId,
  (token: string, id: string) => !!token && !!id
);

export const userOrganization = (
  state: RootState
): PartialOrgInfo | undefined => state.session.userOrganization;
