import React from "react";
import { NOOP } from "../../../helpers/noop";

export type PermissionOptionProps = {
  label: string;
  color?: string;
  onClick?: () => void;
  canBeEdited?: boolean;
  isActive?: boolean;
  dataTestId?: string; // to match share dialog from dashboard
};

export const PermissionsPill: React.FC<PermissionOptionProps> = ({
  label,
  color = "colorForegroundLightTheme",
  onClick = NOOP,
  isActive = false,
  canBeEdited = false,
  dataTestId
}) => {
  const backgroundColor = isActive
    ? `bg-${color}`
    : canBeEdited
    ? "bg-colorForegroundLightTheme"
    : "bg-white";
  const border = isActive
    ? `border border-${color}`
    : "border border-colorForegroundLightTheme";
  const textColor = isActive
    ? "text-colorText"
    : canBeEdited
    ? "text-colorTextAndIconLighterLightTheme"
    : "text-colorForegroundLightTheme";
  const text = `text-sm leading-none text-center ${textColor}`;
  const cursor = canBeEdited && !isActive ? "cursor-pointer" : "cursor-default";
  const pointerEvents =
    canBeEdited && !isActive ? "pointer-events-auto" : "pointer-events-none";

  return (
    <div
      data-test-id={dataTestId}
      className={`${backgroundColor} ${border} ${text} ${cursor} ${pointerEvents} rounded p-1 rounded-lg min-w-12 w-fit`}
      title={label}
      onClick={() => canBeEdited && !isActive && onClick()}
    >
      {label}
    </div>
  );
};

export const PermissionsPillDivider: React.FC = () => {
  return (
    <svg
      className={`text-colorForegroundLightTheme fill-current hidden sm:block`}
      width="10"
      height="5"
    >
      <rect width={10} height={5} />
    </svg>
  );
};
