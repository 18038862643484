import React from "react";
import { UserData } from "../../state/accounts/types";
import { Avatar } from "./avatar";
import { useSelector } from "react-redux";
import { organizationSelectors } from "../../state/organization";
import { licensesSelectors } from "../../state/licenses";
import { DateTime } from "luxon";
import { useI18n } from "../../helpers/hooks/useI18n";
import { projectsSelectors } from "../../state/projects";
import { Project } from "../../state/projects/types";
import { Label } from "../shared/label";

type AvatarDetailsProps = {
  user: UserData;
  positioningClasses?: string; // tailwind classes
};

export const AvatarDetails = ({
  user,
  positioningClasses = ""
}: AvatarDetailsProps) => {
  const t = useI18n("AVATAR.");
  const name = `${user.firstName} ${user.lastName}`;
  const organization = useSelector(organizationSelectors.organization);
  const licenseInfo = useSelector(licensesSelectors.licenseInfo);
  const projects = useSelector(projectsSelectors.projects);
  const expirationDate = user.license
    ? DateTime.fromMillis(user.license?.expire).toLocaleString()
    : undefined;

  const userProjects: Project[] = projects.filter(project =>
    user.projects?.includes(project.id)
  );

  return (
    <div
      className={`min-w-60 max-w-80 absolute bg-white rounded p-6 shadow-xl ${positioningClasses}`}
    >
      <div className="flex justify-center">
        <Avatar user={name} circleStyle="w-12 h-12 flex-none" />
        <div className="flex flex-col p-2">
          <div className="min-h-5 text-sm">{`${user.firstName} ${user.lastName}`}</div>
          <div className="min-h-4 text-xxs">{user.email}</div>
          <div className="min-h-4 text-xxs text-colorTextAndIconLighterLightTheme">
            {organization.name}
          </div>
        </div>
      </div>
      {licenseInfo && user.license && (
        <div className="p-2 border-t-2 border-colorBackgroundLightTheme">
          <div className="text-sm text-center">
            {licenseInfo?.type} {t("USER")}
          </div>
          <div className="text-xxs text-center text-colorTextAndIconLighterLightTheme">
            {expirationDate ? `${t("RENEWAL")} ${expirationDate}` : ""}
          </div>
        </div>
      )}
      {userProjects && userProjects.length > 0 && (
        <div className="border-t-2 border-colorBackgroundLightTheme p-2">
          <div className="text-center mb-2">
            <span>{t("PROJECTS")}</span>
            <span className="ml-1 text-colorTextAndIconLighterLightTheme">
              {userProjects.length}
            </span>
          </div>
          <div className="flex justify-center flex-wrap gap-2">
            {userProjects.map(project => (
              <React.Fragment key={project.name}>
                <Label
                  label={project.name}
                  iconColor={project.appearanceProperties?.color}
                  icon={project.appearanceProperties?.icon}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
