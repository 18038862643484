import React from "react";
import { InputTopLabel } from "./sections/input.top.label";

export type TextareaProps = {
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  toolTip?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  textareaHeight?: string | number;
  transparent?: boolean;
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      value,
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
      disabled = false,
      toolTip,
      style,
      textareaHeight,
      placeholder,
      transparent = true
    },
    ref
  ) => {
    return (
      <div
        className={`p-1 pb-0 mb-1 border-b border-solid border-white rounded-t-lg bg-${
          disabled ? "colorForegroundLightTheme" : "white"
        }`}
        style={style}
        data-test-id="textarea"
      >
        <InputTopLabel text={label} />
        <textarea
          aria-label={label}
          className={`w-full resize-none text-sm border-none outline-none break-words overflow-x-hidden overflow-y-auto input customScrollbar ${
            transparent ? "bg-transparent" : "bg-colorForegroundLightTheme"
          }`}
          style={{
            minHeight: textareaHeight
          }}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          title={toolTip}
          onChange={e => onChange(e.target.value)}
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
          ref={ref}
        />
      </div>
    );
  }
);
