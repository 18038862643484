import React from "react";
import { Input, Label, Select } from "../input";

export type FieldState = {
  value: string;
  touched: boolean;
  error: boolean;
};

type Option = {
  label: string;
  value: string;
}

export type FormFieldInput = {
  id: string;
  label: string;
  placeholder: string;
  changeHandler: (value: FieldState) => void;
  data: FieldState;
  type: "password" | "text";
  required?: boolean;
  errorMessage?: string;
  readonly: boolean;
};

export type FormFieldSelect = {
  id: string;
  label: string;
  changeHandler: (value: FieldState) => void;
  data: FieldState;
  type: "select";
  options: Option[];
  required?: boolean;
  errorMessage?: string;
  readonly: boolean;
};

export type FormFieldProps = FormFieldInput | FormFieldSelect;

export const FormField: React.FC<FormFieldProps> = ({
  id,
  label,
  changeHandler,
  data,
  required = true,
  errorMessage = "",
  readonly,
  ...props
}) => {
  const getNewValue = e => {
    // extracting e.target.value to a new variable is needed to make fireEvent.change work properly while testing form
    const newValue = e.target.value;
    return { ...data, value: newValue };
  };

  return (
    <>
      <Label htmlFor={id} className="text-lg font-bold pr-10">
        {label}
      </Label>
      {props.type === "select" ? (
        <Select
          id={id}
          value={data.value}
          onChange={e => changeHandler(getNewValue(e))}
          disabled={readonly}
          onBlur={_ => changeHandler({ ...data, touched: true })}
        >
          {props.options.map(option => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ) : (
        <Input
          data-test-id={id}
          id={id}
          placeholder={props.placeholder}
          readOnly={readonly}
          onChange={e => changeHandler(getNewValue(e))}
          value={data.value}
          onBlur={_ => changeHandler({ ...data, touched: true })}
          type={props.type}
          required={required}
          error={data.error}
        />
      )}
      <div>
        {data.error && (
          <div className="text-colorWarning text-xs">{errorMessage}</div>
        )}
      </div>
    </>
  );
};
