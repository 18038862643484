import globalHeaders from "./global-headers";
import { httpMethods } from "./http-methods";
import config from "../configuration";
import handleFetchRequest from "./handle-fetch-request";
import {
  ResponsePromise,
  EditUserDepartmentResponse,
  ApiResponse
} from "./types";
import { UserData } from "../state/accounts/types";
import { getMembers } from "./organization";

export function editUserDepartment(
  uid: string,
  department: string,
  orgId: string
): ResponsePromise<EditUserDepartmentResponse> {
  return handleFetchRequest(() =>
    fetch(
      `${config["serviceConfig"].auth}` +
        `/orgs/${orgId}/members/${uid}/department`,
      {
        method: httpMethods.PUT,
        headers: globalHeaders,
        body: JSON.stringify({ department })
      }
    )
  );
}

export function handleEditingMemberDepartment(
  uid: string,
  department: string,
  orgId: string
): Promise<UserData[]> {
  return editUserDepartment(uid, department, orgId)
    .then((result: ApiResponse<EditUserDepartmentResponse>) => {
      if (result.error) {
        throw result.error;
      }
      return result.data;
    })
    .catch(error => {
      return Promise.reject(error);
    })
    .then(() => getMembers(orgId))
    .then((result: ApiResponse<UserData[]>) => {
      if (result.error) {
        throw result.error;
      }
      return result.data;
    })
    .then((response: UserData[] | null | undefined): UserData[] => {
      if (response) {
        const members = response.map(m => {
          let member = {
            ...m,
            email: m.credentials ? m.credentials.email : ""
          };
          delete member.credentials;
          return member;
        });
        return members;
      }
      throw new Error("No members data found");
    })
    .catch(error => {
      console.error(error);
      return Promise.reject(error);
    });
}

export interface UserInvitePayload {
  emails: string[];
  reason: string;
}
export type UserInviteResponse = Map<string, string>;

export function inviteToHoylu(
  userInvite: UserInvitePayload
): ResponsePromise<UserInviteResponse> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].auth}` + `/invite`, {
      method: httpMethods.POST,
      headers: globalHeaders,
      body: JSON.stringify(userInvite)
    })
  );
}