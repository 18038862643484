import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectsSelectors, projectsActions } from "../../../state/projects";
import { CollaboratorBar } from "../../shared/permissions/collaborator.bar";
import { PrimaryButton } from "../../shared/button";
import { deleteUsersFromProject } from "../../../api/projects";
import { showError } from "../../../helpers/show.toast.helpers";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { Spinner } from "../../spinner";
import { isProjectLimitReached } from "./is.limit.reached";
import { PROJECT_ADMIN_ID } from "../../../api/collaborators.roles.ids";
import { usePanelDetector } from "../../../helpers/hooks/usePanelDetector";

export const CollaboratorsList = ({
  projectId,
  addUsers,
  usersLimit,
  isProcessing
}: {
  projectId: string;
  addUsers: (value: boolean) => void;
  usersLimit: number | null;
  isProcessing: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const t = useI18n("PROJECTS.");
  const collaborators = useSelector(
    projectsSelectors.findCollaboratorsByProject(projectId)
  );
  const [usersToRemove, setUsersToRemove] = useState<string[]>([]);
  const { isUserProfilePanel } = usePanelDetector();

  const handleUserSelect = (value: string) => {
    if (!value) return;
    if (usersToRemove.includes(value)) {
      setUsersToRemove(usersToRemove.filter(id => id !== value));
    } else {
      setUsersToRemove([...usersToRemove, value]);
    }
  };

  const removeUsers = async () => {
    if (usersToRemove.length === 0) return;

    try {
      const response = await deleteUsersFromProject(projectId, usersToRemove);

      if (response && !response.error) {
        dispatch(projectsActions.getCollaboratorsAsync.request(projectId));
        setUsersToRemove([]);
      }

      if (response.error) {
        showError(t("REMOVE_USERS_ERROR"));
      }
    } catch (e) {
      showError(t("REMOVE_USERS_ERROR"));
    }
  };

  const usersLimitReached = isProjectLimitReached(
    usersLimit,
    collaborators.length
  );

  const max_height = isUserProfilePanel ? "max-h-lg" : "max-h-2xs";

  return (
    <div data-test-id={"collaborators_list_expanded"}>
      <div className={`overflow-y-auto mb-4 ${max_height}`}>
        <CollaboratorBar
          label={t("ANY_ADMIN")}
          userId={""}
          roleId={PROJECT_ADMIN_ID}
          projectId={""}
          canBeEdited={false}
        />
        {collaborators.map(collaborator => (
          <React.Fragment key={collaborator.email}>
            <CollaboratorBar
              label={collaborator.email}
              userId={collaborator.userId}
              roleId={collaborator.roleId}
              onUserSelect={handleUserSelect}
              projectId={projectId}
            />
          </React.Fragment>
        ))}
      </div>
      {isProcessing && (
        <div className="relative">
          <Spinner
            classNames="absolute inline-block -mt-4 ml-1/2"
            size={4}
            useSpinnerClass={false}
          />
        </div>
      )}
      <div className="flex gap-2 justify-end">
        <PrimaryButton
          onClick={removeUsers}
          disabled={usersToRemove.length === 0 || isProcessing}
        >
          {t("REMOVE_USERS")}
        </PrimaryButton>
        <PrimaryButton
          onClick={() => addUsers(true)}
          disabled={usersLimitReached || isProcessing}
        >
          {usersLimitReached ? t("USERS_LIMIT") : t("ADD_USERS")}
        </PrimaryButton>
      </div>
    </div>
  );
};
