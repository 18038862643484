export class Localized {
  private providers: any[] = [];
  private locales: string[] = [];

  get(address: string): undefined | any {
    for (let i = 0; i < this.providers.length; i++) {
      let value = resolveAddress(address, this.providers[i]);
      if (value !== undefined) {
        return value;
      }
    }
    return undefined;
  }

  string(address: string, ...parameters: any): string {
    let value = this.get(address);
    if (value === undefined || typeof value !== "string") {
      return String(address);
    }

    // parameters substituted into string:
    //   '${0} == parameter 1', '${1} == parameter 2', etc
    if (parameters && parameters.length > 0) {
      let strings = value.split(/(\$\{\d+\})/);
      return strings
        .map((s: any) => {
          let str = s + "";
          let matches = str.match(/^\$\{(\d+)\}$/);
          if (matches) {
            let index = parseInt(matches[1]);
            return index < parameters.length ? parameters[index] : s;
          } else {
            return s;
          }
        })
        .join("");
    }

    return value;
  }

  number(address: string): number {
    let value = this.get(address);
    if (value === undefined || typeof value !== "number") {
      return Number(address);
    }
    return value;
  }

  boolean(address: string): boolean {
    let value = this.get(address);
    if (value === undefined || typeof value !== "boolean") {
      return Boolean(value);
    }
    return value;
  }

  array<T>(address: string): Array<T> {
    let value = this.get(address);
    if (value === undefined || !Array.isArray(value)) {
      return [];
    }
    return value;
  }

  object(address: string): any {
    return this.get(address);
  }

  getCurrentLocales(): string[] {
    return [...this.locales];
  }

  pushLocale(locale: string, provider: any) {
    this.providers.unshift(provider);
    this.locales.unshift(locale);
  }
}

function resolveAddress(address: string, obj: any): any | undefined {
  let keys = address.split(".");
  let current = obj;
  for (let i = 0; i < keys.length; i++) {
    if (current === undefined || typeof current !== "object") {
      return undefined;
    }
    current = current[keys[i]];
  }
  return current;
}
