import { ResponsivePie } from "@nivo/pie";
import { useContext } from "react";
import { AppContext, ManagementPortalContext } from "../../context";

export type TotalAccountsProps = {
  total: number;
  active: number;
};

type LegendEntryProps = {
  color: string;
  value: string;
};

const licensedColor = "#3295EC";
const unlicensedColor = "#D22257";

const LegendEntry = (props: LegendEntryProps) => {
  return (
    <div
      className="flex justify-center border-colorPrimary border rounded p-1.5 flex-1 space-x-1"
      data-test-id="legend entry"
    >
      <div
        className={"rounded-full h-3 w-3 self-center"}
        style={{ backgroundColor: props.color }}
      ></div>
      <span className="text-base self-center">{props.value}</span>
    </div>
  );
};

export const TotalAccounts: React.FC<TotalAccountsProps> = ({
  active,
  total
}) => {
  const context: ManagementPortalContext = useContext(AppContext);

  const data = [
    {
      id: "unlicensed",
      label: context.Localized.string("TOTAL_ACCOUNTS.UNLICENSED"),
      value: total - active,
      color: unlicensedColor
    },
    {
      id: "licensed",
      label: context.Localized.string("TOTAL_ACCOUNTS.LICENSED"),
      value: active,
      color: licensedColor
    }
  ].filter(d => d.value > 0);
  return (
    <div className="h-80 w-100 flex flex-col justify-between">
      <div className="flex justify-between flex-grow-0">
        <span className="text-lg font-bold">
          {context.Localized.string("TOTAL_ACCOUNTS.HEAD")}
        </span>
        <span className="text-lg font-bold">{total}</span>
      </div>
      {!!total && (
        <ResponsivePie
          data={data}
          colors={{ datum: "data.color" }}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
          innerRadius={0.4}
          padAngle={7}
          cornerRadius={2}
          fit={true}
          borderWidth={1}
          enableArcLabels={true}
          arcLabelsTextColor={"#FFF"}
          arcLinkLabelsDiagonalLength={7}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabel={e => e.value?.toString()}
          arcLinkLabel={e => e.label?.toString()}
          arcLinkLabelsTextColor={"#000000"}
          isInteractive={false}
          // legends={[
          //   {
          //     anchor: "bottom",
          //     direction: "row",
          //     justify: false,
          //     translateX: 25,
          //     translateY: 60,
          //     itemsSpacing: 0,
          //     itemWidth: 100,
          //     itemHeight: 18,
          //     itemTextColor: "#999",
          //     itemDirection: "left-to-right",
          //     itemOpacity: 1,
          //     symbolSize: 18,
          //     symbolShape: "circle",
          //     effects: [
          //       {
          //         on: "hover",
          //         style: {
          //           itemTextColor: "#000"
          //         }
          //       }
          //     ]
          //   }
          // ]}
        />
      )}
      <div className="flex justify-evenly space-x-5">
        <LegendEntry
          value={context.Localized.string("TOTAL_ACCOUNTS.LICENSED")}
          color={licensedColor}
        />
        <LegendEntry
          value={context.Localized.string("TOTAL_ACCOUNTS.UNLICENSED")}
          color={unlicensedColor}
        />
      </div>
    </div>
  );
};
