import { Icon } from "../icon";

type HeaderTitleProps = {
  icon: string; // name of the icon from icon fonts; look for hoylu-ui-icons
  title: string;
};

export function HeaderTitle({ icon, title }: HeaderTitleProps) {
  return (
    <div className={"text-base flex align-center"} title={title}>
      <Icon icon={icon} title={title} />
      <div className={"p-1"}>{title}</div>
    </div>
  );
}
