import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { projectsActions } from "../../../state/projects";
import { Project } from "../../../state/projects/types";
import { PrimaryButton, TextButton } from "../../shared/button";
import { Section } from "../../shared/sections/section";
import { ProjectRoom } from "./project.room";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { usePanelDetector } from "../../../helpers/hooks/usePanelDetector";
import { Collaborators } from "./collaborators";

interface ProjectEditorProps {
  project: Project;
  editCollaborators: boolean;
  onClose?: () => void;
}

export const ProjectEditor = ({
  onClose,
  editCollaborators,
  project
}: ProjectEditorProps) => {
  const dispatch = useDispatch();
  const t = useI18n(`PROJECTS.`);
  const tg = useI18n();
  const [editedProject, setEditedProject] = useState(project);
  const [hasErrors, setHasErrors] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isAddingCollaborators, setIsAddingCollaborators] = useState(false);
  const [showSaveButtons, setShowSaveButtons] = useState(true);

  let projectChanges = useRef<Partial<Project> | null>(null);

  const { isUserProfilePanel } = usePanelDetector();

  const updateProject = (
    updatedProject: Project,
    newProjectChanges: Partial<Project>
  ) => {
    if (!isTouched) setIsTouched(true);
    setEditedProject(updatedProject);
    projectChanges.current = {
      ...projectChanges.current,
      ...newProjectChanges
    };
  };

  const saveChanges = () => {
    if (projectChanges.current) {
      dispatch(
        projectsActions.updateProjectAsync.request({
          updates: { ...projectChanges.current },
          projectId: editedProject.id
        })
      );
    }
    onClose && onClose();
  };

  return (
    <>
      <div className="w-120">
        <Section
          title={t("EDITOR_TITLE")}
          icon={"settings2"}
          initiallyCollapsed={editCollaborators}
        >
          <ProjectRoom
            project={editedProject}
            updateProject={updateProject}
            hasErrors={setHasErrors}
          />
        </Section>
        {!(isUserProfilePanel && !editCollaborators) && (
          <Section
            title={
              isAddingCollaborators ? t("ADD_USERS_TITLE") : t("COLLABORATORS")
            }
            icon={"persons"}
            initiallyCollapsed={!editCollaborators}
          >
            <Collaborators
              project={project}
              isAddingCollaborators={isAddingCollaborators}
              setIsAddingCollaborators={setIsAddingCollaborators}
              setShowSaveButtons={setShowSaveButtons}
            />
          </Section>
        )}
      </div>
      {showSaveButtons && (
        <div className="text-right">
          {onClose && <TextButton onClick={onClose}>{tg("CANCEL")}</TextButton>}
          <PrimaryButton
            onClick={saveChanges}
            disabled={hasErrors || !isTouched}
          >
            {tg("SAVE")}
          </PrimaryButton>
        </div>
      )}
    </>
  );
};
