import React, { useState } from "react";

export type CheckboxProps = {
  onChange: (value: boolean) => void;
  initiallyChecked?: boolean;
  disabled?: boolean;
  title?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  initiallyChecked = false,
  disabled = false,
  title
}) => {
  const [isChecked, setIsChecked] = useState(initiallyChecked);
  return (
    <input
      type={"checkbox"}
      title={title}
      onChange={e => {
        onChange(e.target.checked);
        setIsChecked(e.target.checked);
      }}
      checked={isChecked}
      disabled={disabled}
    />
  );
}