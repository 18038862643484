import { toast } from "react-toastify";
import { getErrorMessage, toasterOptions } from "../state/errors/errors.epic";

export const showError = (message: string) => {
  toast.error(() => <div>{message}</div>, {
    ...toasterOptions
  });
};

export const showErrorToast = (message: string, error: any) => {
  toast.error(
    () => (
      <div>
        <span>{message}</span>
        <br />
        <i>{getErrorMessage(error)}</i>
      </div>
    ),
    { ...toasterOptions }
  );
};

export const showSuccessToast = (message: string) => {
  toast.success(message, toasterOptions);
};
