import { SessionData } from "./session/types";
import { prepareSessionData } from "../api/auth";

export default function createInitialState(
  token: string
): { session: SessionData } {
  return {
    session: prepareSessionData(token)
  };
}
