import React, { useState } from "react";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { Modal } from "../../modals/modal";
import { ModalHeader } from "../../modals/modalElements/modal.header";
import { PrimaryButton, SecondaryButton } from "../../shared/button";
import { UserData } from "../../../state/accounts/types";
import { useDispatch } from "react-redux";
import { requestDeleteAccount } from "../../../state/session/actions";
import { Checkbox } from "../../shared/checkbox";

export interface ConfirmAccountDeleteProps {
  user: UserData;
  onCancel: () => void;
}

export const ConfirmAccountDelete: React.FC<ConfirmAccountDeleteProps> = ({
  user,
  onCancel
}) => {
  const t = useI18n("CONFIRM_ACCOUNT_DELETE.");
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  return (
    <Modal handleOutsideClick={onCancel}>
      <ModalHeader modalTitle={t("TITLE") + user.email} closeModal={onCancel} />
      <div className="p-1 pb-4 flex flex-col w-full justify-between gap-4">
        <span>{t("P1")}</span>
        <ul className="list-disc list-inside">
          <li className="px-2 py-1">{t("POINT1")}</li>
          <li className="px-2 py-1">{t("POINT2")}</li>
        </ul>
        <span className="whitespace-pre-line">{t("P2")}</span>
      </div>
      <div className="p-1 flex justify-between items-center">
        <div className="flex items-center pt-2">
          <label className="ml-1 text-accent">
            <Checkbox
              initiallyChecked={false}
              onChange={() => {
                setConfirm(c => !c);
              }}
            />
            <span className="ml-2">{t("CONFIRM")}</span>
          </label>
        </div>
      </div>
      <div className="flex justify-end mt-6 space-x-2">
        <SecondaryButton onClick={onCancel}>{t("CANCEL")}</SecondaryButton>
        <PrimaryButton
          onClick={() => dispatch(requestDeleteAccount.request())}
          disabled={!confirm}
        >
          {t("DELETE")}
        </PrimaryButton>
      </div>
    </Modal>
  );
};
