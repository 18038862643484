import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Column,
  IdType,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import type { UserData } from "../../state/accounts/types";
import { licensesActions } from "../../state/licenses";
import { organizationSelectors } from "../../state/organization";
import { sessionSelectors } from "../../state/session";
import { PrimaryButton } from "../shared/button";
import { EditAccountDialog } from "./edit-account-dialog";
import { GlobalFilter } from "./global-filter";
import { RemoveAccounts } from "./remove-accounts";
import { Table } from "../shared/table/table";
import { AddAccountModal } from "../accounts/add.accounts.modal";
import Papa from "papaparse";
import {
  DefaultCell,
  DepartmentCell,
  LabelCell,
  NameCell
} from "./account.list.cells";
import { getLicenseInfoAsync } from "../../state/licenses/actions";
import { Dropdown, Option } from "../shared/dropdown";
import { downloadCsv } from "../../helpers/download.csv";
import { MultiSelectDropdown } from "../shared/multiselect.dropdown";
import { LocalizingFunction, useI18n } from "../../helpers/hooks/useI18n";
import { generateSelectionColumn } from "../shared/table/selection.column";

enum TableIDs {
  SELECTION = "selection",
  NAME = "firstName",
  DEPARTMENT = "department",
  EDIT = "edit",
  LICENSE = "license",
  LAST_LOGIN_MILLIS = "lastLoginMillis"
}

const editColumn = (
  title: string,
  setEditedAccount: (account: UserData) => void
): Column<UserData> => ({
  id: TableIDs.EDIT,
  disableSortBy: true,
  width: "2rem",
  Cell: ({ row }) => (
    <div
      data-test-id={"accounts-list-edit-account"}
      className={
        "hicon plus text-lg text-colorTimelineLightTheme w-fit cursor-pointer"
      }
      onClick={() => setEditedAccount(row.original)}
      title={title}
    />
  )
});

const filterByLastLoginMillis = (
  rows: Row<UserData>[],
  _: IdType<UserData>[],
  filterValue: number
) => {
  return rows.filter(row => {
    return DateTime.fromMillis(row.original.lastLoginMillis)
      .startOf("day")
      .equals(DateTime.fromMillis(filterValue).startOf("day"));
  });
};

const filterByDepartment = (
  rows: Row<UserData>[],
  _: IdType<UserData>[],
  filterValue: Option<string>[]
) => {
  if (filterValue.length === 0) return rows;
  return rows.filter((row: Row<UserData>) => {
    return filterValue
      .map(option => option.value)
      .includes(row.original.department);
  });
};

const sortByDepartment = (rowA: Row<UserData>, rowB: Row<UserData>) => {
  const collator = new Intl.Collator(undefined, {
    sensitivity: "accent"
  });
  const a = rowA.original.department;
  const b = rowB.original.department;
  return collator.compare(a, b);
};

const sortByName = (rowA: Row<UserData>, rowB: Row<UserData>) => {
  const collator = new Intl.Collator(undefined, {
    sensitivity: "accent"
  });
  const a = `${rowA.original.firstName} ${rowA.original.lastName}`;
  const b = `${rowB.original.firstName} ${rowB.original.lastName}`;
  return collator.compare(a, b);
};

function prepareCsvContent(t: LocalizingFunction, members: UserData[]): string {
  const csvKeys = [
    t("EXPORT.EMAIL"),
    t("EXPORT.FIRST_NAME"),
    t("EXPORT.LAST_NAME"),
    t("EXPORT.DEPARTMENT"),
    t("EXPORT.EXPIRATION"),
    t("EXPORT.LAST_ACCESS")
  ];
  const never = t("LAST_ACCESSED_NEVER");
  const none = t("LICENSE_NONE");

  const toArray = (user: UserData) => [
    user.email,
    user.firstName,
    user.lastName,
    user.department,
    user.license
      ? DateTime.fromMillis(user.license.expire).toLocaleString()
      : none,
    user.lastLoginMillis
      ? DateTime.fromMillis(user.lastLoginMillis).toLocaleString()
      : never
  ];

  const csvContent: string = Papa.unparse({
    fields: csvKeys,
    data: members.map(toArray)
  });

  return csvContent;
}

export const AccountsList: React.FC = () => {
  const dispatch = useDispatch();
  const t = useI18n("ACCOUNTS_LIST.");
  const tb = useI18n("");
  const orgId = useSelector(sessionSelectors.orgId);
  const members = useSelector(organizationSelectors.members);
  const departments = useSelector(organizationSelectors.departments.names);
  const [editedAccount, setEditedAccount] = useState<UserData | undefined>(
    undefined
  );
  const [addAccount, setAddAccount] = useState(false);

  const departmentsOptions = useMemo(() => {
    return departments
      .filter(d => d !== "")
      .map(d => ({ label: d, value: d } as Option<string>));
  }, [departments]);

  const lastSeenOptions = useMemo(() => {
    const result: Option<number | undefined>[] = [];
    members.forEach(m => {
      const lastSeen = m.lastLoginMillis
        ? DateTime.fromMillis(m.lastLoginMillis).startOf("day").toLocaleString()
        : t("LAST_ACCESSED_NEVER");
      if (result.find(r => r.label === lastSeen)) return;
      result.push({
        label: lastSeen,
        value: m.lastLoginMillis
      });
    });
    return [...result.values()];
  }, [members, t]);

  const columns = useMemo(
    (): Column<UserData>[] => [
      {
        Header: t("NAME"),
        accessor: (r: UserData) => `${r.firstName} ${r.lastName} ${r.email}`,
        id: TableIDs.NAME as keyof UserData,
        sortType: sortByName,
        width: "55%",
        Cell: ({ row }) => <NameCell user={row.original} />
      },
      {
        Header: t("DEPARTMENT"),
        accessor: "department",
        id: TableIDs.DEPARTMENT as keyof UserData,
        sortType: sortByDepartment,
        filter: filterByDepartment,
        width: "15%",
        Cell: ({ row, setFilter }) => (
          <DepartmentCell
            id={row.original.uid}
            department={row.original.department}
            clearFilters={() => setFilter(TableIDs.DEPARTMENT, [])}
          />
        )
      },
      {
        Header: t("LAST_SEEN"),
        accessor: r =>
          r.lastLoginMillis
            ? DateTime.fromMillis(r.lastLoginMillis).toLocaleString()
            : t("LAST_ACCESSED_NEVER"),
        id: TableIDs.LAST_LOGIN_MILLIS as keyof UserData,
        disableGlobalFilter: false,
        width: "15%",
        sortType: "number",
        filter: filterByLastLoginMillis,
        Cell: ({ value }) => <DefaultCell label={value} />
      },
      {
        Header: t("LICENSES"),
        accessor: (r: UserData) => r,
        id: TableIDs.LICENSE as keyof UserData, // this is not a column for user license, but for license labels
        width: "15%",
        disableSortBy: true,
        Cell: cell => <LabelCell account={cell.value} />
      }
    ],
    [t]
  );
  const initialState = { sortBy: [{ id: TableIDs.NAME }] };
  const getRowId = useMemo(() => (user: UserData) => user.uid, []);

  useEffect(() => {
    dispatch(getLicenseInfoAsync.request());
  }, [orgId, dispatch]);

  const instance = useTable<UserData>(
    {
      columns,
      initialState,
      data: members,
      getRowId,
      autoResetSortBy: false,
      autoResetFilters: false
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        generateSelectionColumn<UserData>(),
        ...columns,
        editColumn(t("EDIT"), u => {
          dispatch(licensesActions.getAvailable(orgId));
          setEditedAccount(u);
        })
      ]);
      //@ts-expect-error
      hooks.getSortByToggleProps.push((props, { column }) => {
        return [
          props,
          {
            title: column.canSort ? t("SORT_BY", column.Header) : undefined
          }
        ];
      });
    }
  );

  const exportAccounts = () => {
    const csvContent = prepareCsvContent(t, members);
    downloadCsv(csvContent, "accounts.csv");
  };

  const { setFilter, state, setAllFilters } = instance;

  const getColumnFilters = (columnId: string): any => {
    return state.filters.find(filter => columnId === filter.id)?.value;
  };

  return (
    <div className="space-y-3">
      <span className="text-xl font-bold">{t("TITLE")}</span>
      <div className="flex flex-col header-md:flex-row space-y-4 header-md:space-y-0 justify-between">
        <div className="space-x-4 flex-none">
          <PrimaryButton
            onClick={() => {
              setAddAccount(!addAccount);
            }}
          >
            {t("ADD_ACCOUNTS")}
          </PrimaryButton>
          <PrimaryButton onClick={exportAccounts}>
            {t("EXPORT_ACCOUNTS")}
          </PrimaryButton>
          <RemoveAccounts selectedRowIds={instance.state.selectedRowIds} />
        </div>
      </div>
      <div className={"flex flex-row items-center justify-center gap-4"}>
        <div>{tb("PROJECTS.FILTER")}</div>
        <MultiSelectDropdown
          options={departmentsOptions}
          category={t("DEPARTMENT")}
          handleChange={(newValues: Option<string>[]) => {
            setFilter(TableIDs.DEPARTMENT, newValues);
          }}
          currentSelection={getColumnFilters(TableIDs.DEPARTMENT)}
        />
        <Dropdown
          options={lastSeenOptions}
          heading={t("LAST_SEEN")}
          handleChange={(newValue: Option<number | undefined>) =>
            setFilter(TableIDs.LAST_LOGIN_MILLIS, newValue.value ?? 0)
          }
          currentValue={getColumnFilters(TableIDs.LAST_LOGIN_MILLIS)}
        />
        <GlobalFilter {...instance} placeholder={t("FILTER_PLACEHOLDER")} />
        <PrimaryButton
          additionalStyling="self-end"
          onClick={() => {
            setAllFilters([]);
            setFilter(TableIDs.DEPARTMENT, []);
          }}
        >
          {tb("PROJECTS.RESET")}
        </PrimaryButton>
      </div>
      <Table {...instance} />
      {editedAccount && (
        <EditAccountDialog
          account={editedAccount}
          onClose={() => setEditedAccount(undefined)}
        />
      )}

      {addAccount && (
        <AddAccountModal
          onClose={() => {
            setAddAccount(false);
          }}
        />
      )}
    </div>
  );
};
