import { Header } from "../header";
import React, { useContext, useState } from "react";
import { AppContext, ManagementPortalContext } from "../../../context";
import { Input, Label } from "../../input";
import { useDispatch, useSelector } from "react-redux";
import {
  organizationActions,
  organizationSelectors
} from "../../../state/organization";
import { PrimaryButton } from "../../shared/button";
import { saveDepartments } from "../../../api/organization";
import { FullPageTile } from "../../tile";
import { Spinner } from "../../spinner";

const InfoPart = ({ label, value }) => (
  <>
    <span className="text-lg font-bold">{label}</span>
    <span className="text-base">{value}</span>
  </>
);

function CompanyInformationDetails() {
  const dispatch = useDispatch();
  const context: ManagementPortalContext = useContext(AppContext);
  const isLoading = useSelector(organizationSelectors.isLoading);
  const organization = useSelector(organizationSelectors.organization);

  const [sending, setSending] = useState(false);
  const [newDepartments, setNewDepartments] = useState("");

  const commitDepartments = () => {
    setSending(true);
    saveDepartments(
      organization.uid,
      newDepartments
        .split(",")
        .filter(d => d !== "")
        .map(d => d.trim())
    )
      .then(() => {
        dispatch(organizationActions.getOrganizationInfo(organization.uid));
      })
      .finally(() => {
        setSending(false);
        setNewDepartments("");
      });
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <FullPageTile>
      <div
        className="grid gap-y-4 gap-x-10 items-center break-words"
        style={{ gridTemplateColumns: "minmax(8rem, auto) minmax(4rem, 1fr)" }}
      >
        <InfoPart
          label={context.Localized.string("COMPANY_INFORMATION.COMPANY_NAME")}
          value={organization.name}
        />

        <InfoPart
          label={context.Localized.string("COMPANY_INFORMATION.DOMAINS")}
          value={organization.domains.join(", ")}
        />

        <InfoPart
          label={context.Localized.string("COMPANY_INFORMATION.ADMINS")}
          value={organization.members
            .filter(m => organization.admins.includes(m.uid))
            .map(m => m.email)
            .join(", ")}
        />

        <InfoPart
          label={context.Localized.string("COMPANY_INFORMATION.DEPARTMENTS")}
          value={organization.departments.filter(d => d).join(", ")}
        />

        <Label
          htmlFor="organization_new_departments"
          className="text-lg font-bold"
        >
          {context.Localized.string("COMPANY_INFORMATION.ADD_DEPARTMENTS")}
        </Label>
        <Input
          data-test-id="add departments"
          id="organization_new_departments"
          placeholder={context.Localized.string(
            "COMPANY_INFORMATION.ADD_DEPARTMENTS_PLACEHOLDER"
          )}
          readOnly={sending}
          onChange={e => setNewDepartments(e.target.value)}
          value={newDepartments}
        />

        <span />
        <PrimaryButton
          disabled={sending}
          additionalStyling="w-max"
          onClick={commitDepartments}
        >
          {context.Localized.string("COMPANY_INFORMATION.SAVE_DEPARTMENTS")}
        </PrimaryButton>
      </div>
    </FullPageTile>
  );
}

export const CompanyInformation = (): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);

  return (
    <div className="container flex-1 flex flex-col">
      <Header title={context.Localized.string("COMPANY_INFORMATION.TITLE")} />
      <CompanyInformationDetails />
    </div>
  );
};
