import React, { CSSProperties } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useI18n } from "../../helpers/hooks/useI18n";
import { Option } from "./dropdown";

export type MultiSelectDropdownProps<TValue> = {
  options: Option<TValue>[];
  disableSearch?: boolean;
  disable?: boolean;
  hasSelectAll?: boolean;
  handleChange?: (newValues: Option<TValue>[]) => any;
  currentSelection: Option<TValue>[];
  category: string;
  style?: CSSProperties;
  tooltip?: string;
  limit?: number;
  dropdownClassName?: string;
  closeOnChangedValue?: boolean;
  ItemRenderer?: any; // it is any in MultiSelect library
};

export const MultiSelectDropdown = <TValue,>({
  options = [],
  disableSearch = false,
  disable = false,
  hasSelectAll = false,
  handleChange = () => {},
  currentSelection = [],
  category,
  style,
  tooltip,
  limit,
  ItemRenderer = DefaultItemRenderer,
  dropdownClassName = ""
}: MultiSelectDropdownProps<TValue>) => {
  const t = useI18n("DROPDOWN.");
  const mainContainerStyle =
    "min-w-dropdown " +
    "max-w-dropdown " +
    "w-fit " +
    "multiselectDropdown " +
    `${disable ? "bg-colorButtonPrimaryDisabled" : ""}`;
  const closeStyle =
    "align-middle " +
    "text-sm " +
    "hicon windowclose " +
    "cursor-pointer " +
    "text-colorTextAndIconLightTheme";
  const arrowStyle = "text-colorText hicon ";

  const customValueRenderer = (selected: Option<TValue>[]) => {
    /**
     * example for content:
     * No selection:   Type
     * One selection:  bug
     * Two selections: bug (+2)
     */
    let content = category;
    if (selected.length === 1) {
      content = selected[0].label;
    } else if (selected.length > 1) {
      content = `${selected[0].label} (+${selected.length - 1})`;
    }
    return (
      <div className={"text-colorText"} title={tooltip ?? content}>
        <span className={"w-dropdown"}>{content}</span>
      </div>
    );
  };

  return (
    <div
      onPointerDown={e => e.stopPropagation()}
      style={style}
      className={mainContainerStyle}
      onKeyDown={e => e.stopPropagation()}
      data-test-id="multiselect-dropdown"
    >
      <MultiSelect
        className={`${dropdownClassName}`}
        ArrowRenderer={({ expanded }) => (
          <span className={`${arrowStyle} ${expanded ? "up" : "down"}`} />
        )}
        options={options}
        value={currentSelection}
        onChange={(values: Option<TValue>[]) => {
          let selected;
          if (!limit) selected = values;
          else if (limit > 0) selected = values.slice(0, limit);
          //first n values
          else selected = values.slice(limit); //last n values
          handleChange(selected);
        }}
        labelledBy="dropdown"
        disableSearch={disableSearch} // Can disable ability to search to filter options
        disabled={disable} // Can disable dropdown
        hasSelectAll={hasSelectAll}
        ItemRenderer={ItemRenderer}
        valueRenderer={customValueRenderer}
        ClearSelectedIcon={
          <span title={t("CLEAR_ALL")} className={closeStyle} />
        }
        ClearIcon={<span title={t("CLEAR_SEARCH")} className={closeStyle} />}
        overrideStrings={{
          allItemsAreSelected: t("ALL_ITEMS_SELECTED"),
          clearSearch: t("CLEAR_SEARCH"),
          noOptions: t("NO_OPTIONS"),
          search: t("SEARCH"),
          selectAll: t("SELECT_ALL"),
          selectSomeItems: category
        }}
      />
    </div>
  );
};

const DefaultItemRenderer = ({
  checked,
  option,
  onClick,
  disabled
}: IDefaultItemRendererProps): JSX.Element => {
  const mainContainerStyle =
    "flex " +
    "gap-2 " +
    "items-center " +
    `${checked && "text-colorTextAndIconLightTheme"}`;
  return (
    <div className={mainContainerStyle}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span className={"truncate"} title={option.label}>
        {option.label}
      </span>
    </div>
  );
};

export type IDefaultItemRendererProps = {
  checked: boolean;
  option: Option<any>;
  disabled?: boolean;
  onClick: (e: any) => any;
};
