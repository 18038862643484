import { useEffect, useMemo, useState } from "react";
import { SubscriptionInfo } from "../../../state/licenses/types";
import { CellProps, Column, TableInstance, useTable } from "react-table";
import { DateTime } from "luxon";
import { PrimaryButton } from "../../shared/button";
import { ResponsePromise } from "../../../api/types";
import { Table } from "../../shared/table/table";
import { LocalizingFunction, useI18n } from "../../../helpers/hooks/useI18n";
import { Spinner } from "../../spinner";
import { FullPageTile } from "../../tile";
import { IntegrationHeader } from "../../integrations/integration-header";

const getRowId = (cl: SubscriptionInfo) => cl.id;

export interface SubscriptionsProps {
  getSubscriptions: () => ResponsePromise<SubscriptionInfo[]>;
  getCustomerPortalSessionUrl: (customerId: string) => ResponsePromise<string>;
}

export const Subscriptions: React.FC<SubscriptionsProps> = ({
  getSubscriptions,
  getCustomerPortalSessionUrl
}) => {
  const t = useI18n("BILLING.SUBSCRIPTIONS.");
  const ts = useI18n("BILLING.SUBSCRIPTIONS.STATUSES.");
  const [subscriptions, setSubscriptions] = useState<SubscriptionInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getSubscriptions().then(({ data, error }) => {
      if (isActive) {
        setIsLoading(false);
        if (data) setSubscriptions(data);
        if (error) console.warn(error);
      }
    });
    return () => {
      isActive = false;
    };
  }, [getSubscriptions]);

  const manageCustomer = async (customerId: string) => {
    try {
      const { data, error, status } = await getCustomerPortalSessionUrl(
        customerId
      );
      if (data) window.open(data, "_self");
      else console.warn(error, status);
    } catch (error) {
      console.error(error);
    }
  };

  const groupedSubscriptions = useMemo(
    () =>
      subscriptions.reduce((grouped, subscription) => {
        const { customerId } = subscription;
        const group = grouped[customerId];
        if (group) {
          group.push(subscription);
        } else {
          grouped[customerId] = [subscription];
        }
        return grouped;
      }, {} as Record<string, SubscriptionInfo[]>),
    [subscriptions]
  );

  const columns = useMemo(
    (): Column<SubscriptionInfo>[] => [
      {
        Header: t("PLAN_NAME"),
        accessor: "productName",
        width: "20%"
      },
      {
        Header: t("STATUS"),
        accessor: "status",
        width: "20%",
        Cell: ({ value }) => <>{ts(value)}</>
      },
      {
        Header: t("PURCHASED"),
        accessor: s => DateTime.fromISO(s.startDate),
        id: "startDate",
        width: "20%",
        Cell: ({ value }) => value.toLocaleString(DateTime.DATE_FULL)
      },
      {
        Header: t("RENEWAL_DATE"),
        accessor: s => DateTime.fromISO(s.currentPeriodEnd),
        id: "currentPeriodEnd",
        width: "20%",
        Cell: ({ value, row }: CellProps<SubscriptionInfo, DateTime>) =>
          row.original.status === "active" && !row.original.canceledAt ? (
            <span className="text-colorUpgrade">
              {value.toLocaleString(DateTime.DATE_FULL)}
            </span>
          ) : (
            <>-</>
          )
      },
      {
        Header: t("MANAGED_BY"),
        accessor: "customerEmail",
        width: "20%"
      }
    ],
    [t]
  );

  return (
    <FullPageTile className="flex flex-col gap-10 justify-center">
      <IntegrationHeader text={t("MY_SUBSCRIPTIONS")} />
      {isLoading ? (
        <Spinner />
      ) : subscriptions.length === 0 ? (
        <div>{t("NO_SUBSCRIPTIONS_TO_MANAGE")}</div>
      ) : (
        Object.entries(groupedSubscriptions).map(
          ([customerId, subscriptions]) => (
            <CustomerSubscriptions
              customerId={customerId}
              subscriptions={subscriptions}
              columns={columns}
              manageCustomer={manageCustomer}
              t={t}
              key={customerId}
            />
          )
        )
      )}
      <div>{t("PROCESSING_TIME_NOTICE")}</div>
    </FullPageTile>
  );
};

interface CustomerSubscriptionsProps {
  subscriptions: SubscriptionInfo[];
  columns: Column<SubscriptionInfo>[];
  customerId: string;
  manageCustomer: (customerId: string) => void;
  t: LocalizingFunction;
}

const CustomerSubscriptions: React.FC<CustomerSubscriptionsProps> = ({
  subscriptions,
  columns,
  customerId,
  manageCustomer,
  t
}) => {
  const table: TableInstance<SubscriptionInfo> = useTable({
    columns,
    data: subscriptions,
    getRowId
  });

  return (
    <div className="flex flex-col">
      <Table {...table} useTablePagination={false} />
      <div className="flex flex-row justify-end">
        <PrimaryButton onClick={() => manageCustomer(customerId)}>
          {t("MANAGE")}
        </PrimaryButton>
      </div>
    </div>
  );
};
