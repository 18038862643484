import { EmailData, EmailEntry } from "./email.entry";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { PrimaryButton } from "../shared/button";
import { AppContext, ManagementPortalContext } from "../../context";
import { EMAIL_REGEX } from "./email_regex";

type EmailCorrectionProps = {
  initialEmails: EmailData[];
  onContinue: (correctedEmails: EmailData[]) => void;
};

export const emailCorrectionColumns: CSSProperties = {
  gridTemplateColumns: "min-content 1fr"
};

const validate = (payload: EmailData): EmailData => {
  return { ...payload, isValid: EMAIL_REGEX.test(payload.email) };
};

export const EmailCorrection = (props: EmailCorrectionProps): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);

  const [canContinue, setCanContinue] = useState(false);
  const [emails, setEmails] = useState<Record<string, EmailData>>(
    props.initialEmails.reduce((previousValue, currentValue) => {
      previousValue[currentValue.id] = validate(currentValue);
      return previousValue;
    }, {})
  );

  const updateEntry = (payload: EmailData) => {
    emails[payload.id] = validate(payload);

    setEmails({ ...emails });
  };

  useEffect(() => {
    setCanContinue(
      Object.values(emails)
        .filter(e => e.selected)
        .every(e => e.isValid)
    );
  }, [emails]);

  return (
    <div className="flex flex-col" style={{ minWidth: "75vw" }}>
      <div className="overflow-y-auto my-4 max-h-70vh ">
        <div className="grid gap-y-2" style={emailCorrectionColumns}>
          {Object.values(emails).map(e => (
            <EmailEntry emailData={e} updateEmail={updateEntry} key={e.id} />
          ))}
        </div>
      </div>
      <PrimaryButton
        additionalStyling="w-max self-center"
        disabled={!canContinue}
        onClick={() => props.onContinue(Object.values(emails))}
      >
        {context.Localized.string("ADD_ACCOUNTS.EMAIL_CORRECTION.CONTINUE")}
      </PrimaryButton>
    </div>
  );
};
