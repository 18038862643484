import { httpMethods } from "./http-methods";
import globalHeaders from "./global-headers";
import config from "../configuration";
import handleFetchRequest from "./handle-fetch-request";
import { LicenceAssignmentParameters, ResponsePromise } from "./types";
import { LicenseInfo, License } from "../state/licenses/types";
import { UserData } from "../state/accounts/types";

export function getAvailable(orgId: string): ResponsePromise<License[]> {
  return handleFetchRequest<License[]>(() =>
    fetch(
      `${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/licenses/available`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
}

export function setToUser({
  orgId,
  licenseId,
  userId
}: LicenceAssignmentParameters): ResponsePromise<UserData[]> {
  return handleFetchRequest<UserData[]>(() =>
    fetch(
      `${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/licenses/assign`,
      {
        method: httpMethods.POST,
        headers: globalHeaders,
        body: JSON.stringify([{ userId, licenseId }])
      }
    )
  );
}

export function takeFromUser({
  orgId,
  userId,
  licenseId
}: LicenceAssignmentParameters): ResponsePromise<UserData[]> {
  return handleFetchRequest<UserData[]>(() =>
    fetch(
      `${config["serviceConfig"].auth}/api/v1/orgs/${orgId}/licenses/assign`,
      {
        method: httpMethods.DELETE,
        headers: globalHeaders,
        body: JSON.stringify([{ userId, licenseId }])
      }
    )
  );
}

export function getLicenseInfo(): ResponsePromise<LicenseInfo> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].licensing}/api/v1/license`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
}
