import React, { useState, Fragment } from "react";
import { useI18n } from "../../helpers/hooks/useI18n";
import {
  extractPermissions,
  sendEmail,
  sortUsers
} from "../../helpers/share.dialog.helpers";
import { ShareDialogPermissionsBar } from "./share.dialog.permission.bar";
import { PrimaryButton } from "../shared/button";
import { Checkbox } from "../shared/checkbox";
import { isWorkspaceAdministrator } from "../../helpers/is.workspace.administrator";
import { Workspace } from "../../api/types";
import {
  Permissions,
  ShareOptionValue,
  ShareType,
  USER_ACCESS_OPTIONS
} from "../shared/permissions/permissions.types";

type WorkspacePermissionsProps = {
  workspace: Workspace;
  permissionLevelSets: Permissions;
  email: string;
  updatePermissions: (newPermissions: Permissions) => Promise<void>;
  permissionsIsOpen: boolean;
  shareLink: string | undefined;
  showOrgAdminPolicy: boolean;
};

export function addNewUsers(
  value: ShareOptionValue,
  editedUsers: string[],
  permissions: Permissions,
  updatePermissions: (newPermissions: Permissions) => Promise<void>
): Promise<void> {
  // only filter if adding new users - no filter when updating an existing user
  editedUsers = editedUsers.filter(user => {
    return (
      !permissions.administrators.includes(user) &&
      !permissions.writers.includes(user) &&
      !permissions.readers.includes(user) &&
      !permissions.unspecifiedUsers.includes(user)
    );
  });
  return updateUserPermissions(
    value,
    editedUsers,
    permissions,
    updatePermissions
  );
}

export function updateUserPermissions(
  value: ShareOptionValue,
  editedUsers: string[],
  permissions: Permissions,
  updatePermissions: (newPermissions: Permissions) => Promise<void>
): Promise<void> {
  let newPerms: Permissions = {
    administrators: [],
    writers: [],
    readers: [],
    unspecifiedUsers: [],
    globalAccess: permissions.globalAccess
  };

  switch (value) {
    case ShareType.ADMIN:
      newPerms.administrators = permissions.administrators.concat(editedUsers);
      newPerms.writers = permissions.writers.concat(editedUsers);
      newPerms.readers = permissions.readers.concat(editedUsers);
      newPerms.unspecifiedUsers = permissions.unspecifiedUsers.filter(
        user => !editedUsers.includes(user)
      );
      break;
    case ShareType.EDIT:
      newPerms.administrators = permissions.administrators.filter(
        user => !editedUsers.includes(user)
      );
      newPerms.writers = permissions.writers.concat(editedUsers);
      newPerms.readers = permissions.readers.concat(editedUsers);
      newPerms.unspecifiedUsers = permissions.unspecifiedUsers.filter(
        user => !editedUsers.includes(user)
      );
      break;
    case ShareType.READ:
      newPerms.administrators = permissions.administrators.filter(
        user => !editedUsers.includes(user)
      );
      newPerms.writers = permissions.writers.filter(
        user => !editedUsers.includes(user)
      );
      newPerms.readers = permissions.readers.concat(editedUsers);
      newPerms.unspecifiedUsers = permissions.unspecifiedUsers.filter(
        user => !editedUsers.includes(user)
      );
      break;
    case ShareType.NO_ACCESS:
      newPerms.administrators = permissions.administrators.filter(
        user => !editedUsers.includes(user)
      );
      newPerms.writers = permissions.writers.filter(
        user => !editedUsers.includes(user)
      );
      newPerms.readers = permissions.readers.filter(
        user => !editedUsers.includes(user)
      );
      newPerms.unspecifiedUsers = permissions.unspecifiedUsers.concat(
        editedUsers
      );
      break;
    case ShareType.REMOVE:
      newPerms.administrators = permissions.administrators.filter(
        user => !editedUsers.includes(user)
      );
      newPerms.writers = permissions.writers.filter(
        user => !editedUsers.includes(user)
      );
      newPerms.readers = permissions.readers.filter(
        user => !editedUsers.includes(user)
      );
      newPerms.unspecifiedUsers = permissions.unspecifiedUsers.filter(
        user => !editedUsers.includes(user)
      );
      break;
  }
  if (newPerms.administrators.length < 1) {
    return Promise.reject();
  }
  return updatePermissions(newPerms);
}

export const WorkspacePermissions: React.FC<WorkspacePermissionsProps> = ({
  workspace,
  permissionLevelSets,
  email,
  updatePermissions,
  permissionsIsOpen,
  shareLink,
  showOrgAdminPolicy
}) => {
  const t = useI18n("SHARE_WORKSPACE.WORKSPACE_PERMISSIONS.");
  const tp = useI18n("SHARE_WORKSPACE.");
  const tcl = useI18n("SHARE_WORKSPACE.COPY_EMAIL_LINK.");
  const [isAddingUsers, toggleAddUser] = useState(false);
  const [newUsersPermission, setNewUsersPermission] = useState(
    ShareType.EDIT as ShareOptionValue
  );
  const [usersToAdd, updateUsersToAdd] = useState("");
  const [notifyUsers, setNotifyUsers] = useState(false);

  if (!workspace) return <></>;

  const permissions: Permissions = extractPermissions(workspace);
  const isAdmin = isWorkspaceAdministrator(
    email,
    workspace.administrators,
    showOrgAdminPolicy
  );

  let users = sortUsers(permissionLevelSets);

  if (!isAdmin) {
    let user = users.find(user => user.email === email);
    users = permissionLevelSets.administrators.map(user => ({
      email: user,
      permission: ShareType.ADMIN
    }));
    if (user) {
      users.push(user);
    }
  }

  const evaluateEmailList = async () => {
    if (usersToAdd === "") {
      return;
    }
    const emailList = usersToAdd.split(",").map(e => e.trim());
    const index = emailList.indexOf(email.trim());
    if (index > -1) {
      emailList.splice(index, 1);
    }
    if (emailList.length !== 0) {
      await addNewUsers(
        newUsersPermission,
        emailList,
        permissions,
        updatePermissions
      );
      if (notifyUsers && shareLink) {
        sendEmail(workspace.documentName ?? "", shareLink, tcl, emailList);
        setNotifyUsers(false);
      }
    }
    toggleAddUser(false);
    updateUsersToAdd("");
  };

  return (
    <div className={permissionsIsOpen ? "" : "hidden"}>
      {!isAddingUsers ? (
        <Fragment>
          <div
            className={
              "flex flex-col gap-2 max-h-2xs sm:max-h-xs customScrollbar"
            }
          >
            {users.map(user => (
              <div className={"py-3/2"} key={user.email}>
                <ShareDialogPermissionsBar
                  isAdmin={isAdmin}
                  currentUser={email.trim()}
                  text={user.email.trim()}
                  enabledOption={user.permission}
                  commitUpdate={(shareOption: ShareOptionValue) =>
                    updateUserPermissions(
                      shareOption,
                      [user.email],
                      permissions,
                      updatePermissions
                    )
                  }
                  options={USER_ACCESS_OPTIONS}
                />
              </div>
            ))}
            {showOrgAdminPolicy && (
              <ShareDialogPermissionsBar
                isAdmin={false}
                enabledOption={ShareType.ADMIN}
                text={t("BY_POLICY_ANY_ORG_ADMIN")}
                currentUser="org admin"
                options={USER_ACCESS_OPTIONS}
              />
            )}
          </div>
          {isAdmin && (
            <div className="flex justify-end mt-2">
              <PrimaryButton
                title={t("ADD_USERS")}
                onClick={() => {
                  toggleAddUser(true);
                }}
              >
                {t("ADD_USERS")}
              </PrimaryButton>
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <div className={"pt-3/2"}>
            <ShareDialogPermissionsBar
              text={t("USER_PERMISSION")}
              enabledOption={newUsersPermission}
              commitUpdate={async (shareOption: ShareOptionValue) =>
                setNewUsersPermission(shareOption)
              }
              isAdmin={isAdmin}
              options={USER_ACCESS_OPTIONS}
            />
            <textarea
              className={
                "bg-dark-selected textarea rounded border-none p-4 mt-3 mb-3/2 h-48 w-full"
              }
              value={usersToAdd}
              onChange={e => {
                updateUsersToAdd(e.target.value);
              }}
            ></textarea>
            <div className="flex justify-between text-sm">
              <div className={"flex items-center gap-2"}>
                <Checkbox
                  initiallyChecked={notifyUsers}
                  onChange={() => {
                    setNotifyUsers(!notifyUsers);
                  }}
                  disabled={!shareLink}
                />
                <span>{t("NOTIFY_USERS")}</span>
              </div>
              <div className={"flex items-center gap-2 text-sm"}>
                <PrimaryButton
                  title={t("CANCEL")}
                  onClick={() => toggleAddUser(false)}
                  additionalStyling="btn-negative mt-0 mb-0 "
                >
                  {t("CANCEL")}
                </PrimaryButton>
                <PrimaryButton
                  title={`${t("ADD_WITH")} ${tp(newUsersPermission.title)} ${t(
                    "ACCESS"
                  )}`}
                  onClick={evaluateEmailList}
                  additionalStyling={`bg-${newUsersPermission.color}`}
                >
                  {`${t("ADD_WITH")} ${tp(newUsersPermission.title)} ${t(
                    "ACCESS"
                  )}`}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
