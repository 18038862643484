import { Icon } from "../../shared/icon";
import React from "react";
import { WorkspaceTypeData, workspaceTypes } from "../workspace.types";
import { Spinner } from "../../spinner";
import { Workspace } from "../../../api/types";
import config from "../../../configuration";

export const WorkspaceNameCell = ({ workspace }: { workspace: Workspace }) => {
  const { module, documentName, documentId, documentType } = workspace;

  const getWorkspaceType = (): WorkspaceTypeData => {
    let type = "";

    if (module && module.name) {
      type = module.name;

      if (type.includes("pullplanning")) {
        type = "pullplanning";
      }
    } else {
      type = documentType?.toLowerCase() || "";
    }

    const typeObject = workspaceTypes[type];

    return typeObject || workspaceTypes["default"];
  };

  const type = getWorkspaceType();
  const workspaceUrl =
    config.serviceConfig.hoylu_app + `/${workspace.documentId}`;

  return (
    <div className="flex max-h-12 overflow-hidden">
      {!documentName ? (
        <div className="w-8 h-12 relative">
          <Spinner classNames="block mt-4 absolute" size={4} />
        </div>
      ) : (
        <div className="self-center flex justify-center items-center mr-2 shrink-0 text-4xl">
          <Icon title={type.name} icon={type.icon} />
        </div>
      )}
      <div>
        <div className="font-bold truncate" title={documentName}>
          {documentName}
        </div>
        <div className="text-colorTextAndIconLighterLightTheme underline">
          <a href={workspaceUrl} target="_blank">
            {documentId}
          </a>
        </div>
      </div>
    </div>
  );
};
