// Groups types

export interface Group {
  id: string;
  displayName: string;
}

export interface GroupStateMap {
  [key: string]: GroupState;
}
export interface GroupsState {
  allGroups: GroupStateMap;
  hasMoreGroups: boolean;
  groupStartsWith?: string;
  activeGroupIds: string[];
  loading: boolean;
  error: boolean;
}

export interface GroupState extends Group {
  updating: boolean;
  error: boolean;
}

export const GET_GROUPS_ERROR = "@groups/GET_GROUPS_ERROR";
export const GET_GROUPS_LOADING = "@groups/GET_GROUPS_LOADING";
export const GET_GROUPS_SUCCESS = "@groups/GET_GROUPS_SUCCESS";

export const UPDATE_GROUP_LICENSE_ERROR = "@groups/UPDATE_GROUP_LICENSE_ERROR";
export const UPDATE_GROUP_LICENSE_LOADING =
  "@groups/UPDATE_GROUP_LICENSE_LOADING";
export const UPDATE_GROUP_LICENSE_SUCCESS =
  "@groups/UPDATE_GROUP_LICENSE_SUCCESS";

export const UPDATE_GROUP_STARTS_WITH_QUERY =
  "@groups/UPDATE_GROUP_STARTS_WITH_QUERY";
