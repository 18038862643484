import { Textarea } from "../../shared/textarea";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../../../context";
import { PrimaryButton, TextButton } from "../../shared/button";
import { EMAIL_REGEX } from "../../accounts/email_regex";
import { useSelector } from "react-redux";
import { projectsSelectors } from "../../../state/projects";
import { useCombinedRefs, usePasteOnlyEmail } from "@hoylu/client-common";
import { usePanelDetector } from "../../../helpers/hooks/usePanelDetector";

interface AddUsersProps {
  projectId: string;
  cancel: () => void;
  apply: (emails: string[]) => void;
}

export const AddUsers = ({ cancel, apply, projectId }: AddUsersProps) => {
  const context = useContext(AppContext);
  const collaborators = useSelector(projectsSelectors.collaborators);
  const [addedEmails, setAddedEmails] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [validEmails, setValidEmails] = useState<string[]>([]);
  const alreadyAddedEmails = useMemo(
    () =>
      collaborators[projectId]?.map(
        collaborator => collaborator.email.toLowerCase() ?? []
      ),
    [projectId, collaborators]
  );
  const onlyEmailsRef = usePasteOnlyEmail(",\n");
  const elementRef = useRef<HTMLTextAreaElement | null>(null);
  const { isUserProfilePanel } = usePanelDetector();
  const combinedRef = useCombinedRefs(elementRef, onlyEmailsRef);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.focus();
    }
  }, []);

  const onEdit = (emails: string) => {
    setAddedEmails(emails);
    const emailsArray = emails
      ?.replace(/\s/g, "")
      .split(",")
      .filter(e => !!e); // to remove empty values that can happen when e.g. user left trailing comma
    const allValid = emailsArray.every(e => EMAIL_REGEX.test(e));
    setIsValid(allValid);

    if (allValid) {
      // to lower upper case
      setValidEmails(
        emailsArray.filter(
          email => !alreadyAddedEmails.includes(email.toLowerCase())
        )
      );
    }
  };

  return (
    <div data-test-id={"add-users-expanded"}>
      <Textarea
        label={context.Localized.string("PROJECTS.ADD_USERS_HINT")}
        value={addedEmails}
        textareaHeight="8rem"
        onChange={(value: string) => onEdit(value)}
        ref={combinedRef}
        transparent={!isUserProfilePanel}
      />
      <div className="flex justify-end gap-2 mt-4">
        <TextButton onClick={cancel}>
          {context.Localized.string("CANCEL")}
        </TextButton>
        <PrimaryButton
          onClick={() => apply(validEmails)}
          disabled={!addedEmails || !isValid}
        >
          {context.Localized.string("PROJECTS.ADD_USERS")}
        </PrimaryButton>
      </div>
    </div>
  );
};
