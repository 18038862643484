import { useContext, useState } from "react";
import { TableInstance, useAsyncDebounce } from "react-table";
import { AppContext } from "../../context";
// this import is needed for unit tests, without it useAsyncDebounce will cause error
import "regenerator-runtime/runtime";

export type GlobalFilterProps = Pick<
  TableInstance<any>,
  "setGlobalFilter" | "state"
> & {
  placeholder?: string;
};

export function GlobalFilter({
  setGlobalFilter,
  state: { globalFilter },
  placeholder
}: GlobalFilterProps): JSX.Element {
  const context = useContext(AppContext);
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span className="flex-none whitespace-nowrap relative">
      <span
        className={
          "hicon magnifier pointer-events-none absolute left-0 pl-1 text-2xl text-colorPrimary"
        }
      />
      <input
        className="w-80 h-8 pl-7 border border-colorPrimary placeholder-colorPrimary bg-transparent rounded outline-none text-sm"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={placeholder || context.Localized.string("SEARCH")}
        aria-label="search"
      />
    </span>
  );
}
