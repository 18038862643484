import { useContext, useMemo } from "react";
import { DateTime } from "luxon";
import { AppContext, ManagementPortalContext } from "../../context";
import { TwoLevelCell } from "../shared/table/two.level.cell";

export const DatesCell = ({ startDate, endDate }) => {
  const context: ManagementPortalContext = useContext(AppContext);
  const start = startDate ? DateTime.fromISO(startDate) : startDate;
  const end = endDate ? DateTime.fromISO(endDate) : endDate;
  const t = (address: string) => context.Localized.string(address);

  const isPastEndDate = useMemo((): boolean => {
    if (!end) return false;

    const currentDate = DateTime.now();
    return end < currentDate;
  }, [end]);

  return (
    <TwoLevelCell
      topValue={start ? start.toLocaleString() : t("PROJECTS.NOT_STARTED")}
      bottomValue={end ? end.toLocaleString() : t("PROJECTS.NA")}
      topTitle={t("PROJECTS.START")}
      bottomTitle={t("PROJECTS.END")}
      bottomAlert={isPastEndDate}
    />
  );
};
