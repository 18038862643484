import { applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { RootAction, RootState } from "typesafe-actions";
import api from "../api";
import { ApiType } from "../api/types";

export const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  ApiType
>({
  dependencies: api
});

export default applyMiddleware(epicMiddleware);
