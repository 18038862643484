import { WorkspacesList } from "./list/workspaces.list";
import { useSelector } from "react-redux";
import { organizationSelectors } from "../../state/organization";
import { useCallback, useEffect, useState } from "react";
import type {
  ApiResponse,
  Workspace,
  WorkspacesResponse
} from "../../api/types";
import { WorkspacesSort } from "../../api/types";
import {
  getFirstPageOfWorkspaces,
  getPageOfWorkspacesByUrl
} from "../../api/workspaces";
import { prepareWorkspacesCsv } from "./list/prepare.workspaces.csv";
import { showError, showErrorToast } from "../../helpers/show.toast.helpers";
import { getErrorMessage } from "../../state/errors/errors.epic";
import { WorkspacesPagination } from "./list/workspaces.pagination";
import { useI18n } from "../../helpers/hooks/useI18n";

const pagesUrls = new Map<number, string>();

export const OrganizationWorkspaces = () => {
  const t = useI18n("WORKSPACES.");
  const orgId = useSelector(organizationSelectors.uid);
  const [isLoading, setIsLoading] = useState(false);
  const [preparingExport, setPreparingExport] = useState(false);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sort, setSort] = useState<WorkspacesSort | undefined>(
    WorkspacesSort.createDescending
  );

  const handlePageFetchingError = useCallback(
    (error?: string) => {
      let message = getErrorMessage(error);

      if (!message) message = t("PAGE_FETCH_ERROR");

      showError(message);
    },
    [t]
  );

  const getFirstPage = useCallback(
    async (showLoading = true) => {
      if (showLoading) setIsLoading(true);

      const result = await getFirstPageOfWorkspaces({
        orgId,
        limit: pageSize,
        sort
      });

      if (showLoading) setIsLoading(false);

      if (!result || result.error) {
        return handlePageFetchingError(result.error);
      }

      if (result.data?.next) {
        pagesUrls.set(2, result.data.next);
      }

      if (result.data?.workspaces) {
        setWorkspaces(result.data.workspaces);
        setPageNumber(1);
      }
    },
    [handlePageFetchingError, orgId, pageSize, sort]
  );

  const getNextPage = async () => {
    const nextPageUrl = pagesUrls.get(pageNumber + 1);

    if (!nextPageUrl) return;
    setIsLoading(true);
    const result = await getPageOfWorkspacesByUrl(nextPageUrl);
    setIsLoading(false);

    if (!result || result.error) {
      return handlePageFetchingError(result.error);
    }

    if (result.data?.next) {
      const nextPage = pageNumber + 2;
      pagesUrls.set(nextPage, result.data.next);
    }

    if (result.data?.workspaces) {
      setWorkspaces(result.data.workspaces);
      setPageNumber(pageNumber => pageNumber + 1);
    }
  };

  const getPreviousPage = async () => {
    const previousPage = pageNumber - 1;
    if (previousPage < 1) return;

    let result: ApiResponse<WorkspacesResponse>;

    setIsLoading(true);

    if (previousPage === 1) {
      result = await getFirstPageOfWorkspaces({ orgId, limit: pageSize, sort });
    } else {
      const url = pagesUrls.get(previousPage);
      if (!url) return;
      result = await getPageOfWorkspacesByUrl(url);
    }

    setIsLoading(false);

    if (!result || result.error) {
      return handlePageFetchingError(result.error);
    }

    if (result.data?.workspaces) {
      setWorkspaces(result.data.workspaces);
      setPageNumber(pageNumber => pageNumber - 1);
    }

    if (result.data?.next) {
      pagesUrls.set(pageNumber, result.data.next);
    }
  };

  const silentPageRefresh = useCallback(async () => {
    if (pageNumber < 2) {
      getFirstPage(false);
    } else {
      const currentPageUrl = pagesUrls.get(pageNumber);

      if (!currentPageUrl) return;

      const result = await getPageOfWorkspacesByUrl(currentPageUrl);

      if (result.data?.next) {
        const nextPage = pageNumber + 1;
        pagesUrls.set(nextPage, result.data.next);
      }

      if (result.data?.workspaces) {
        setWorkspaces(result.data.workspaces);
      }
    }
  }, [pageNumber, getFirstPage]);

  const exportWorkspaces = async () => {
    setPreparingExport(true);

    try {
      await prepareWorkspacesCsv(t, orgId);
    } catch (e) {
      showErrorToast("Failed to prepare CSV export.", e);
      console.error(e);
    }

    setPreparingExport(false);
  };

  useEffect(() => {
    pagesUrls.clear();
    getFirstPage();
  }, [pageSize, sort, getFirstPage]);

  return (
    <>
      <WorkspacesList
        sort={sort}
        setSort={setSort}
        silentPageRefresh={silentPageRefresh}
        workspaces={workspaces}
        exportWorkspaces={exportWorkspaces}
        isLoading={isLoading}
        isPreparingExport={preparingExport}
      />
      <WorkspacesPagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        nextPage={getNextPage}
        canNextPage={!!pagesUrls.get(pageNumber + 1)}
        getFirstPage={getFirstPage}
        pageNumber={pageNumber}
        previousPage={getPreviousPage}
      />
    </>
  );
};
