import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { organizationSelectors } from "../../../state/organization";
import { useAsyncDebounce } from "react-table";
// this import is needed for unit tests, without it useAsyncDebounce will cause error
import "regenerator-runtime/runtime";
import { isUserData, UserData } from "../../../state/accounts/types";
import { Avatar } from "../../avatar/avatar";
import { Icon } from "../icon";
import { useMemo } from "react";

interface AvatarsCellProps {
  usersEmails: string[]; // we use emails instead of ids as in projects we can have collaborators who doesn't belong to organization
  editAction: () => void;
  showActionButton?: boolean;
}

export const AvatarsCell = ({
  usersEmails,
  editAction,
  showActionButton = true
}: AvatarsCellProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const plusRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const [maxAvatars, setMaxAvatars] = useState(3);
  const members = useSelector(organizationSelectors.members);
  const spaceTakenByInfoAboutOverflow = 1;

  const handleResize = useAsyncDebounce(() => {
    const availableSpace = checkHowManyAvatarsCanBeRendered();

    if (availableSpace && availableSpace !== maxAvatars) {
      setMaxAvatars(availableSpace);
    }
  }, 100);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const checkHowManyAvatarsCanBeRendered = (): number | undefined => {
    if (containerRef.current) {
      const gap = 4; // flex gap equal to 0.25rem
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const plusWidth = plusRef.current?.getBoundingClientRect().width || 24;
      const childWidth =
        (childRef.current?.getBoundingClientRect().width || 28) + gap;

      const availableSpace = containerWidth - plusWidth;
      return Math.floor(availableSpace / childWidth);
    }
  };

  useEffect(() => {
    setMaxAvatars(checkHowManyAvatarsCanBeRendered() ?? 6);
  }, [usersEmails.length]);

  const usersToShow = useMemo(() => {
    return maxAvatars && maxAvatars < usersEmails.length
      ? usersEmails.slice(0, maxAvatars - spaceTakenByInfoAboutOverflow)
      : usersEmails;
  }, [maxAvatars, usersEmails]);

  const usersDetails = useMemo((): (UserData | string)[] => {
    const foundMembers = members.filter(member =>
      usersToShow.includes(member.email)
    );

    return usersToShow.reduce((acc: (UserData | string)[], email) => {
      const userData = foundMembers.find(member => member.email === email);
      userData ? acc.push(userData) : acc.push(email);
      return acc;
    }, [] as (UserData | string)[]);
  }, [usersToShow, members]);

  const getKey = user => {
    return isUserData(user) ? user.email : user;
  };

  return (
    <div className="flex items-center w-full" ref={containerRef}>
      <div className="flex gap-1">
        {usersDetails.map(user => (
          <div ref={childRef} key={getKey(user)}>
            <Avatar
              user={user}
              circleStyle="inline-block w-7 h-7"
              textStyle="text-sm font-bold"
              avatarDetailsPosition="top-6"
            />
          </div>
        ))}
        {maxAvatars < usersEmails.length && (
          <div className="flex justify-center items-center w-7 h-7 rounded-full text-colorTextAndIconLighterLightTheme text-xs bg-colorDayOffLightTheme">
            {`+${
              usersEmails.length - maxAvatars + spaceTakenByInfoAboutOverflow
            }`}
          </div>
        )}
      </div>
      {showActionButton && (
        <div
          className="ml-1 text-colorTextAndIconLighterLightTheme"
          ref={plusRef}
        >
          <Icon icon="plus" onClick={editAction} />
        </div>
      )}
    </div>
  );
};
