import { useContext, useState } from "react";
import { useI18n } from "../../../../helpers/hooks/useI18n";
import { PriceInfo, ProductInfo } from "../../../../state/licenses/types";
import { Header } from "../../../navbar/header";
import { PrimaryButton, SecondaryButton } from "../../../shared/button";
import { Select } from "../../../input";
import type { ResponsePromise } from "../../../../api/types";
import { PlanTile } from "./plan.tile";
import { AppContext } from "../../../../context";

interface StripeProductProps {
  product: ProductInfo;
  getCheckoutSessionUrl: (priceId: string) => ResponsePromise<string>;
}

export const StripeProduct: React.FC<StripeProductProps> = ({
  getCheckoutSessionUrl,
  product
}) => {
  const tp = useI18n("BILLING.PLANS.");
  const tl = useI18n("LINKS.");

  const [selectedPriceId, setSelectedPriceId] = useState<string>(
    product.defaultPriceId ?? product.prices[0].id
  );
  const [isLoading, setIsLoading] = useState(false);

  const canSelectPrice = product.prices.length > 1;

  const learnMore = () => window.open(tl("PLANS"), "_blank");

  const checkout = async () => {
    if (!selectedPriceId) throw new Error("Price not selected");
    setIsLoading(true);
    try {
      const { data, error, status } = await getCheckoutSessionUrl(
        selectedPriceId
      );
      setIsLoading(false);
      if (data) window.open(data, "_self");
      else console.warn(error, status);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <PlanTile>
      <Header title={product.name} />
      {product.description}
      <div className="self-center">
        <Select
          value={selectedPriceId}
          onChange={e => setSelectedPriceId(e.target.value)}
          disabled={!canSelectPrice}
          id="prices"
        >
          {product.prices.map(p => (
            <PriceOption key={p.id} price={p} />
          ))}
        </Select>
      </div>
      <div className="grow" />
      <div className="grid grid-flow-col auto-cols-fr gap-4">
        <SecondaryButton onClick={learnMore}>
          {tp("LEARN_MORE")}
        </SecondaryButton>
        <PrimaryButton
          disabled={!selectedPriceId || isLoading}
          onClick={checkout}
          additionalStyling="bg-colorUpgrade hover:bg-colorUpgradeHover active:bg-colorUpgradeActive text-colorTableBackground"
        >
          {tp("UPGRADE")}
        </PrimaryButton>
      </div>
    </PlanTile>
  );
};

const PriceOption: React.FC<{ price: PriceInfo }> = ({ price }) => {
  const t = useContext(AppContext).Localized;
  const path = "BILLING.PLANS.PRODUCTS.";

  const { id, interval, intervalCount, unitAmount, currency } = price;
  const amount = (unitAmount / 100).toFixed(2);
  const translatedInterval = t.get(path + interval) ?? interval;
  const translatedCurrency = t.get(path + currency) ?? currency;
  const displayedInterval =
    intervalCount > 1
      ? `${intervalCount} ${translatedInterval}`
      : translatedInterval;

  return (
    <option value={id}>
      {`${amount} ${translatedCurrency} / ${displayedInterval}`}
    </option>
  );
};
