import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column, useRowSelect, useSortBy, useTable } from "react-table";
import { AppContext } from "../../context";
import { groupsActions, groupsSelectors } from "../../state/groups";
import { GroupState } from "../../state/groups/types";
import { IndeterminateCheckbox } from "../accounts-list/indeterminate-checkbox";
import { PrimaryButton } from "../shared/button";
import { Input } from "../input";
import { Spinner } from "../spinner";
import { Table } from "../table";
import { FullPageTile } from "../tile";
import { IntegrationHeader } from "../integrations/integration-header";

function createSelectionColumn(): Column<GroupState> {
  return {
    id: "selection",
    disableSortBy: true,
    width: "2rem",
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    Cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      </div>
    )
  };
}

function createColumns(title: string): Column<GroupState>[] {
  return [
    {
      Header: title,
      accessor: "displayName",
      width: "auto",
      Cell: ({ row, value }) => {
        return (
          <div className="flex">
            <div className="flex-grow truncate">{value}</div>
            {row.original.updating && (
              <Spinner classNames="inline-block m-1 flex-none" size={3} />
            )}
          </div>
        );
      }
    }
  ];
}

export const AdAdministration: React.FC = () => {
  const context = useContext(AppContext);

  const activeGroups = useSelector(groupsSelectors.activeGroups);
  const inactiveGroups = useSelector(groupsSelectors.inactiveGroups);
  const hasMoreGroups = useSelector(groupsSelectors.hasMoreGroups);
  const groupStartsWith = useSelector(groupsSelectors.groupStartsWith);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(groupsActions.getGroups());
  }, [dispatch]);

  const activeColumns = useMemo(() => {
    return createColumns(
      context.Localized.string("GROUP_MANAGEMENT.ACTIVE_GROUPS_TITLE")
    );
  }, [context]);
  const inactiveColumns = useMemo(
    () =>
      createColumns(
        context.Localized.string("GROUP_MANAGEMENT.NONACTIVE_GROUPS_TITLE")
      ),
    [context]
  );
  const activeSelectionColumn = useMemo(createSelectionColumn, []);
  const inactiveSelectionColumn = useMemo(createSelectionColumn, []);
  const getRowId = useMemo(() => (group: GroupState) => group.id, []);

  const activeGroupsTable = useTable<GroupState>(
    {
      columns: activeColumns,
      data: activeGroups,
      getRowId,
      disableSortBy: true
    },
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [activeSelectionColumn, ...columns]);
    }
  );
  const inactiveGroupsTable = useTable<GroupState>(
    {
      columns: inactiveColumns,
      data: inactiveGroups,
      getRowId,
      disableSortBy: true
    },
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        inactiveSelectionColumn,
        ...columns
      ]);
    }
  );

  const toggle = (selectedRowIds: Record<string, boolean>, enable: boolean) => {
    for (const key in selectedRowIds) {
      dispatch(groupsActions.updateGroupLicense(key, enable));
    }
  };

  return (
    <FullPageTile className="space-y-4">
      <IntegrationHeader
        text={context.Localized.string("GROUP_MANAGEMENT.HEADER")}
      />
      {hasMoreGroups && (
        <div>
          <div>
            {context.Localized.string("GROUP_MANAGEMENT.MORE_PAGES_AVAILABLE")}
          </div>
          <div
            className="grid gap-2"
            style={{ gridTemplateColumns: "1fr auto" }}
          >
            <Input
              type="text"
              value={groupStartsWith}
              onChange={e =>
                dispatch(groupsActions.updateStartsWithQuery(e.target.value))
              }
            />
            <PrimaryButton onClick={() => dispatch(groupsActions.getGroups())}>
              {context.Localized.string(
                "GROUP_MANAGEMENT.REFINE_GROUPS_BUTTON"
              )}
            </PrimaryButton>
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 gap-x-8 items-start auto-rows-min">
        <PrimaryButton
          onClick={() => toggle(inactiveGroupsTable.state.selectedRowIds, true)}
          disabled={
            Object.keys(inactiveGroupsTable.state.selectedRowIds).length === 0
          }
        >
          {context.Localized.string("GROUP_MANAGEMENT.ACTIVATE")}
        </PrimaryButton>
        <PrimaryButton
          onClick={() => toggle(activeGroupsTable.state.selectedRowIds, false)}
          disabled={
            Object.keys(activeGroupsTable.state.selectedRowIds).length === 0
          }
        >
          {context.Localized.string("GROUP_MANAGEMENT.DEACTIVATE")}
        </PrimaryButton>
        <Table {...inactiveGroupsTable} />
        <Table {...activeGroupsTable} />
      </div>
    </FullPageTile>
  );
};
