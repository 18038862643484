export interface ProjectsState {
  loadingProjects: boolean;
  errorProjects: boolean;
  projects: Project[];
  collaborators: ProjectsCollaborators;
  /**
   * When user is admin of any project he is a project admin.
   * */
  isProjectAdmin: boolean;
}

export interface AppearanceProperties {
  icon: string; // hicons class
  color: string; // hash color
}

export type Project = {
  name: string;
  id: string;
  ownerId: string;
  ownerType: "User" | "Org";
  startDate: string | null;
  endDate: string | null;
  maxProjectUsers: number | null;
  maxProjectWorkspaces: number | null;
  status: string;
  description: string | null;
  appearanceProperties: AppearanceProperties | null;
  userRole?: UserRole;
};

export type UserRole = {
  containerId: string;
  userId: string;
  email: string;
  roleId: string;
  roleName: string;
};

export interface Collaborator {
  containerId: string;
  userId: string;
  email: string;
  roleId: string;
  roleName: string;
}

export interface AddUserMappingRequest {
  projectId: string;
  userEmail: string;
  roleId: string; // UUID
}

export interface UpdateUserMappingRequest {
  projectId: string;
  userId: string; // UUID
  roleId: string; // UUID
}

export interface DeleteUserMappingRequest {
  projectId: string;
  userIds: string[]; // UUIDs
}

export interface UserMapping {
  containerId: string;
  userId: string; // UUID
  email: string | null;
  roleId: string; // UUID
  roleName: string | null;
}

export interface ErrorResponse<T> {
  code: T;
  message: string;
}

export interface ProjectsCollaborators {
  [key: string]: Collaborator[]; // use id of a Project as a key to store collaborators by project
}

export const GET_ALL_ORG_PROJECTS_LOADING =
  "@projects/GET_ALL_ORG_PROJECTS_LOADING";
export const GET_ALL_ORG_PROJECTS_ERROR =
  "@projects/GET_ALL_ORG_PROJECTS_ERROR";
export const GET_ALL_ORG_PROJECTS_SUCCESS =
  "@projects/GET_ALL_ORG_PROJECTS_SUCCESS";

export const GET_MY_PROJECTS_LOADING = "@projects/GET_MY_PROJECTS_LOADING";
export const GET_MY_PROJECTS_ERROR = "@projects/GET_MY_PROJECTS_ERROR";
export const GET_MY_PROJECTS_SUCCESS = "@projects/GET_MY_PROJECTS_SUCCESS";

export const GET_COLLABORATORS_LOADING = "@projects/GET_COLLABORATORS_LOADING";
export const GET_COLLABORATORS_ERROR = "@projects/GET_COLLABORATORS_ERROR";
export const GET_COLLABORATORS_SUCCESS = "@projects/GET_COLLABORATORS_SUCCESS";

export const UPDATE_PROJECT_LOADING = "@projects/UPDATE_PROJECT_LOADING";
export const UPDATE_PROJECT_ERROR = "@projects/UPDATE_PROJECT_ERROR";
export const UPDATE_PROJECT_SUCCESS = "@projects/UPDATE_PROJECT_SUCCESS";

export const ADD_USER_TO_PROJECT_LOADING =
  "@projects/ADD_USER_TO_PROJECT_LOADING";
export const ADD_USER_TO_PROJECT_ERROR = "@projects/ADD_USER_TO_PROJECT_ERROR";
export const ADD_USER_TO_PROJECT_SUCCESS =
  "@projects/ADD_USER_TO_PROJECT_SUCCESS";

export const UPDATE_USER_FROM_PROJECT_LOADING =
  "@projects/UPDATE_USER_FROM_PROJECT_LOADING";
export const UPDATE_USER_FROM_PROJECT_ERROR =
  "@projects/UPDATE_USER_FROM_PROJECT_ERROR";
export const UPDATE_USER_FROM_PROJECT_SUCCESS =
  "@projects/UPDATE_USER_FROM_PROJECT_SUCCESS";

export const DELETE_USERs_FROM_PROJECT_LOADING =
  "@projects/DELETE_USERS_FROM_PROJECT_LOADING";
export const DELETE_USERs_FROM_PROJECT_ERROR =
  "@projects/DELETE_USERS_FROM_PROJECT_ERROR";
export const DELETE_USERs_FROM_PROJECT_SUCCESS =
  "@projects/DELETE_USERS_FROM_PROJECT_SUCCESS";
