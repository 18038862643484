import React from "react";
import { HeaderTitle } from "./header.title";
import { ExpandCollapseIcon } from "./collapsable.icon";

type SectionHeaderProps = {
  sectionIcon: string; // name of the icon from icon fonts (no need to add hicon class to this name)
  sectionTitle: string;
  onClick?: () => void;
  children?: React.ReactNode;
  isCollapsed: boolean;
};

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  sectionIcon,
  sectionTitle,
  onClick,
  children,
  isCollapsed
}: SectionHeaderProps) => {
  let classes = "flex justify-between items-center";
  if (onClick) classes += " cursor-pointer";
  if (!isCollapsed) classes += " mb-2";

  return (
    <div
      className={classes}
      onClick={onClick}
      data-test-id={"section_" + sectionTitle}
    >
      <HeaderTitle icon={sectionIcon} title={sectionTitle} />
      {children}
      {onClick && <ExpandCollapseIcon isCollapsed={isCollapsed} />}
    </div>
  );
};
