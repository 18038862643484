// Invite-admin types

export const INVITE_ADMIN_LOADING = "@invite-admin/INVITE_ADMIN_LOADING";
export const INVITE_ADMIN_SUCCESS = "@invite-admin/INVITE_ADMIN_SUCCESS";
export const INVITE_ADMIN_ERROR = "@invite-admin/INVITE_ADMIN_ERROR";
export const RESET_INVITE_SUCCESS = "@invite-admin/RESET_INVITE_SUCCESS";

export interface InviteAdminState {
  loading: boolean;
  success: boolean;
  error: boolean;
}
