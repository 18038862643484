import { IconButton, PrimaryButton } from "../../shared/button";
import React, { useMemo, useState } from "react";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { Column, TableInstance, useRowSelect, useTable } from "react-table";
import { Table } from "../../shared/table/table";
import { printNiceDayDate } from "../../../helpers/print.nice.day.date";
import { WorkspaceNameCell } from "./workspace.name.cell";
import { LinkAccessCell } from "./link.access.cell";
import { WorkspaceLabelsCell } from "./labels.cell";
import { AvatarsCell } from "../../shared/table/avatars.cell";
import { useSelector } from "react-redux";
import { organizationSelectors } from "../../../state/organization";
import { ShareWorkspaceDialog } from "../../share.dialog/share.workspace.dialog";
import { Spinner } from "../../spinner";
import { isWorkspaceAdministrator } from "../../../helpers/is.workspace.administrator";
import { WorkspacesSort } from "../../../api/types";
import type { Workspace } from "../../../api/types";

const shareWorkspace = (
  label: string,
  showShareDialog: (workspace: Workspace, editUsersPermissions: boolean) => void
): Column<Workspace> => ({
  id: "edit",
  disableSortBy: true,
  width: "5.5rem",
  Cell: ({ row }) => (
    <IconButton
      title={label}
      icon="dispatch"
      disabled={!row.original}
      onClick={() => showShareDialog(row.original, false)}
    />
  )
});

type WorkspaceListProps = {
  sort?: WorkspacesSort;
  setSort?: (sort: WorkspacesSort) => void;
  silentPageRefresh: () => void;
  workspaces: Workspace[];
  isLoading?: boolean;
  exportWorkspaces?: () => void;
  isPreparingExport?: boolean;
  showPermissionsColumns?: boolean;
};

export const WorkspacesList = ({
  sort,
  setSort,
  silentPageRefresh,
  workspaces,
  isLoading,
  exportWorkspaces,
  isPreparingExport,
  showPermissionsColumns = true
}: WorkspaceListProps): JSX.Element => {
  const t = useI18n("WORKSPACES.");
  const userData = useSelector(organizationSelectors.getCurrentUser);
  const isOrgAdminAccessPolicySet = useSelector(
    organizationSelectors.orgPolicyAllOrgAdminsGetAdminAccess
  );
  const [editUsersPermissions, setEditUsersPermissions] = useState(false);
  const [editedWorkspace, setEditedWorkspace] = useState<Workspace | undefined>(
    undefined
  );

  const showShareDialog = (
    workspace: Workspace,
    editUsersPermissions: boolean
  ) => {
    setEditUsersPermissions(editUsersPermissions);
    setEditedWorkspace(workspace);
  };

  const columns = useMemo((): Column<Workspace>[] => {
    const prepareSortMenu = (
      ascending: WorkspacesSort,
      descending: WorkspacesSort
    ) => {
      return sort && setSort ? (
        <div className="w-12 pr-6">
          <div
            data-test-id={ascending}
            onClick={() => setSort(ascending)}
            className={`hicon triangleup cursor-pointer ${
              sort === ascending ? "text-black" : ""
            }`}
          />
          <div
            data-test-id={descending}
            onClick={() => setSort(descending)}
            className={`hicon triangledown cursor-pointer ${
              sort === descending ? "text-black" : ""
            }`}
          />
        </div>
      ) : null;
    };

    // the sum of columnsWidth should be below 100 as we need also space for "share" column added with hook
    const columnsWidth = {
      name: 25,
      created: 10,
      last: 10,
      labels: 20,
      link: 10,
      admin: 15
    };

    const getColumnWidthAsPercentage = (baseRatio: number): string => {
      if (showPermissionsColumns) return `${baseRatio}%`;

      let totalColumnsWidth = 0;

      for (const column in columnsWidth) {
        totalColumnsWidth += columnsWidth[column];
      }

      const optionalColumnsWidth = columnsWidth.link + columnsWidth.admin;
      const enlargementRatio =
        totalColumnsWidth / (totalColumnsWidth - optionalColumnsWidth);
      const calculatedWidth = baseRatio * enlargementRatio;

      return `${calculatedWidth}%`;
    };

    const baseColumns = [
      {
        Header: () => (
          <div className="flex justify-between">
            <div>
              <div>{t("NAME")}</div>
              <div>{t("ID")}</div>
            </div>
            {prepareSortMenu(
              WorkspacesSort.nameAscending,
              WorkspacesSort.nameDescending
            )}
          </div>
        ),
        id: "name",
        width: getColumnWidthAsPercentage(columnsWidth.name),
        Cell: ({ row }) => <WorkspaceNameCell workspace={row.original} />
      },
      {
        Header: () => (
          <div className="flex">
            <div className="flex items-center mr-4">
              <div>{t("CREATED")}</div>
            </div>
            {prepareSortMenu(
              WorkspacesSort.createAscending,
              WorkspacesSort.createDescending
            )}
          </div>
        ),
        id: "created",
        width: getColumnWidthAsPercentage(columnsWidth.created),
        Cell: ({ row }) => (
          <div className="text-colorTextAndIconLighterLightTheme text-sm">
            {printNiceDayDate(row.original.createTime || "", "")}
          </div>
        )
      },
      {
        Header: t("LAST_EDITED"),
        id: "last",
        width: getColumnWidthAsPercentage(columnsWidth.last),
        Cell: ({ row }) => {
          return (
            <div className="text-colorTextAndIconLighterLightTheme text-sm">
              {printNiceDayDate(row.original.lastModified, "")}
            </div>
          );
        }
      },
      {
        Header: t("LABELS"),
        id: "labels",
        width: getColumnWidthAsPercentage(columnsWidth.labels),
        Cell: ({ row }) => (
          <WorkspaceLabelsCell
            labels={row.original.labels ?? []}
            workspaceId={row.original.documentId}
            isAdministrator={isWorkspaceAdministrator(
              userData?.email,
              row.original.administrators,
              isOrgAdminAccessPolicySet
            )}
            refreshPage={silentPageRefresh}
          />
        )
      }
    ];

    // these columns are optional
    const permissionColumns = [
      {
        Header: t("LINK"),
        id: "link",
        width: getColumnWidthAsPercentage(columnsWidth.link),
        Cell: ({ row }) => (
          <LinkAccessCell
            globalAccess={row.original.globalAccess}
            hasPassword={row.original.hasPassword}
          />
        )
      },
      {
        Header: t("ADMINS"),
        id: "admins",
        width: getColumnWidthAsPercentage(columnsWidth.admin),
        Cell: ({ row }) => (
          <AvatarsCell
            usersEmails={row.original.administrators || []}
            editAction={() => showShareDialog(row.original, true)}
          />
        )
      }
    ];

    return [
      ...baseColumns,
      ...(showPermissionsColumns ? permissionColumns : [])
    ];
  }, [
    t,
    isOrgAdminAccessPolicySet,
    userData?.email,
    sort,
    silentPageRefresh,
    setSort,
    showPermissionsColumns
  ]);

  const data = useMemo(() => workspaces, [workspaces]);
  const getRowId = useMemo(
    () => (workspace: Workspace) => workspace.documentId,
    []
  );

  const table: TableInstance<Workspace> = useTable(
    {
      columns,
      data,
      getRowId
    },
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        ...columns,
        shareWorkspace(t("SHARE"), showShareDialog)
      ]);
    }
  );

  return (
    <>
      <div className="flex justify-between items-center mb-8 relative">
        <div>
          <span className="text-xl font-bold pr-4">{t("LIST_TITLE")}</span>
          {exportWorkspaces && (
            <PrimaryButton
              onClick={exportWorkspaces}
              disabled={isPreparingExport || isLoading}
            >
              {t("EXPORT")}
            </PrimaryButton>
          )}
          {isPreparingExport && (
            <>
              {/* hidden div is here to make it sure that tailwind will include in build classes h-4 and w-4 we are dynamically creating in that spinner*/}
              <div className="h-4 w-4 hidden" />
              <Spinner
                classNames="inline-block mt-2 ml-2 absolute"
                size={4}
                useSpinnerClass={false}
              />
            </>
          )}
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Table {...table} useTablePagination={false} />
      )}
      {editedWorkspace && userData && (
        <ShareWorkspaceDialog
          workspace={editedWorkspace}
          userData={userData}
          onClose={() => setEditedWorkspace(undefined)}
          openPermissionsInitially={editUsersPermissions}
          refreshPage={silentPageRefresh}
        />
      )}
    </>
  );
};
