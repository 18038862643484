import React, { ButtonHTMLAttributes } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  additionalStyling?: string;
  additionalChildStyling?: string;
}

export interface IconButtonProps extends ButtonProps {
  icon: string;
}

const baseButton = `
font-normal 
py-1 px-5 
rounded 
h-8 
inline-block 
outline-none 
active:outline-none 
disabled:cursor-not-allowed`;

const primary = `
bg-colorButtonPrimary 
hover:bg-colorButtonPrimaryHover 
disabled:bg-colorButtonPrimaryDisabled 
active:bg-colorButtonPrimaryPress `;

const secondary = `
bg-colorTableBackground 
border 
border-colorPrimary 
disabled:text-colorPrimary `;

const plus = `
font-bold 
align-bottom 
py-0.5 px-1.5 
rounded-lg 
space-x-1.5 
w-auto 
flex `;

const iconButton = `
font-bold 
align-bottom 
py-0.5 px-1.5 
rounded-lg 
space-x-1.5 
w-auto 
flex 
border `;

const text = `
text-colorPrimaryLightTheme
hover:text-colorButtonPrimaryHover
border-none
bg-transparent`;

const Button: React.FC<ButtonProps> = ({
  children,
  additionalStyling = "",
  ...props
}) => {
  return (
    <button type="button" className={baseButton + additionalStyling} {...props}>
      {children ?? props.title}
    </button>
  );
};

export const PrimaryButton: React.FC<ButtonProps> = ({
  additionalStyling = "",
  ...props
}) => {
  return <Button {...props} additionalStyling={primary + additionalStyling} />;
};

export const SecondaryButton: React.FC<ButtonProps> = ({
  additionalStyling = "",
  ...props
}) => {
  return (
    <Button {...props} additionalStyling={secondary + additionalStyling} />
  );
};

export const TextButton: React.FC<ButtonProps> = ({
  additionalStyling = "",
  ...props
}) => {
  return <Button {...props} additionalStyling={text + additionalStyling} />;
};

export const PlusButton: React.FC<ButtonProps> = ({
  additionalStyling = "",
  additionalChildStyling = "plus",
  ...props
}) => {
  return (
    <button type="button" className={plus + additionalStyling} {...props}>
      <span
        className={
          "hicon -mt-0.5 inline-block text-colorButtonPrimary " +
          additionalChildStyling
        }
      />
    </button>
  );
};

export const IconButton: React.FC<IconButtonProps> = ({
  // The Icon button will default to Black text and a grey background
  // use additionalStyling: "border-colorButtonPrimary", additionalChildStyling: "text-colorButtonPrimary", for the blue variation.
  additionalStyling = "",
  additionalChildStyling = "",
  icon,
  disabled,
  ...props
}) => {
  const disabledStyle = disabled
    ? "text-colorTimelineLightTheme cursor-default"
    : "";

  return (
    <button
      type="button"
      disabled={disabled}
      className={
        "bg-colorTableBackground " +
        iconButton +
        additionalStyling +
        disabledStyle
      }
      {...props}
    >
      <span
        className={`hicon ${icon} text-xl -mt-0.5 inline-block ${additionalChildStyling}`}
      />
      <span className={additionalChildStyling}>{props.title}</span>
    </button>
  );
};

export const PageButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <button
      {...props}
      className={
        "font-bold rounded w-6 h-6 align-text-bottom inline-block outline-none active:outline-none disabled:cursor-not-allowed disabled:text-colorPrimary"
      }
    >
      {children}
    </button>
  );
};
