export type WorkspaceTypes = {
  [key: string]: WorkspaceTypeData;
};

export type WorkspaceTypeData = {
  name: string;
  icon: string; // hoylu icons class
};

export const workspaceTypes = {
  // HOYLU modules
  blank: { name: "Blank Workspace", icon: "blank" },
  "business-model": { name: "Business Model Canvas", icon: "business-model" },
  kanban: { name: "Kanban", icon: "kanban" },
  "pi-planning": { name: "PI Planning", icon: "pi-planning" },
  pullplanning: { name: "Pull Planning", icon: "pullplanning" },
  retrospective: { name: "Retrospective", icon: "retrospective" },
  "swot-analysis": { name: "SWOT Analysis", icon: "swot-analysis" },
  "simple-drawing": { name: "Simple Drawing", icon: "simple-drawing" },
  "value-proposition": {
    name: "Value Proposition Canvas",
    icon: "value-proposition"
  },
  "weekly-planner": { name: "Weekly Planner", icon: "weekly-planner" },
  "agile-planning": { name: "Agile Planning", icon: "agile-planning" },
  "supply-chain": { name: "Supply Chain", icon: "supply-chain" },

  // legacy types
  sketch: { name: "Sketch", icon: "sketch" },
  flow: { name: "Flow", icon: "flow" },

  // unrecognized
  default: { name: "", icon: "default" }
};
