import { combineReducers } from "redux";
import groups from "./groups";
import session from "./session/reducer";
import inviteAdmin from "./invite-admin";
import organization from "./organization";
import licenses from "./licenses";
import accounts from "./accounts";
import featureFlags from "./featureFlags";
import jira from "./jira";
import agility from "./agility";
import projects from "./projects";

const rootReducer = combineReducers({
  groups,
  session,
  inviteAdmin,
  organization,
  licenses,
  accounts,
  featureFlags,
  jira,
  agility,
  projects
});

export default rootReducer;
