import { httpMethods } from "./http-methods";
import globalHeaders from "./global-headers";
import config from "../configuration";
import handleFetchRequest from "./handle-fetch-request";
import { ResponsePromise } from "./types";
import { ProductInfo, SubscriptionInfo } from "../state/licenses/types";
import { BillingPath, ProfilePanelPath } from "../routes";

export function getProducts(): ResponsePromise<ProductInfo[]> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].licensing}/api/v2/subscription/products`, {
      method: httpMethods.GET,
      headers: globalHeaders
    })
  );
}

export function getCustomerPortalSessionUrl(
  customerId: string
): ResponsePromise<string> {
  const returnUrl = `${config["serviceConfig"].management_portal}/${ProfilePanelPath}/${BillingPath}`;
  return handleFetchRequest(() =>
    fetch(
      `${
        config["serviceConfig"].licensing
      }/api/v2/subscription/customer-portal-session/${customerId}?returnUrl=${encodeURIComponent(
        returnUrl
      )}`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
}

export function getCheckoutSessionUrl(
  priceId: string
): ResponsePromise<string> {
  const returnUrl = `${config["serviceConfig"].management_portal}/${ProfilePanelPath}/${BillingPath}`;
  return handleFetchRequest(() =>
    fetch(
      `${
        config["serviceConfig"].licensing
      }/api/v2/subscription/create-checkout-session?priceId=${priceId}&returnUrl=${encodeURIComponent(
        returnUrl
      )}`,
      {
        method: httpMethods.GET,
        headers: globalHeaders
      }
    )
  );
}

export function getSubscriptions(
  signal?: AbortSignal
): ResponsePromise<SubscriptionInfo[]> {
  return handleFetchRequest(() =>
    fetch(`${config["serviceConfig"].licensing}/api/v2/subscription/my`, {
      method: httpMethods.GET,
      headers: globalHeaders,
      signal
    })
  );
}
