import type { Project } from "../../../state/projects/types";
import { PrimaryButton } from "../../shared/button";
import { ProjectLimitInformation } from "../project.editor/project.limit.information";
import React, { useMemo } from "react";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { useSelector } from "react-redux";
import { projectsSelectors } from "../../../state/projects";
import { Icon } from "../../shared/icon";
import { printNiceDayDate } from "../../../helpers/print.nice.day.date";
import { useCanEditProject } from "../../../helpers/hooks/useCanEditProject";

type ProjectInfoProps = {
  project: Project;
  edit: () => void;
  workspacesQuantity: number | undefined;
};

export const ProjectInfo = ({
  project,
  edit,
  workspacesQuantity
}: ProjectInfoProps) => {
  const t = useI18n("PROJECTS.");
  const collaborators = useSelector(
    projectsSelectors.findCollaboratorsByProject(project.id)
  );
  const canEditProject = useCanEditProject(project);
  const datesFieldValue = useMemo(
    () =>
      `${printNiceDayDate(project.startDate, "")} - ${printNiceDayDate(
        project.endDate,
        ""
      )}`,
    [project.startDate, project.endDate]
  );

  return (
    <div className="flex flex-col" data-test-id="project_info">
      <div className="font-bold self-center">{project.name}</div>
      <Icon
        icon={project.appearanceProperties?.icon ?? ""}
        color={project.appearanceProperties?.color}
        additionalStyles="block self-center flex justify-center  h-32 w-32 text-9xl items-center"
      />
      <InfoField title={t("STATUS")}>
        <span>{project.status}</span>
      </InfoField>
      <InfoField title={t("DURATION")}>
        <span>{datesFieldValue}</span>
      </InfoField>
      <InfoField title={t("USER_COUNT")}>
        <ProjectLimitInformation
          limit={project.maxProjectUsers}
          currentAmount={collaborators.length}
        />
      </InfoField>
      <InfoField title={t("WORKSPACES_COUNT")}>
        <ProjectLimitInformation
          limit={project.maxProjectWorkspaces}
          currentAmount={workspacesQuantity || 0}
        />
      </InfoField>
      <InfoField title={t("DESCRIPTION")}>
        <div className="max-h-40 overflow-y-auto customScrollbar">
          {project.description}
        </div>
      </InfoField>
      <div className="flex justify-center py-4">
        <PrimaryButton onClick={edit} disabled={!canEditProject}>
          Edit
        </PrimaryButton>
      </div>
    </div>
  );
};

type InfoFieldProps = {
  title: string;
  children: React.ReactNode;
};

const InfoField = ({ title, children }: InfoFieldProps) => {
  return (
    <div className="pt-1">
      <div className="font-bold">{title}</div>
      {children}
    </div>
  );
};
