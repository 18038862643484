export interface InputTopLabelProps {
  text: string;
  error?: string;
}

export const InputTopLabel = ({ text, error = "" }) => {
  return (
    <label
      className={`text-${
        error ? "colorWarning" : "text-colorPrimaryLightTheme"
      } block text-xxs`}
    >
      {error || text}
    </label>
  );
};
