export const store = (key: string, value: string): void => {
  value && value !== "undefined" && localStorage.setItem(key, value);
};

export const append = (data: object): void => {
  Object.keys(data).forEach(key => {
    data[key] && localStorage.setItem(key, data[key]);
  });
};

export const get = (key: string): any => {
  const result = localStorage.getItem(key);
  switch (result?.[key]) {
    case "false":
      return false;
    case "null":
      return null;
    case "undefined":
      return undefined;
    default:
      return result;
  }
};

export function getMany(...keys: string[]) {
  return keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: get(key)
    }),
    {}
  );
}

export const clear = (key: string) => localStorage.removeItem(key);

export const clearMany = (...keys: string[]) => keys.forEach(clear);
