import { Column } from "react-table";
import { IndeterminateCheckbox } from "../../accounts-list/indeterminate-checkbox";
import React from "react";

export function generateSelectionColumn<T extends Object>(
  limitToggleAllToPage = true
): Column<T> {
  return {
    id: "selection",
    disableSortBy: true,
    width: "2rem",
    Header: ({
      getToggleAllPageRowsSelectedProps,
      getToggleAllRowsSelectedProps
    }) => (
      <div>
        <IndeterminateCheckbox
          {...(limitToggleAllToPage
            ? { ...getToggleAllPageRowsSelectedProps() }
            : { ...getToggleAllRowsSelectedProps() })}
        />
      </div>
    ),
    Cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      </div>
    )
  };
}
