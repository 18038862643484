import { combineEpics } from "redux-observable";
import {
  getUserProfileEpic,
  loginEpic,
  logoutEpic,
  getUserOrgInfoEpic,
  refreshTokenEpic,
  requestDeleteEpic
} from "./session/epics";
import {
  editMemberDepartmentEpic,
  organizationInfoEpic,
  organizationPoliciesEpic,
  removeMembersEpic,
  saveOrganizationDepartmentsEpic,
  saveOrganizationPoliciesEpic
} from "./organization/epics";
import {
  getAvailableEpic,
  getLicenseInfoEpic,
  setLicenseToUserEpic,
  takeLicenseFromUserEpic
} from "./licenses/epics";
import { sendADAdminInviteEpic } from "./invite-admin/epics";
import { getGroupsEpic, updateGroupLicenseEpic } from "./groups/epics";
import { dismissErrorEpic, errorsEpic } from "./errors/errors.epic";
import { getJiraConnectionsEpic } from "./jira/epics";
import { getAgilityConnectionsEpic } from "./agility/epics";
import {
  getCollaboratorsEpic,
  getAllOrgProjectsEpic,
  getMyProjectsEpic,
  updateProjectEpic
} from "./projects/epics";

export const rootEpic = combineEpics(
  loginEpic,
  refreshTokenEpic,
  logoutEpic,
  getUserProfileEpic,
  getUserOrgInfoEpic,
  requestDeleteEpic,
  organizationInfoEpic,
  organizationPoliciesEpic,
  saveOrganizationPoliciesEpic,
  saveOrganizationDepartmentsEpic,
  removeMembersEpic,
  editMemberDepartmentEpic,
  getAvailableEpic,
  setLicenseToUserEpic,
  takeLicenseFromUserEpic,
  sendADAdminInviteEpic,
  updateGroupLicenseEpic,
  getGroupsEpic,
  errorsEpic,
  dismissErrorEpic,
  getJiraConnectionsEpic,
  getLicenseInfoEpic,
  getAgilityConnectionsEpic,
  getAllOrgProjectsEpic,
  getMyProjectsEpic,
  getCollaboratorsEpic,
  updateProjectEpic
);
