import { AppContext, ManagementPortalContext } from "../../context";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { sessionSelectors } from "../../state/session";
import logo from "../../assets/img/logo.svg";
import { UserPanel } from "./user.panel";
import { organizationSelectors } from "../../state/organization";
import { Link, useLocation } from "react-router-dom";
import { useI18n } from "../../helpers/hooks/useI18n";
import { ManagementPanelPath, ProfilePanelPath } from "../../routes";

export const Header = (): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);
  const t = useI18n("USER_PANEL.");
  const isAuthenticated: boolean = useSelector(
    sessionSelectors.isAuthenticated
  );

  const isLoading = useSelector(organizationSelectors.isLoading);
  const organization = useSelector(organizationSelectors.organization);

  const userId = useSelector(sessionSelectors.userId);
  const user = useSelector(sessionSelectors.loggedInUserData);

  const location = useLocation();

  return (
    <header className="flex items-start justify-center">
      <div className="container p-4 inline-flex content-center h-16 border-b-2 border-colorTableHeadPortalBackground">
        <div className="inline-flex flex-1 content-center justify-start space-x-4">
          <img className="object-scale-down" src={logo} alt="icon" />
          <div className="inline-block self-center text-2xl">
            <span className="font-bold hidden lg:inline">
              {context.Localized.string("HEADER.TITLE")}
            </span>
            {organization.name && (
              <>
                <span className="m-2 hidden lg:inline">-</span>
                {organization.name}
              </>
            )}
          </div>
        </div>
        {isAuthenticated && (
          <div className="inline-flex flex-grow-0 space-x-10">
            {location.pathname.startsWith("/" + ManagementPanelPath) ? (
              <Link
                to={ProfilePanelPath}
                className="self-center font-bold text-base"
              >
                {t("MY_PROFILE")}
              </Link>
            ) : (
              <Link
                to={ManagementPanelPath}
                className="self-center font-bold text-base"
              >
                {t("ORGANIZATION")}
              </Link>
            )}
          </div>
        )}

        {isAuthenticated && !isLoading && userId && <UserPanel user={user} />}
      </div>
    </header>
  );
};
