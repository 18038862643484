import { Connection } from "../../../state/jira/types";
import { AgilityConnectionDetails } from "./connection-details";

export type AgilityConnectionsProps = {
  connections: Connection[];
};

export const AgilityConnections = ({ connections }): JSX.Element => {
  return (
    <ul>
      {connections.map((connection, index) => {
        return (
          <AgilityConnectionDetails
            key={connection.name + index.toString()}
            connection={connection}
          />
        );
      })}
    </ul>
  );
};
