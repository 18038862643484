import { Epic } from "redux-observable";
import { ApiResponse, ApiType } from "../../api/types";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import { from, of } from "rxjs";
import * as types from "../projects/types";
import {
  getCollaboratorsAsync,
  getMyProjectsAsync,
  getAllOrgProjectsAsync,
  updateProjectAsync
} from "./actions";

export const getAllOrgProjectsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { projects }: ApiType) => {
  return action$.pipe(
    filter(isActionOf(getAllOrgProjectsAsync.request)),
    mergeMap(action =>
      from(projects.getAllOrgProjects(action.payload)).pipe(
        map((response: ApiResponse<types.Project[]>) => {
          if (response.data) {
            return getAllOrgProjectsAsync.success(response.data);
          }

          return getAllOrgProjectsAsync.failure(
            response.error ? response.error : "No data received"
          );
        }),
        catchError(error =>
          of(getAllOrgProjectsAsync.failure(error.toString()))
        )
      )
    )
  );
};

export const getMyProjectsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { projects }: ApiType) => {
  return action$.pipe(
    filter(isActionOf(getMyProjectsAsync.request)),
    mergeMap(_ =>
      from(projects.getMyProjects()).pipe(
        map((response: ApiResponse<types.Project[]>) => {
          if (response.data) {
            return getMyProjectsAsync.success(response.data);
          }
          if (response.status === 204) {
            return getMyProjectsAsync.success([]);
          }

          return getMyProjectsAsync.failure(
            response.error ? response.error : "No data received"
          );
        }),
        catchError(error => of(getMyProjectsAsync.failure(error.toString())))
      )
    )
  );
};

export const getCollaboratorsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { projects }: ApiType) => {
  return action$.pipe(
    filter(isActionOf(getCollaboratorsAsync.request)),
    mergeMap(action =>
      from(projects.getCollaborators(action.payload)).pipe(
        map((response: ApiResponse<types.Collaborator[]>) => {
          if (response.data) {
            return getCollaboratorsAsync.success({
              [action.payload]: response.data
            });
          }

          return getCollaboratorsAsync.failure(
            response.error ? response.error : "No data received"
          );
        }),
        catchError(error => of(getCollaboratorsAsync.failure(error.toString())))
      )
    )
  );
};

export const updateProjectEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { projects }: ApiType) => {
  return action$.pipe(
    filter(isActionOf(updateProjectAsync.request)),
    mergeMap(action =>
      from(
        projects.updateProject(action.payload.updates, action.payload.projectId)
      ).pipe(
        map((response: ApiResponse<types.Project>) => {
          if (response.data) {
            return updateProjectAsync.success(response.data);
          }

          return updateProjectAsync.failure(
            response.error ? response.error : "Updating project failed"
          );
        }),
        catchError(error => of(updateProjectAsync.failure(error.toString())))
      )
    )
  );
};
