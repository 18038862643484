import { useContext } from "react";
import { AppContext } from "../../../context";
import React from "react";
import { Header } from "../header";
import { Tile } from "../../tile";
import { OrganizationWorkspaces } from "../../workspaces/organization.workspaces";

export const Workspaces = () => {
  const context = useContext(AppContext);

  return (
    <div>
      <Header title={context.Localized.string("WORKSPACES.TITLE")} />
      <Tile className="flex-grow">
        <OrganizationWorkspaces />
      </Tile>
    </div>
  );
};
