import { useSelector } from "react-redux";
import { sessionSelectors } from "../../../state/session";
import { Tile } from "../../tile";
import { useState } from "react";
import { assignColor } from "../../avatar/avatar";
import { Header } from "../../navbar/header";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { Marble, getMarbleContent } from "@hoylu/client-common";
import { SecondaryButton } from "../../shared/button";
import { ConfirmAccountDelete } from "./confirm.account.delete";
import { handleLogin, prepareSessionData } from "../../../api/auth";
import { showError, showErrorToast } from "../../../helpers/show.toast.helpers";

export const ProfileInfo: React.FC = () => {
  const t = useI18n("PROFILE.");
  const user = useSelector(sessionSelectors.loggedInUserData);
  const orgInfo = useSelector(sessionSelectors.userOrganization);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);

  const openConfirmDeleteModal = async () => {
    try {
      const response = await handleLogin();
      const { userId } = prepareSessionData(response.token);
      if (userId && userId === user!.uid) {
        setShowDeleteAccount(true);
      } else {
        showError(t("AUTHENTICATION_FAILED"));
      }
    } catch (error) {
      showErrorToast(t("AUTHENTICATION_FAILED"), error);
      console.warn(error);
    }
  };

  if (!user) return null;

  const { name: organizationName } = orgInfo ?? {};
  const { firstName, lastName, email, companyName, department } = user;

  const initials = getMarbleContent(firstName, lastName);
  const marbleColor = assignColor(initials);
  return (
    <div className="container h-full flex flex-col overflow-y-auto">
      <Header title={t("USER_PROFILE")} />
      <div className="flex flex-col sm:flex-row sm:justify-center">
        <Tile>
          <div className="h-96 w-72 flex flex-col justify-center items-center gap-2">
            <Marble
              title=""
              style={{
                backgroundColor: marbleColor,
                width: "6rem",
                height: "6rem",
                marginBottom: "2rem"
              }}
            >
              <span className={"self-center font-black text-3xl"}>
                {initials}
              </span>
            </Marble>
            <div className="font-bold">{`${firstName} ${lastName}`}</div>
            <div>{organizationName}</div>
            <div className="h-28 flex flex-col justify-end">
              <SecondaryButton onClick={openConfirmDeleteModal}>
                {t("DELETE_ACCOUNT")}
              </SecondaryButton>
            </div>
          </div>
        </Tile>

        <Tile className="flex-grow p-10 max-w-6xl">
          <span className="text-lg font-bold">{t("DETAILS")}</span>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 gap-x-10 m-4">
            <div className="flex flex-col md:flex-row justify-between xl:order-1">
              <div>{t("FIRST_NAME")}</div>
              <div>{firstName}</div>
            </div>
            <div className="flex flex-col md:flex-row justify-between xl:order-3">
              <div>{t("LAST_NAME")}</div>
              <div>{lastName}</div>
            </div>
            <div className="flex flex-col md:flex-row justify-between xl:order-5">
              <div>{t("EMAIL")}</div>
              <div>{email}</div>
            </div>
            <div className="flex flex-col md:flex-row justify-between xl:order-2">
              <div>{t("COMPANY")}</div>
              <div>{companyName}</div>
            </div>
            <div className="flex flex-col md:flex-row justify-between xl:order-4">
              <div>{t("DEPARTMENT")}</div>
              <div>{department}</div>
            </div>
            <div className="flex flex-col md:flex-row justify-between xl:order-6">
              <div>{t("ORGANIZATION")}</div>
              <div>{organizationName}</div>
            </div>
          </div>
        </Tile>
      </div>
      {showDeleteAccount && (
        <ConfirmAccountDelete
          user={user}
          onCancel={() => setShowDeleteAccount(false)}
        />
      )}
    </div>
  );
};
