import { Epic } from "redux-observable";
import {
  ApiResponse,
  ApiType,
  HoyluAzureGroupDataResponse
} from "../../api/types";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { sessionSelectors } from "../session";
import { getGroupsAsync, updateGroupLicenseAsync } from "./actions";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import { groupStartsWith } from "./selectors";

export const updateGroupLicenseEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  ApiType
> = (action$, state$, { organization }) => {
  return action$.pipe(
    filter(isActionOf(updateGroupLicenseAsync.request)),
    mergeMap(action => {
      const orgId = sessionSelectors.orgId(state$.value);
      const { groupId, enable } = action.payload;
      return from(
        organization[enable ? "addGroups" : "removeGroups"](orgId, [groupId])
      ).pipe(
        map((response: ApiResponse<number>) => {
          if (response.error) {
            return updateGroupLicenseAsync.failure(groupId);
          }

          return updateGroupLicenseAsync.success({ groupId, isActive: enable });
        }),
        catchError(error => of(updateGroupLicenseAsync.failure(groupId)))
      );
    })
  );
};

export const getGroupsEpic: Epic<RootAction, RootAction, RootState, ApiType> = (
  action$,
  state$,
  { organization }
) => {
  return action$.pipe(
    filter(isActionOf(getGroupsAsync.request)),
    mergeMap(() => {
      const orgId = sessionSelectors.orgId(state$.value);
      const startsWith = groupStartsWith(state$.value) || "";
      return from(organization.getAvailableSSOGroups(orgId, startsWith)).pipe(
        map((response: ApiResponse<HoyluAzureGroupDataResponse>) => {
          const { data, error } = response;
          if (error || !data || !Array.isArray(data.groupList)) {
            return getGroupsAsync.failure(error || "Returned invalid data");
          }

          const { nextPageKey, groupList } = data;

          return getGroupsAsync.success({ nextPageKey, groups: groupList });
        }),
        catchError(error => of(getGroupsAsync.failure(error.toString())))
      );
    })
  );
};
