// Groups reducer
import { GroupStateMap, GroupsState } from "./types";
import update from "immutability-helper";
import { createReducer } from "typesafe-actions";
import {
  getGroupsAsync,
  updateGroupLicenseAsync,
  updateStartsWithQuery
} from "./actions";
import { getOrganizationInfoAsync } from "../organization/actions";

export const initialState: GroupsState = {
  allGroups: {} as GroupStateMap,
  hasMoreGroups: false,
  groupStartsWith: "",
  activeGroupIds: [],
  loading: false,
  error: false
};

const skipIfGroupDoesNotExist = (
  state: GroupStateMap,
  groupId: string
): GroupStateMap | undefined => (state[groupId] ? undefined : state);

export default createReducer(initialState)
  .handleAction(updateStartsWithQuery, (state, action) => ({
    ...state,
    groupStartsWith: action.payload
  }))
  .handleAction(updateGroupLicenseAsync.success, (state, action) => {
    const groupId = action.payload.groupId;
    let allGroups: GroupStateMap =
      skipIfGroupDoesNotExist(state.allGroups, groupId) ??
      update(state.allGroups, {
        [groupId]: {
          updating: { $set: false }
        }
      });
    let activeGroupIds = action.payload.isActive
      ? [...state.activeGroupIds, action.payload.groupId]
      : state.activeGroupIds.filter(id => id !== action.payload.groupId);
    return { ...state, allGroups, activeGroupIds };
  })
  .handleAction(updateGroupLicenseAsync.request, (state, action) => {
    const groupId = action.payload.groupId;
    let allGroups: GroupStateMap =
      skipIfGroupDoesNotExist(state.allGroups, groupId) ??
      update(state.allGroups, {
        [groupId]: {
          updating: { $set: true },
          error: { $set: false }
        }
      });
    return { ...state, allGroups };
  })
  .handleAction(updateGroupLicenseAsync.failure, (state, action) => {
    const groupId = action.payload;
    let allGroups: GroupStateMap =
      skipIfGroupDoesNotExist(state.allGroups, groupId) ??
      update(state.allGroups, {
        [groupId]: {
          updating: { $set: false },
          error: { $set: true }
        }
      });
    return { ...state, allGroups };
  })
  .handleAction(getGroupsAsync.request, (state, action) =>
    update(state, {
      loading: { $set: true },
      error: { $set: false }
    })
  )
  .handleAction(getGroupsAsync.success, (state, action) => {
    const newGroups = action.payload.groups.reduce(
      (groups, item) => ({
        ...groups,
        [item.id]: {
          id: item.id,
          displayName: item.displayName,
          error: false,
          updating: false
        }
      }),
      {}
    );
    return update(state, {
      loading: { $set: false },
      hasMoreGroups: {
        $set: !!action.payload.nextPageKey || state.hasMoreGroups
      },
      allGroups: { $set: newGroups }
    });
  })
  .handleAction(getGroupsAsync.failure, (state, action) =>
    update(state, {
      loading: { $set: false },
      error: { $set: true }
    })
  )
  .handleAction(getOrganizationInfoAsync.success, (state, action) => ({
    ...state,
    activeGroupIds: action.payload.hoyluUserGroups
  }));
