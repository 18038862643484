import { useCallback, useContext } from "react";
import { AppContext } from "../../context";

export type LocalizingFunction = (
  address: string,
  ...parameters: any
) => string;

/** Get a localization function. To be used in React Functional Components */
export function useI18n(prefix: string = ""): LocalizingFunction {
  const context = useContext(AppContext);
  return useCallback((addr: string, ...parameters: any[]) => {
    return context.Localized.string(prefix + addr, ...parameters);
  }, []);
}
