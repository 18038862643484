// Invite-admin actions
import * as types from "./types";
import { createAction, createAsyncAction } from "typesafe-actions";

export function sendADAdminInvite(email: string) {
  return sendADAdminInviteAsync.request(email);
}

export const sendADAdminInviteAsync = createAsyncAction(
  types.INVITE_ADMIN_LOADING,
  types.INVITE_ADMIN_SUCCESS,
  types.INVITE_ADMIN_ERROR
)<string, undefined, string>();

export const resetInviteSuccess = createAction(types.RESET_INVITE_SUCCESS)();
