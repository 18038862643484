import * as types from "./types";
import { LicenseInfo, License } from "./types";
import { UserData } from "../accounts/types";
import { createAsyncAction } from "typesafe-actions";

export function getAvailable(orgId: string) {
  return getAvailableLicencesAsync.request(orgId);
}

export function setLicenseToUser(licenseId: string, userId: string) {
  return setLicenseToUserAsync.request({ licenseId, userId });
}

export function takeFromUser(user: UserData) {
  return takeLicenseFromUserAsync.request(user);
}

export const getAvailableLicencesAsync = createAsyncAction(
  types.GET_AVAILABLE_LOADING,
  types.GET_AVAILABLE_SUCCESS,
  types.GET_AVAILABLE_ERROR
)<string, License[], string>();

export const setLicenseToUserAsync = createAsyncAction(
  types.SET_LICENSE_TO_USER_LOADING,
  types.SET_LICENSE_TO_USER_SUCCESS,
  types.SET_LICENSE_TO_USER_ERROR
)<{ licenseId: string; userId: string }, undefined, string>();

export const takeLicenseFromUserAsync = createAsyncAction(
  types.TAKE_LICENSE_FROM_USER_LOADING,
  types.TAKE_LICENSE_FROM_USER_SUCCESS,
  types.TAKE_LICENSE_FROM_USER_ERROR
)<UserData, undefined, string>();

export const getLicenseInfoAsync = createAsyncAction(
  types.GET_LICENSE_INFO_LOADING,
  types.GET_LICENSE_INFO_SUCCESS,
  types.GET_LICENSE_INFO_ERROR
)<undefined, LicenseInfo, string>();
