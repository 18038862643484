import React from "react";
import { Label } from "../../shared/label";
import { useI18n } from "../../../helpers/hooks/useI18n";
import { GlobalAccess } from "../../../api/types";

type LinkAccessCellType = {
  globalAccess: GlobalAccess;
  hasPassword: boolean;
};

type AccessLabelProps = {
  name: string;
  icon: string; // hicon class
  color: string; // tailwind color class
};

export const LinkAccessCell = ({
  globalAccess,
  hasPassword
}: LinkAccessCellType): JSX.Element => {
  const t = useI18n("WORKSPACES.");
  let labelProps: AccessLabelProps;

  switch (globalAccess) {
    case "READ":
      labelProps = {
        name: t("READ"),
        icon: "show",
        color: "bg-colorPermissionsRead"
      };
      break;
    case "WRITE":
      labelProps = { name: t("EDIT"), icon: "pen", color: "bg-colorSuccess" };
      break;
    case "NONE":
      labelProps = {
        name: t("NO"),
        icon: "cancelsign",
        color: "bg-colorPermissionsNo"
      };
      break;
  }

  return (
    <div className="flex flex-col gap-1">
      {labelProps && (
        <Label
          label={labelProps.name}
          icon={labelProps.icon}
          backgroundColor={labelProps.color}
        />
      )}
      {hasPassword && (
        <Label
          label={t("PASSWORD")}
          icon="exclamationsign"
          backgroundColor="bg-colorBorder"
        />
      )}
    </div>
  );
};
