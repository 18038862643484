import { createAsyncAction } from "typesafe-actions";
import * as types from "./types";

/**
 * Only for org admins.
 * */
export const getAllOrgProjectsAsync = createAsyncAction(
  types.GET_ALL_ORG_PROJECTS_LOADING,
  types.GET_ALL_ORG_PROJECTS_SUCCESS,
  types.GET_ALL_ORG_PROJECTS_ERROR
)<string, types.Project[], string>();

/**
 * Fetches projects for any user, also for org admin
 * */
export const getMyProjectsAsync = createAsyncAction(
  types.GET_MY_PROJECTS_LOADING,
  types.GET_MY_PROJECTS_SUCCESS,
  types.GET_MY_PROJECTS_ERROR
)<undefined, types.Project[], string>();

export const getCollaboratorsAsync = createAsyncAction(
  types.GET_COLLABORATORS_LOADING,
  types.GET_COLLABORATORS_SUCCESS,
  types.GET_COLLABORATORS_ERROR
)<string, types.ProjectsCollaborators, string>();

export const updateProjectAsync = createAsyncAction(
  types.UPDATE_PROJECT_LOADING,
  types.UPDATE_PROJECT_SUCCESS,
  types.UPDATE_PROJECT_ERROR
)<
  { updates: Partial<types.Project>; projectId: string },
  types.Project,
  string
>();
