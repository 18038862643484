import React from "react";
import { Label as LabelType } from "../../../api/types";
import { Label } from "../../shared/label";
import { updateLabels } from "../../../api/workspaces";
import { showErrorToast } from "../../../helpers/show.toast.helpers";

type WorkspaceLabelsCellProps = {
  labels: LabelType[];
  workspaceId: string;
  isAdministrator: boolean;
  refreshPage: () => void;
};

export const WorkspaceLabelsCell = ({
  labels,
  workspaceId,
  isAdministrator,
  refreshPage
}: WorkspaceLabelsCellProps) => {
  const removeLabel = async (name: string) => {
    const filteredLabels = labels.filter(label => label.name !== name);
    const response = await updateLabels(workspaceId, filteredLabels);

    if (response.error)
      showErrorToast("Failed to remove label ", response.error);

    if (response.data) {
      refreshPage();
    }
  };

  return (
    <div className="flex gap-1 max-h-11 flex-wrap overflow-hidden">
      {Array.isArray(labels) &&
        labels.map(label => (
          <Label
            key={label.name}
            label={label.name}
            backgroundColor="bg-colorButtonPrimary"
            onRemove={
              isAdministrator ? () => removeLabel(label.name) : undefined
            }
          />
        ))}
    </div>
  );
};
