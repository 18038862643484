import React from "react";
import { PageButton } from "../../shared/button";
import { MAX_LIMIT_OF_WORKSPACES_PER_REQUEST } from "../../../api/workspaces";
import { PageSizeSelect } from "../../shared/table/page.size.select";

type WorkspacesPaginationProps = {
  pageSize: number;
  setPageSize: (number: number) => void;
  nextPage: () => void;
  canNextPage: boolean;
  getFirstPage: () => void;
  pageNumber: number;
  previousPage: () => void;
};

export const WorkspacesPagination = ({
  pageSize,
  setPageSize,
  nextPage,
  canNextPage,
  getFirstPage,
  pageNumber,
  previousPage
}: WorkspacesPaginationProps): JSX.Element => {
  // last value shouldn't exceed MAX_LIMIT_OF_WORKSPACES_PER_REQUEST as it will be ignored and MAX_LIMIT_OF_WORKSPACES_PER_REQUEST will be fetched instead
  const pageSizeOptions = [10, 20, 30, 40, MAX_LIMIT_OF_WORKSPACES_PER_REQUEST];
  const canFetchPrevious = pageNumber < 2;

  return (
    <div className="flex flex-wrap justify-end space-x-1 leading-tight">
      <div className="flex-grow flex justify-center space-x-2">
        <PageButton onClick={() => getFirstPage()} disabled={canFetchPrevious}>
          <span data-test-id="first" className={"hicon doublearrowleft"} />
        </PageButton>
        <PageButton onClick={() => previousPage()} disabled={canFetchPrevious}>
          <span data-test-id="previous" className={"hicon left text-sm"} />
        </PageButton>
        <span data-test-id="page-number">{pageNumber}</span>
        <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
          <span data-test-id="next" className={"hicon right text-sm"} />
        </PageButton>
      </div>
      <PageSizeSelect
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageSizeOptions={pageSizeOptions}
      />
    </div>
  );
};
