import { FullPageTile } from "../../tile";
import { useEffect, useState } from "react";
// import { EulaAgreement } from "./eula-agreement";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../spinner";
import { UpsellMessage } from "../../upsell/upsell-message";
import { agilityActions, agilitySelectors } from "../../../state/agility";
import { EulaAgreement } from "../eula-agreement";
import { AgilityConnections } from "./agility-connections";
import { AgilityConnectionForm } from "./connection-form";

type AgilityIntegrationProps = {
  hasAccessToAgility: boolean;
};

export const AgilityIntegration = ({
  hasAccessToAgility
}: AgilityIntegrationProps) => {
  const dispatch = useDispatch();
  const [isEulaAccepted, setIsEulaAccepted] = useState(false);
  const isLoading = useSelector(agilitySelectors.loading);
  const connections = useSelector(agilitySelectors.getConnections);

  useEffect(() => {
    dispatch(agilityActions.getAgilityConnectionsAsync.request());
  }, [dispatch]);

  useEffect(() => {
    if (connections.length > 0) {
      setIsEulaAccepted(true);
    }
  }, [connections.length]);

  return hasAccessToAgility ? (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          {!isEulaAccepted && (
            <FullPageTile>
              <EulaAgreement
                onAcceptance={() => setIsEulaAccepted(true)}
                serviceName="Agility"
              />
            </FullPageTile>
          )}
          {isEulaAccepted && (
            <>
              <FullPageTile>
                <AgilityConnectionForm />
              </FullPageTile>
              {!!connections.length && (
                <AgilityConnections connections={connections} />
              )}
            </>
          )}
        </div>
      )}
    </>
  ) : (
    <FullPageTile>
      <UpsellMessage />
    </FullPageTile>
  );
};
