import { useSelector } from "react-redux";
import { sessionSelectors } from "../../state/session";
import { organizationSelectors } from "../../state/organization";
import { PROJECT_ADMIN_ID } from "../../api/collaborators.roles.ids";
import { Project } from "../../state/projects/types";

export const useCanEditProject = (project: Project | undefined) => {
  const isAdmin = useSelector(sessionSelectors.isAdmin);
  const orgId = useSelector(organizationSelectors.uid);

  if (!project) return false;

  return (
    (isAdmin && project.ownerId === orgId) ||
    project.userRole?.roleId === PROJECT_ADMIN_ID
  );
};
