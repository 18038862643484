// Invite-admin reducer
import { InviteAdminState } from "./types";
import update from "immutability-helper";
import { createReducer } from "typesafe-actions";
import { resetInviteSuccess, sendADAdminInviteAsync } from "./actions";

export const initialState: InviteAdminState = {
  loading: false,
  success: false,
  error: false
};

export default createReducer(initialState)
  .handleAction(sendADAdminInviteAsync.request, (state, action) =>
    update(state, {
      loading: { $set: true },
      error: { $set: false }
    })
  )
  .handleAction(sendADAdminInviteAsync.success, (state, action) =>
    update(state, {
      loading: { $set: false },
      success: { $set: true },
      error: { $set: false }
    })
  )
  .handleAction(sendADAdminInviteAsync.failure, (state, action) =>
    update(state, {
      loading: { $set: false },
      success: { $set: false },
      error: { $set: true }
    })
  )
  .handleAction(resetInviteSuccess, (state, action) =>
    update(state, {
      success: { $set: false }
    })
  );
