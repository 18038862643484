import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const contextClass = {
  success: "bg-colorSuccess",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300"
};

export const Toaster: React.FC = () => (
  <ToastContainer
    toastClassName={({ type } = {}) =>
      contextClass[type || "default"] +
      " relative flex p-1 mb-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
    }
  />
);
