import { FC, useEffect } from "react";
import { Header, Login } from "./components";
import { useSelector, useDispatch } from "react-redux";
import { sessionActions, sessionSelectors } from "./state/session";
import { ManagementPanel } from "./components/managementPanel/management.panel";
import { getUserProfileAsync, refreshToken } from "./state/session/actions";
import { Footer } from "./components/footer";
import { Toaster } from "./components/toaster";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ConsentRoutes } from "./components/consent/consent";
import { ProfilePanel } from "./components/profilePanel/profile.panel";
import { ManagementPanelPath, ProfilePanelPath } from "./routes";
import { getMyProjectsAsync } from "./state/projects/actions";
import { organizationActions } from "./state/organization";

function App() {
  const dispatch = useDispatch();
  const orgId = useSelector(sessionSelectors.orgId);
  const isLoggedIn = useSelector(sessionSelectors.isLoggedIn);
  const isAuthenticated: boolean = useSelector(
    sessionSelectors.isAuthenticated
  );
  const userId = useSelector(sessionSelectors.userId);
  const isAdmin = useSelector(sessionSelectors.isAdmin);

  useEffect(() => {
    if (isLoggedIn && !isAuthenticated) {
      dispatch(refreshToken());
    }
  }, [dispatch, isLoggedIn, isAuthenticated]);

  useEffect(() => {
    if (isLoggedIn && isAuthenticated && userId) {
      dispatch(getUserProfileAsync.request(userId));
    }
  }, [dispatch, isLoggedIn, isAuthenticated, userId]);

  useEffect(() => {
    if (isAuthenticated && !isAdmin) {
      //determine isProjectAdmin for child routes
      dispatch(getMyProjectsAsync.request());
    }
  }, [dispatch, isAuthenticated, isAdmin]);

  useEffect(() => {
    if (isAdmin && orgId) {
      dispatch(organizationActions.getOrganizationInfo(orgId));
    }
  }, [dispatch, orgId, isAdmin]);

  useEffect(() => {
    if (isAuthenticated && userId) {
      dispatch(sessionActions.getUserOrgInfoAsync.request(userId));
    }
  }, [dispatch, isAuthenticated, userId]);

  const defaultPath = isAdmin ? ManagementPanelPath : ProfilePanelPath;

  return (
    <div className="h-full min-h-full flex flex-col text-colorText">
      <Routes>
        {ConsentRoutes}
        <Route
          path="/*"
          element={<PageLayout isAuthenticated={isAuthenticated} />}
        >
          <Route path={`${ProfilePanelPath}/*`} element={<ProfilePanel />} />
          <Route path={ManagementPanelPath} element={<ManagementPanel />} />
        </Route>
        <Route path="*?" element={<Navigate to={defaultPath} replace />} />
      </Routes>
    </div>
  );
}

const PageLayout: FC<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => (
  <>
    <Header />
    {isAuthenticated ? <Outlet /> : <Login />}
    <Footer />
    <Toaster />
  </>
);

export default App;
