import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { sessionActions } from "../../state/session";
import { Avatar } from "../avatar/avatar";
import logout from "../../assets/img/icons/logout.svg";
import caret from "../../assets/img/icons/caret.svg";
import { UserData } from "../../state/accounts/types";
import { Link } from "react-router-dom";
import { ManagementPanelPath, ProfilePanelPath } from "../../routes";
import { useI18n } from "../../helpers/hooks/useI18n";

type UserPanelProps = {
  user?: UserData | undefined;
};

export const UserPanel = ({ user }: UserPanelProps): JSX.Element => {
  const t = useI18n("USER_PANEL.");
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  const initiateLogout = (): void => {
    dispatch(sessionActions.logoutAsync.request());
  };

  useEffect(() => {
    if (user) {
      var name = "";
      if (user.firstName) {
        name += user.firstName + " ";
      }
      if (user.lastName) {
        name += user.lastName;
      }
      if (name === "") {
        name = user.email;
      }
      setUserName(name);
      setUserRole(user.department);
    } else {
      setUserName("");
      setUserRole("");
    }
  }, [user]);

  return (
    <div
      className="ml-4 flex-grow-0 border-l-2 border-colorTableHeadPortalBackground flex content-center justify-between relative"
      data-test-id="User panel"
    >
      <div className="ml-4 mr-4 flex flex-col flex-auto content-center justify-start self-center items-end">
        <span className="text-sm font-bold">{userName}</span>
        <span className="text-colorPrimary text-xs">{userRole}</span>
      </div>
      <Avatar
        user={user || ""}
        circleStyle={"h-12 w-12"}
        avatarDetailsPosition="top-0 right-0"
      />
      <img
        className="h-8 self-center"
        src={caret}
        onClick={() => setOpen(!isOpen)}
        alt="menu icon"
      />
      <ul
        className="absolute right-0 flex-col space-y-4 border-2 border-colorPrimary bg-colorTableBackground p-2 z-10"
        style={{ display: isOpen ? "flex" : "none", top: "110%" }}
      >
        <li>
          <Link to={ProfilePanelPath} onClick={() => setOpen(false)}>
            {t("MY_PROFILE")}
          </Link>
        </li>
        <li>
          <Link to={ManagementPanelPath} onClick={() => setOpen(false)}>
            {t("ORGANIZATION")}
          </Link>
        </li>
        <li
          className="flex justify-between cursor-pointer"
          onClick={initiateLogout}
        >
          <img className="h-8 self-center" src={logout} alt="logout icon" />
          <span className="self-center text-base">{t("LOGOUT")}</span>
        </li>
      </ul>
    </div>
  );
};
