import { LocalizingFunction } from "./hooks/useI18n";
import { GlobalAccess, Workspace } from "../api/types";
import {
  ShareOptionValue,
  ShareType,
  Permissions
} from "../components/shared/permissions/permissions.types";

export const sanitizeUrl = (string: string): string => {
  const stringUrl = string.trim();
  try {
    let url = new URL(stringUrl);
    if (url.protocol === "http:" || url.protocol === "https:") {
      // When string is a valid url - remove www. prefix from hostname
      return url.hostname.replace("www.", "");
    }
  } catch (_) {
    // When string is not a valid url - remove www. prefix from string
    return string.replace("www.", "");
  }
  return string;
};

export const calculatePermissions = (
  globalAccess: GlobalAccess
): ShareOptionValue => {
  switch (globalAccess) {
    case "WRITE":
      return ShareType.EDIT;
    case "READ":
      return ShareType.READ;
    case "NONE":
    default:
      return ShareType.NO_ACCESS;
  }
};

export function formatWorkspaceId(workspaceId: string) {
  let formattedId: string = "";
  for (let i = 1; i <= workspaceId.length; i++) {
    formattedId += workspaceId[i - 1];
    if (i % 3 === 0 && i !== workspaceId.length) {
      formattedId += " ";
    }
  }
  return formattedId;
}

export function sendEmail(
  workspaceName: string,
  shareLink: string,
  localizingFunction: LocalizingFunction,
  recipients?: string[]
) {
  workspaceName = sanitizeUrl(workspaceName);
  let mailTo = "";
  if (recipients) {
    recipients.forEach((recipient, index) => {
      mailTo += recipient;
      if (recipients.length - 1 !== index) {
        mailTo += ";";
      }
    });
  }
  const emailLink =
    `mailto:${mailTo}?subject=${localizingFunction(
      "WORKSPACE_SHARED_WITH_YOU"
    )} ${encodeURIComponent(workspaceName)}&` +
    `body=${localizingFunction(
      "WORKSPACE_SHARED_WITH_YOU"
    )}%0D${encodeURIComponent(
      workspaceName
    )}%0D${shareLink}%0D%0D${localizingFunction("LEARN_MORE")}`;
  window.open(emailLink, "_blank");
}

export function sortUsers(
  permissionLevelSets: Permissions
): { email: string; permission: ShareOptionValue }[] {
  return [
    ...permissionLevelSets.administrators.map(user => ({
      email: user,
      permission: ShareType.ADMIN
    })),
    ...permissionLevelSets.writers.map(user => ({
      email: user,
      permission: ShareType.EDIT
    })),
    ...permissionLevelSets.readers.map(user => ({
      email: user,
      permission: ShareType.READ
    })),
    ...permissionLevelSets.unspecifiedUsers.map(user => ({
      email: user,
      permission: ShareType.NO_ACCESS
    }))
  ].sort((userA, userB) => {
    if (userA.email < userB.email) {
      return -1;
    }
    if (userA.email > userB.email) {
      return 1;
    }
    return 0;
  });
}

export const saveToClipboard = (text: string | undefined): void => {
  if (!text || !navigator.clipboard) return;
  navigator.clipboard.writeText(text);
};

export const extractPermissions = (workspace: Workspace): Permissions => {
  return {
    administrators: workspace.administrators ?? [],
    writers: workspace.writers ?? [],
    readers: workspace.readers ?? [],
    unspecifiedUsers: workspace.unspecifiedUsers ?? [],
    globalAccess: workspace.globalAccess ?? "WRITE"
  };
};

export const filterExistingPermissions = (
  workspace: Workspace
): Permissions => {
  return {
    administrators: workspace.administrators ?? [],
    writers:
      workspace.writers?.filter(writer => {
        return !workspace.administrators.includes(writer);
      }) ?? [],
    readers:
      workspace.readers?.filter(reader => {
        return !(
          workspace.writers.includes(reader) ||
          workspace.administrators.includes(reader)
        );
      }) ?? [],
    unspecifiedUsers: workspace.unspecifiedUsers ?? [],
    globalAccess: workspace.globalAccess ?? "WRITE"
  };
};
