// Organization selectors
import { createSelector } from "reselect";
import isEmpty from "lodash/isEmpty";
import { RootState } from "typesafe-actions";
import {
  DepartmentsState,
  Organization,
  Policies,
  PoliciesState
} from "./types";
import { UserData } from "../accounts/types";

export const getCurrentUser = (state: RootState): UserData | undefined => {
  return state.organization.payload?.members?.find(
    member => member.uid === state.session.userId
  );
};

export const organization = (state: RootState): Organization => {
  return state.organization.payload || {};
};

export const isLoading = (state: RootState): boolean =>
  state.organization.loading || false;
export const error = (state: RootState): boolean | null =>
  state.organization.error || null;

export const loginType: (
  state: RootState
) => string = createSelector(organization, (org: Organization) =>
  isEmpty(org) ? "" : org.loginType
);

export const isAllowedADIntegration: (
  state: RootState
) => boolean = createSelector(
  organization,
  loginType,
  (org: Organization, loginType) =>
    org && org.isAllowedADIntegration && loginType !== "Okta"
);

export const agreementDate: (state: RootState) => number = createSelector(
  organization,
  (org: Organization) => org && org.agreementDate
);

export const members: (
  state: RootState
) => UserData[] = createSelector(organization, (org: Organization) =>
  isEmpty(org) || isEmpty(org.members) ? [] : org.members
);

export const admins: (
  state: RootState
) => string[] = createSelector(organization, (org: Organization) =>
  isEmpty(org) || isEmpty(org.admins) ? [] : org.admins
);

export const adminEmails: (
  state: RootState
) => string[] = createSelector(
  members,
  admins,
  (ms: UserData[], ad: string[]) =>
    ms && ad ? ms.filter(m => ad.indexOf(m.uid) !== -1).map(ad => ad.email) : []
);

export const uid: (state: RootState) => string = createSelector(
  organization,
  (org: Organization) => (isEmpty(org) ? "" : org.uid)
);

const _policies = (state: RootState): PoliciesState =>
  state.organization.policies || {};
const _policiesPayload = (state: RootState): Policies =>
  state.organization.policies.payload || {};

export const orgPolicyAllOrgAdminsGetAdminAccess = (
  state: RootState
): boolean => {
  return !!state.organization.policies.payload?.active?.find(
    policy => policy === "all_org_admins_get_admin_access"
  );
};

export const orgPolicyDisallowExternalRead = (state: RootState): boolean => {
  return !!state.organization.policies.payload?.active?.find(
    policy => policy === "disallow_external_read"
  );
};

export const orgPolicyDisallowExternalWrite = (state: RootState): boolean => {
  return !!state.organization.policies.payload?.active?.find(
    policy => policy === "disallow_external_write"
  );
};

export const policies = {
  loading: createSelector(_policies, (p: PoliciesState) => p.loading),

  error: createSelector(_policies, (p: PoliciesState) => p.error),

  fetched: createSelector(_policiesPayload, (pp: Policies) => !isEmpty(pp)),

  supported: createSelector(
    _policiesPayload,
    (pp: Policies) => pp.supported || {}
  ),

  active: createSelector(_policiesPayload, (pp: Policies) => pp.active || [])
};

const _departments = (state: RootState): DepartmentsState =>
  state.organization.departments || {};

export const departments = {
  names: createSelector(organization, (org: Organization) =>
    isEmpty(org) || isEmpty(org.departments) ? [] : org.departments
  ),

  loading: createSelector(_departments, (d: DepartmentsState) => d.loading),

  error: createSelector(_departments, (d: DepartmentsState) => d.error)
};
