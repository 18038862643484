import { Connection } from "./types";
import { RootState } from "typesafe-actions";

export const getConnections = (state: RootState): Connection[] => {
  return state.jira.payload ? state.jira.payload : [];
};

export const loading = (state: RootState): boolean => {
  return state.jira.loading ? state.jira.loading : false;
};

export const error = (state: RootState): boolean => {
  return state.jira.error ? state.jira.error : false;
};
