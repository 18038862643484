import React, {
  HTMLAttributes,
  InputHTMLAttributes,
  SelectHTMLAttributes,
  useState
} from "react";

export const EntryLabel = ({ children }) => {
  return <label className="text-xs self-center">{children}</label>;
};

export const EntryInput: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  className = "",
  ...props
}) => (
  <input
    className={
      "appearance-none leading-tight px-2 py-1 outline-none rounded focus:outline-none focus:ring-2 " +
      (props.readOnly
        ? ""
        : props.required && !props.value
        ? "bg-colorTableHeadPortalBackground border-2 border-red-500 focus:ring-red-500 "
        : "bg-colorTableHeadPortalBackground focus:ring-colorAccent ") +
      className
    }
    {...props}
  />
);

export const EntryMsg: React.FC<HTMLAttributes<HTMLSpanElement>> = ({
  children,
  className = "",
  ...props
}) => {
  return (
    <span className={`text-base text-center my-1 ${className}`} {...props}>
      {children}
    </span>
  );
};

export const EntrySelect: React.FC<SelectHTMLAttributes<HTMLSelectElement>> = ({
  children,
  placeholder,
  onChange,
  ...props
}) => {
  const [selected, setSelected] = useState(props.value);
  const tailwindStyle =
    "px-2 py-1 mr-3 overflow-hidden appearance-none leading-tight w-full h-full rounded absolute bg-transparent outline-none focus:outline-none focus:ring-2 focus:ring-colorAccent ";
  return (
    <div className="leading-tight w-full h-full bg-colorTableHeadPortalBackground rounded inline-block relative">
      {placeholder && (!selected || selected === "") && (
        <span className={`text-gray-400 ${tailwindStyle}`}>{placeholder}</span>
      )}
      <select
        className={tailwindStyle}
        {...props}
        onChange={e => {
          setSelected(e.target.value);
          onChange && onChange(e);
        }}
      >
        {children}
      </select>
    </div>
  );
};
