import { useEffect, useState } from "react";
import { ProductInfo } from "../../../../state/licenses/types";
import { Enterprise } from "./enterprise";
import { AddProject } from "./add.project";
import { StripeProduct } from "./stripe.product";
import { ResponsePromise } from "../../../../api/types";
import { useSelector } from "react-redux";
import { licensesSelectors } from "../../../../state/licenses";
import { featureFlagsSelectors } from "../../../../state/featureFlags";
import { FullPageTile } from "../../../tile";
import { Spinner } from "../../../spinner";
import { PlanTile } from "./plan.tile";
import { useI18n } from "../../../../helpers/hooks/useI18n";
import { IntegrationHeader } from "../../../integrations/integration-header";

export interface PlansProps {
  getProducts: () => ResponsePromise<ProductInfo[]>;
  getCheckoutSessionUrl: (priceId: string) => ResponsePromise<string>;
}

export const Plans: React.FC<PlansProps> = ({
  getProducts,
  getCheckoutSessionUrl
}) => {
  const t = useI18n("BILLING.PLANS.");

  const licenseInfo = useSelector(licensesSelectors.licenseInfo);
  const showStripePurchase = useSelector(
    featureFlagsSelectors.showStripePurchase
  );

  const isLicenseExpired = licenseInfo?.isExpired;
  const licenseType = licenseInfo?.type;
  const showProducts =
    (licenseType === "Free" || isLicenseExpired) && showStripePurchase;

  const [products, setProducts] = useState<ProductInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!showProducts) return;
    let isActive = true;
    setIsLoading(true);
    getProducts().then(({ data, error }) => {
      if (isActive) {
        setIsLoading(false);
        if (data) setProducts(data);
        if (error) console.warn(error);
      }
    });
    return () => {
      isActive = false;
    };
  }, [getProducts, showProducts]);

  return (
    <FullPageTile>
      <IntegrationHeader text={t("TITLE")} />
      <div className="flex flex-row flex-wrap justify-center gap-4">
        {isLoading && (
          <PlanTile>
            <Spinner />
          </PlanTile>
        )}
        {products.map(product => (
          <StripeProduct
            product={product}
            getCheckoutSessionUrl={getCheckoutSessionUrl}
            key={product.id}
          />
        ))}
        <AddProject />
        <Enterprise />
      </div>
    </FullPageTile>
  );
};
