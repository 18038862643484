import { createReducer } from "typesafe-actions";
import { Project, ProjectsState } from "./types";
import {
  getCollaboratorsAsync,
  getMyProjectsAsync,
  getAllOrgProjectsAsync,
  updateProjectAsync
} from "./actions";
import { PROJECT_ADMIN_ID } from "../../api/collaborators.roles.ids";

export const initialState: ProjectsState = {
  loadingProjects: false,
  errorProjects: false,
  projects: [] as Project[],
  collaborators: {},
  isProjectAdmin: false
};

export default createReducer(initialState)
  .handleAction(getAllOrgProjectsAsync.request, state => ({
    ...state,
    loadingProjects: true,
    errorProjects: false
  }))
  .handleAction(getAllOrgProjectsAsync.success, (state, action) => {
    if (action.payload) {
      return {
        ...state,
        loadingProjects: false,
        errorProjects: false,
        projects: action.payload,
        isProjectAdmin: true
      };
    } else {
      return state;
    }
  })
  .handleAction(getAllOrgProjectsAsync.failure, state => ({
    ...state,
    loadingProjects: false,
    errorProjects: true,
    isProjectAdmin: false
  }))
  .handleAction(getMyProjectsAsync.request, state => ({
    ...state,
    loadingProjects: true,
    errorProjects: false
  }))
  .handleAction(getMyProjectsAsync.success, (state, action) => {
    const isProjectAdmin = action.payload.some(
      project => project.userRole?.roleId === PROJECT_ADMIN_ID
    );
    if (action.payload && isProjectAdmin) {
      return {
        ...state,
        loadingProjects: false,
        errorProjects: false,
        projects: action.payload,
        isProjectAdmin
      };
    }
    return {
      ...state,
      loadingProjects: false
    };
  })
  .handleAction(getMyProjectsAsync.failure, state => {
    return {
      ...state,
      loadingProjects: false,
      errorProjects: true,
      isProjectAdmin: false
    };
  })
  .handleAction(getCollaboratorsAsync.success, (state, action) => ({
    ...state,
    collaborators: { ...state.collaborators, ...action.payload }
  }))
  .handleAction(updateProjectAsync.success, (state, action) => {
    const updatedProjectIndex = state.projects.findIndex(
      project => project.id === action.payload.id
    );

    const updatedProjects = [
      ...state.projects.slice(0, updatedProjectIndex),
      {
        // Update endpoint doesn't return information about user role, thus we need to keep user role from previous state.
        // This has a side effect - user can take away admin permissions from himself,
        // and UI will not be updated immediately (only after refresh).
        // It is safe to do this that way since user without permissions can't edit anything.
        ...state.projects[updatedProjectIndex],
        ...action.payload
      },
      ...state.projects.slice(updatedProjectIndex + 1)
    ];

    return {
      ...state,
      projects: updatedProjects
    };
  });
