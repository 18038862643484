import React, { useState } from "react";

type DropdownPickerProps = {
  label: string;
  children: DropdownPickerChildren;
};

type DropdownPickerChildren = {
  picked: JSX.Element;
  dropdown: JSX.Element;
};

export const DropdownPicker: React.FC<DropdownPickerProps> = ({
  label,
  children
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div
      data-test-id={"dropdown-picker"}
      className={
        "flex flex-col relative p-1 mb-1 w-9 cursor-pointer bg-white flex-wrap rounded justify-between"
      }
      tabIndex={0}
      onClick={() => setShowDropdown(!showDropdown)}
      onBlur={() => setShowDropdown(false)}
    >
      <div
        data-test-id={"dropdown-picker-label"}
        className={"text-xxs text-colorPrimaryLightTheme"}
        title={label}
      >
        {label}
      </div>
      <div className={"text-xs leading-none flex items-center gap-1"}>
        {children.picked}
        <div
          data-test-id={"dropdown-picker-indicator-icon"}
          className={"hicon down text-colorTextAndIconLightTheme"}
        ></div>
      </div>
      {showDropdown && children.dropdown}
    </div>
  );
};
