type SpinnerProps = {
  size?: number;
  classNames?: string;
  useSpinnerClass?: boolean;
};

export const Spinner = ({
  classNames = "",
  size = 16,
  useSpinnerClass = true
}: SpinnerProps): JSX.Element => (
  <div
    data-test-id="spinner"
    className={`${
      useSpinnerClass ? "spinner " : ""
    }w-${size} h-${size} ${classNames}`}
  >
    <div className="double-bounce1" />
    <div className="double-bounce2" />
  </div>
);
