import { Icon } from "../icon";
import { AppContext } from "../../../context";
import { useContext } from "react";

type ExpandCollapseIconProps = {
  isCollapsed: boolean;
};

export function ExpandCollapseIcon({ isCollapsed }: ExpandCollapseIconProps) {
  const context = useContext(AppContext);

  const collapseIcon: JSX.Element = (
    <Icon icon={"up"} title={context.Localized.string("SHARED.COLLAPSE")} />
  );
  const expandIcon: JSX.Element = (
    <Icon icon={"down"} title={context.Localized.string("SHARED.EXPAND")} />
  );

  return isCollapsed ? expandIcon : collapseIcon;
}
