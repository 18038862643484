import React, { ReactNode } from "react";
import { Icon } from "../../shared/icon";
import { useI18n } from "../../../helpers/hooks/useI18n";

type ModalHeaderProps = {
  closeModal?: () => void;
  modalTitle: string;
  mainIcon?: () => JSX.Element;
  headerTitleClicked?: () => void;
  children?: ReactNode;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  closeModal,
  modalTitle,
  mainIcon,
  headerTitleClicked,
  children
}) => {
  const t = useI18n("");
  return (
    <div className="w-full inline-flex justify-end mb-2">
      <div className="flex flex-row w-full justify-between">
        <div className="flex flex-row">
          {mainIcon ? mainIcon() : <></>}
          <div
            className={`text-colorTextAndIconLightTheme font-bold ${
              headerTitleClicked ? "cursor-pointer" : ""
            }`}
            onClick={headerTitleClicked}
          >
            {modalTitle}
          </div>
        </div>
        {children}
      </div>
      {closeModal && (
        <Icon icon={"windowclose"} onClick={closeModal} title={t("CLOSE")} />
      )}
    </div>
  );
};
