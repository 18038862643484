import React, { useContext, useState } from "react";
import { AppContext, ManagementPortalContext } from "../../context";
import { DropdownPicker } from "../dropdown.picker";
import { randomPick } from "../../helpers/random.pick";

export const DEFAULT_DROPDOWN_PICKER_ICONS = [
  "star",
  "bolt",
  "flag",
  "emoji",
  "heart",
  "construction",
  "construction2",
  "vacation",
  "launch",
  "location",
  "send",
  "shipswheel",
  "library",
  "bistribute",
  "medal",
  "recolor",
  "measuretool",
  "pathtool",
  "rulertool",
  "binoculars",
  "board",
  "retain",
  "harddisk",
  "cloud",
  "key",
  "cash",
  "robot",
  "moustache",
  "cake",
  "lab"
];

export type DropdownIconPickerProps = {
  onIconPick: (icon: string) => void;
  icons?: string[];
  initial?: string; // hoylu icon class (hicon part not needed); e.g. "robot"
};

/**
 * DropdownIconPicker - use it to pick an icon from given palette.
 * - icons { string[] } - array of icons from hoylu-ui-icons (no need to include "hicon" here),
 * - onIconPick { (color: string) => void } - a callback which will be called on icon click.
 * */
export const DropdownIconPicker: React.FC<DropdownIconPickerProps> = ({
  onIconPick,
  initial,
  icons = DEFAULT_DROPDOWN_PICKER_ICONS
}) => {
  const context: ManagementPortalContext = useContext(AppContext);
  const [currentIcon, setCurrentIcon] = useState(initial ?? randomPick(icons));
  return (
    <DropdownPicker label={context.Localized.string("ICON")}>
      {{
        picked: <PreviewIconEntry icon={currentIcon} />,
        dropdown: (
          <IconsDropdown
            onIconPick={(icon: string) => {
              setCurrentIcon(icon);
              onIconPick(icon);
            }}
            icons={icons}
          />
        )
      }}
    </DropdownPicker>
  );
};

type IconDropdownProps = {
  icons: string[];
  onIconPick: (icon: string) => void;
};

/**
 * IconsDropdown - this will generate dropdown with given icons as a grid of 4 icons per row.
 * - colors { string[] } - array of icons,
 * - onIconPick { (color: string) => void } - a callback which will be called on icon click,
 * */
export const IconsDropdown: React.FC<IconDropdownProps> = ({
  icons,
  onIconPick
}) => {
  return (
    <div
      data-test-id={"dropdown-icon-picker-dropdown"}
      className={
        "absolute grid grid-cols-5 bg-white left-0 top-full mt-1 rounded w-max overflow-hidden"
      }
    >
      {icons.map(icon => {
        return <IconEntry key={icon} icon={icon} onIconPick={onIconPick} />;
      })}
    </div>
  );
};

type IconEntryProps = {
  icon: string;
  onIconPick?: (icon: string) => void;
};

/**
 * IconEntry - a cell of the IconsDropdown.
 * - icon { string } - icon name from hoylu-ui-icons (no need to include "hicon" here),
 * - onIconPick { (color: string) => void } - a callback which will be called on icon click.
 * */
export const IconEntry: React.FC<IconEntryProps> = ({ icon, onIconPick }) => {
  const iconClassName = `leading-none p-1 text-colorTextAndIconLightTheme hover:bg-colorTableHeadPortalBackground hicon ${icon}`;
  return (
    <div
      data-test-id={icon}
      className={iconClassName}
      onClick={() => onIconPick && onIconPick(icon)}
    ></div>
  );
};

type PreviewIconEntryProps = {
  icon: string;
};

const PreviewIconEntry: React.FC<PreviewIconEntryProps> = ({ icon }) => {
  return (
    <div
      data-test-id={"dropdown-picker-icon-preview"}
      className={`hicon ${icon} text-colorTextAndIconLightTheme`}
    ></div>
  );
};
