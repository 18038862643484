// Groups actions
import * as types from "./types";
import { Group } from "./types";
import { createAction, createAsyncAction } from "typesafe-actions";

export function updateGroupLicense(groupId: string, enable: boolean) {
  return updateGroupLicenseAsync.request({ groupId, enable });
}

export function getGroups() {
  return getGroupsAsync.request();
}

export const updateStartsWithQuery = createAction(
  types.UPDATE_GROUP_STARTS_WITH_QUERY
)<string>();

export const updateGroupLicenseAsync = createAsyncAction(
  types.UPDATE_GROUP_LICENSE_LOADING,
  types.UPDATE_GROUP_LICENSE_SUCCESS,
  types.UPDATE_GROUP_LICENSE_ERROR
)<
  { groupId: string; enable: boolean },
  { groupId: string; isActive: boolean },
  string
>();

export const getGroupsAsync = createAsyncAction(
  types.GET_GROUPS_LOADING,
  types.GET_GROUPS_SUCCESS,
  types.GET_GROUPS_ERROR
)<undefined, { nextPageKey: string; groups: Group[] }, string>();
